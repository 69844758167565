import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { eventApi } from 'api';
import { Dashboard } from 'components/dashboard';
import { Spacer } from 'components/Spacer';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { DashboardData } from 'model/Dashboard';
import { EventStatus } from 'model/event/Event';
import { EmergencySort } from 'model/event/EventVictim';
import { DashboardSearch } from 'model/event/SearchEvent';
import Facility from 'model/facilities/Facility';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { facilityStore } from 'stores/facility/facility.store';
import {
  FacilityDashboardFilters,
  FacilityDashboardFiltersType,
} from './facility.dashboard.filters.component';
import { dashboardDataToRowItems } from './facility.dashboard.utilites';
import { eventStore } from 'stores/event';
import { HeaderKey } from 'components/dashboard/dashboard.component';
import { useTranslation } from 'react-i18next';

type EventDashboardPageLocalStore = {
  selectedFacility?: Facility;
  dashboardData?: DashboardData;
  filters: FacilityDashboardFiltersType;
};

export const FacilityDashboardPage = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { facilityId } = useParams<{ facilityId: string }>();

  const localStore = useLocalStore<EventDashboardPageLocalStore>(() => ({
    filters: {
      active: true,
    },
  }));

  useEffect(() => {
    facilityStore.getFacility(parseInt(facilityId, 10)).then((facility) => {
      localStore.selectedFacility = facility?.data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  useEffect(() => {
    const { selectedFacility } = localStore;
    if (!selectedFacility) {
      return;
    }

    const search: DashboardSearch = {
      eventDateStart: localStore.filters.dateStart,
      eventDateEnd: localStore.filters.dateEnd,
      eventStatus: localStore.filters.active ? [EventStatus.ACTIVE] : undefined,
      facilitiesIds: [selectedFacility.Id],
      groupBy: 'EVENTS',
    };
    eventApi.getDashboard(search).then((data) => {
      localStore.dashboardData = data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStore.selectedFacility, localStore.filters]);

  const handleFiltersChange = (value: FacilityDashboardFiltersType) => {
    runInAction(() => {
      localStore.filters = value;
    });
  };

  const handleEventRowClick = async (eventUuidd: string) => {
    const event = await eventStore.getEvent(eventUuidd);
    if (event) {
      history.push('/evenements/consultation', {
        event,
      });
    } else {
      console.error(`unable to get event with uuid =${eventUuidd}`);
    }
  };
  const goBack = () => {
    history.goBack();
  };

  const { data, total } = useMemo(() => {
    const { dashboardData } = localStore;
    if (!dashboardData) {
      return { data: [], total: [] };
    }
    return dashboardDataToRowItems(dashboardData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStore.dashboardData]);

  return (
    <Grid container>
      <Grid container item>
        <Button onClick={goBack} color="primary" variant="outlined" startIcon={<ArrowBackIcon />}>
          Retour
        </Button>
        <Spacer direction="horizontal" size="1rem" />
        <Typography variant="h4" component="h1">
          {`Tableau de bord de "${localStore.selectedFacility?.name}"`}
        </Typography>
      </Grid>
      <Spacer direction="vertical" size="1rem" />
      <Grid item xs={12}>
        <FacilityDashboardFilters
          filters={localStore.filters}
          total={localStore.dashboardData?.total || 0}
          setFilters={handleFiltersChange}
        />
      </Grid>
      <Spacer direction="vertical" size="1rem" />
      {data.length === 0 && <Typography variant="subtitle1">Aucun événement</Typography>}
      {data.length !== 0 && localStore.dashboardData?.totalDetails && (
        <Dashboard
          headerNames={Object.values<HeaderKey>(EmergencySort)
            .concat('total')
            .map((es) => t(`dashboards.facilities.columns.${es}`))}
          data={data}
          total={total}
          onClickRow={handleEventRowClick}
        />
      )}
    </Grid>
  );
});
