import { ResponseType, responseTypeDeserializer, responseTypeSerializer } from 'model/ResponseType';
import { custom, identifier, reference, serializable } from 'serializr';
import { resolveNurseGroupById } from 'stores/algo/nurse/nurseAlgo.resolvers';
import { NurseAlgoGroup } from './Group';
import { CommonAlgoQuestion } from '../CommonAlgoQuestion';

export class NurseAlgoQuestion implements CommonAlgoQuestion {
  @serializable(identifier())
  private id?: number;

  @serializable
  description?: string;

  @serializable
  label?: string;

  orderValue?: number;

  @serializable(reference(NurseAlgoGroup, resolveNurseGroupById as any)) // Super le typing de serializr :(
  parentGroup?: NurseAlgoGroup;

  @serializable
  responseMandatory?: boolean;

  @serializable
  responseMultiple?: boolean;

  @serializable(custom(responseTypeDeserializer, responseTypeSerializer))
  responseType?: ResponseType;

  @serializable
  title?: string;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
