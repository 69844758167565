import EventVictim from 'model/event/EventVictim';
import { VictimAge } from 'model/Victim';
import { locationStore } from 'stores/location.store';

export function mapFormDataToVictim(eventVictim: EventVictim, victimForm: any): EventVictim {
  const eventVictimData: EventVictim = {
    ...eventVictim,
    emergencySort: victimForm.emergencySort.value,
    facility: victimForm.facility.value,
    stayedThere: victimForm.stayedThere.value,
    arrivedToFacility: victimForm.arrivedToFacility.value,
    description: victimForm.description.value,
    medicalDiagnostic: victimForm.medicalDiagnostic.value,
    victim: {
      ...eventVictim.victim,
      uuid: eventVictim?.victim?.uuid || victimForm.uuid.value,
      code: victimForm.code.value,
      lastName: victimForm.lastName.value,
      firstName: victimForm.firstName.value,
      sex: victimForm.sex.value,
      ageRange: victimForm.ageRange.value,
      age: victimForm.age.value,
      address: victimForm.address.value,
      addressDepartment: locationStore.getDepartmentFromId(victimForm.addressDepartment.value),
    },
  };
  return eventVictimData;
}

export function formatVictimDataForUpdate(eventVictim: EventVictim): unknown {
  const victimData = {
    ...eventVictim,
    facilityId: eventVictim.facility?.id,
    victim: {
      ...eventVictim.victim,
      addressDepartment: eventVictim.victim?.addressDepartment?.id,
    },
  };
  return victimData;
}

export function copyObjectProperty<T, K extends keyof T>(source: T, value: T[K], property: K) {
  source[property] = value;
}

export function convertAgeToAgeSlice(age: number | undefined): VictimAge {
  if (!age) {
    return VictimAge.NOT_SPECIFIED;
  }
  if (age <= 5 && age >= 0) {
    return VictimAge.FROM_0_TO_5_YEARS;
  } else if (age <= 15) {
    return VictimAge.FROM_6_TO_15_YEARS;
  } else if (age <= 25) {
    return VictimAge.FROM_16_TO_25_YEARS;
  } else if (age <= 35) {
    return VictimAge.FROM_26_TO_35_YEARS;
  } else if (age <= 49) {
    return VictimAge.FROM_36_TO_49_YEARS;
  } else if (age <= 65) {
    return VictimAge.FROM_50_65_YEARS;
  } else if (age > 65) {
    return VictimAge.FROM_66;
  } else {
    return VictimAge.NOT_SPECIFIED;
  }
}
