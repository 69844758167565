import { serializable, identifier, raw } from 'serializr';
import { AlgoCallReason } from '../AlgoCallReason';
import { CommonAlgoGroup } from '../CommonAlgoGroup';

export class NurseAlgoGroup implements CommonAlgoGroup {
  @serializable(identifier())
  private id?: number;

  @serializable
  initial?: boolean;

  @serializable
  name?: string;

  @serializable
  priority?: number;

  @serializable(raw())
  callReason?: AlgoCallReason;

  @serializable
  selectCallReason?: boolean;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
