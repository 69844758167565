import { get, set, clear, del } from 'idb-keyval';
import { autorun, reaction } from 'mobx';
import { serialize } from 'serializr';
import { appStatusStore } from 'stores/appStatus.store';
import { authStore } from 'stores/auth/auth.store';
import { SideEffectFn } from './Persistable.model';

function reactOnUserChanges(sideEffectFn: SideEffectFn) {
  autorun(() => {
    if (authStore.authenticatedUser) {
      sideEffectFn();
    }
  });
}
function reactOnNetworkChange(sideEffectFn: SideEffectFn, delay = 1000) {
  reaction(
    () => appStatusStore.isConnected,
    () => {
      if (appStatusStore.isConnected) {
        sideEffectFn();
      }
    },
    { delay }
  );
}

const MARK_AS_UNSYNCHRONIZED = false;

export interface SynchronizableData<T> {
  isSynchronized: boolean;
  markedForDeletion?: boolean;
  lastSynchronizedOn?: Date;
  storedAt?: Date;
  data: T;
}

export type IDBRecord<T> = Record<string, T>;

async function offlineGet<T>(key: string): Promise<SynchronizableData<T>> {
  return get<SynchronizableData<T>>(key);
}

async function offlineGetRecord<T>(key: string) {
  let record = await get<IDBRecord<SynchronizableData<T>> | undefined>(key);
  if (!record) {
    record = {};
  }
  return record;
}

function wrapIntoSynchronizableData<T>(
  data: T,
  date: Date,
  isSynchronized: boolean,
  cstor: new () => T,
  withSerialization: boolean
): SynchronizableData<T> {
  return {
    storedAt: date,
    isSynchronized,
    lastSynchronizedOn: date,
    data: withSerialization ? serialize(cstor, data) : data,
  };
}

async function onlineSetRecords<T>(
  key: string,
  cstor: new () => T,
  data: IDBRecord<T>,
  isSynchronized = true,
  withSerialization = false
): Promise<IDBRecord<SynchronizableData<T>>> {
  const now = new Date();

  const wrappers = Object.entries(data).reduce<IDBRecord<SynchronizableData<T>>>(
    (records, [uuid, dataItem]) => {
      records[uuid] = wrapIntoSynchronizableData(
        dataItem,
        now,
        isSynchronized,
        cstor,
        withSerialization
      );
      return records;
    },
    {}
  );
  set(key, wrappers);
  return wrappers;
}

/** Store an object that has been previously synchronized (online mode) */
async function onlineSetRecordItem<T>(
  key: string,
  recordKey: string,
  synchronizableData: SynchronizableData<T>
): Promise<SynchronizableData<T>> {
  const record = await offlineGetRecord<T>(key);
  const now = new Date();
  synchronizableData.isSynchronized = true;
  synchronizableData.lastSynchronizedOn = now;
  synchronizableData.storedAt = now;

  record[recordKey] = synchronizableData;
  await set(key, record);
  return synchronizableData;
}

/** Store an object that is not synchronized (offline mode) */
async function setUnsynchronizedRecordItem<T>(
  key: string,
  recordKey: string,
  synchronizableData: SynchronizableData<T>
): Promise<SynchronizableData<T>> {
  const record = await offlineGetRecord<T>(key);
  const now = new Date();
  synchronizableData.isSynchronized = MARK_AS_UNSYNCHRONIZED;
  synchronizableData.storedAt = now;
  record[recordKey] = synchronizableData;
  await set(key, record);
  return synchronizableData;
}

async function offlineSetRecordItem<T>(
  key: string,
  cstor: new () => T,
  recordKey: string,
  data: T,
  isSynchronized = true,
  withSerialization = false
): Promise<SynchronizableData<T>> {
  const synchronizableData = wrapIntoSynchronizableData(
    data,
    new Date(),
    isSynchronized,
    cstor,
    withSerialization
  );
  const record = await offlineGetRecord<T>(key);
  record[recordKey] = synchronizableData;
  await set(key, record);
  return synchronizableData;
}

async function offlineSet<T>(
  key: string,
  cstor: new () => T,
  data: T | T[],
  isSynchronized = true,
  withSerialization = false
): Promise<SynchronizableData<T | T[]>> {
  const now = new Date();
  const wrapper = wrapIntoSynchronizableData(data, now, isSynchronized, cstor, withSerialization);
  await set(key, wrapper);
  return wrapper;
}

async function offlineDeleteRecordItem<T>(
  key: string,
  recordKey: string,
  synchronizableData: SynchronizableData<T>
): Promise<SynchronizableData<T>> {
  const record = await offlineGetRecord<T>(key);
  const now = new Date();
  synchronizableData.isSynchronized = MARK_AS_UNSYNCHRONIZED;
  synchronizableData.storedAt = now;

  // Mark for deletion and store it
  synchronizableData.markedForDeletion = true;
  record[recordKey] = synchronizableData;
  await set(key, record);
  return synchronizableData;
}

async function onlineDeleteRecordItem<T>(
  key: string,
  recordKey: string,
  synchronizableData: SynchronizableData<T>
): Promise<SynchronizableData<T>> {
  const record = await offlineGetRecord<T>(key);
  const now = new Date();
  synchronizableData.isSynchronized = true;
  synchronizableData.lastSynchronizedOn = now;
  synchronizableData.storedAt = now;
  synchronizableData.markedForDeletion = true;
  try {
    //Send the deletion command
    await del(key);
  } catch (error) {
    console.error(error);
    // If could not delete, store it, for further deletion
    record[recordKey] = synchronizableData;
    await set(key, record);
  }
  return synchronizableData;
}

async function clearOfflineStorage(): Promise<void> {
  return clear();
}

export const storableStoreHelpers = {
  reactOnUserChanges,
  reactOnNetworkChange,
  offlineGet,
  offlineGetRecord,
  offlineSet,
  offlineSetRecordItem,
  offlineDeleteRecordItem,
  clearOfflineStorage,
  onlineSetRecords,
  onlineSetRecordItem,
  onlineDeleteRecordItem,
  setUnsynchronizedRecordItem,
};
