import { serializable, primitive, date, object, identifier } from 'serializr';
import { SynchronizableData } from 'stores/Persistable';
import Department from './Department';

export default class District {
  @serializable(identifier())
  id?: number;
  @serializable(object(Department))
  department?: Department;
  @serializable(primitive())
  name?: string;
}

export class DistrictSynchronizableData implements SynchronizableData<District> {
  constructor(isSynchronized: boolean, data: District) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(District))
  data: District;
}
