import { Button, Grid, Paper, PaperProps, Theme, Typography, withStyles } from '@material-ui/core';
import { Departments } from 'constants/index';
import Call, { CallSynchronizableData, CallType } from 'model/Call';
import { NeedConfirmError } from 'model/Errors';
import { NurseAlgoDispatcher } from 'pages/calls/common/NurseAlgo/NurseAlgoDispatcher';
import { SearchFacilityFilterProps } from 'pages/facilities/facilities.list.model';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { nurseAlgoStore } from 'stores/algo/nurse';
import { nurseCallStore } from 'stores/call/nurseCall.store';
import { NotificationType, uiStore } from 'stores/ui.store';
import { FORM_MODE } from 'utils/forms';
import { victimDetailsStore } from './victim.details.store';

const VictimAlgorithmPage: React.FC = () => {
  const history = useHistory<{
    mode: FORM_MODE;
  }>();
  const goToFacilitiesHistory = useHistory<{
    filters: Partial<SearchFacilityFilterProps>;
    mode: FORM_MODE;
  }>();
  const [call, setCall] = React.useState<Call>();

  const handleCancel = () => {
    history.replace(
      `/evenements/victimes/${
        history.location.state.mode === FORM_MODE.CREATE ? 'nouveau' : 'consultation'
      }`,
      history.location.state
    );
  };

  React.useEffect(() => {
    const eventVictim = victimDetailsStore.victim;
    const call = new Call();
    call.callType = CallType.EMERGENCY;
    call.callerIsVictim = true;
    if (eventVictim && eventVictim.victim) {
      call.victimAge = eventVictim.victim.age;
      call.victimAgeRange = eventVictim.victim.ageRange;
      call.victimCode = eventVictim.victim.code;
      call.victimSex = eventVictim.victim.sex;
      call.victimFirstName = eventVictim.victim.firstName;
      call.victimLastName = eventVictim.victim.lastName;
      call.locationDepartment = eventVictim.victim.addressDepartment?.ihsiCode as Departments;
    }
    setCall(call);
    //Reset algo results when leaving page
    return () => {
      try {
        nurseAlgoStore.resetAlgo();
      } catch (ignored) {
        console.error(ignored);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToFacilities = async () => {
    const algoRunResult = nurseAlgoStore.algoRunResult;
    if (!algoRunResult || !call) {
      uiStore.pushNotification('Veuillez déterminer le diagnostique', NotificationType.WARNING);
      return;
    }

    const nurseAlgoDiagnosticId = algoRunResult.diagnostic.Id;
    const synchronizableCall = new CallSynchronizableData(true, call);
    try {
      nurseCallStore.setPendingCall({
        synchronizableCall: { ...synchronizableCall, data: { ...call, nurseAlgoDiagnosticId } },
        algoRunResult,
      });

      const filters: Partial<SearchFacilityFilterProps> = {
        selectedEquipmentIds:
          algoRunResult.diagnostic.content?.orientation?.equipments.map(
            (equipment) => equipment.Id
          ) || [],
        selectedSpecialtyIds:
          algoRunResult.diagnostic.content?.orientation?.services.map((service) => service.Id) ||
          [],
        selectedDepartmentIshicode: call.locationDepartment,
      };

      goToFacilitiesHistory.replace('/evenements/victimes/regulation/etablissements', {
        filters,
        ...history.location.state,
      });
    } catch (error) {
      if (error instanceof NeedConfirmError) {
        return;
      }
      uiStore.pushNotification("Echec de l'enregistrement de l'appel", NotificationType.ERROR);
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={6} container>
        <Typography variant="h4" component="h1">
          Régulation d'une victime
        </Typography>
        <InfoBloc>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12} container alignItems="center" spacing={1}>
              {call && (
                <NurseAlgoDispatcher
                  nurseAlgoStore={nurseAlgoStore}
                  call={call}
                  formCallType={call.callType}
                  algoAvailable={true}
                />
              )}
            </Grid>
          </Grid>
        </InfoBloc>
        <Grid item xs={12} container justify="space-between">
          <Button variant="contained" size="large" onClick={handleCancel}>
            Annuler
          </Button>
          <Button variant="contained" size="large" color="primary" onClick={goToFacilities}>
            Sélection établissement
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const InfoBloc = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
}))((props: PaperProps) => (
  <Paper classes={props.classes} elevation={0}>
    {props.children}
  </Paper>
));

export default VictimAlgorithmPage;
