import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { UIWithResponsesQuestion, CommonAlgoResponse } from 'model/algo';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { QuestionContainer } from '../QuestionContainer';

interface QuestionSingleChoiceProps {
  selected: CommonAlgoResponse | null;
  question: UIWithResponsesQuestion;
  withoutLabel?: boolean;
  onResponse: (choice: CommonAlgoResponse) => void;
  onClickPrevious?: () => void;
}

interface QuestionMultiChoiceProps {
  selected: CommonAlgoResponse[] | null;
  question: UIWithResponsesQuestion;
  withoutLabel?: boolean;
  onResponse: (choice: CommonAlgoResponse[]) => void;
}
interface QuestionChoiceProps {
  selected: CommonAlgoResponse[] | null;
  question: UIWithResponsesQuestion;
  withoutLabel?: boolean;
  onResponse: (choice: CommonAlgoResponse[] | CommonAlgoResponse) => void;
}

const QuestionSingleChoice: FC<QuestionSingleChoiceProps> = ({
  question,
  selected,
  withoutLabel,
  onResponse,
  onClickPrevious,
}) => {
  return (
    <QuestionContainer
      renderLabel={!withoutLabel ? question.label : undefined}
      description={question.description}
    >
      <FormControl>
        <RadioGroup value={selected?.Id}>
          {question.responses.map((response, index) => (
            <FormControlLabel
              key={index}
              value={response.Id}
              control={<Radio color="primary" />}
              label={response.label}
              onChange={() => onResponse(response)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </QuestionContainer>
  );
};

const QuestionMultiChoices: FC<QuestionMultiChoiceProps> = ({
  selected,
  question,
  withoutLabel,
  onResponse,
}) => {
  const [checkedResponses, setCheckedResponses] = useState<CommonAlgoResponse[]>([]);

  useEffect(() => {
    const initiallySelectedResponses = question.responses.filter((resp) =>
      selected?.includes(resp)
    );
    setCheckedResponses(initiallySelectedResponses);
  }, [selected, question]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    response: CommonAlgoResponse,
    checked: boolean
  ) => {
    event.preventDefault();
    if (!checked && checkedResponses.includes(response)) {
      const newResponses = checkedResponses.filter(
        (existingResponse) => existingResponse.Id !== response.Id
      );
      setCheckedResponses(newResponses);
    } else if (checked && !checkedResponses.includes(response)) {
      const newResponses = response.excludeOthers
        ? [response]
        : [...checkedResponses.filter((resp) => !resp.excludeOthers), response];
      setCheckedResponses(newResponses);
    }
  };

  const handleSendResponse = () => {
    onResponse(checkedResponses);
  };

  return (
    <QuestionContainer
      renderLabel={!withoutLabel ? question.label : undefined}
      description={question.description}
    >
      <FormControl fullWidth={true}>
        {question.responses.map((response, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                color="primary"
                checked={checkedResponses.includes(response)}
                onChange={(event, checked) => handleChange(event, response, checked)}
                name={`${response.Id}`}
              />
            }
            label={response.label}
          />
        ))}
      </FormControl>

      <Button
        onClick={handleSendResponse}
        color="primary"
        variant="contained"
        disabled={checkedResponses.length === 0}
      >
        Valider
      </Button>
    </QuestionContainer>
  );
};

export const QuestionChoice: FC<QuestionChoiceProps> = ({
  question,
  selected,
  withoutLabel,
  onResponse,
}) => {
  return (
    <>
      {question.responseMultiple && (
        <QuestionMultiChoices
          question={question}
          selected={selected}
          withoutLabel={withoutLabel}
          onResponse={onResponse}
        />
      )}
      {!question.responseMultiple && (
        <QuestionSingleChoice
          question={question}
          selected={selected?.[0] || null}
          withoutLabel={withoutLabel}
          onResponse={onResponse}
        />
      )}
    </>
  );
};
