import { appStatusStore } from 'stores/appStatus.store';
import { IDBRecord, ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { locationApi } from '../api';
import Department, { DepartmentSynchronizableData } from 'model/location/Department';
import City, { CitySynchronizableData } from 'model/location/City';

function departmentsArrayToRecord(departments: Department[]): IDBRecord<Department> {
  return departments.reduce<IDBRecord<Department>>((record, department) => {
    if (department.id) {
      record[department.id] = department;
    }
    return record;
  }, {});
}

function citiesArrayToRecord(cities: City[]): IDBRecord<City> {
  return cities.reduce<IDBRecord<City>>((record, city) => {
    if (city.id) {
      record[city.id] = city;
    }
    return record;
  }, {});
}

export interface LocationResourceMethods {
  getDepartments: () => Promise<DepartmentSynchronizableData[]>;
  getCities: () => Promise<CitySynchronizableData[]>;
}

const DEPARTMENTS_COLLECTION_KEY = 'departments:list';
const CITIES_COLLECTION_KEY = 'cities:list';

export const locationResourceProvider: ResourceProvider<LocationResourceMethods> = {
  onlineResourceProvider: {
    getDepartments: async () => {
      const response = await locationApi.getDepartments();
      const records = departmentsArrayToRecord(response.content);

      const storedDepartments: IDBRecord<DepartmentSynchronizableData> = await storableStoreHelpers.onlineSetRecords(
        DEPARTMENTS_COLLECTION_KEY,
        Department,
        records
      );

      return Object.values(storedDepartments);
    },
    getCities: async () => {
      const response = await locationApi.getCities();
      const records = citiesArrayToRecord(response.content);

      const storedCities: IDBRecord<CitySynchronizableData> = await storableStoreHelpers.onlineSetRecords(
        CITIES_COLLECTION_KEY,
        City,
        records
      );
      return Object.values(storedCities);
    },
  },
  offlineResourceProvider: {
    getDepartments: async () => {
      const storedDepartments = await storableStoreHelpers.offlineGet(DEPARTMENTS_COLLECTION_KEY);
      return Object.values(storedDepartments);
    },
    getCities: async () => {
      const storedCities = await storableStoreHelpers.offlineGet(CITIES_COLLECTION_KEY);
      return Object.values(storedCities);
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
