import { action, decorate, observable, runInAction, toJS } from 'mobx';
import { PersistableStore, registerStorableStore, storableStoreHelpers } from 'stores/Persistable';
import { permissionResourceProvider } from '../../resourceProvider/permission.provider';
import { Profile, Permission_V15 } from 'model/User';

export default class PermissionsStore implements PersistableStore {
  /** profiles: Record<Profile, Record<'defaultPermissions' | 'availablePermissions', Permission_V15[]>> */
  profiles: Record<string, Record<string, string[]>> = {};
  /** aggregatedPermissions: Record<profile, Record<module, Record<permission, isDefault>>> */
  aggregatedPermissions: Record<string, Record<string, Record<string, boolean>>> = {};
  profileDefaultPermissions: Record<string, string[]> = {};
  lastSynchronization: Date | undefined;
  dataLoaded = false;

  init() {
    storableStoreHelpers.reactOnUserChanges(() => this.loadPermissions());
    storableStoreHelpers.reactOnNetworkChange(() => this.loadPermissions());
  }

  async loadPermissions() {
    await this.getProfiles();
    await this.buildPermissions();
    runInAction(() => {
      this.setLastSynchronization();
      this.setDataLoaded(true);
    });
  }

  async getProfiles() {
    const profiles = await permissionResourceProvider.get().getProfiles();
    this.profiles = profiles;
  }

  /** Aggregates permissions data as required by front components (just  to make it logical and usable) */
  async buildPermissions() {
    Object.entries(this.profiles).forEach(
      ([profile, permissions]: [string, Record<string, string[]>]) => {
        const availablePermissions: string[] = permissions['availablePermissions'];
        const defaultPermissions: string[] = permissions['defaultPermissions'];
        this.profileDefaultPermissions[profile] = defaultPermissions;
        availablePermissions
          .filter((permission) => Object.keys(Permission_V15).includes(permission))
          .forEach((permission) => {
            const currentModule: string = permission.split('_')[0];
            if (!this.aggregatedPermissions[profile]) {
              this.aggregatedPermissions[profile] = {};
            }
            const profilePermissions: Record<string, Record<string, boolean>> = this
              .aggregatedPermissions[profile];
            if (!profilePermissions[currentModule]) {
              profilePermissions[currentModule] = {};
            }
            profilePermissions[currentModule][permission] = defaultPermissions.includes(permission);
          });
      }
    );
  }
  getProfileDefaultPermissions(profile: Profile | string): string[] {
    return toJS(this.profileDefaultPermissions[profile]);
  }

  setLastSynchronization(date: Date = new Date()) {
    this.lastSynchronization = date;
  }

  setDataLoaded(isLoaded: boolean) {
    this.dataLoaded = isLoaded;
  }
}

decorate(PermissionsStore, {
  profiles: observable,
  aggregatedPermissions: observable,
  lastSynchronization: observable,
  dataLoaded: observable,
  getProfiles: action,
  setDataLoaded: action,
  setLastSynchronization: action,
});

export const permissionsStore = new PermissionsStore();

registerStorableStore(permissionsStore);
