import axios from 'axios';
import { IPageResponse } from 'model/IPageResponse';
import { Profile, Permission_V15 } from 'model/User';
import { Module } from 'constants/index';

export async function getModules(): Promise<IPageResponse<Record<Module, Permission_V15[]>>> {
  return axios
    .get('/api/users/permissions/modules')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getProfiles(): Promise<
  IPageResponse<Record<Profile, Record<string, Permission_V15[]>>>
> {
  return axios
    .get('/api/users/permissions/profiles')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getPermissions(): Promise<IPageResponse<Permission_V15>> {
  return axios
    .get('/api/users/permissions')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
