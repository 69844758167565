import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  Paper,
  PaperProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaterialDatePicker from 'components/common/form/MaterialDatePicker';
import eventFormBuilder from './event.form.builder';
import Event, { EventSynchronizableData } from 'model/event/Event';
import { getInputFieldProps } from 'utils/forms';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { locationStore } from 'stores/location.store';
import { eventStore } from 'stores/event';
import { uiStore } from 'stores/ui.store';
import { mapFormDataToEvent } from 'utils/event.utilities';
import { authStore } from 'stores/auth/auth.store';
import { Profile } from 'model/User';

const EventCreatePage: React.FC = () => {
  const history = useHistory<{ event?: EventSynchronizableData }>();
  const departments = locationStore.departmentsArray;
  const synchronizableEvent = history.location.state?.event;
  const [eventForm] = React.useState(
    eventFormBuilder({
      initialValues: synchronizableEvent?.data,
    })
  );

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleSubmit = () => {
    eventForm.validate();
    if (eventForm.isValid) {
      if (!synchronizableEvent) {
        throw new Error('No event in history!');
      }
      const eventData: Event = mapFormDataToEvent(synchronizableEvent, eventForm);
      eventStore.saveEvent({ ...synchronizableEvent, data: eventData }).then(() => {
        uiStore.pushSuccessNotification('Évènement créé avec succès');
        history.push('/evenements');
      });
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Déclaration d'un évènement
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InfoBloc>
            <Grid item xs={12}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Titre"
                    required
                    fullWidth
                    maxLength={50}
                    {...getInputFieldProps(eventForm.title)}
                  />
                )}
              </Observer>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={4}>
                <Observer>
                  {() => (
                    <MaterialSelectInput
                      required
                      fullWidth
                      size="small"
                      label={'Département'}
                      {...getInputFieldProps(eventForm.locationDepartment)}
                    >
                      {departments.map(({ id, name }, index) => (
                        <MenuItem key={index} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </MaterialSelectInput>
                  )}
                </Observer>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Détail localisation"
                    fullWidth
                    maxLength={100}
                    {...getInputFieldProps(eventForm.locationAdditionalInfos)}
                  />
                )}
              </Observer>
            </Grid>

            <Grid item xs={12}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Description (CAN)"
                    fullWidth
                    multiline={true}
                    rows={2}
                    rowsMax={4}
                    maxLength={250}
                    {...getInputFieldProps(eventForm.descriptionCAN)}
                    disabled={
                      !authStore.hasProfile(Profile.INFIRMIER) &&
                      !authStore.hasProfile(Profile.PERMANENCIER)
                    }
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Description (ambulancier) "
                    fullWidth
                    multiline={true}
                    rows={2}
                    rowsMax={4}
                    maxLength={250}
                    {...getInputFieldProps(eventForm.descriptionParamedic)}
                    disabled={!authStore.hasProfile(Profile.PARAMEDIC)}
                  />
                )}
              </Observer>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <MaterialDatePicker
                      required
                      disableFuture
                      label="Date"
                      views={['month', 'date']}
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  )}
                </Observer>
              </Grid>
            </MuiPickersUtilsProvider>
          </InfoBloc>
        </Grid>
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <Button variant="contained" size="large" onClick={history.goBack}>
            Annuler
          </Button>
          <Button variant="contained" size="large" color="primary" onClick={() => handleSubmit()}>
            Valider la fiche
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const InfoBloc = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
}))((props: PaperProps) => (
  <Paper classes={props.classes} elevation={0}>
    {props.children}
  </Paper>
));

export default EventCreatePage;
