import { custom, identifier, reference, serializable, list, primitive } from 'serializr';
import { resolveNurseGroupById } from 'stores/algo/nurse/nurseAlgo.resolvers';
import {
  AlgoCombinaison,
  algoCombinaisonDeserializer,
  algoCombinaisonSerializer,
  ScoreInclusion,
  scoreInclusionDeserializer,
  scoreInclusionSerializer,
} from '../AlgoCombinaison';
import { WithScoreMatchCombinaison } from '../MatchCombinaison';
import { NurseAlgoGroup } from './Group';

export class NurseAlgoRoute implements WithScoreMatchCombinaison {
  @serializable(identifier())
  private id?: number;

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable(list(primitive()))
  responses?: number[];

  @serializable(reference(NurseAlgoGroup, resolveNurseGroupById as any)) // Super le typing de serializr :(
  fromGroup?: NurseAlgoGroup;

  @serializable(reference(NurseAlgoGroup, resolveNurseGroupById as any)) // Super le typing de serializr :(
  toGroup?: NurseAlgoGroup;

  @serializable(reference(NurseAlgoGroup, resolveNurseGroupById as any)) // Super le typing de serializr :(
  toSubGroup?: NurseAlgoGroup;

  @serializable
  scoreMax?: number;

  @serializable
  scoreMin?: number;

  @serializable(custom(scoreInclusionDeserializer, scoreInclusionSerializer))
  scoreInclusion?: ScoreInclusion;

  @serializable
  toCallReason?: number;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
