import { Profile } from 'model/User';
import * as React from 'react';
import { authStore } from 'stores/auth/auth.store';
import CallPreRegulationNursePage from './call.preRegulation.nurse.page';
import CallPreRegulationPermanentPage from './call.preRegulation.permanent.page';

const CallPreRegulationRouter = (props: any) => {
  return authStore.hasProfile(Profile.INFIRMIER) ? (
    <CallPreRegulationNursePage {...props} />
  ) : (
    <CallPreRegulationPermanentPage {...props} />
  );
};

export default CallPreRegulationRouter;
