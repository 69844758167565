import { ResponseType, responseTypeDeserializer, responseTypeSerializer } from 'model/ResponseType';
import { custom, identifier, reference, serializable } from 'serializr';
import { resolvePermanentGroupById } from 'stores/algo/permanent/permanentAlgo.resolvers';
import { CommonAlgoQuestion } from '../CommonAlgoQuestion';
import { PermanentAlgoGroup } from './Group';

export class PermanentQuestion implements CommonAlgoQuestion {
  @serializable(identifier())
  private id?: number;

  @serializable(custom(responseTypeDeserializer, responseTypeSerializer))
  responseType?: ResponseType;

  @serializable(reference(PermanentAlgoGroup, resolvePermanentGroupById as any)) // Super le typing de serializr :(
  parentGroup?: PermanentAlgoGroup;

  @serializable
  description?: string;

  @serializable
  label?: string;

  @serializable
  orderValue?: number;

  @serializable
  responseMultiple?: boolean;

  @serializable
  responseMandatory?: boolean;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
