import {
  CommonAlgoGroup,
  CommonAlgoQuestion,
  CommonAlgoResponse,
  UIPendingGroup,
  UIQuestionsGroup,
  UIVerticalModeSubGroupContext,
  UIWithResponsesQuestion,
  WithScoreAlgoResponse,
  WithScoreAlgoRoute,
} from 'model/algo';
import { responsesMatchCombinaison } from './CommonAlgo.utilities';
import { GroupsToDisplay } from './GroupsToDisplay';

export interface UIGroup<T extends CommonAlgoGroup> {
  group: T;
  index: number;
  isSubGroup: boolean;
}

export type SetUserResponseType = (
  groupId: number,
  userResponse: CommonAlgoResponse | CommonAlgoResponse[]
) => void;

export type GoToPreviousQuestionGroupType = () => void;

/**
 * Function to check if a group have been responded by user
 */
export type IsFulfilledGroupFunctionType = (
  groupId: number,
  subGroupInclusion: SubGroupInclusion
) => boolean;
/**
 * User responses storage structure: groupId => questionId => Response[]
 */
export type UserResponsesType<R extends CommonAlgoResponse> = Map<number, Map<number, R[]>>;

export interface CommonAlgoStore {
  resetAlgo: () => void;
  currentQuestionsGroup: UIQuestionsGroup | undefined;
  setUserResponse: SetUserResponseType;
  goToPreviousQuestionGroup: GoToPreviousQuestionGroupType;
  verticalModeSubGroupContext: UIVerticalModeSubGroupContext | undefined;
}

export type SubGroupInclusion = { includeSubGroups: boolean };

export type NextQuestionsGroups = {
  parents: CommonAlgoGroup[];
  subs: CommonAlgoGroup[];
};
export const CommonAlgo = {
  /**
   * get the next QuestionsGroups matching routes with current user responses
   * @param groupId current groupId
   * @param routes All algo routes
   * @param userResponses All existing user responses array
   */
  getNextQuestionsGroups(
    groupId: number,
    routes: WithScoreAlgoRoute[],
    userResponses: WithScoreAlgoResponse[]
  ): NextQuestionsGroups {
    const nextGroups: { parents: CommonAlgoGroup[]; subs: CommonAlgoGroup[] } = {
      parents: [],
      subs: [],
    };

    for (const route of routes) {
      if (route.fromGroup?.Id !== groupId) {
        continue;
      }
      if (responsesMatchCombinaison(route, userResponses)) {
        if (route.toSubGroup) {
          nextGroups.subs.push(route.toSubGroup);
        }
        if (route.toGroup) {
          nextGroups.parents.push(route.toGroup);
        }
      }
    }
    return nextGroups;
  },

  /**
   * Get group or subGroup to be displayed to user in actual GroupsToDisplay depending if user already respond to some groups in GroupsToDisplay
   * @param groupsToDisplay Actual parent/sub groups to be responded by user depending on routes matching
   * @param isFulfilledGroup Function to check if a group have been responded by user
   * @param includeSubGroups (boolean) Need check in sub groups
   */
  getGroupToDisplay<T extends CommonAlgoGroup>(
    groupsToDisplay: GroupsToDisplay<T>,
    isFulfilledGroup: IsFulfilledGroupFunctionType,
    { includeSubGroups }: SubGroupInclusion
  ): UIGroup<T> | undefined {
    const groups = groupsToDisplay.groupsValues;

    const notFilledGroup = groups.find(
      (group) => !isFulfilledGroup(group.Id, { includeSubGroups: true })
    );

    if (!notFilledGroup) {
      return undefined;
    }
    // Inspection des sous groupes
    if (includeSubGroups) {
      const notFilledSubGroup = notFilledGroup.subGroups.find(
        (group) => !isFulfilledGroup(group.Id, { includeSubGroups: false })
      );
      if (notFilledSubGroup) {
        return {
          group: notFilledSubGroup,
          index: groups.indexOf(notFilledGroup),
          isSubGroup: true,
        };
      }
    }
    return {
      group: notFilledGroup,
      index: groups.indexOf(notFilledGroup),
      isSubGroup: false,
    };
  },

  /**
   *
   * @param userResponses All existing user responses (groupId -> questionId -> response[])
   * @param groupId Group id user respond to
   * @param userResponse New user responses (single or multi)
   */
  setInUserResponses<R extends CommonAlgoResponse>(
    userResponses: UserResponsesType<R>,
    groupId: number,
    userResponse: R | R[]
  ) {
    const newResponses = Array.isArray(userResponse) ? userResponse : [userResponse];
    const currentGroupResponses = userResponses.get(groupId) || new Map<number, R[]>();
    newResponses.forEach((newResponse) => {
      const questionId = newResponse.questionId;
      const existing = currentGroupResponses.get(questionId) || [];
      currentGroupResponses.set(questionId, [...existing, newResponse]);
    });
    userResponses.set(groupId, currentGroupResponses);
  },

  /**
   * Return all user responses for a group
   * @param groupId
   * @param userResponses All existing user responses (groupId -> questionId -> response[])
   */
  getForGroupIdUserResponses<R extends CommonAlgoResponse>(
    groupId: number,
    userResponses: UserResponsesType<R>
  ): CommonAlgoResponse[] {
    const groupResponses = userResponses.get(groupId);
    if (!groupResponses) {
      return [];
    }
    return Array.from(groupResponses.values()).flat();
  },

  /**
   * Remove all user responses (if exist) for a group
   * @param groups
   * @param userResponses All existing user responses (groupId -> questionId -> response[])
   * @returns true if userResponses are mutated
   */
  removeAlreadyExistingResponse<R extends CommonAlgoResponse>(
    groups: CommonAlgoGroup[],
    userResponses: UserResponsesType<R>
  ): boolean {
    let mutation = false;
    for (const group of groups) {
      mutation = mutation || userResponses.delete(group.Id);
    }
    return mutation;
  },

  /**
   * Return an array of group titles and group completion in current GroupsToDisplay.
   * If there is multi components should display a stepper
   * @param groupsToDisplay Actual parent/sub groups to be responded by user depending on routes matching
   * @param isFulfilledGroup Function to check if a group have been responded by user
   */
  getPendingGroupNames<T extends CommonAlgoGroup>(
    groupsToDisplay: GroupsToDisplay<T>,
    isFulfilledGroup: IsFulfilledGroupFunctionType
  ): UIPendingGroup[] {
    const groups = groupsToDisplay.groupsValues;
    const firstNotFilledGroup = CommonAlgo.getGroupToDisplay(groupsToDisplay, isFulfilledGroup, {
      includeSubGroups: false,
    });
    const activeIndex = firstNotFilledGroup?.index || 0;
    return (
      groups.map(({ name }, index) => ({
        name,
        answered: index < activeIndex,
        active: activeIndex === index,
      })) || []
    );
  },
  /**
   * Return all UIWithResponsesQuestion for a group
   * @param groupId
   * @param allAlgoQuestions
   * @param allAlgoResponses
   */
  getUIWithResponsesQuestionForGroup<Q extends CommonAlgoQuestion, R extends CommonAlgoResponse>(
    groupId: number,
    questions: Q[],
    responses: R[]
  ): UIWithResponsesQuestion[] {
    const withResponsesQuestions: UIWithResponsesQuestion[] = [];
    const questionsForGroup = questions.filter((question) => question.parentGroup?.Id === groupId);
    for (let question of questionsForGroup) {
      const forQuestionResponses = responses.filter(
        (response) => response.questionId === question.Id
      );

      withResponsesQuestions.push({
        ...question,
        Id: question.Id,
        responses: forQuestionResponses,
      });
    }

    return withResponsesQuestions;
  },

  /**
   * Return a specific context for displaying simultaneous all groups in a vertical mode
   * @param groupsToDisplay Actual parent/sub groups to be responded by user depending on routes matching
   * @param isFulfilledGroup  Function to check if a group have been responded by user
   * @param userResponses All existing user responses (groupId -> questionId -> response[])
   * @param allAlgoQuestions
   * @param allAlgoResponses
   */
  getVerticalModeSubGroupContext<
    T extends CommonAlgoGroup,
    Q extends CommonAlgoQuestion,
    R extends CommonAlgoResponse
  >(
    groupsToDisplay: GroupsToDisplay<T>,
    isFulfilledGroup: IsFulfilledGroupFunctionType,
    userResponses: UserResponsesType<R>,
    allAlgoQuestions: Q[],
    allAlgoResponses: R[]
  ): UIVerticalModeSubGroupContext | undefined {
    const group = groupsToDisplay.groupsValues.find((group) => group.subGroups.length > 0);
    const subgroup = group?.subGroups.find(
      (subGroup) => !isFulfilledGroup(subGroup.Id, { includeSubGroups: false })
    );
    if (!group || !subgroup) {
      return undefined;
    }
    const parentGroupResponses = CommonAlgo.getForGroupIdUserResponses(group.Id, userResponses);
    return {
      parentGroup: group,
      parentGroupResponseLabel: parentGroupResponses[0]?.label,
      subGroup: {
        groupId: subgroup.Id,
        groupName: subgroup.name,
        questions: CommonAlgo.getUIWithResponsesQuestionForGroup(
          subgroup.Id,
          allAlgoQuestions,
          allAlgoResponses
        ),
        isSubGroup: true,
      },
    };
  },
};
