import React from 'react';
import { appStatusStore } from 'stores/appStatus.store';

const ConnectionStatusListener: React.FC = () => {
  React.useEffect(() => {
    // Registering connection status event listeners
    function updateConnectionStatus(): void {
      appStatusStore.setConnectionStatus(navigator.onLine);
    }
    window.addEventListener('offline', updateConnectionStatus, false);
    window.addEventListener('online', updateConnectionStatus, false);
    return () => {
      window.removeEventListener('offline', updateConnectionStatus);
      window.removeEventListener('online', updateConnectionStatus);
    };
  }, []);
  return <></>;
};

export default ConnectionStatusListener;
