import { CallMotif } from 'model/Call';
import React, { FC, useState } from 'react';
import { Button, MenuItem, Typography } from '@material-ui/core';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { useTranslation } from 'react-i18next';

interface CallMotifContainerProps {
  onSelectMotif: (motif: CallMotif) => void;
}

const CallMotifContainer: FC<CallMotifContainerProps> = ({ onSelectMotif }) => {
  const { t } = useTranslation();
  const [selectedMotif, setSelectedMotif] = useState<CallMotif | undefined>();

  const handleMotifSelection = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMotif(evt.target.value as CallMotif);
  };

  const handleClickSubmit = () => {
    if (selectedMotif) {
      onSelectMotif(selectedMotif);
    }
  };

  return (
    <AlgoContainer
      renderTitle={
        <Typography variant="h4" color="textSecondary">
          Renseigner la raison de l'appel
        </Typography>
      }
    >
      <MaterialSelectInput
        required={true}
        fullWidth
        size="medium"
        label={"Motil de l'appel"}
        onChange={handleMotifSelection}
      >
        {Object.keys(CallMotif)
          .filter((key) => key !== CallMotif.NOT_SPECIFIED)
          .map((key) => (
            <MenuItem key={key} value={key}>
              {t(`calls.motif.${key}`)}
            </MenuItem>
          ))}
      </MaterialSelectInput>
      <Button
        onClick={handleClickSubmit}
        disabled={!selectedMotif}
        color="primary"
        variant="contained"
      >
        Valider le motif
      </Button>
    </AlgoContainer>
  );
};

export { CallMotifContainer };
