import { Paper, PaperProps, Theme, withStyles } from '@material-ui/core';
import React from 'react';

export const InfoBloc = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
}))((props: PaperProps) => (
  <Paper classes={props.classes} elevation={0}>
    {props.children}
  </Paper>
));

interface DefaultStyleProp {
  classes: any;
  children: any;
}

export const InfoSubBloc = withStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
}))((props: DefaultStyleProp) => <div className={props.classes.root}>{props.children}</div>);
