import { action, observable, runInAction } from 'mobx';
import { NurseAlgoDiagnostic } from 'model/algo/nurse';
import { CallSynchronizableData, CallType, RegulationMeans } from 'model/Call';
import Facility from 'model/facilities/Facility';
import { FacilityAnswer } from 'model/VictimRegulation';
import { SearchFacilityFilterProps } from 'pages/facilities/facilities.list.model';
import { callResourceProvider } from 'resourceProvider/call.provider';
import { deserialize } from 'serializr';
import { NurseAlgoDataStore, nurseAlgoDataStore } from 'stores/algo/nurse/NurseAlgoData.store';
import { facilityStore } from 'stores/facility';
import { WithFacilityStoreDeserializationContextArgs } from 'stores/facility/facility.resolvers';
import { noopCallback } from 'stores/serialize.context';
import callFormBuilder from '../common/call.form.builder';

export class CallPostRegulationUiStore {
  @observable
  private _synchronizableCall?: CallSynchronizableData;

  @observable
  private _selectedFacility?: Facility;

  @observable
  private _selectedDiagnostic?: NurseAlgoDiagnostic;

  @observable
  private _selectedFacilityAnswer?: FacilityAnswer;

  @observable
  private _selectedRegulationMeans?: RegulationMeans;

  @observable
  callForm: any;

  @observable
  filters?: Partial<SearchFacilityFilterProps>;

  @observable
  loading: boolean = true;

  @observable
  facilityDialogOpen: boolean = false;

  /**
   * Load call if none is already loaded
   */
  async loadCallIfNeccesary(callUuid: string): Promise<void> {
    if (!this._synchronizableCall) {
      this.loadCall(callUuid);
    }
  }

  @action
  async loadCall(callUuid: string): Promise<CallSynchronizableData | undefined> {
    this.loading = true;
    this.initialize();
    const contextArgs: WithFacilityStoreDeserializationContextArgs<NurseAlgoDataStore> = {
      store: nurseAlgoDataStore,
      facilityStore,
    };

    const call = await callResourceProvider.get().getCall(callUuid);
    runInAction(() => {
      this._synchronizableCall = deserialize(
        CallSynchronizableData,
        call,
        noopCallback,
        contextArgs
      );
      this._selectedDiagnostic = this._synchronizableCall.data.diagnostic;
      this._selectedFacilityAnswer = this._synchronizableCall.data.facilityAnswer;
      this._selectedRegulationMeans = this._synchronizableCall.data.regulationMeans;
      this.callForm = callFormBuilder({ initialValues: this._synchronizableCall.data });
      this.loading = false;
    });
    return this._synchronizableCall;
  }

  @action
  initialize() {
    this.callForm = callFormBuilder({});
    this.loading = true;
    this._synchronizableCall = undefined;
    this._selectedDiagnostic = undefined;
    this._selectedFacility = undefined;
    this.facilityDialogOpen = false;
  }

  clear() {
    this.initialize();
  }

  get selectedFacility(): Facility | undefined {
    if (!this._synchronizableCall) {
      throw new Error(
        'Invalid State - A call should have been loaded. Please call loadCall function.'
      );
    }
    if (!this._synchronizableCall?.data.facility) {
      return undefined;
    }

    if (this._selectedFacility) {
      return this._selectedFacility;
    }

    return facilityStore.getFacilityById(this._synchronizableCall.data.facility?.Id);
  }

  get synchronizableCall() {
    if (this.loading) {
      return undefined;
    }
    if (!this.loading && !this._synchronizableCall) {
      throw new Error(
        'Invalid State - A call should have been loaded. Please call loadCall function.'
      );
    }
    return this._synchronizableCall;
  }

  get selectedDiagnostic(): NurseAlgoDiagnostic | undefined {
    if (this.loading) {
      throw new Error('Invalid State - Do not call while loading data');
    }
    if (!this.loading && !this._synchronizableCall) {
      throw new Error(
        'Invalid State - A call should have been loaded. Please call loadCall function.'
      );
    }
    if (!this.loading && !this._synchronizableCall?.data.diagnostic) {
      if (this._synchronizableCall?.data.callType === CallType.EMERGENCY) {
        throw new Error(
          'Invalid Data - An emergency regulated call must have a selected diagnostic.'
        );
      }
      return undefined;
    }

    if (this._selectedDiagnostic) {
      return this._selectedDiagnostic;
    }
    const diagnosticId = this._synchronizableCall?.data.diagnostic?.Id || 0;
    const diagnostic = nurseAlgoDataStore.getDiagnosticById(diagnosticId);
    if (!diagnostic) {
      throw new Error('Invalid Data - No diagnostic found');
    }
    return diagnostic;
  }

  get selectedFacilityAnswer(): FacilityAnswer | undefined {
    return this._selectedFacilityAnswer;
  }

  get selectedRegulationMeans(): RegulationMeans | undefined {
    return this._selectedRegulationMeans;
  }

  @action
  setNewSelectedDiagnostic(diagnostic: NurseAlgoDiagnostic) {
    this._selectedDiagnostic = diagnostic;
  }

  @action
  setNewSelectedFacility(facilityId: number) {
    this._selectedFacility = facilityStore.getFacilityById(facilityId);
  }

  @action
  setNewSelectedFacilityAnswer(facilityAnswer: FacilityAnswer) {
    this._selectedFacilityAnswer = facilityAnswer;
  }

  @action
  setNewSelectedRegulationMeans(regulationMeans: RegulationMeans) {
    this._selectedRegulationMeans = regulationMeans;
  }

  constructor() {
    this.initialize();
  }
}

export const callPostRegulationUiStore = new CallPostRegulationUiStore();
