import { custom, identifier, list, primitive, raw, reference, serializable } from 'serializr';
import { resolveAdviceById } from 'stores/algo/permanent/permanentAlgo.resolvers';
import {
  AlgoCombinaison,
  algoCombinaisonDeserializer,
  algoCombinaisonSerializer,
} from '../AlgoCombinaison';
import { MatchCombinaison } from '../MatchCombinaison';

export interface AdviceDocument {
  id: number;
  contentType?: string;
  filename?: string;
  name?: string;
}

export class PermanentAlgoAdvice {
  @serializable(identifier())
  id?: number;

  @serializable
  value?: string;

  @serializable(raw())
  document?: AdviceDocument;
}

export class PermanentAlgoResultAdvices implements MatchCombinaison {
  @serializable(identifier())
  id?: number;

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable(list(primitive()))
  responses?: number[];

  @serializable(reference(PermanentAlgoAdvice, resolveAdviceById as any)) // Super le typing de serializr :(
  advice?: PermanentAlgoAdvice;
}
