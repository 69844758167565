import axios, { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { Defer } from 'utils/Defer';

class RefreshLock {
  defer?: Defer<any>;
  private requestId: string | null = null;

  lock(uuid: string): boolean {
    if (this.status === 'PENDING') {
      console.warn('Attempt to lock an already lock RefreshLock');
      return false;
    }

    this.requestId = uuid;
    this.defer = new Defer<any>();
    return true;
  }

  unlock(uuid: string): boolean {
    if (this.requestId === uuid) {
      setTimeout(() => {
        // Utilisation d'un "setTimeout" afin de s'assurer que le passage du next tick de l'event loop
        this.defer?.resolve();
        this.requestId = null;
      });
      return true;
    }
    return false;
  }

  get pending() {
    return this.defer ? this.defer.promise : Promise.resolve();
  }

  get status() {
    return this.defer?.status;
  }
}
export const refreshLock = new RefreshLock();

const _authenticationClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
_authenticationClient.interceptors.request.use((config: any) => config);

export async function authenticate(username: string, password: string): Promise<AxiosResponse> {
  try {
    return await _authenticationClient.post('/api/authentication/authenticate', {
      username,
      password,
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function logout(token: string) {
  return await _authenticationClient.delete(`/api/authentication/logout/${token}`);
}

export async function refreshToken(token: string): Promise<string> {
  const refreshRequestId = uuid();
  try {
    const lockSuccess = refreshLock.lock(refreshRequestId);
    if (!lockSuccess) {
      return Promise.reject('Unable to acquire lock');
    }
    const response = await _authenticationClient.get(`/api/authentication/refresh/${token}`);
    const authorizationHeader = response.headers.authorization;
    if (response.status === 200 && authorizationHeader.length) {
      return authorizationHeader;
    }
    console.warn('Token validation endpoint call did not return 200');
    return Promise.reject('Invalid token');
  } catch (err) {
    return Promise.reject(err);
  } finally {
    refreshLock.unlock(refreshRequestId);
  }
}

/**
 * A successful request would receive a response with status 200.
 *
 * @export
 * @param {string} username
 * @returns
 */
export async function resetPasswordRequest(username: string) {
  try {
    return await _authenticationClient.get(`/api/authentication/resetPassword/${username}`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function resetPasswordSubmit(username: string, token: string) {
  try {
    return await _authenticationClient.put(`/api/authentication/resetPassword/${username}`, {
      validationKey: token,
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
