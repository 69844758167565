import { AlgoBodySelection } from 'model/algo';

export type PermanencierHumanBodyZone = AlgoBodySelection;

export type HumanFace = 'front' | 'back';

export const PERMANENCIER_HUMAN_BODY_ZONES: ReadonlyArray<PermanencierHumanBodyZone> = [
  'ABDOMEN',
  'MEMBERS',
  'HEAD',
  'FACE',
  'CHEST',
  'PELVIS',
  'SPINE',
];

export function isPermanencierHumanBodyZone(value: string): value is PermanencierHumanBodyZone {
  return PERMANENCIER_HUMAN_BODY_ZONES.includes(value as any);
}
