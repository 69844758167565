import User from 'model/User';

export const prepareSubmitData = (userForm: any): User => {
  const submitData: User = {
    profile: userForm.profile.value,
    firstName: userForm.firstName.value,
    lastName: userForm.lastName.value,
    email: userForm.email.value,
    phone: userForm.phone.value,
    enabled: userForm.enabled.value,
    permissions: userForm.permissions.value,
    facilities: userForm.facilities.value,
  };

  return submitData;
};
