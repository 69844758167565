import { CommonAlgoGroup } from 'model/algo';
import { observable, computed } from 'mobx';

export type WithSubGroupsAlgoGroup<T extends CommonAlgoGroup> = T & {
  subGroups: T[];
};

export class GroupsToDisplay<T extends CommonAlgoGroup> {
  @observable private groups = new Map<number, WithSubGroupsAlgoGroup<T>>();

  @computed
  get groupsValues() {
    return Array.from(this.groups.values());
  }

  getGroupById(groupId: number) {
    return this.groups.get(groupId);
  }
  addGroup(group: T) {
    this.groups.set(group.Id, { ...group, Id: group.Id, subGroups: [] });
  }
  setGroups(groups: T[]) {
    this.groups.clear();
    groups.forEach((group) => {
      this.addGroup(group);
    });
  }

  addSubGroups(parentGroupId: number, groups: T[]) {
    if (groups.length === 0) {
      return;
    }
    const parentGroup = this.groups.get(parentGroupId);
    if (!parentGroup) {
      throw new Error(`Can't add sub-groups to undefined group with id "${parentGroupId}" `);
    }
    parentGroup.subGroups = [...parentGroup.subGroups, ...groups];
  }
}
