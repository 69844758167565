import Chip from '@material-ui/core/Chip';
import { CallStatus } from 'model/Call';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface StatusChipProps {
  status: CallStatus;
}

export default function StatusChip(props: StatusChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      color={props.status === CallStatus.TO_REGULATE ? 'secondary' : 'primary'}
      style={{ textTransform: 'uppercase', fontWeight: 700 }}
      label={t(`forms.call.status.${props.status}`)}
    />
  );
}
