import DateFnsUtils from '@date-io/date-fns';
import { Button, createStyles, Grid, makeStyles, Paper, Tab, Tabs, Theme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { FC, FormEvent, useState } from 'react';
import { exportsService } from 'services';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { StatisticsTablePage } from 'pages/statistics/statistics.table.page';
import StatisticsFiguresPage from 'pages/statistics/statistics.figures.page';
import 'vendors/chart.js-plugin-labels';

export const ReportsPage: FC = () => {
  const [dateStart, setDateStart] = useState<string | null>(null);
  const [dateEnd, setDateEnd] = useState<string | null>(null);
  const [tabId, setTabId] = useState<number>(0);
  const history = useHistory();

  const classes = useStyles();

  function handleTabChange(evt: any, value: number) {
    setTabId(value);
  }

  async function handleSubmit(dateStart: any, dateEnd: any) {
    setDateStart(dateStart);
    setDateEnd(dateEnd);
    if (tabId === 0) {
      exportsService.saveCallExportAs({
        userCreationDateStart: dateStart || undefined,
        userCreationDateEnd: dateEnd || undefined,
      });
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Tabs
          value={tabId}
          onChange={handleTabChange}
          indicatorColor="primary"
          className={classes.hideOnPrint}
        >
          <Tab label="Export appels" />
          <Tab label="Statistiques appels" />
          <Tab label="Graphiques appels" />
        </Tabs>
      </Grid>
      <Grid item xs={12} className={classes.hideOnPrint}>
        <SearchForm
          onSubmit={handleSubmit}
          label={tabId === 0 ? 'Extraire les données' : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        {tabId === 1 && <StatisticsTablePage dateStart={dateStart} dateEnd={dateEnd} />}
        {tabId === 2 && <StatisticsFiguresPage dateStart={dateStart} dateEnd={dateEnd} />}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={history.goBack}
          className={classes.hideOnPrint}
        >
          Retour
        </Button>
      </Grid>
    </Grid>
  );
};

const SearchForm: FC<{
  label?: string;
  onSubmit: any;
}> = ({ onSubmit, label = 'Afficher les données' }) => {
  const [dateStart, setDateStart] = useState<string | null>(null);
  const [dateEnd, setDateEnd] = useState<string | null>(null);
  const [dateStartError, setDateStartError] = useState<string | undefined>();
  const [dateEndError, setDateEndError] = useState<string | undefined>();

  function handleSubmit(event?: FormEvent) {
    event?.preventDefault();
    onSubmit(dateStart, dateEnd);
  }
  function handleDateStartChange(date: MaterialUiPickersDate | null) {
    let dateError;
    let strDate;
    if (date) {
      strDate = date.toString();
      try {
        strDate = date.toISOString();
      } catch (e) {
        dateError = 'Date invalide'; // i18nized
      }
    } else {
      strDate = null;
    }
    setDateStart(strDate);
    setDateStartError(dateError);
  }

  function handleDateEndChange(date: MaterialUiPickersDate | null) {
    let dateError;
    let strDate;

    if (date) {
      strDate = date.toString();
      try {
        strDate = date.toISOString();
      } catch (e) {
        dateError = 'Date invalide'; // i18nized
      }
    } else {
      strDate = null;
    }
    setDateEnd(strDate);
    setDateEndError(dateError);
  }

  return (
    <Paper>
      <form onSubmit={handleSubmit}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="center" spacing={4}>
            <Grid item>
              <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-start"
                label="Date de début"
                value={dateStart}
                onChange={handleDateStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date start',
                }}
                error={!!dateStartError}
                helperText={dateStartError}
                autoOk
                disableFuture
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-end"
                label="Date de fin"
                value={dateEnd}
                onChange={handleDateEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date start',
                }}
                error={!!dateEndError}
                helperText={dateEndError}
                autoOk
                disableFuture
              />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={4}>
            <Grid item>
              <Button color="primary" variant="contained" type="submit">
                {label}
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hideOnPrint: {
      '@media print': {
        display: 'none',
      },
    },
  })
);
