import * as React from 'react';
import {
  FormControl,
  makeStyles,
  createStyles,
  Theme,
  FormHelperText,
  BaseTextFieldProps,
  OutlinedInputProps,
  OutlinedInput,
  Typography,
  Tooltip,
} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';

interface MaterialInputProps extends BaseTextFieldProps {
  classes?: any;
  onBlur?: OutlinedInputProps['onBlur'];
  onChange?: OutlinedInputProps['onChange'];
  onFocus?: OutlinedInputProps['onFocus'];
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  InputProps?: Partial<OutlinedInputProps>;
  inputProps?: OutlinedInputProps['inputProps'];
  editing?: boolean;
  maxLength?: number;
}
const MaterialInput: React.FC<MaterialInputProps> = ({
  id,
  label = '',
  placeholder,
  fullWidth = false,
  error = false,
  disabled = false,
  type = 'text',
  required = false,
  value = undefined,
  onChange,
  onBlur,
  helperText,
  name,
  autoComplete,
  autoFocus = false,
  inputProps,
  size,
  editing = true,
  ...rest
}) => {
  const classes = useStyles();
  const displayValue = (value: any) => value || 'N/R';
  return (
    <FormControl
      className={classes.formControl}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
      required={required}
      size={size}
    >
      <FormLabel className={classes.label} disabled={disabled} required={required && editing}>
        {label}
      </FormLabel>
      {!editing && (
        <Tooltip title={label || ''} placement="top-start">
          <Typography>{displayValue(value)}</Typography>
        </Tooltip>
      )}
      {editing && (
        <>
          <OutlinedInput
            name={name}
            className={classes.input}
            id={id}
            labelWidth={0}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            inputProps={{
              ...inputProps,
              onBlur: onBlur,
              maxLength: rest.maxLength,
            }}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            rows={rest.rows}
            rowsMax={rest.rowsMax}
            multiline={rest.multiline}
            onKeyDown={rest.onKeyDown}
          />
          <FormHelperText className={classes.helperText} variant="outlined">
            {(!!error && helperText) || ' '}
          </FormHelperText>
        </>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      background: 'none',
    },
    input: {
      backgroundColor: 'white',
    },
    label: {
      paddingLeft: '1px',
      marginBottom: theme.spacing(0.5),
    },
    helperText: {
      margin: 0,
      marginTop: 0,
      fontSize: '11px',
      marginLeft: '1px',
    },
  })
);
export default MaterialInput;
