import { date, identifier, object, primitive, serializable } from 'serializr';
import { SynchronizableData } from 'stores/Persistable';
import { EditableRessource } from './EditableRessource';

export default class Speciality implements EditableRessource {
  @serializable(identifier())
  id?: number;

  @serializable(primitive())
  name?: string;

  @serializable(primitive())
  deactivatable: boolean = false;
  @serializable(primitive())
  updatable: boolean = false;
  @serializable(primitive())
  deletable: boolean = false;
  @serializable(primitive())
  enabled: boolean = false;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}

export class SpecialitySynchronizableData implements SynchronizableData<Speciality> {
  constructor(isSynchronized: boolean, data: Speciality) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(Speciality))
  data: Speciality;
}

export type SpecialityCreation = {
  name: string;
};
