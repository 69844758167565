import { makeStyles, Paper, Theme } from '@material-ui/core';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import React, { FC } from 'react';

const PRIORITY_LABEL: { [key in PermanentAlgoPriority]: string } = {
  HIGHT: 'P1',
  MEDIUM: 'P2',
  LOW: 'P3',
  UNKNOWN: '',
};

function getBoxColor(theme: Theme, priority: PermanentAlgoPriority): string {
  switch (priority) {
    case PermanentAlgoPriority.HIGHT:
      return theme.palette.error.main;
    case PermanentAlgoPriority.MEDIUM:
      return theme.palette.warning.main;
    case PermanentAlgoPriority.LOW:
      return theme.palette.success.main;
    default:
      return theme.palette.grey[400];
  }
}

export interface PriorityIndicatorProps {
  priority: PermanentAlgoPriority;
  size?: 'normal' | 'small';
}
const usePriorityBoxStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (props: PriorityIndicatorProps) => getBoxColor(theme, props.priority),
    height: (props: PriorityIndicatorProps) => (props.size === 'small' ? '40px' : '50px'),
    width: (props: PriorityIndicatorProps) => (props.size === 'small' ? '40px' : '50px'),
    color: 'white',
    fontWeight: 600,
  },
}));

export const PriorityIndicator: FC<PriorityIndicatorProps> = (props) => {
  const classes = usePriorityBoxStyles(props);
  return (
    <Paper elevation={3} classes={classes}>
      {PRIORITY_LABEL[props.priority]}
    </Paper>
  );
};
