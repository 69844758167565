import { action, observable, reaction, runInAction } from 'mobx';
import {
  NurseAlgoCardDocument,
  NurseAlgoDiagnostic,
  NurseAlgoGroup,
  NurseAlgoQuestion,
  NurseAlgoResponse,
  NurseAlgoResultDiagnostic,
  NurseAlgoRoute,
} from 'model/algo/nurse';
import { Permission_V15 } from 'model/User';
import { nurseAlgoResourceProvider } from 'resourceProvider/nurseAlgo.provider';
import { deserialize } from 'serializr';
import { authStore } from 'stores/auth/auth.store';
import { facilityStore } from 'stores/facility';
import { WithFacilityStoreDeserializationContextArgs } from 'stores/facility/facility.resolvers';
import { PersistableStore, registerStorableStore, storableStoreHelpers } from 'stores/Persistable';
import { noopCallback } from 'stores/serialize.context';

type VoidFunction = () => void;

/**
 * In charge of loading from API all data required for nurse algorithm
 */
export class NurseAlgoDataStore implements PersistableStore {
  // DATA from API
  @observable cardDocuments: NurseAlgoCardDocument[] = [];
  @observable diagnostics: NurseAlgoDiagnostic[] = [];
  @observable groups: NurseAlgoGroup[] = [];
  @observable questions: NurseAlgoQuestion[] = [];
  @observable responses: NurseAlgoResponse[] = [];
  @observable resultDiagnostics: NurseAlgoResultDiagnostic[] = [];
  @observable routes: NurseAlgoRoute[] = [];
  @observable algoLoaded = false;
  @observable lastSynchronization: Date | undefined;

  init() {
    // Nécessite d'attendre que les facilities soient chargés avant de fetcher les données de l'algo infirmier
    reaction(
      () => facilityStore.dataLoaded,
      (facilitiesLoaded) => {
        if (facilitiesLoaded) {
          this.loadNurseAlgo();
        }
      }
    );
    storableStoreHelpers.reactOnNetworkChange(() => this.loadNurseAlgo());
  }

  @action('NURSE:LOAD_ALGO')
  async loadNurseAlgo() {
    const hasAccess =
      ((authStore.hasPermission(Permission_V15.CALLS_CREATE) ||
        authStore.hasPermission(Permission_V15.CALLS_UPDATE)) &&
        authStore.hasPermission(Permission_V15.CALLS_REGULATION_IDE)) ||
      authStore.hasPermission(Permission_V15.VICTIMS_REGULATION_IDE);
    if (!hasAccess) {
      this.algoLoaded = true;
      return;
    }
    const nurseAlgoResource = nurseAlgoResourceProvider.get();
    const {
      cardDocuments,
      diagnostics,
      groups,
      questions,
      responses,
      resultDiagnostics,
      routes,
    } = await nurseAlgoResource.fetchAlgo();

    runInAction(() => {
      const contextArgs: WithFacilityStoreDeserializationContextArgs<NurseAlgoDataStore> = {
        store: this,
        facilityStore,
      };

      this.cardDocuments = deserialize(
        NurseAlgoCardDocument,
        cardDocuments,
        noopCallback,
        contextArgs
      );
      this.diagnostics = deserialize(NurseAlgoDiagnostic, diagnostics, noopCallback, contextArgs);
      this.groups = deserialize(NurseAlgoGroup, groups, noopCallback, contextArgs);
      this.questions = deserialize(NurseAlgoQuestion, questions, noopCallback, contextArgs);
      this.responses = deserialize(NurseAlgoResponse, responses, noopCallback, contextArgs);
      this.resultDiagnostics = deserialize(
        NurseAlgoResultDiagnostic,
        resultDiagnostics,
        noopCallback,
        contextArgs
      );
      this.routes = deserialize(NurseAlgoRoute, routes, noopCallback, contextArgs);

      this.algoLoaded = true;
      this.setLastSynchronization();
    });
  }

  getDiagnosticById(diagnosticId: number): NurseAlgoDiagnostic | undefined {
    return this.diagnostics.find((diagnostic) => diagnostic.Id === diagnosticId);
  }

  @action
  setLastSynchronization(date: Date = new Date()) {
    this.lastSynchronization = date;
  }
}

export const nurseAlgoDataStore = new NurseAlgoDataStore();

registerStorableStore(nurseAlgoDataStore);
