import { Field, fieldsAggregatorBuilder, FORM_MODE_CREATE, FORM_VALID_ON_BLUR } from 'utils/forms';
import { isRequired, maxLength } from 'utils/forms/validators';

export default function loginFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    username: new Field({
      initValue: undefined,
      disabled: false,
      validate: [isRequired, maxLength(100)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    password: new Field({
      initValue: undefined,
      disabled: false,
      validate: [isRequired, maxLength(100)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
