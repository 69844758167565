import { Field, fieldsAggregatorBuilder, FORM_VALID_ON_BLUR } from 'utils/forms';
import { isRequired, minLength, forbiddenChars } from 'utils/forms/validators';

export function updatePasswordFormBuilder(disabled = false) {
  const fields = {
    currentPassword: new Field({
      initValue: '',
      disabled,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    newPassword: new Field({
      initValue: '',
      disabled,
      validate: [isRequired, forbiddenChars(['I', 'l', 'O', '0']), minLength(8)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    newPasswordConfirm: new Field({
      initValue: '',
      disabled,
      validate: [
        isRequired,
        (value) => {
          if (value === fields.newPassword.value) {
            return { valid: true };
          } else {
            return {
              valid: false,
              message: 'Les nouveaux mot de passe saisis ne sont pas identiques',
            };
          }
        },
        minLength(8),
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
