import { action } from 'mobx';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import { Permission_V15, Profile } from 'model/User';
import React from 'react';
import { authStore } from 'stores/auth/auth.store';

interface RenderIfAuthorizedProps {
  hasPermission?: Permission_V15[];
  hasProfile?: Profile[];
  otherCheck?: boolean;
}
/** Renders if authenticated user has any of the permissions provided (if provided) and any of the profile provided (if provided) */
const RenderIfAuthorized: React.FC<RenderIfAuthorizedProps> = ({
  hasPermission = [],
  hasProfile = [],
  otherCheck = true,
  children,
  ...rest
}) => {
  const localStore = useLocalStore(() => ({
    isAuthorized: false,
    setAuthorized(isAuthorized: boolean) {
      this.isAuthorized = isAuthorized;
    },
  }));

  React.useEffect(
    action(() => {
      let isAuthorized: boolean = false;
      const checkPermission: boolean = hasPermission.length > 0;
      const checkProfile: boolean = hasProfile.length > 0;

      if (checkPermission) {
        isAuthorized =
          hasPermission.filter((requiredPerm: Permission_V15) =>
            authStore.hasPermission(requiredPerm)
          ).length > 0;
      }
      if (checkProfile) {
        isAuthorized =
          checkPermission === isAuthorized &&
          hasProfile.filter((requiredProf: Profile) => authStore.hasProfile(requiredProf)).length >
            0;
      }
      localStore.setAuthorized(authStore.isAuthenticated && isAuthorized && otherCheck);
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPermission, hasProfile, otherCheck]
  );

  return useObserver(() => (
    <>
      {localStore.isAuthorized &&
        children &&
        React.cloneElement(children as React.ReactElement<any>, rest)}
    </>
  ));
};

export default RenderIfAuthorized;

// /** Renders if authenticated user has any of the permissions provided (if provided) and any of the profile provided (if provided) */
// const RenderIfAuthorized: React.FC<RenderIfAuthorizedProps> = ({
//   hasPermission = [],
//   hasProfile = [],
//   children,
// }) => {
//   const [isAuthorized, setAuthorized] = React.useState<boolean>(false);

//   React.useEffect(() => {
//     let isAuthorized: boolean = false;
//     const checkPermission: boolean = hasPermission.length > 0;
//     const checkProfile: boolean = hasProfile.length > 0;
//     if (checkPermission) {
//       isAuthorized =
//         hasPermission.filter((requiredPerm: Permission_V15) =>
//           authStore.hasPermission(requiredPerm)
//         ).length > 0;
//     }
//     if (checkProfile) {
//       isAuthorized =
//         checkPermission === isAuthorized &&
//         hasProfile.filter((requiredProf: Profile) => authStore.hasProfile(requiredProf)).length > 0;
//     }
//     setAuthorized(authStore.isAuthenticated && isAuthorized);
//   }, [hasPermission, hasProfile]);

//   return <>{isAuthorized && children}</>;
// };
