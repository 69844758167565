import {
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import EventVictim from 'model/event/EventVictim';
import * as React from 'react';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import { uiStore } from 'stores/ui.store';
import { numbersOnly } from 'utils/input.utilities';

interface VictimSearchModalProps {
  onCancel: () => void;
  onClose: () => void;
  isOpen: boolean;
}
const VictimSearchModal: React.FC<VictimSearchModalProps> = ({
  onCancel,
  onClose,
  isOpen = false,
}) => {
  const classes = useStyles();
  const history = useHistory<{ code?: string; eventVictims?: EventVictim[] }>();

  const [victimCode, setVictimCode] = React.useState<string | undefined>(undefined);

  const goToSearchPage = async (code: string) => {
    history.push(`/victimes/recherche/${code}`);
  };

  const handleCodeScan = async (data: string | null) => {
    if (data) {
      setVictimCode(data);
      uiStore.pushSuccessNotification('Scan réussie');
      goToSearchPage(data);
    }
  };

  const handleClickConfirm = async () => {
    if (victimCode) {
      goToSearchPage(victimCode);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Rechercher une victime</Typography>
          </Grid>
          <Grid item xs={10}>
            <MaterialInput
              size="small"
              label="N° de Bracelet"
              onKeyDown={numbersOnly}
              maxLength={6}
              value={victimCode}
              onChange={(e) => setVictimCode(e.target.value)}
            />
          </Grid>
          <Hidden smUp>
            <Grid item xs={12}>
              <QrReader
                delay={1000}
                style={{
                  width: '100%',
                  marginBottom: 8,
                }}
                onError={(err: any) => console.error(err)}
                onScan={handleCodeScan}
              />
            </Grid>
          </Hidden>
          <Grid item xs={6} container justify="center">
            <Button variant="contained" onClick={onCancel}>
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6} container justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickConfirm}
              disabled={!victimCode || victimCode.length === 0}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      maxWidth: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      margin: '50px auto 0',
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
    },
  })
);

export default VictimSearchModal;
