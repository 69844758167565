import { compareAsc } from 'date-fns';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import { CallSynchronizableData } from 'model/Call';
import { SeverityDegree } from 'model/Diagnostic';
import { parseJSONDate } from './date.utilities';

export function getPriorityValue(priority: PermanentAlgoPriority): number {
  switch (priority) {
    case PermanentAlgoPriority.HIGHT:
      return 3;
    case PermanentAlgoPriority.MEDIUM:
      return 2;
    case PermanentAlgoPriority.LOW:
      return 1;
    default:
      return 0;
  }
}
export function sortCalls(calls: CallSynchronizableData[]): CallSynchronizableData[] {
  // On ordonne les calls par leur priority
  const perPriorityCalls: { [prio in PermanentAlgoPriority]: CallSynchronizableData[] } = {
    [PermanentAlgoPriority.HIGHT]: [],
    [PermanentAlgoPriority.MEDIUM]: [],
    [PermanentAlgoPriority.LOW]: [],
    [PermanentAlgoPriority.UNKNOWN]: [],
  };
  for (const call of calls) {
    const callPriority = call.data.priority || PermanentAlgoPriority.UNKNOWN;
    perPriorityCalls[callPriority].push(call);
  }

  // On trie chaque groupe de call par priority selon leur dates
  for (const callsOfPriority of Object.values(perPriorityCalls)) {
    callsOfPriority.sort((call1, call2) => {
      return compareAsc(
        parseJSONDate(call1.data.userCreationDate!),
        parseJSONDate(call2.data.userCreationDate!)
      );
    });
  }
  return [
    ...perPriorityCalls.HIGHT,
    ...perPriorityCalls.MEDIUM,
    ...perPriorityCalls.LOW,
    ...perPriorityCalls.UNKNOWN,
  ];
}

export function isDiagnosticSeveryUA(severityDegree?: SeverityDegree) {
  return (
    SeverityDegree.UA === severityDegree || SeverityDegree.UA_UR_CONDITIONAL === severityDegree
  );
}
