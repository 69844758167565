import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Spacer } from 'components/Spacer';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { appStatusStore } from 'stores/appStatus.store';
import { UpdateLanguageForm } from './UpdateLanguageForm';
import { UpdatePasswordForm } from './UpdatePassword';

const FormContainer: React.FC = ({ children }) => {
  return (
    <Paper>
      <Box px={1} py={2}>
        {children}
      </Box>
    </Paper>
  );
};

export const AccountPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const connected = appStatusStore.isConnected;

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={10} lg={8}>
        <Typography variant="h4" component="h1">
          {t('account.title.myAccount')}
        </Typography>
        {!connected && (
          <Alert variant="filled" severity="info">
            {t('account.alert.disabled')}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={10} lg={8}>
        <FormContainer>
          <UpdatePasswordForm disabled={!connected} />
        </FormContainer>

        <Spacer direction="vertical" size="1rem" />

        <FormContainer>
          <UpdateLanguageForm disabled={!connected} />
        </FormContainer>
        <Box p={2}>
          <Grid container item justify="space-between">
            <Button type="submit" size="large" variant="contained" onClick={handleGoBack}>
              {t('account.button.back')}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
});
