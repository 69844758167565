import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import {
  Grid,
  Typography,
  MenuItem,
  Paper,
  Button,
  Chip,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Hidden,
} from '@material-ui/core';
import Event, { EventSynchronizableData, EventStatus } from 'model/event/Event';
import eventFormBuilder from './event.form.builder';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import MaterialInput from 'components/common/form/MaterialInput';
import { getInputFieldProps, FORM_MODE } from 'utils/forms';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { locationStore } from 'stores/location.store';
import Department from 'model/location/Department';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaterialDatePicker from 'components/common/form/MaterialDatePicker';
import { eventStore } from 'stores/event';
import { uiStore } from 'stores/ui.store';
import { authStore } from 'stores/auth/auth.store';
import { deleteVictim, mapFormDataToEvent } from 'utils/event.utilities';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'components/Spacer';
import AddIcon from '@material-ui/icons/AddCircle';
import EventVictim, { OrientationStatus, OperationType } from 'model/event/EventVictim';
import EditIcon from '@material-ui/icons/Edit';
import { Permission_V15, Profile } from 'model/User';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TableChartIcon from '@material-ui/icons/TableChart';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { isMobile } from 'react-device-detect';
import { victimDetailsStore } from './victims/victim.details.store';

const EventDetailsPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory<
    { event?: EventSynchronizableData; eventVictim?: EventVictim; mode?: FORM_MODE } | undefined
  >();
  const victimDetailHistory = useHistory<{
    mode?: FORM_MODE;
  }>();
  const departments = locationStore.departmentsArray;
  const synchronizableEvent = history.location.state?.event;
  const [eventForm] = React.useState(
    eventFormBuilder({
      initialValues: synchronizableEvent?.data,
    })
  );
  const [currentEvent, setCurrentEvent] = React.useState<EventSynchronizableData>();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [eventDetailsEditing, setEventDetailsEditing] = React.useState(false);
  const [victimForDeletion, setVictimForDeletion] = React.useState<EventVictim | undefined>(
    undefined
  );
  const [isEventDeletionConfirmationDialogOpen, setOpenEventDeletionConfirmDialog] = React.useState(
    false
  );
  const [
    isEventStatusChangeConfirmationDialogOpen,
    setOpenEventStatusChangeConfirmationDialog,
  ] = React.useState(false);

  React.useEffect(() => {
    // Redirect to list page if trying to access directly
    if (!synchronizableEvent) {
      history.replace('/evenements');
    } else {
      eventStore.getEvent(synchronizableEvent.data?.uuid).then((sEvent) => setCurrentEvent(sEvent));
    }
  }, [history, synchronizableEvent]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleClickEdit = () => {
    if (eventDetailsEditing) {
      handleSubmit();
    } else {
      setEventDetailsEditing(!eventDetailsEditing);
    }
  };

  const handleClickCancelEdit = () => {
    setEventDetailsEditing(!eventDetailsEditing);
  };

  const handleClickDeleteEvent = () => {
    setOpenEventDeletionConfirmDialog(true);
  };

  const handleDeleteEvent = () => {
    if (currentEvent) {
      eventStore.deleteEvent(currentEvent).then(() => {
        setOpenEventDeletionConfirmDialog(false);
        uiStore.pushSuccessNotification('Évènement supprimé avec succès');
        history.goBack();
      });
    }
  };

  const handleClickNewVictim = () => {
    if (currentEvent) {
      victimDetailsStore.setEvent(currentEvent);
      victimDetailsStore.setVictim(new EventVictim());
      victimDetailHistory.push('/evenements/victimes/nouveau', {
        mode: FORM_MODE.CREATE,
      });
    }
  };

  const handleClickVictim = (eventVictim: EventVictim) => () => {
    if (currentEvent) {
      victimDetailsStore.setEvent(currentEvent);
      victimDetailsStore.setVictim(eventVictim);
      victimDetailHistory.push('/evenements/victimes/consultation', {
        mode: FORM_MODE.EDIT,
      });
    }
  };

  const handleClickDeleteVictim = (eventVictim: EventVictim) => () => {
    setVictimForDeletion(eventVictim);
  };

  const handleDeleteVictim = (victimToDelete?: EventVictim) => {
    if (currentEvent?.data && !!victimToDelete) {
      if (deleteVictim(currentEvent, victimToDelete.victim.uuid)) {
        setVictimForDeletion(undefined);
        eventStore.saveEvent(currentEvent, true).then(() => {
          uiStore.pushSuccessNotification('Victime supprimée avec succès');
        });
      }
    }
  };

  const handleClickReturn = () => {
    history.push('/evenements');
  };

  const handleClickChangeEventStatus = () => {
    setOpenEventStatusChangeConfirmationDialog(true);
  };

  const handleChangeEventStatus = () => {
    if (currentEvent) {
      currentEvent.data.status = currentEvent.data.isActive
        ? EventStatus.INACTIVE
        : EventStatus.ACTIVE;
      eventStore
        .saveEvent(currentEvent)
        .then(() => setOpenEventStatusChangeConfirmationDialog(false));
    }
  };

  const handleSubmit = () => {
    eventForm.validate();
    if (eventForm.isValid) {
      if (!currentEvent) {
        throw new Error('No event in history!');
      }
      const eventData: Event = mapFormDataToEvent(currentEvent, eventForm);
      // updates event status on screen without risking to affect final saving(below)
      currentEvent.data.status = eventData.status;
      eventStore.saveEvent({ ...currentEvent, data: eventData }).then(() => {
        setEventDetailsEditing(false);
        uiStore.pushSuccessNotification('Évènement modifié avec succès');
      });
    }
  };
  const goToEventDashboard = (eventUuid?: string) => {
    if (!eventUuid) {
      throw new Error('Event should have an uuid !!!!');
    }
    history.push(`/evenements/${eventUuid}/dashboard`);
  };

  return useObserver(() => {
    if (!currentEvent) {
      return null;
    }
    return (
      <Grid container spacing={2}>
        {!!victimForDeletion && (
          <ConfirmationDialog
            title="Confirmation de suppression"
            message="Confirmez-vous la suppression de la victime ?"
            onConfirm={() => handleDeleteVictim(victimForDeletion)}
            onCancel={() => setVictimForDeletion(undefined)}
          />
        )}

        {isEventDeletionConfirmationDialogOpen && (
          <ConfirmationDialog
            title="Confirmation de suppression"
            message="Confirmez-vous la suppression de l'évènement ?"
            onConfirm={handleDeleteEvent}
            onCancel={() => setOpenEventDeletionConfirmDialog(false)}
          />
        )}
        {isEventStatusChangeConfirmationDialogOpen && (
          <ConfirmationDialog
            title="Confirmation de changement de statut"
            message={`Confirmez-vous la ${t(
              `event.action.${currentEvent?.data.status}`
            )} de l'évènement ?`}
            onConfirm={handleChangeEventStatus}
            onCancel={() => setOpenEventStatusChangeConfirmationDialog(false)}
          />
        )}
        <Grid item xs={12} container justify="space-between" alignItems="center" spacing={2}>
          <Grid item container xs={12} md={8}>
            <Typography variant="h4" component="h2">
              Détails de l'évènement
            </Typography>
            <Spacer direction="horizontal" size="1rem" />
            <Chip
              color={currentEvent.data.isActive ? 'secondary' : 'primary'}
              style={{ fontSize: 14 }}
              label={
                <Typography variant="button">
                  {t(`event.status.${currentEvent.data.status}`)}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} container>
            <Button startIcon={<ArrowBackIcon />} onClick={handleClickReturn}>
              Retour aux évenements
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.paper}>
            <Grid container spacing={1} justify="space-between">
              <Grid container item xs={12} justify="space-between" alignItems="center">
                <Grid item xs={12} md={8}>
                  <MaterialInput
                    size="small"
                    label="Titre"
                    required
                    fullWidth
                    editing={eventDetailsEditing}
                    {...getInputFieldProps(eventForm.title)}
                  />
                </Grid>
                <RenderIfAuthorized hasPermission={[Permission_V15.EVENTS_DASHBOARD]}>
                  <Hidden smDown>
                    <Grid item xs={12} md={4} container justify="flex-end">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => goToEventDashboard(currentEvent.data.uuid)}
                        startIcon={<TableChartIcon />}
                      >
                        Tableau de bord
                      </Button>
                    </Grid>
                  </Hidden>
                </RenderIfAuthorized>
              </Grid>

              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Grid item md={8}>
                    <MaterialSelectInput
                      required
                      fullWidth
                      size="small"
                      label={'Département'}
                      editing={eventDetailsEditing}
                      {...getInputFieldProps(eventForm.locationDepartment)}
                      renderValue={(departmentId: any) =>
                        locationStore.getDepartmentFromId(departmentId)?.name
                      }
                    >
                      {departments.map(({ id, name }: Department) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </MaterialSelectInput>
                  </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={12} md={6}>
                    <MaterialDatePicker
                      required
                      disableFuture
                      label="Date"
                      views={['month', 'date']}
                      value={selectedDate}
                      onChange={handleDateChange}
                      disabled={!eventDetailsEditing}
                      editing={eventDetailsEditing}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={8}>
                  <MaterialInput
                    size="small"
                    label="Détail localisation"
                    fullWidth
                    multiline={true}
                    rows={2}
                    rowsMax={2}
                    {...getInputFieldProps(eventForm.locationAdditionalInfos)}
                    editing={eventDetailsEditing}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <MaterialInput
                  size="small"
                  label="Description (CAN)"
                  fullWidth
                  multiline={true}
                  rows={2}
                  rowsMax={4}
                  {...getInputFieldProps(eventForm.descriptionCAN)}
                  editing={eventDetailsEditing}
                  disabled={
                    !authStore.hasProfile(Profile.INFIRMIER) &&
                    !authStore.hasProfile(Profile.PERMANENCIER)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MaterialInput
                  size="small"
                  label="Description (ambulancier) "
                  fullWidth
                  multiline={true}
                  rows={2}
                  rowsMax={4}
                  maxLength={250}
                  {...getInputFieldProps(eventForm.descriptionParamedic)}
                  editing={eventDetailsEditing}
                  disabled={!authStore.hasProfile(Profile.PARAMEDIC)}
                />
              </Grid>
            </Grid>
            <Spacer direction="vertical" size="1rem" />
            <Grid item xs={12} container justify="flex-end" alignItems="center" spacing={1}>
              {!eventDetailsEditing && authStore.hasPermission(Permission_V15.EVENTS_DELETE) && (
                <>
                  <Grid item xs={6} md={'auto'}>
                    <Button
                      variant="outlined"
                      size="large"
                      color="secondary"
                      onClick={handleClickDeleteEvent}
                      startIcon={<DeleteIcon />}
                    >
                      Supprimer
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={'auto'}>
                    <Button
                      variant="outlined"
                      size="large"
                      color={currentEvent?.data.isActive ? 'secondary' : 'primary'}
                      onClick={handleClickChangeEventStatus}
                      startIcon={
                        currentEvent?.data.isActive ? <BlockIcon /> : <CheckCircleOutlinedIcon />
                      }
                    >
                      {t(`event.verb.${currentEvent?.data.status}`)}
                    </Button>
                  </Grid>
                </>
              )}
              {eventDetailsEditing && (
                <Grid item xs={6} md={'auto'}>
                  <Button
                    variant="contained"
                    size="large"
                    color={'default'}
                    onClick={handleClickCancelEdit}
                    fullWidth={isMobile}
                  >
                    Annuler
                  </Button>
                  <Spacer direction="horizontal" size="1rem" />
                </Grid>
              )}
              {authStore.hasPermission(Permission_V15.EVENTS_UPDATE) && (
                <Grid item xs={6} md={'auto'}>
                  <Button
                    variant="contained"
                    size="large"
                    color={eventDetailsEditing ? 'primary' : 'default'}
                    onClick={handleClickEdit}
                    fullWidth={isMobile}
                  >
                    {eventDetailsEditing ? 'Valider' : 'Modifier'}
                  </Button>
                </Grid>
              )}
              {/* </Grid> */}
            </Grid>
          </Paper>
        </Grid>
        <Spacer direction="vertical" size="1rem" />
        <Grid item xs={12} container justify={'space-between'} spacing={2}>
          <Grid item xs={12} md={6} container spacing={1} direction="column">
            <Grid item>
              <Typography variant="h4" component="h2">
                Victimes
              </Typography>
            </Grid>
            <Grid
              item
            >{`${currentEvent.data.victims.length} victime(s) visible(s) sur  ${currentEvent.data.totalVictims} déclarée(s)`}</Grid>
          </Grid>

          <Grid item>
            {authStore.hasPermission(Permission_V15.VICTIMS_CREATE) && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickNewVictim}
              >
                Nouvelle victime
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="center">
          {currentEvent?.data?.victims
            .filter((eVictim) => eVictim.operation !== OperationType.DELETE)
            .map((eVictim) => {
              return (
                <Grid item xs={12} md={4} key={eVictim.victim.uuid}>
                  <VictimCardStyled
                    eventVictim={eVictim}
                    onClickEdit={handleClickVictim(eVictim)}
                    onClickDelete={handleClickDeleteVictim(eVictim)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  });
};

const VictimCard: React.FC<{
  eventVictim: EventVictim;
  onClickCard?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}> = ({ eventVictim, onClickCard, onClickEdit, onClickDelete, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Card {...rest}>
      <CardContent>
        <Grid item xs={12} container>
          <Grid item xs={12} container justify="space-between">
            <Chip
              color={
                eventVictim.orientationStatus === OrientationStatus.ORIENTED
                  ? 'primary'
                  : 'secondary'
              }
              label={t(`victim.orientationStatus.${eventVictim.orientationStatus}`)}
            />
            {eventVictim.arrivedToFacility && (
              <Chip color={'primary'} label={"Arrivée à l'hôpital"} />
            )}
            {authStore.hasPermission(Permission_V15.VICTIMS_UPDATE) && (
              <IconButton onClick={onClickEdit}>
                <EditIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>N° Bracelet</b>
                  </td>
                  <td>{eventVictim.victim?.code}</td>
                </tr>
                <tr>
                  <td>
                    <b>Prénom</b>
                  </td>
                  <td>{eventVictim.victim?.firstName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Nom</b>
                  </td>
                  <td>{eventVictim.victim?.lastName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Sexe</b>
                  </td>
                  <td>{t(`victim.sex.${eventVictim.victim?.sex}`)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Age</b>
                  </td>
                  <td>{t(`victim.age.${eventVictim.victim?.ageRange}`)}</td>
                </tr>
                <tr>
                  <td>
                    <b>Triage</b>
                  </td>
                  <td>
                    {eventVictim.emergencySort && t(`victim.triage.${eventVictim.emergencySort}`)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Orientation</b>
                  </td>
                  <td>{eventVictim.stayedThere || eventVictim.facility?.name}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        {authStore.hasPermission(Permission_V15.VICTIMS_DELETE) && (
          <Grid item xs={12} container justify="flex-end">
            <Tooltip title={<span>{t('Supprimer')}</span>}>
              <IconButton onClick={onClickDelete} style={{ color: 'red' }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const VictimCardStyled = styled(VictimCard)(({ theme, ...rest }) => {
  const { eventVictim } = rest as { eventVictim: EventVictim };
  return {
    borderTopColor:
      eventVictim.orientationStatus === OrientationStatus.ORIENTED
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    borderTopWidth: theme.spacing(1),
    borderTopStyle: 'solid',
  };
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      padding: theme.spacing(1),
      transition: theme.transitions.create(['height', 'max-height'], {
        duration: theme.transitions.duration.standard,
      }),
    },
  })
);

export default EventDetailsPage;
