import { appStatusStore } from 'stores/appStatus.store';
import {
  IDBRecord,
  ResourceProvider,
  storableStoreHelpers,
  SynchronizableData,
} from 'stores/Persistable';
import { equipmentApi } from '../api';
import Equipment from 'model/facilities/Equipment';

function equipmentsArrayToRecord(equipments: Equipment[]): IDBRecord<Equipment> {
  return equipments.reduce<IDBRecord<Equipment>>((record, equipment) => {
    if (equipment.id) {
      record[equipment.id] = equipment;
    }
    return record;
  }, {});
}

export interface EquipmentResourceMethods {
  getEquipments: () => Promise<SynchronizableData<Equipment>[]>;
  getEquipment: (uuid: string) => Promise<SynchronizableData<Equipment>>;
  saveEquipment: (equipment: Equipment) => Promise<SynchronizableData<Equipment>>;
  updateEquipment: (equipment: Equipment) => Promise<SynchronizableData<Equipment>>;
  deleteEquipment: (equipment: Equipment) => Promise<SynchronizableData<Equipment>>;
}

const EQUIPMENTS_COLLECTION_KEY = 'equipments:list';

export const equipmentResourceProvider: ResourceProvider<EquipmentResourceMethods> = {
  onlineResourceProvider: {
    getEquipments: async () => {
      const response = await equipmentApi.getEquipments();
      const callsAsRecord = equipmentsArrayToRecord(response.content);

      const storedEquipments: IDBRecord<SynchronizableData<
        Equipment
      >> = await storableStoreHelpers.onlineSetRecords(
        EQUIPMENTS_COLLECTION_KEY,
        Equipment,
        callsAsRecord
      );

      return Object.values(storedEquipments);
    },
    getEquipment: async (id: string) => {
      const response = await equipmentApi.getEquipment(id);
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        EQUIPMENTS_COLLECTION_KEY,
        Equipment,
        String(response.id),
        response
      );

      return stored;
    },
    saveEquipment: async (equipment: Equipment) => {
      const response = await equipmentApi.saveEquipment(equipment);
      if (!response.id) {
        throw new Error('Invalid data - New equipment has no "id"');
      }
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        EQUIPMENTS_COLLECTION_KEY,
        Equipment,
        String(response.id),
        response
      );
      return stored;
    },
    updateEquipment: async (equipment: Equipment) => {
      const response = await equipmentApi.updateEquipment(equipment);
      if (!response.id) {
        throw new Error('Invalid data - New equipment has no "id"');
      }
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        EQUIPMENTS_COLLECTION_KEY,
        Equipment,
        String(response.id),
        response
      );
      return stored;
    },
    deleteEquipment: async (equipment: Equipment) => {
      const response = await equipmentApi.deleteEquipment(equipment);
      if (!response.id) {
        throw new Error('Invalid data - New equipment has no "id"');
      }
      const record = await storableStoreHelpers.offlineGetRecord<Equipment>(String(equipment.Id));
      const syncEquipment = record[response.id];
      syncEquipment.data = response;
      const stored = await storableStoreHelpers.onlineDeleteRecordItem<Equipment>(
        EQUIPMENTS_COLLECTION_KEY,
        String(response.id),
        syncEquipment
      );
      return stored;
    },
  },
  offlineResourceProvider: {
    getEquipments: async () => {
      const storedEquipments = await storableStoreHelpers.offlineGet(EQUIPMENTS_COLLECTION_KEY);
      return Object.values(storedEquipments);
    },

    getEquipment: async (uuid: string) => {
      const storedEquipments = await storableStoreHelpers.offlineGetRecord<Equipment>(
        EQUIPMENTS_COLLECTION_KEY
      );
      return storedEquipments[uuid];
    },
    saveEquipment: async (equipment: Equipment) => {
      throw new Error('Fonctionnalité non disponible offline');
    },
    updateEquipment: async (equipment: Equipment) => {
      throw new Error('Fonctionnalité non disponible offline');
    },
    deleteEquipment: async (equipment: Equipment) => {
      throw new Error('Fonctionnalité non disponible offline');
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
