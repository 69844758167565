import { Button, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { QuestionContainer } from 'components/algorithm/Question/QuestionContainer';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import React, { FC, ReactNode } from 'react';
import { ContentItem, NurseAlgoResultParagraph } from './NurseAlgoResultParagraph';
import { NurseAlgoResultTitle } from './NurseAlgoResultTitle';
import { DangerousHtml } from 'components/DangerousHtml';
import { nurseAlgoResourceProvider } from 'resourceProvider/nurseAlgo.provider';
import { isDiagnosticSeveryUA } from 'utils/call.utilities';

interface NurseAlgoResultProps {
  algoRunResult: UINurseAlgoRunResult;
  actionButton?: ReactNode;
}

export const NurseAlgoResult: FC<NurseAlgoResultProps> = ({
  algoRunResult: { diagnostic, score },
  actionButton,
}) => {
  const handleOpenBehaviorDocument = async (documentId: number) => {
    const adviceBinary = await nurseAlgoResourceProvider
      .get()
      .fetchBehaviorDocumentBinary(documentId);
    const objectUrl = URL.createObjectURL(adviceBinary);
    window.open(objectUrl);
  };

  return (
    <AlgoContainer
      renderTitle={<NurseAlgoResultTitle diagnosticSeverity={diagnostic.severityDegree} />}
      renderActions={actionButton}
      diagnosticSeverity={diagnostic.severityDegree}
    >
      <QuestionContainer>
        <Typography
          variant="h4"
          color="textSecondary"
          style={
            isDiagnosticSeveryUA(diagnostic.severityDegree) ? { fontWeight: 700, color: 'red' } : {}
          }
        >
          {diagnostic.name}{' '}
          {diagnostic.bookReferences?.map((bookReference, index) => (
            <DangerousHtml key={bookReference.Id} html={bookReference.pageNumber || ''} />
          ))}
        </Typography>

        {diagnostic.content && (
          <>
            {diagnostic.content.decisions.length > 0 && (
              <NurseAlgoResultParagraph title="Decision :">
                {diagnostic.content.decisions.map((decision, index) => (
                  <ContentItem key={index} displayType={decision.displayType}>
                    <DangerousHtml html={decision.content} />
                  </ContentItem>
                ))}
              </NurseAlgoResultParagraph>
            )}

            {diagnostic.content.severityIndexes.length > 0 && (
              <NurseAlgoResultParagraph title="Indice de gravité / tri :">
                {diagnostic.content.severityIndexes.map((severityIndex, index) => (
                  <ContentItem key={index}>
                    <DangerousHtml html={severityIndex.content} />{' '}
                    {severityIndex.displayType === 'SCORE' && score !== undefined && score}
                  </ContentItem>
                ))}
              </NurseAlgoResultParagraph>
            )}

            {diagnostic.content.behaviors.length > 0 && (
              <NurseAlgoResultParagraph title=" Conduite à tenir :">
                {diagnostic.content.behaviors.map((behavior, index) => (
                  <ContentItem key={index} displayType={behavior.displayType}>
                    {!behavior.documentId && behavior.content && (
                      <DangerousHtml html={behavior.content} />
                    )}
                    {behavior.documentId && (
                      <Button
                        startIcon={<DescriptionIcon />}
                        onClick={() => {
                          behavior.documentId && handleOpenBehaviorDocument(behavior.documentId.Id);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        {behavior.documentId.name}
                      </Button>
                    )}
                  </ContentItem>
                ))}
              </NurseAlgoResultParagraph>
            )}
            {diagnostic.bookReferences?.length && (
              <NurseAlgoResultParagraph title="Prise en charge par l’infirmier sur place (fiches CAN) :">
                {diagnostic.bookReferences.map((bookReference, index) => (
                  <ContentItem key={bookReference.Id}>
                    <DangerousHtml html={bookReference.pageNumber || ''} />
                  </ContentItem>
                ))}
              </NurseAlgoResultParagraph>
            )}
            {diagnostic.content.orientation && diagnostic.content.orientation.text.length > 0 && (
              <NurseAlgoResultParagraph title="Orientation requise :">
                {diagnostic.content.orientation.text.map((orientationText, index) => (
                  <ContentItem key={index}>
                    <DangerousHtml html={orientationText} />
                  </ContentItem>
                ))}
              </NurseAlgoResultParagraph>
            )}
          </>
        )}
      </QuestionContainer>
    </AlgoContainer>
  );
};
