import { serializable, primitive, date, object, identifier } from 'serializr';
import { SynchronizableData } from 'stores/Persistable';
import District from './District';

export default class City {
  @serializable(identifier())
  id?: number;
  @serializable(object(District))
  district?: District;
  @serializable(primitive())
  name?: string;
}

export class CitySynchronizableData implements SynchronizableData<City> {
  constructor(isSynchronized: boolean, data: City) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(City))
  data: City;
}
