import { action, decorate, observable } from 'mobx';
import { eventResourceProvider } from 'resourceProvider/event.provider';
import { deserialize } from 'serializr';
import { appStatusStore } from '../appStatus.store';
import { authStore } from '../auth/auth.store';
import Event, { EventStatus, EventSynchronizableData } from 'model/event/Event';
import { sortEvents } from 'utils/event.utilities';
import Department from 'model/location/Department';

const DATA_REFRESH_INTERVAL = 10000;

async function saveOrUpdateEvent(
  synchronizableEvent: EventSynchronizableData,
  updateVictims: boolean = false
) {
  const eventData = synchronizableEvent.data;
  eventData.updateVictims = updateVictims;
  if (!synchronizableEvent.lastSynchronizedOn) {
    return eventResourceProvider.get().createEvent(synchronizableEvent);
  } else if (synchronizableEvent.markedForDeletion) {
    eventResourceProvider.get().deleteEvent(eventData.uuid, synchronizableEvent);
    return synchronizableEvent;
  } else {
    return eventResourceProvider.get().updateEvent(eventData.uuid, synchronizableEvent);
  }
}

export default class EventStore {
  events = observable.map(new Map<String, EventSynchronizableData>());
  lastSynchronization: Date | undefined;
  selectedDepartment: Department | undefined;

  setSelectedDepartment(department: Department | undefined) {
    this.selectedDepartment = department;
  }

  addEvent(eventUuid: string, newCall: EventSynchronizableData) {
    this.events.set(eventUuid, newCall);
  }

  setEvents(newEvents: EventSynchronizableData[]) {
    const sortedEvents: EventSynchronizableData[] = sortEvents(newEvents);
    // replace was not working properly, so combination of clear + merge instead
    this.events.clear();
    this.events.merge(sortedEvents.map((event) => [event.data.uuid, event]));
  }

  createNewEvent(): Promise<EventSynchronizableData> {
    const newEvent = new Event();
    const synchronizableEvent: EventSynchronizableData = {
      isSynchronized: false, // new calls are initially unsynchronized
      data: newEvent,
    };
    return Promise.resolve(synchronizableEvent);
  }

  async saveEvent(
    toSaveEvent: EventSynchronizableData,
    updateVictims: boolean = false
  ): Promise<EventSynchronizableData> {
    const event = await saveOrUpdateEvent(toSaveEvent, updateVictims);
    this.addEvent(event.data.uuid, event);
    return event;
  }

  async getEvents(eventStatuses: EventStatus[] = [EventStatus.ACTIVE]) {
    const result = await eventResourceProvider.get().getEvents(eventStatuses);
    const events = deserialize(EventSynchronizableData, result);
    this.setEvents(events);
    this.lastSynchronization = new Date();
  }

  async getEvent(uuid: string): Promise<EventSynchronizableData | undefined> {
    const event = await eventResourceProvider.get().getEvent(uuid);
    return deserialize(EventSynchronizableData, event);
  }

  async deleteEvent(event: EventSynchronizableData) {
    await eventResourceProvider.get().deleteEvent(event.data.uuid, event);
  }

  // TODO: Voir pour indiquer la nécessité de synchronizer les victimers
  async synchronizeEvents() {
    // Push events
    const unSynchronizedEvents = Array.from(this.events.values()).filter(
      (event) => !event.isSynchronized
    );
    //
    if (unSynchronizedEvents.length > 0) {
      // synchronize event with server
      const synchronizationResults = await Promise.allSettled(
        unSynchronizedEvents.map((event) => saveOrUpdateEvent(event))
      );
      // Mark events as synchronized
      synchronizationResults.forEach((result: PromiseSettledResult<EventSynchronizableData>) => {
        if (result.status === 'fulfilled') {
          const event = result.value;
          this.events.set(event.data.uuid, {
            ...event,
            isSynchronized: true,
            lastSynchronizedOn: new Date(),
          });
        }
      });
    }
    // Retrieve calls
    this.getEvents();
  }

  constructor() {
    setInterval(() => {
      // Synchronisation des appels lors de la reconnexion
      if (authStore.isAuthenticated && appStatusStore.isConnected) {
        this.synchronizeEvents();
      }
    }, DATA_REFRESH_INTERVAL);
  }
}

decorate(EventStore, {
  events: observable,
  lastSynchronization: observable,
  selectedDepartment: observable,
  getEvents: action,
  addEvent: action,
  setEvents: action,
  synchronizeEvents: action,
  setSelectedDepartment: action,
});

export const eventStore = new EventStore();
