import { observable, action, autorun, decorate } from 'mobx';

class AppStatusStore {
  isConnected: boolean = navigator.onLine; // online/offline network status
  offlineSince?: Date;

  setConnectionStatus(status: boolean): void {
    this.isConnected = status;
  }

  constructor() {
    autorun(() => {
      console.debug('est en ligne:', this.isConnected);
      if (!this.isConnected) {
        this.offlineSince = new Date();
        console.debug('app is disconnected since ' + this.offlineSince);
      }
    });
  }
}

decorate(AppStatusStore, {
  isConnected: observable,
  offlineSince: observable,
  setConnectionStatus: action('APP_SET_CONNECTION_STATUS'),
});

export default AppStatusStore;
export const appStatusStore = new AppStatusStore();
