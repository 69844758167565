import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialInput from 'components/common/form/MaterialInput';
import React from 'react';
import { InputFieldProps } from 'utils/forms';

export default function BilanDialog({
  isOpen,
  onClose,
  onSave,
  inputProps,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  onSave?: VoidFunction;
  inputProps?: InputFieldProps;
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">Bilan</DialogTitle>
      <DialogContent>
        <MaterialInput
          multiline={true}
          rows={4}
          rowsMax={4}
          fullWidth
          inputProps={{ maxLength: 249, height: '100%' }}
          {...inputProps}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Annuler
        </Button>
        <Button color="primary" variant="contained" onClick={() => onSave && onSave()}>
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
