import { custom, identifier, list, primitive, serializable } from 'serializr';
import {
  AlgoCombinaison,
  algoCombinaisonSerializer,
  algoCombinaisonDeserializer,
} from '../AlgoCombinaison';

/**
 * Warning. Priority order in enum is meaningfully
 * Used in comparison
 */
export enum PermanentAlgoPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGHT = 'HIGHT',
  UNKNOWN = 'UNKNOWN',
}

export class PermanentAlgoResultPriority {
  @serializable(identifier())
  id?: number;

  @serializable(custom(permanentAlgoPriorityDeserializer, permanentAlgoPrioritySerializer))
  priority?: PermanentAlgoPriority;

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable(list(primitive()))
  responses?: number[];
}

export function permanentAlgoPriorityDeserializer(type: string): PermanentAlgoPriority {
  return PermanentAlgoPriority[type as keyof typeof PermanentAlgoPriority];
}

export function permanentAlgoPrioritySerializer(type: PermanentAlgoPriority): string {
  return PermanentAlgoPriority[type];
}
