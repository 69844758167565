import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { NurseAlgoItemDisplayType } from 'model/algo/nurse';

const Container = styled.div`
  padding: 1rem;
`;

const Content = styled.div`
  padding-top: 0.5rem;
`;

export const ContentItem = styled.div<{ displayType?: NurseAlgoItemDisplayType }>`
  font-weight: ${({ displayType }) => (displayType === 'IMPORTANT' ? 'bold' : 'normal')};
  padding-left: ${({ displayType }) => (displayType === 'SUBLIST' ? '1rem' : '0')};
`;

type NurseAlgoResultParagraphProps = {
  title: string;
};
export const NurseAlgoResultParagraph: FC<NurseAlgoResultParagraphProps> = ({
  title,
  children,
}) => {
  return (
    <Container>
      <Typography variant="h5" color="textSecondary">
        {title}
      </Typography>
      <Content>{children}</Content>
    </Container>
  );
};
