import {
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import TuneIcon from '@material-ui/icons/Tune';
import { MaterialAutocomplete } from 'components/common/form/MaterialAutocomplete';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import MaterialSwitch from 'components/common/form/MaterialSwitch';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import { action, IObservableArray, reaction } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import Equipment from 'model/facilities/Equipment';
import { FacilityCategory, FacilityType } from 'model/facilities/Facility';
import Speciality from 'model/facilities/Speciality';
import City from 'model/location/City';
import Department from 'model/location/Department';
import { Permission_V15 } from 'model/User';
import React from 'react';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { uiStore } from 'stores/ui.store';
import { FORM_MODE, getInputFieldProps, getSwitchFieldProps } from 'utils/forms';

export const FacilityForm: React.FC<{ facilityForm: any; formEditing: boolean }> = ({
  facilityForm,
  formEditing,
}) => {
  const localStore = useLocalStore<{
    filteredCities: City[];
    equipmentDialog: boolean;
    specialityDialog: boolean;
  }>(() => ({
    filteredCities: locationStore.citiesArray,
    equipmentDialog: false,
    specialityDialog: false,
  }));

  React.useEffect(
    () =>
      reaction(
        () => facilityForm.locationDepartmentId?.value,
        (currentSelectedDepartment: number) => {
          const cities = locationStore.citiesArray;
          localStore.filteredCities = currentSelectedDepartment
            ? cities.filter((city) => city.district?.department?.id === currentSelectedDepartment)
            : cities;

          facilityForm.locationCity.setValue('');
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClickEditEquipments = action(() => {
    localStore.equipmentDialog = true;
  });

  const handleClickEditSpecialities = action(() => {
    localStore.specialityDialog = true;
  });

  return (
    <FormContainer>
      <form>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Typography variant="button">Identité</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Observer>
              {() => (
                <MaterialInput
                  label="Nom"
                  required
                  fullWidth
                  autoComplete="new-password"
                  {...getInputFieldProps(facilityForm.name)}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="flex-end">
              <Observer>
                {() => (
                  <MaterialInput
                    label="Téléphone"
                    autoComplete="new-password"
                    {...getInputFieldProps(facilityForm.phoneNumber)}
                  />
                )}
              </Observer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Observer>
              {() => (
                <MaterialInput
                  label="Adresse"
                  fullWidth
                  autoComplete="new-password"
                  {...getInputFieldProps(facilityForm.address)}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Observer>
              {() => (
                <MaterialSelectInput
                  required
                  fullWidth
                  size="small"
                  label={'Département'}
                  autoComplete="new-password"
                  editing={formEditing}
                  {...getInputFieldProps(facilityForm.locationDepartmentId)}
                  renderValue={(departmentId: any) =>
                    locationStore.getDepartmentFromId(departmentId)?.name
                  }
                >
                  {locationStore.departmentsArray.map(({ id, name }: Department) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </MaterialSelectInput>
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Observer>
              {() => (
                <MaterialAutocomplete
                  required
                  options={localStore.filteredCities}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option: City | undefined, value: City | undefined) => {
                    return option?.id === value?.id;
                  }}
                  label="Commune"
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  openOnFocus
                  fullWidth
                  editing={formEditing}
                  {...getInputFieldProps(facilityForm.locationCity)}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={12} sm={6} md={4}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    label="Catégorie"
                    required
                    displayEmpty
                    fullWidth
                    autoComplete="new-password"
                    {...getInputFieldProps(facilityForm.category)}
                  >
                    {Object.keys(FacilityCategory).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    label="Type"
                    required
                    displayEmpty
                    fullWidth
                    autoComplete="new-password"
                    renderValue={(value: any) => value || ''}
                    {...getInputFieldProps(facilityForm.type)}
                  >
                    {Object.keys(FacilityType).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={1}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="button">Équipements</Typography>
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={12} sm={6} md={4}>
              <Observer>
                {() => (
                  <MaterialSwitch
                    label="Sang suffisant"
                    {...getSwitchFieldProps(facilityForm.sufficientBloodLevel)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Observer>
                {() => (
                  <MaterialSwitch
                    label="Oxygène suffisant"
                    {...getSwitchFieldProps(facilityForm.sufficientOxygenLevel)}
                  />
                )}
              </Observer>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} container spacing={1}>
            <Grid item xs={10}>
              <Observer>
                {() => (
                  <MaterialAutocomplete
                    required={true}
                    options={facilityStore.equipmentsArray.filter((equipment) => equipment.enabled)}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option: Equipment, value: Equipment) =>
                      option?.id === value?.id
                    }
                    label="Equipements"
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    openOnFocus
                    fullWidth
                    editing={formEditing}
                    multiple
                    renderTags={(value: Equipment[], getTagProps) => null}
                    {...getInputFieldProps(facilityForm.equipments)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={2} container>
              <RenderIfAuthorized hasPermission={[Permission_V15.FACILITIES_ADMIN]}>
                <ClickableIcon onClick={handleClickEditEquipments}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    bgcolor="primary.main"
                    padding={0.8}
                    marginTop={3}
                    borderRadius={2}
                    boxShadow={1}
                  >
                    <EditIcon />
                  </Box>
                </ClickableIcon>
              </RenderIfAuthorized>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Observer>
                {() => (
                  <>
                    {facilityForm.equipments.value.map((eqpt: Equipment, index: number) => (
                      <Grid item key={index}>
                        <Chip
                          label={eqpt.name}
                          onDelete={() => {
                            (facilityForm.equipments.value as IObservableArray).remove(eqpt);
                          }}
                          color="primary"
                          disabled={facilityForm.mode === FORM_MODE.CONSULT}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </Observer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1} justify="flex-end">
            <Grid item xs={10}>
              <Observer>
                {() => (
                  <MaterialAutocomplete
                    required={true}
                    options={facilityStore.specialitiesArray}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option: Speciality, value: Speciality) =>
                      option?.id === value?.id
                    }
                    label="Services"
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    openOnFocus
                    fullWidth
                    editing={formEditing}
                    multiple
                    renderTags={(value: Speciality[], getTagProps) => null}
                    {...getInputFieldProps(facilityForm.specialities)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={2} container>
              <RenderIfAuthorized hasPermission={[Permission_V15.FACILITIES_ADMIN]}>
                <ClickableIcon onClick={handleClickEditSpecialities}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="primary.main"
                    padding={0.8}
                    marginTop={3}
                    borderRadius={2}
                    boxShadow={1}
                  >
                    <EditIcon />
                  </Box>
                </ClickableIcon>
              </RenderIfAuthorized>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Observer>
                {() => (
                  <>
                    {facilityForm.specialities.value.map((svc: Speciality) => (
                      <Grid item key={svc.Id}>
                        <Chip
                          label={svc.name}
                          onDelete={() => {
                            (facilityForm.specialities.value as IObservableArray).remove(svc);
                          }}
                          color="primary"
                          disabled={facilityForm.mode === FORM_MODE.CONSULT}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </Observer>
            </Grid>
          </Grid>
        </Grid>
        <Observer>
          {() => (
            <>
              {localStore.equipmentDialog && (
                <EquipmentDialog onClose={action(() => (localStore.equipmentDialog = false))} />
              )}
            </>
          )}
        </Observer>
        <Observer>
          {() => (
            <>
              {localStore.specialityDialog && (
                <SpecialityDialog onClose={action(() => (localStore.specialityDialog = false))} />
              )}
            </>
          )}
        </Observer>
      </form>
    </FormContainer>
  );
};

const FormContainer: React.FC = ({ children }) => {
  return (
    <Paper>
      <Box p={2}>{children}</Box>
    </Paper>
  );
};

const EditIcon = styled(TuneIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
}));

const ClickableIcon = styled('div')({
  cursor: 'pointer',
});

const EquipmentDialog: React.FC<{ onClose: VoidFunction }> = ({ onClose }) => {
  const localStore = useLocalStore<{ equipments: Equipment[]; newItem: string }>(() => ({
    equipments: facilityStore.equipmentsArray,
    newItem: '',
  }));
  const handleClickAdd = () => {
    const newEquipment = new Equipment();
    newEquipment.name = localStore.newItem;
    facilityStore.addEquipment(newEquipment).then(() => {
      uiStore.pushSuccessNotification('Nouvel équipement créé avec succès');
    });
  };

  const handleClickDelete = (equipment: Equipment) => () => {
    facilityStore
      .deleteEquipment(equipment)
      .catch((err) => uiStore.pushErrorNotification('Suppression impossible'));
  };

  const handleClickBlock = (equipment: Equipment) => () => {
    facilityStore.toggleBlockEquipment(equipment);
  };

  return (
    <Dialog open={true} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="xs">
      <Box px={1} py={2}>
        <Typography component="h2" variant="h6" style={{ textTransform: 'uppercase' }}>
          Gestion des équipements
        </Typography>
      </Box>
      <Box px={2} display="flex" justifyContent="flex-end">
        <Typography variant="body2">
          {facilityStore.equipmentsArray.length} equipements disponibles
        </Typography>
      </Box>
      <Box p={1}>
        <Divider />
        <Box overflow="auto" maxHeight={200}>
          <Observer>
            {() => (
              <List dense={true}>
                {facilityStore.equipmentsArray.map((it) => (
                  <ListItem key={it.Id} disabled={!it.enabled}>
                    <ListItemText primary={it.name} />
                    <ListItemSecondaryAction>
                      {it.updatable && !it.enabled && (
                        <Tooltip title="Réactiver">
                          <IconButton
                            edge="start"
                            aria-label="deactivate"
                            onClick={handleClickBlock(it)}
                            color="primary"
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {it.updatable && it.deactivatable && it.enabled && (
                        <Tooltip title="Désactiver">
                          <IconButton
                            edge="start"
                            aria-label="deactivate"
                            onClick={handleClickBlock(it)}
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {it.updatable && it.deletable && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            color="secondary"
                            onClick={handleClickDelete(it)}
                            disabled={!it.enabled}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Observer>
        </Box>
        <Box paddingBottom={1}>
          <Divider />
        </Box>
        <Box px={1}>
          <Observer>
            {() => (
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={8}>
                  <MaterialInput
                    label="Nouvel équipment"
                    fullWidth
                    value={localStore.newItem}
                    onChange={action((e: any) => (localStore.newItem = e.target.value))}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClickAdd}
                    color="primary"
                    disabled={!localStore.newItem.length}
                  >
                    <AddCircleIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Observer>
        </Box>

        <Button variant="contained" onClick={onClose}>
          Retour
        </Button>
      </Box>
    </Dialog>
  );
};

const SpecialityDialog: React.FC<{ onClose: VoidFunction }> = ({ onClose }) => {
  const localStore = useLocalStore<{ specialities: Speciality[]; newItem: string }>(() => ({
    specialities: facilityStore.specialitiesArray,
    newItem: '',
  }));
  const handleClickAdd = () => {
    facilityStore.addSpeciality({ name: localStore.newItem }).then(() => {
      uiStore.pushSuccessNotification('Nouveau service créé avec succès');
    });
  };

  const handleClickDelete = (speciality: Speciality) => () => {
    facilityStore
      .deleteSpeciality(speciality)
      .catch((err) => uiStore.pushErrorNotification('Suppression impossible'));
  };

  const handleClickBlock = (speciality: Speciality) => () => {
    facilityStore.toggleBlockSpeciality(speciality);
  };

  return (
    <Dialog open={true} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="xs">
      <Box px={1} py={2}>
        <Typography component="h2" variant="h6" style={{ textTransform: 'uppercase' }}>
          Gestion des services
        </Typography>
      </Box>
      <Box px={2} display="flex" justifyContent="flex-end">
        <Typography variant="body2">
          {localStore.specialities.length} services disponibles
        </Typography>
      </Box>
      <Box p={1}>
        <Divider />
        <Box overflow="auto" maxHeight={200}>
          <Observer>
            {() => (
              <List dense={true}>
                {localStore.specialities.map((it) => (
                  <ListItem key={it.Id} disabled={!it.enabled}>
                    <ListItemText primary={it.name} />
                    <ListItemSecondaryAction>
                      {it.updatable && it.deactivatable && it.enabled && (
                        <Tooltip title="Désactiver">
                          <IconButton
                            edge="start"
                            aria-label="deactivate"
                            onClick={handleClickBlock(it)}
                            disabled={!it.enabled}
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {it.updatable && it.deletable && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            color="secondary"
                            onClick={handleClickDelete(it)}
                            disabled={!it.enabled}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Observer>
        </Box>
        <Box paddingBottom={1}>
          <Divider />
        </Box>
        <Box px={1}>
          <Observer>
            {() => (
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={8}>
                  <MaterialInput
                    label="Nouveau service"
                    fullWidth
                    value={localStore.newItem}
                    onChange={action((e: any) => (localStore.newItem = e.target.value))}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleClickAdd}
                    color="primary"
                    disabled={!localStore.newItem.length}
                  >
                    <AddCircleIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Observer>
        </Box>

        <Button variant="contained" onClick={onClose}>
          Fermer
        </Button>
      </Box>
    </Dialog>
  );
};
