import * as React from 'react';
import {
  TextField,
  InputAdornment,
  makeStyles,
  createStyles,
  Theme,
  BaseTextFieldProps,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export interface ISearchBarProps extends BaseTextFieldProps {
  onCancel?: any;
  onSearch?: (terms: string) => any;
  onChange?: any;
}

export default function SearchBar({ onSearch, ...rest }: ISearchBarProps) {
  const classes = useStyles();
  const [searchContent, setSearchContent] = React.useState('');
  const handleChange = (evt: React.ChangeEvent<any>) => {
    setSearchContent(evt.target.value);
    if (onSearch) {
      onSearch(evt.target.value);
    }
  };
  return (
    <div className={classes.searchWrapper}>
      <TextField
        {...rest}
        variant="outlined"
        value={searchContent}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchWrapper: {
      display: 'flex',
    },
  })
);
