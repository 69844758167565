import { action, computed, observable, runInAction, transaction } from 'mobx';
import Equipment, { EquipmentSynchronizableData } from 'model/facilities/Equipment';
import Facility, { FacilitySynchronizableData } from 'model/facilities/Facility';
import Speciality, {
  SpecialityCreation,
  SpecialitySynchronizableData,
} from 'model/facilities/Speciality';
import { equipmentResourceProvider } from 'resourceProvider/equipment.provider';
import { facilityResourceProvider } from 'resourceProvider/facility.provider';
import { specialityResourceProvider } from 'resourceProvider/speciality.provider';
import { deserialize } from 'serializr';
import { PersistableStore, registerStorableStore, storableStoreHelpers } from 'stores/Persistable';
import { noopCallback } from '../serialize.context';

export default class FacilityStore implements PersistableStore {
  @observable
  equipments = observable.map(new Map<number, EquipmentSynchronizableData>());
  @observable
  specialities = observable.map(new Map<number, SpecialitySynchronizableData>());
  @observable
  facilities = observable.map(new Map<number, FacilitySynchronizableData>());
  @observable
  lastSynchronization: Date | undefined;
  @observable
  dataLoaded = false;

  init() {
    storableStoreHelpers.reactOnUserChanges(() => this.loadFacilities());
    storableStoreHelpers.reactOnNetworkChange(() => this.loadFacilities());
  }

  async loadFacilities() {
    await this.getSpecialities();
    await this.getEquipments();
    await this.getFacilities();

    this.setDataLoaded(true);
  }

  setFacilities(newFacilities: FacilitySynchronizableData[]) {
    this.facilities.clear();
    this.facilities.merge(newFacilities.map((facility) => [facility.data.Id, facility]));
  }

  async getFacilities() {
    const result = await facilityResourceProvider.get().getFacilities();

    const facilities = deserialize(FacilitySynchronizableData, result);
    transaction(() => {
      this.setFacilities(facilities);
      this.setLastSynchronization();
    });
  }

  async getFacility(facilityId: number): Promise<FacilitySynchronizableData | undefined> {
    const facility = await facilityResourceProvider.get().getFacility(facilityId);
    return deserialize(FacilitySynchronizableData, facility, noopCallback);
  }

  async getSpecialities() {
    const result = await specialityResourceProvider.get().getSpecialities();
    const specialities = deserialize(SpecialitySynchronizableData, result, noopCallback);
    this.setSpecialities(specialities);
  }

  @action
  setSpecialities(newSpecialities: SpecialitySynchronizableData[]) {
    this.specialities.clear();
    this.specialities.merge(newSpecialities.map((speciality) => [speciality.data.Id, speciality]));
  }

  async getEquipments() {
    const result = await equipmentResourceProvider.get().getEquipments();
    const specialities = deserialize(EquipmentSynchronizableData, result);
    runInAction(() => {
      this.setEquipments(specialities);
    });
  }

  @action
  setEquipments(newEquipments: EquipmentSynchronizableData[]) {
    this.equipments.clear();
    this.equipments.merge(newEquipments.map((equipment) => [equipment.data.Id, equipment]));
  }

  getFacilityById(facilityId: number): Facility | undefined {
    return this.facilities.get(facilityId)?.data;
  }

  @computed
  get facilitiesArray(): Facility[] {
    return Array.from(this.facilities.values()).map(
      (synchronizableFacility) => synchronizableFacility.data
    );
  }

  @computed
  get enabledFacilitiesArray(): Facility[] {
    return Array.from(this.facilities.values())
      .map((synchronizableFacility) => synchronizableFacility.data)
      .filter((facility) => facility.enabled);
  }

  @computed
  get specialitiesArray(): Speciality[] {
    return Array.from(this.specialities.values()).map(
      (synchronizableSpecialitie) => synchronizableSpecialitie.data
    );
  }

  @computed
  get equipmentsArray(): Equipment[] {
    return Array.from(this.equipments.values()).map(
      (synchronizableEquipment) => synchronizableEquipment.data
    );
  }

  @action
  setLastSynchronization(date: Date = new Date()) {
    this.lastSynchronization = date;
  }

  @action
  setDataLoaded(isLoaded: boolean) {
    this.dataLoaded = isLoaded;
  }

  async addEquipment(newEquipment: Equipment) {
    const result = await equipmentResourceProvider.get().saveEquipment(newEquipment);
    runInAction(() => {
      const equipment = deserialize(EquipmentSynchronizableData, result);
      this.equipments.set(equipment.data!.Id, equipment);
    });
  }

  async addSpeciality(newSpeciality: SpecialityCreation) {
    const result = await specialityResourceProvider.get().saveSpeciality(newSpeciality);
    runInAction(() => {
      const speciality = deserialize(SpecialitySynchronizableData, result);
      this.specialities.set(speciality.data!.Id, speciality);
    });
  }

  async deleteSpeciality(speciality: Speciality) {
    const deletedSpeciality = deserialize(
      SpecialitySynchronizableData,
      await specialityResourceProvider.get().deleteSpeciality(speciality)
    );
    runInAction(() => {
      this.specialities.delete(deletedSpeciality.data.Id);
    });
  }

  async deleteEquipment(equipment: Equipment) {
    const deletedEquipment = deserialize(
      EquipmentSynchronizableData,
      await equipmentResourceProvider.get().deleteEquipment(equipment)
    );
    runInAction(() => {
      this.equipments.delete(deletedEquipment.data.Id);
    });
  }

  async toggleBlockSpeciality(speciality: Speciality) {
    speciality.enabled = !speciality.enabled;
    const updatedSpeciality = deserialize(
      SpecialitySynchronizableData,
      await specialityResourceProvider.get().updateSpeciality(speciality)
    );
    runInAction(() => {
      this.specialities.set(updatedSpeciality.data.Id, updatedSpeciality);
    });
  }

  async toggleBlockEquipment(equipment: Equipment) {
    equipment.enabled = !equipment.enabled;
    const updatedEquipment = deserialize(
      EquipmentSynchronizableData,
      await equipmentResourceProvider.get().updateEquipment(equipment)
    );
    runInAction(() => {
      this.equipments.set(updatedEquipment.data.Id, updatedEquipment);
    });
  }
}

export const facilityStore = new FacilityStore();

registerStorableStore(facilityStore);
