import { action, observable } from 'mobx';
import { EventSynchronizableData } from 'model/event/Event';
import EventVictim from 'model/event/EventVictim';

export default class VictimDetailsStore {
  @observable
  victimEvent?: EventSynchronizableData;
  @observable
  victim?: EventVictim;

  @action
  setVictim(victim: EventVictim) {
    this.victim = victim;
  }

  @action
  setEvent(event: EventSynchronizableData) {
    this.victimEvent = event;
  }

  clearData() {
    this.victimEvent = undefined;
    this.victim = undefined;
  }
}

export const victimDetailsStore = new VictimDetailsStore();
