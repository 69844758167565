import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface PermanentAlgoResultReasonsProps {
  reasons: string[];
}
export const PermanentAlgoResultReasons: FC<PermanentAlgoResultReasonsProps> = ({ reasons }) => {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Motif de recours
      </Typography>
      <List dense={true}>
        {reasons.map((reason, index) => (
          <ListItem key={index}>
            <ListItemText primary={reason} />
          </ListItem>
        ))}
      </List>
    </>
  );
};
