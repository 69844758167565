import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
  FORM_VALID_ON_CHANGE,
} from 'utils/forms';
import { isRequired, isBetween, maxLength } from 'utils/forms/validators';

export default function victimFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    uuid: new Field({
      initValue: initialValues && initialValues.uuid,
      disabled: true,
    }),
    code: new Field({
      initValue: (initialValues && initialValues.code) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        maxLength(6),
        (val, label) =>
          fields.lastName.isEmpty || fields.firstName.isEmpty
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      label: '',
    }),
    lastName: new Field({
      initValue: (initialValues && initialValues.lastName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        maxLength(32),
        (val, label) =>
          fields.code.isEmpty
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    firstName: new Field({
      initValue: (initialValues && initialValues.firstName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        maxLength(32),
        (val, label) =>
          fields.code.isEmpty
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    sex: new Field({
      initValue: (initialValues && initialValues.sex) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    ageRange: new Field({
      initValue: (initialValues && initialValues.ageRange) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    age: new Field({
      initValue: (initialValues && initialValues.age) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isBetween(0, 140)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    addressDepartment: new Field({
      initValue:
        (initialValues && initialValues.addressDepartment && initialValues.addressDepartment.id) ||
        undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    emergencySort: new Field({
      initValue: (initialValues && initialValues.emergencySort) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    address: new Field({
      initValue: (initialValues && initialValues.address) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(200)],
      label: '',
    }),
    facility: new Field({
      initValue: initialValues ? initialValues.facility : undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    stayedThere: new Field({
      initValue: initialValues ? initialValues.stayedThere : false,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    arrivedToFacility: new Field({
      initValue: initialValues ? initialValues.arrivedToFacility : false,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    description: new Field({
      initValue: (initialValues && initialValues.description) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(32)],
      label: '',
    }),
    medicalDiagnostic: new Field({
      initValue: (initialValues && initialValues.medicalDiagnostic) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(32)],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
