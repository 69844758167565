import Facility from './facilities/Facility';
import { serializable, primitive, object } from 'serializr';
import Diagnostic from './Diagnostic';

export enum RegulationType {
  REGULATION = 'REGULATION',
  TRANSFERT = 'TRANSFERT',
}

export enum FacilityAnswer {
  ACCEPT = 'ACCEPT',
  UNAVAILABLE = 'UNAVAILABLE',
  REFUSE = 'REFUSE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  NONE = 'NONE',
}

export default class VictimRegulation {
  @serializable(primitive())
  regulationType?: RegulationType;
  @serializable(object(Facility))
  fromFacility?: Facility;
  @serializable(object(Facility))
  toFacility?: Facility;
  @serializable(primitive())
  facilityAnswer?: FacilityAnswer;
  @serializable(object(Diagnostic))
  diagnostic: Diagnostic;

  constructor() {
    this.diagnostic = new Diagnostic();
  }
}
