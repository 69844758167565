import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { mainTheme } from 'layout/MainLayout';
import { getInputFieldProps } from 'utils/forms';
import MaterialInput from 'components/common/form/MaterialInput';

interface PasswordResetFormProps {
  username: any;
  form?: any;
  onSubmit: (evt: any) => void;
  onCancel: () => void;
}
const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ username, onSubmit, onCancel }) => {
  const classes = useStyles(mainTheme);
  const { t } = useTranslation();

  return useObserver(() => (
    <form className={classes.form} noValidate>
      <Grid item xs={12}>
        <MaterialInput
          // margin="normal"
          required
          fullWidth
          id="username"
          label={t('login.username')}
          name="username"
          autoFocus
          {...getInputFieldProps(username)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Button size="medium" startIcon={<ArrowBackIcon />} onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
        >
          {t('buttons.validate')}
        </Button>
      </Grid>
    </form>
  ));
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
}));

export default PasswordResetForm;
