import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { authStore } from 'stores/auth/auth.store';
import { uiStore } from 'stores/ui.store';
import ErrorMessages from 'components/common/errors';
import { NotificationType } from 'stores/ui.store';
import loginFormBuilder from './login.from.builder';

import LoginForm from './login.form';
import { mainTheme } from 'layout/MainLayout';
import { redirectUserTo } from '../../routes';

const LoginPage: React.FC = () => {
  const classes = useStyles(mainTheme);
  const history = useHistory();
  const { t } = useTranslation();
  const loginForm = loginFormBuilder({});

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    loginForm.validate();
    if (loginForm.isValid) {
      const { username, password } = loginForm;
      authStore
        .login(username.value, password.value)
        .then(() => history.push(redirectUserTo()))
        .catch((err) => {
          if (err.response) {
            const { status } = err.response;
            switch (status) {
              case 403:
                uiStore.pushNotification(t('login.errors.disabled'), NotificationType.ERROR);
                break;
              case 400:
              default:
                uiStore.pushNotification(t('login.errors.credentials'), NotificationType.ERROR);
            }
          }
        });
    }
  };

  const handleClickForgottenPassword = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    history.push('/password-reset/request');
  };

  React.useEffect(() => {
    /* Redirects to homepage if authenticated   */
    if (authStore.isAuthenticated) {
      const redirection: string = redirectUserTo();
      history.push(redirection);
    }
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grid container justify="center" alignItems="center" className={classes.title}>
          <Grid item xs={12} md={10}>
            <Typography component="h1" variant="h1" align="center" color="primary">
              {t('app.title')}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {t('app.description')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={10}>
            <ErrorMessages errors={uiStore.errors} />
          </Grid>
        </Grid>
        <div className={classes.paper}>
          <Typography component="h2" variant="h4">
            {t('login.signin')}
          </Typography>
          <LoginForm
            form={loginForm}
            onSubmit={handleSubmit}
            onClickForgottenPassword={handleClickForgottenPassword}
          />
        </div>
      </Container>
    </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
}));

export default LoginPage;
