import { observable, action } from 'mobx';

export default class CallUIStore {
  @observable confirmSaveDialogOpen = false;

  @action('OPEN_CONFIRMSAVE_DIALOG')
  openConfirmSaveDialog() {
    this.confirmSaveDialogOpen = true;
  }

  @action('CLOSE_CONFIRMSAVE_DIALOG')
  closeConfirmSaveDialog() {
    this.confirmSaveDialogOpen = false;
  }
}
export const callUIStore = new CallUIStore();
