import { serializrErrorTracer } from 'model/model.utilities';
import { primitive, serializable, object } from 'serializr';
import Facility from '../facilities/Facility';
import Victim from '../Victim';
import Event from './Event';

export enum EmergencySort {
  UA = 'UA',
  UR = 'UR',
  UD = 'UD',
  IMP = 'IMP',
  DCD = 'DCD',
  UNSORTED = 'UNSORTED',
}

export enum OrientationStatus {
  TO_ORIENT = 'TO_ORIENT',
  ORIENTED = 'ORIENTED',
}

export enum OperationType {
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
}

export default class EventVictim {
  @serializable(object(Victim, serializrErrorTracer))
  victim: Victim = new Victim();
  @serializable(primitive())
  emergencySort: EmergencySort = EmergencySort.UNSORTED;
  @serializable(primitive())
  arrivedToFacility: boolean = false;
  @serializable(primitive())
  stayedThere: boolean = false;
  @serializable(primitive())
  orientationStatus: OrientationStatus = OrientationStatus.TO_ORIENT;
  @serializable(primitive())
  facilityId?: number;
  @serializable(object(Facility, serializrErrorTracer))
  facility?: Facility;
  @serializable
  operation: OperationType = OperationType.UPSERT;
  @serializable(object(Event, serializrErrorTracer))
  event: Event = new Event();
  // Description du patient
  @serializable(primitive(serializrErrorTracer))
  description?: string;
  // Diagnostic médical
  @serializable(primitive(serializrErrorTracer))
  medicalDiagnostic?: string;
}
