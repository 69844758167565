import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ErrorMessages from 'components/common/errors';
import MainAppBar from 'components/MainAppBar';
import { UpdateAlert } from 'components/UpdateAlert';
import { reaction } from 'mobx';
import * as React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import * as Scroll from 'react-scroll';
import { ErrorMessage, uiStore } from 'stores/ui.store';
import ApplicationInstallBanner from './ApplicationInstallBanner';
import ApplicationInstallBanneriOS from './ApplicationInstallBanneriOS';

const MainLayout: React.FunctionComponent = ({ children }) => {
  const classes = useStyles(mainTheme);

  React.useEffect(() => {
    const reactionDisposer = reaction(
      () =>
        uiStore.errors.map((error: ErrorMessage) => {
          return error.message;
        }),
      () => Scroll.animateScroll.scrollToTop()
    );
    return () => reactionDisposer();
  }, []);

  return (
    <Container component="div" maxWidth="lg" className={classes.root}>
      <CssBaseline />
      <MainAppBar />
      <main className={classes.content}>
        {!isIOS && isMobile && <ApplicationInstallBanner />}
        {isIOS && <ApplicationInstallBanneriOS />}
        <UpdateAlert />
        <Grid container justify="center">
          <Grid item xs={12} sm={10} lg={8}>
            <ErrorMessages errors={uiStore.errors} />
          </Grid>
        </Grid>
        <React.Fragment>{children}</React.Fragment>
      </main>
    </Container>
  );
};

export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#73c45a',
    },
    secondary: {
      main: '#e74c3c',
    },
    action: {
      selected: 'rgba(115, 196, 90, 0.2)',
    },
  },
  typography: {
    h4: {
      fontSize: '1.8rem',
    },
    h6: {
      fontSize: '1.1rem',
    },
  },
  props: {
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      // size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
  },
  overrides: {
    MuiButton: {
      // root: {
      //   color: 'white',
      // },
      // contained: {
      //   color: 'white',
      // },
      outlined: {
        backgroundColor: 'white',
      },
      containedPrimary: {
        color: 'white',
      },
    },
    MuiChip: {
      colorPrimary: {
        color: '#fff',
      },
      colorSecondary: {
        backgroundColor: '#e74c3c',
      },
    },
    MuiFormControl: {
      root: {
        //Tous les inputs ont un fond blanc
        backgroundColor: 'white',
      },
      marginDense: {
        marginTop: 4,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Inconsolata',
        fontSize: 13,
      },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.46)',
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '10px 5px',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 5px',
      },
    },
    MuiSnackbar: {
      root: {
        marginTop: 40,
        marginBottom: 30,
      },
    },
    MuiTablePagination: {
      select: {
        padding: '5px',
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableCell: {},
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 960,
      [theme.breakpoints.down('md')]: {
        minWidth: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    content: {
      paddingTop: '70px',
    },
  })
);

export default MainLayout;
