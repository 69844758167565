import {
  Button,
  Collapse,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Checkbox,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MaterialInput from 'components/common/form/MaterialInput';
import { useLocalStore, Observer } from 'mobx-react-lite';
import { Profile } from 'model/User';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_MODE, FORM_MODE_EDIT, getInputFieldProps } from 'utils/forms';
import { formatPhoneNumber } from 'utils/forms/formatters';
import { permissionsStore } from 'stores/auth/permissions.store';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { action, autorun, IObservableArray, toJS } from 'mobx';
import { MaterialAutocomplete } from 'components/common/form/MaterialAutocomplete';
import { facilityStore } from 'stores/facility';
import Facility from 'model/facilities/Facility';

export interface UserFormProps {
  form: any;
  mode: string;
  onCancel: any;
  onSubmit: any;
  onClickResetPassword?: any;
  userCanEdit?: boolean;
}

interface LocalStore {
  selectedProfile: string | Profile;
  selectedPermissions: IObservableArray<string>;
  formMode: string;
}

const UserForm: React.FC<UserFormProps> = ({
  form,
  mode,
  onCancel,
  onSubmit,
  onClickResetPassword,
  userCanEdit = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const localStore = useLocalStore<LocalStore>(() => ({
    selectedProfile: form.profile.value,
    selectedPermissions: form.permissions.value,
    formMode: mode,
  }));

  React.useEffect(
    () => {
      localStore.selectedProfile = form.profile.value;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.profile.value]
  );

  // Remove facility when selecting a non FACILITY_PERSONAL profile
  React.useEffect(
    () =>
      autorun(() => {
        if (localStore.selectedProfile !== Profile.FACILITY_PERSONAL) {
          form.facilities.reset();
        }
        if (localStore.formMode === FORM_MODE_EDIT) {
          // During modification either we applies default permissions when selecting on  other profile
          if (localStore.selectedProfile !== form.profile.value) {
            localStore.selectedPermissions.replace(
              permissionsStore.getProfileDefaultPermissions(localStore.selectedProfile)
            );
          } else {
            localStore.selectedPermissions.replace(form.permissions.value);
          }
        } else if (localStore.formMode === FORM_MODE.CREATE) {
          // During creation, default permissions apply
          localStore.selectedPermissions.replace(
            permissionsStore.getProfileDefaultPermissions(localStore.selectedProfile)
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(
    () => {
      localStore.formMode = mode;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  React.useEffect(
    () => {
      localStore.selectedPermissions.replace(form.permissions.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.permissions.value]
  );

  const _handleSubmit = () => {
    if (localStore.formMode !== FORM_MODE.CONSULT) {
      form.permissions.setValue(toJS(localStore.selectedPermissions));
      form.profile.setValue(localStore.selectedProfile);
    }
    onSubmit();
  };

  const _handleCancel = () => {
    if (localStore.formMode !== FORM_MODE.CONSULT) {
      //restore values
      form.reset();
      localStore.selectedPermissions.replace(form.permissions.value);
      localStore.selectedProfile = form.profile.value;
    }
    onCancel();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    placeholder={t('forms.user.inputs.lastname.placeholder')}
                    label={t('forms.user.inputs.lastname.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.lastName)}
                    disabled={mode === FORM_MODE.CONSULT}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    placeholder={t('forms.user.inputs.firstname.placeholder')}
                    label={t('forms.user.inputs.firstname.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.firstName)}
                    disabled={mode === FORM_MODE.CONSULT}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    type="email"
                    placeholder={t('forms.user.inputs.email.placeholder')}
                    label={t('forms.user.inputs.email.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.email)}
                    disabled={mode === FORM_MODE.CONSULT}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    type="tel"
                    placeholder={t('forms.user.inputs.phone.label')}
                    label={t('forms.user.inputs.phone.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.phone, {}, { formatter: formatPhoneNumber })}
                    maxLength={8}
                    disabled={mode === FORM_MODE.CONSULT}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    label={t('forms.user.inputs.profile.label')}
                    displayEmpty={false}
                    required
                    value={localStore.selectedProfile}
                    onChange={action((e: any) => {
                      localStore.selectedProfile = e.target.value;
                    })}
                    disabled={mode === FORM_MODE.CONSULT}
                  >
                    {Object.keys(Profile)
                      .filter((pfl) => pfl !== Profile.NONE)
                      .map((key) => (
                        <MenuItem key={key} value={key}>
                          {t(`users.profiles.${key}`)}
                        </MenuItem>
                      ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <>
                    {localStore.selectedProfile === Profile.FACILITY_PERSONAL && (
                      <MaterialAutocomplete
                        multiple
                        options={facilityStore.enabledFacilitiesArray}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option: Facility, value: Facility) =>
                          option.id === value.id
                        }
                        label="Établissement d'attribution"
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        openOnFocus
                        fullWidth
                        required={localStore.selectedProfile === Profile.FACILITY_PERSONAL}
                        {...getInputFieldProps(form.facilities)}
                      />
                    )}
                  </>
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} container>
              <Observer>
                {() => (
                  <>
                    {permissionsStore.aggregatedPermissions[localStore.selectedProfile] &&
                      Object.entries(
                        permissionsStore.aggregatedPermissions[localStore.selectedProfile]
                      )
                        .sort(
                          (
                            [module1, p1]: [string, Record<string, boolean>],
                            [module2, p2]: [string, Record<string, boolean>]
                          ) => module1.localeCompare(module2)
                        )
                        .map(([module, permissions]: [string, Record<string, boolean>]) => (
                          <>
                            <Grid item xs={12}>
                              <b>{t(`users.modules.${module}`)}</b>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                              {Object.entries(permissions).map(
                                ([perm, isDefault]: [string, boolean]) => (
                                  <div
                                    style={{
                                      border: '1px solid black',
                                      display: 'flex',
                                      flexGrow: 1,
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderBottom: '1px solid black',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {t(`users.permissions.${perm}`) || `_`}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <Checkbox
                                        color="primary"
                                        size="small"
                                        disabled={mode === FORM_MODE.CONSULT || isDefault}
                                        checked={
                                          localStore.selectedPermissions.includes(perm) || isDefault
                                        }
                                        onChange={(e) =>
                                          !e.target.checked
                                            ? localStore.selectedPermissions.remove(perm)
                                            : localStore.selectedPermissions.push(perm)
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </Grid>
                          </>
                        ))}
                  </>
                )}
              </Observer>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={mode !== FORM_MODE.CREATE}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      onClick={onClickResetPassword}
                      disabled={!userCanEdit}
                    >
                      <Typography color="textSecondary">Réinitialiser le mot de passe</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Button size="medium" startIcon={<ArrowBackIcon />} onClick={_handleCancel}>
          {mode === FORM_MODE.CONSULT ? 'Retour' : 'Annuler'}
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          onClick={_handleSubmit}
          disabled={!userCanEdit}
        >
          {mode === FORM_MODE.EDIT
            ? 'Enregistrer'
            : mode === FORM_MODE.CREATE
            ? 'Créer'
            : 'Modifier'}
        </Button>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1, 2),
    },
  })
);

export default UserForm;
