import { Loader } from 'components/common/Loader';
import { observer } from 'mobx-react-lite';
import { UIPermanentAlgoRunResult } from 'model/algo/permanent';
import { CallMotif, CallType } from 'model/Call';
import { TransferFacilities } from 'model/facilities/Facility';
import React, { FC, useState } from 'react';
import { PermanentAlgoStore } from 'stores/algo/permanent';
import { callStore } from 'stores/call';
import { facilityStore } from 'stores/facility';
import { PermanentAlgoResult } from './PermanentAlgoResult';
import { PermanentAlgoWelcome } from './PermanentAlgoWelcome';
import { Button } from '@material-ui/core';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import { AlgoErrorBoundary } from 'components/algorithm/AlgoErrorBoundary';
import { CommonAlgo } from 'components/algorithm/CommonAlgo';
import { TransferForm } from '../TransfertForm';
import { CallMotifContainer } from 'components/algorithm/CallMotifContainer';

interface PermanentAlgoDispatcherProps {
  permanentAlgoStore: PermanentAlgoStore;
  callType?: CallType;
  transferFacilities?: TransferFacilities;
  callResult?: UIPermanentAlgoRunResult;
  algoAvailable: boolean;
  onMotifSelection: (motif: CallMotif) => void;
}
export const PermanentAlgoDispatcher: FC<PermanentAlgoDispatcherProps> = observer(
  ({
    permanentAlgoStore,
    callType,
    transferFacilities,
    callResult,
    algoAvailable = false,
    onMotifSelection,
  }) => {
    const [existingResult, setExistingResult] = useState<UIPermanentAlgoRunResult | undefined>(
      callResult
    );
    const [started, setStarted] = useState<boolean>(false);

    const [selectedMotif, setSelectedMotif] = useState<boolean>(false);

    const currentQuestionsGroup = permanentAlgoStore.currentQuestionsGroup;

    const handleClickRestart = () => {
      setExistingResult(undefined);
      setStarted(true);
      permanentAlgoStore.startAlgo();
    };

    const handleMotifSelection = (motif: CallMotif) => {
      onMotifSelection(motif);
      if (motif) setSelectedMotif(true);
    };

    React.useEffect(() => {
      setExistingResult(callResult);
    }, [callResult]);

    if (callType === CallType.TRANSFER) {
      return (
        <TransferForm
          callStore={callStore}
          facilityStore={facilityStore}
          transferFacilities={transferFacilities}
        />
      );
    }
    if (!permanentAlgoStore.algoLoaded) {
      return (
        <AlgoContainer>
          <Loader />
        </AlgoContainer>
      );
    }

    const resultToDisplay =
      existingResult || (!currentQuestionsGroup && permanentAlgoStore.algoRunResult);
    if (resultToDisplay) {
      return (
        <AlgoErrorBoundary>
          <PermanentAlgoResult
            algoRunResult={resultToDisplay}
            actionButton={
              <Button onClick={handleClickRestart} variant="outlined" color="secondary">
                Recommencer
              </Button>
            }
          />
        </AlgoErrorBoundary>
      );
    }

    if (!started) {
      return (
        <PermanentAlgoWelcome
          algoActivated={algoAvailable && callType === CallType.EMERGENCY}
          onStartAlgo={() => {
            setStarted(true);
          }}
        />
      );
    } else if (!selectedMotif) {
      return <CallMotifContainer onSelectMotif={handleMotifSelection} />;
    } else {
      return (
        <CommonAlgo
          algoLoaded={permanentAlgoStore.algoLoaded}
          allQuestionsGroups={permanentAlgoStore.allQuestionsGroups}
          currentUiQuestionsGroup={permanentAlgoStore.currentQuestionsGroup}
          uiVerticalModeSubGroupContext={permanentAlgoStore.verticalModeSubGroupContext}
          goToPreviousQuestionGroup={permanentAlgoStore.goToPreviousQuestionGroup}
          setUserResponse={permanentAlgoStore.setUserResponse}
          startAlgo={permanentAlgoStore.startAlgo}
        />
      );
    }
  }
);
