import { Grid } from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSelectBlocs from 'components/common/form/MaterialSelectBlocs';
import { CallType } from 'model/Call';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFieldProps } from 'utils/forms';
import { InfoSubBloc } from './FormBloc';

type CallTypeProps = {
  callTypeInputProps: InputFieldProps;
  purposeInputProps: InputFieldProps;
};
export const CallTypeFormFields: FC<CallTypeProps> = ({
  callTypeInputProps,
  purposeInputProps,
}) => {
  const { t } = useTranslation();

  return (
    <InfoSubBloc>
      <Grid container spacing={1} justify="space-between">
        <Grid item lg={6}>
          <MaterialSelectBlocs
            required
            label="Type d'appel"
            options={Object.values(CallType)
              .filter((val) => typeof val === 'string')
              .map((val) => ({
                id: val,
                value: t(`forms.call.type.${val}`),
              }))}
            size="large"
            {...callTypeInputProps}
          />
        </Grid>
        <Grid item lg={6}>
          <MaterialInput
            label={"Circonstances de l'urgence"}
            multiline={true}
            rows={2}
            rowsMax={4}
            fullWidth
            inputProps={{ maxLength: 249 }}
            {...purposeInputProps}
          />
        </Grid>
      </Grid>
    </InfoSubBloc>
  );
};
