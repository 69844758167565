import {
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { callApi } from 'api';
import { CallStatus, CallType, RegulationMeans } from 'model/Call';
import { SeverityDegree } from 'model/Diagnostic';

export const StatisticsTablePage: FC<{ dateStart: any; dateEnd: any }> = ({
  dateStart,
  dateEnd,
}) => {
  const [callTotalCount, setCallTotalCount] = useState<number | undefined>();
  const [countIndex1, setCountIndex1] = useState<number | undefined>();
  const [countIndex2, setCountIndex2] = useState<number | undefined>();
  const [countIndex3, setCountIndex3] = useState<number | undefined>();
  const [countIndex4, setCountIndex4] = useState<number | undefined>();
  const [countIndex5, setCountIndex5] = useState<number | undefined>();
  const [countIndex6, setCountIndex6] = useState<number | undefined>();
  const classes = useStyles();

  useEffect(() => {
    handleSubmit(dateStart, dateEnd);
  }, [dateStart, dateEnd]);

  async function handleSubmit(dateStart: any, dateEnd: any) {
    // event?.preventDefault();
    // Nbre d’appels total
    const callTotalCount = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
    });
    setCallTotalCount(callTotalCount);
    //Nbre d’appels de type transfert et urgence (à réguler + régulé)
    const countIndex1 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      callType: [CallType.EMERGENCY, CallType.HARMFUL],
    });
    setCountIndex1(countIndex1);
    // Nbre d’appels régulés (transfert et urgence)
    const countIndex2 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      callType: [CallType.EMERGENCY, CallType.HARMFUL],
      status: [CallStatus.REGULATED],
    });
    setCountIndex2(countIndex2);
    // Nbre d’urgences absolues total (régulé avec diagnostic en UA)
    const countIndex3 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      status: [CallStatus.REGULATED],
      diagnosticSeverityIndexes: [SeverityDegree.UA],
    });
    setCountIndex3(countIndex3);
    // Nbre d’urgences absolues avec moyen envoyé (régulé avec diagnostic en UA + appel avec envoie de moyen + appel ayant necessité envoie de moyen mais moyen indisponible)
    const countIndex4 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      status: [CallStatus.REGULATED],
      diagnosticSeverityIndexes: [SeverityDegree.UA],
      regulationMeans: [RegulationMeans.NECESSARY_SENT, RegulationMeans.NECESSARY_NOT_AVAILABLE],
    });
    setCountIndex4(countIndex4);
    // Nbre de cas covid confirmés (appel régulé avec diagnostic : Covid Primaire ou Covid Avancé ou Covid rare)
    const countIndex5 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      status: [CallStatus.REGULATED],
      diagnosticSeverityIndexes: [SeverityDegree.UA],
      anyDiagnostics: [36, 37, 38],
    });
    setCountIndex5(countIndex5);
    // Nbre de cas covid suspects
    const countIndex6 = await callApi.countCalls({
      userCreationDateStart: dateStart || undefined,
      userCreationDateEnd: dateEnd || undefined,
      status: [CallStatus.REGULATED],
      diagnosticSeverityIndexes: [SeverityDegree.UA],
      anyDiagnostics: [125],
    });
    setCountIndex6(countIndex6);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: 'transparent', maxHeight: 600 }}
        >
          <Table className={classes.table} aria-label="calls_table" size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography>Titre</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>Décompte</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Nbre d’appels total */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={callTotalCount !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">Total d'appel(s)</Typography>
                  </TableCell>
                  <TableCell align="right">{callTotalCount}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre d’appels de type transfert et urgence (à réguler + régulé) */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex1 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">
                      Appel(s) régulé(s) et à réguler de type Transfert et Urgence
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex1}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre d’appels régulés (transfert et urgence) */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex2 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">
                      Appel(s) régulé(s) de type Transfert et Urgence
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex2}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre d’urgences absolues total (régulé avec diagnostic en UA) */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex3 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">Appel(s) régulé(s) avec Urgence Absolue</Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex3}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre d’urgences absolues avec moyen envoyé (régulé avec diagnostic en UA + appel avec envoie de moyen + appel ayant necessité envoie de moyen mais moyen indisponible) */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex4 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">
                      Appel(s) régulé(s) avec Urgence Absolue et Moyen Envoyé
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex4}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre de cas covid confirmés (appel régulé avec diagnostic : Covid Primaire ou Covid Avancé ou Covid rare) */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex5 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">Cas covid confirmé(s)</Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex5}</TableCell>
                </TableRow>
              </Fade>
              {/* Nbre de cas covid suspects */}
              <Fade timeout={{ appear: 450, enter: 600 }} in={countIndex6 !== undefined}>
                <TableRow className={classes.tableRow}>
                  <TableCell scope="row" align="left">
                    <Typography variant="body2">Cas covid suspecté(s)</Typography>
                  </TableCell>
                  <TableCell align="right">{countIndex6}</TableCell>
                </TableRow>
              </Fade>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 800,
      backgroundColor: theme.palette.background.paper,
    },
    tableRow: {
      height: theme.spacing(6),
    },
  })
);
