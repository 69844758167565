import { appStatusStore } from 'stores/appStatus.store';
import { IDBRecord, ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { eventApi } from '../api';
import Event, { EventStatus, EventSynchronizableData } from '../model/event/Event';

function eventsArrayToRecord(events: Event[]): IDBRecord<Event> {
  return events.reduce<IDBRecord<Event>>((record, event) => {
    record[event.uuid] = event;
    return record;
  }, {});
}

export interface EventResourceMethods {
  getEvents: (statuses: EventStatus[]) => Promise<EventSynchronizableData[]>;
  getEvent: (uuid: string) => Promise<EventSynchronizableData>;
  createEvent: (event: EventSynchronizableData) => Promise<EventSynchronizableData>;
  updateEvent: (uuid: string, event: EventSynchronizableData) => Promise<EventSynchronizableData>;
  deleteEvent: (uuid: string, event: EventSynchronizableData) => Promise<void>;
}

const EVENTS_COLLECTION_KEY = 'events:list';

export const eventResourceProvider: ResourceProvider<EventResourceMethods> = {
  onlineResourceProvider: {
    getEvents: async (statuses: EventStatus[]) => {
      const response = await eventApi.getEvents(statuses);
      const eventsRecord = eventsArrayToRecord(response.content);
      const storedEvents: IDBRecord<EventSynchronizableData> = await storableStoreHelpers.onlineSetRecords(
        EVENTS_COLLECTION_KEY,
        Event,
        eventsRecord
      );
      return Object.values(storedEvents);
    },
    createEvent: async (synchronizableEvent: EventSynchronizableData) => {
      const response = await eventApi.createEvent(synchronizableEvent.data);
      synchronizableEvent.data = response;
      const stored = await storableStoreHelpers.onlineSetRecordItem(
        EVENTS_COLLECTION_KEY,
        response.uuid,
        synchronizableEvent
      );

      return stored;
    },
    updateEvent: async (uuid: string, synchronizableEvent: EventSynchronizableData) => {
      const response = await eventApi.updateEvent(uuid, synchronizableEvent.data);
      synchronizableEvent.data = response;
      const stored = await storableStoreHelpers.onlineSetRecordItem(
        EVENTS_COLLECTION_KEY,
        response.uuid,
        synchronizableEvent
      );
      return stored;
    },
    getEvent: async (uuid: string) => {
      const response = await eventApi.getEvent(uuid);
      const victimsResponse = await eventApi.getEventVictims(uuid);
      response.victims = victimsResponse.content;
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        EVENTS_COLLECTION_KEY,
        Event,
        response.uuid,
        response
      );
      return stored;
    },
    deleteEvent: async (uuid: string, synchronizableEvent: EventSynchronizableData) => {
      const response = await eventApi.deleteEvent(uuid);
      synchronizableEvent.data = response;
      await storableStoreHelpers.onlineDeleteRecordItem(
        EVENTS_COLLECTION_KEY,
        response.uuid,
        synchronizableEvent
      );
    },
  },
  offlineResourceProvider: {
    getEvents: async (statuses: EventStatus[]) => {
      const storedEvents = await storableStoreHelpers.offlineGet(EVENTS_COLLECTION_KEY);
      return Object.values(storedEvents).filter((evt) => statuses.includes(evt.data.status));
    },
    createEvent: async (synchronizableEvent: EventSynchronizableData) => {
      const stored = await storableStoreHelpers.setUnsynchronizedRecordItem(
        EVENTS_COLLECTION_KEY,
        synchronizableEvent.data.uuid,
        synchronizableEvent
      );
      return stored;
    },
    updateEvent: async (uuid: string, synchronizableEvent: EventSynchronizableData) => {
      const stored = await storableStoreHelpers.setUnsynchronizedRecordItem(
        EVENTS_COLLECTION_KEY,
        synchronizableEvent.data.uuid,
        synchronizableEvent
      );
      return stored;
    },
    getEvent: async (uuid: string) => {
      const storedEvents = await storableStoreHelpers.offlineGetRecord<Event>(
        EVENTS_COLLECTION_KEY
      );
      return storedEvents[uuid];
    },
    deleteEvent: async (
      uuid: string,
      synchronizableEvent: EventSynchronizableData
    ): Promise<void> => {
      await storableStoreHelpers.offlineDeleteRecordItem(
        EVENTS_COLLECTION_KEY,
        uuid,
        synchronizableEvent
      );
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
