import { identifier, serializable, reference, custom, list, primitive } from 'serializr';
import { resolveCallReasonById } from 'stores/algo/permanent/permanentAlgo.resolvers';
import {
  algoCombinaisonDeserializer,
  algoCombinaisonSerializer,
  AlgoCombinaison,
} from '../AlgoCombinaison';
import { MatchCombinaison } from '../MatchCombinaison';
import { AlgoCallReason } from '../AlgoCallReason';

export class PermanentAlgoResultCallReason implements MatchCombinaison {
  @serializable(identifier())
  id?: number;

  @serializable(reference(AlgoCallReason, resolveCallReasonById as any))
  reason?: AlgoCallReason;

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable(list(primitive()))
  responses?: number[];
}
