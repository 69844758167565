import { Grid, Typography } from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSelectBlocs from 'components/common/form/MaterialSelectBlocs';
import { useObserver } from 'mobx-react-lite';
import { VictimAge, VictimSex } from 'model/Victim';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_MODE_CONSULT, InputFieldProps } from 'utils/forms';
import { InfoSubBloc } from './FormBloc';
import { numbersOnly } from 'utils/input.utilities';

export type VictimFormFieldsProps = {
  formMode: string;
  editing: boolean;
  callerIsVictim: boolean;
  victimLastNameInputProps: InputFieldProps;
  victimFirstNameInputProps: InputFieldProps;
  victimSexInputProps: InputFieldProps;
  victimAgeRangeInputProps: InputFieldProps;
  victimAgeInputProps: InputFieldProps;
};

export const VictimFormFields: FC<VictimFormFieldsProps> = ({
  formMode,
  editing,
  callerIsVictim,
  victimLastNameInputProps,
  victimFirstNameInputProps,
  victimSexInputProps,
  victimAgeRangeInputProps,
  victimAgeInputProps,
}) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <InfoSubBloc>
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="flex-end">
        <Grid item xs={12}>
          <Typography variant="button">Victime</Typography>
        </Grid>

        <Grid item xs={4}>
          <MaterialInput
            size="small"
            fullWidth
            label="Nom"
            {...victimLastNameInputProps}
            disabled={formMode === FORM_MODE_CONSULT || callerIsVictim === true}
            editing={editing}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialInput
            size="small"
            fullWidth
            label={'Prénom'}
            {...victimFirstNameInputProps}
            disabled={formMode === FORM_MODE_CONSULT || callerIsVictim === true}
            editing={editing}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialInput size="small" fullWidth label={'N° bracelet'} />
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={12} md={4}>
            <MaterialSelectBlocs
              label={'Sexe'}
              options={Object.values(VictimSex)
                .filter((val) => typeof val === 'string')
                .map((val) => ({
                  id: val,
                  value: t(`forms.call.victim.sex.${val}`),
                }))}
              editing={editing}
              {...victimSexInputProps}
            />
          </Grid>
          <Grid item xs={10} md={7}>
            <MaterialSelectBlocs
              label={'Âge (tranche)'}
              options={Object.values(VictimAge)
                .filter((va) => va !== VictimAge.FROM_16_TO_49_YEARS) //deprecated slice
                .map((val) => ({
                  id: val,
                  value: t(`forms.call.victim.age.${val}`),
                }))}
              editing={editing}
              {...victimAgeRangeInputProps}
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <MaterialInput
              size="small"
              fullWidth
              label="Âge"
              type="number"
              {...victimAgeInputProps}
              disabled={formMode === FORM_MODE_CONSULT}
              editing={editing}
              inputProps={{ min: 0, max: 140 }}
              onKeyDown={numbersOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </InfoSubBloc>
  ));
};
