import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { eventApi } from 'api';
import { Spacer } from 'components/Spacer';
import { observer, useLocalStore } from 'mobx-react-lite';
import Event from 'model/event/Event';
import { DashboardSearch } from 'model/event/SearchEvent';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { eventStore } from 'stores/event';
import { runInAction } from 'mobx';
import { EmergencySort } from 'model/event/EventVictim';
import { DashboardData } from 'model/Dashboard';
import { Dashboard } from 'components/dashboard';
import { dashboardDataToRowItems } from './event.dashboard.utilities';
import { useTranslation } from 'react-i18next';
import { HeaderKey } from 'components/dashboard/dashboard.component';

type EventDashboardPageLocalStore = {
  selectedEvent?: Event;
  dashboardData?: DashboardData;
};

export const EventDashboardPage = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventUuid } = useParams<{ eventUuid: string }>();

  const localStore = useLocalStore<EventDashboardPageLocalStore>(() => ({}));

  useEffect(() => {
    eventStore.getEvent(eventUuid).then((event) => {
      runInAction(() => {
        localStore.selectedEvent = event?.data;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventUuid]);

  useEffect(() => {
    const { selectedEvent } = localStore;
    if (!selectedEvent) {
      return;
    }
    const eventId = selectedEvent?.id;
    if (eventId === undefined) {
      throw new Error('Event should have an id');
    }

    const search: DashboardSearch = {
      eventsIds: [eventId],
      groupBy: 'FACILITIES',
    };
    eventApi.getDashboard(search).then((data) => {
      runInAction(() => {
        localStore.dashboardData = data;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStore.selectedEvent]);

  const goBack = () => {
    history.goBack();
  };

  const { data, total } = useMemo(() => {
    if (!localStore.dashboardData) {
      return { data: [], total: [] };
    }
    return dashboardDataToRowItems(localStore.dashboardData);
  }, [localStore.dashboardData]);

  return (
    <Grid container>
      <Grid container item>
        <Button onClick={goBack} color="primary" variant="outlined" startIcon={<ArrowBackIcon />}>
          Retour
        </Button>
        <Spacer direction="horizontal" size="1rem" />
        <Typography variant="h4" component="h1">
          {`Tableau de bord de "${localStore.selectedEvent?.title}"`}
        </Typography>
        <Grid container item xs={4} justify="flex-end" alignItems="center">
          <Typography variant="subtitle1">{`${localStore.selectedEvent?.victims.length} victime(s) visible(s) sur  ${localStore.selectedEvent?.totalVictims} déclarée(s)`}</Typography>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="1rem" />
      {localStore.dashboardData?.total === 0 && (
        <Typography variant="subtitle1">Cet événement ne contient aucune victime</Typography>
      )}
      {localStore.dashboardData?.total !== 0 && (
        <Dashboard
          headerNames={Object.values<HeaderKey>(EmergencySort)
            .concat('total')
            .map((es) => t(`dashboards.events.columns.${es}`))}
          data={data}
          total={total}
        />
      )}
    </Grid>
  );
});
