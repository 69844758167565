export class NeedConfirmError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NeedConfirmError';
  }
}
export class NeedTransferFacilitiesError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NeedTransferFacilitiesError';
  }
}

export class CallPriorityRequiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CallPriorityRequiredError';
  }
}
