import { Button, Grid, Link, makeStyles } from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getInputFieldProps } from 'utils/forms';

export interface LoginFormProps {
  form: any;
  onSubmit: any;
  onClickForgottenPassword?: any;
}

const LoginForm: React.FC<LoginFormProps> = ({ form, onSubmit, onClickForgottenPassword }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <form className={classes.form} noValidate>
          <Observer>
            {() => (
              <MaterialInput
                required
                fullWidth
                id="username"
                label={t('login.username')}
                name="username"
                autoComplete="username"
                autoFocus
                {...getInputFieldProps(form.username)}
              />
            )}
          </Observer>
          <Observer>
            {() => (
              <MaterialInput
                required
                fullWidth
                name="password"
                label={t('login.password')}
                type="password"
                id="password"
                autoComplete="current-password"
                {...getInputFieldProps(form.password)}
              />
            )}
          </Observer>
          <Link href="" onClick={onClickForgottenPassword}>
            Mot de passe oublié ?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
          >
            {t('login.signin')}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default LoginForm;
