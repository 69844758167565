import { Button, ButtonGroup } from '@material-ui/core';
import { CommonAlgoResponse } from 'model/algo';
import { UIWithResponsesQuestion } from 'model/algo';
import React, { FC } from 'react';
import { QuestionContainer } from '../QuestionContainer';

interface QuestionButtonProps {
  selected: CommonAlgoResponse | null;
  question: UIWithResponsesQuestion;
  withoutLabel?: boolean;
  onResponse: (choice: CommonAlgoResponse) => void;
}

export const QuestionButton: FC<QuestionButtonProps> = ({
  selected,
  question,
  withoutLabel,
  onResponse,
}) => {
  return (
    <QuestionContainer
      renderLabel={!withoutLabel ? question.label : undefined}
      description={question.description}
    >
      <ButtonGroup
        color="primary"
        orientation={question.responses.length > 2 ? 'vertical' : 'horizontal'}
        size="large"
      >
        {question.responses.map((response, index) => (
          <Button
            key={index}
            variant={selected?.Id === response.Id ? 'contained' : undefined}
            onClick={() => onResponse(response)}
          >
            {response.label}
          </Button>
        ))}
      </ButtonGroup>
    </QuestionContainer>
  );
};
