import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
// import ErrorMessages from 'components/common/errors';
import { mainTheme } from 'layout/MainLayout';

const NoAuthorizationPage: React.FC = () => {
  const classes = useStyles(mainTheme);
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Grid container justify="center" alignItems="center" className={classes.title}>
          <Grid item xs={12} md={10}>
            <Typography component="h1" variant="h1" align="center" color="primary">
              {t('app.title')}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {
                "Vous n'avez pas les autorisations nécessaires pour  accéder à l'application, merci de contacter votre  administrateur"
              }
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container justify="center">
          <Grid item xs={12} md={10}>
            <ErrorMessages errors={uiStore.errors} />
          </Grid>
        </Grid> */}
      </Container>
    </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default NoAuthorizationPage;
