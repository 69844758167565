import { Divider, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { MaterialAutocomplete } from 'components/common/form/MaterialAutocomplete';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import React, { FC, useMemo } from 'react';
import { locationStore } from 'stores/location.store';
import { InputFieldProps } from 'utils/forms';
import { InfoSubBloc } from './FormBloc';
import { CallType } from 'model/Call';
import { Observer } from 'mobx-react-lite';

type LocationFormFieldsProps = {
  editing: boolean;
  locationDepartmentInputProps: InputFieldProps;
  locationStreetInputProps: InputFieldProps;
  locationCityInputProps: InputFieldProps;
  locationSquareInputProps: InputFieldProps;
  locationAdditionalInfosInputProps: InputFieldProps;
  currentCallType: CallType;
};
export const LocationFormFields: FC<LocationFormFieldsProps> = ({
  editing,
  locationDepartmentInputProps,
  locationStreetInputProps,
  locationCityInputProps,
  locationSquareInputProps,
  locationAdditionalInfosInputProps,
  currentCallType,
}) => {
  const departments = locationStore.departmentsArray;
  const cities = locationStore.citiesArray;

  const filteredCities = useMemo(() => {
    const currentSelectedDepartment = locationDepartmentInputProps.value;
    const filteredCities = currentSelectedDepartment?.length
      ? cities.filter((city) => city.district?.department?.ihsiCode === currentSelectedDepartment)
      : cities;

    return filteredCities.map((city) => city.name);
  }, [locationDepartmentInputProps.value, cities]);

  return (
    <InfoSubBloc>
      <Grid container spacing={1} direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="button">Localisation</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required={currentCallType !== CallType.HARMFUL}
                fullWidth
                size="small"
                label={'Département'}
                editing={editing}
                {...locationDepartmentInputProps}
              >
                {departments.map(({ name, ihsiCode }) => (
                  <MenuItem key={ihsiCode} value={ihsiCode}>
                    {name}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} md={8}>
          <MaterialInput
            size="small"
            label={'N° et nom de rue'}
            fullWidth
            editing={editing}
            {...locationStreetInputProps}
          />
        </Grid>

        <Grid item xs={3}>
          <Observer>
            {() => (
              <MaterialAutocomplete
                required={currentCallType !== CallType.HARMFUL}
                value={locationCityInputProps.value || null}
                options={filteredCities}
                label="Commune"
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={locationCityInputProps.onChange}
                onBlur={locationCityInputProps.onBlur}
                openOnFocus
                fullWidth
                editing={editing}
              />
            )}
          </Observer>
        </Grid>

        <Grid item xs={3}>
          <MaterialInput size="small" label={'Quartier'} fullWidth {...locationSquareInputProps} />
        </Grid>
        <Grid item xs={6}>
          <MaterialInput
            size="small"
            label={'Repère et informations complémentaires'}
            fullWidth
            editing={editing}
            {...locationAdditionalInfosInputProps}
          />
        </Grid>
        <Divider />
      </Grid>
    </InfoSubBloc>
  );
};
