import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { PriorityIndicator } from 'components/common/Priority/PriorityIndicator';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import React, { FC } from 'react';

interface PermanentAlgoResultTitleProps {
  priority: PermanentAlgoPriority;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PermanentAlgoResultTitle: FC<PermanentAlgoResultTitleProps> = (props) => {
  return (
    <Container>
      <Typography variant="h4" color="textSecondary">
        Résultat :
      </Typography>
      <PriorityIndicator priority={props.priority} />
    </Container>
  );
};
