import { appStatusStore } from 'stores/appStatus.store';
import { IDBRecord, ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { facilityApi } from '../api';
import Facility, { FacilitySynchronizableData } from 'model/facilities/Facility';

function facilitiesArrayToRecord(facilities: Facility[]): IDBRecord<Facility> {
  return facilities.reduce<IDBRecord<Facility>>((record, facility) => {
    if (facility.id) {
      record[facility.id] = facility;
    }
    return record;
  }, {});
}

export interface FacilityResourceMethods {
  getFacilities: () => Promise<FacilitySynchronizableData[]>;
  getFacility: (facilityId: number) => Promise<FacilitySynchronizableData>;
}

const FACILITIES_COLLECTION_KEY = 'facilities:list';

export const facilityResourceProvider: ResourceProvider<FacilityResourceMethods> = {
  onlineResourceProvider: {
    getFacilities: async () => {
      const response = await facilityApi.getFacilities();
      const callsRecord = facilitiesArrayToRecord(response.content);

      const storedFacilities: IDBRecord<FacilitySynchronizableData> = await storableStoreHelpers.onlineSetRecords(
        FACILITIES_COLLECTION_KEY,
        Facility,
        callsRecord
      );

      return Object.values(storedFacilities);
    },
    getFacility: async (facilityId: number) => {
      const response = await facilityApi.getFacility(facilityId);
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        FACILITIES_COLLECTION_KEY,
        Facility,
        String(facilityId),
        response
      );

      return stored;
    },
  },
  offlineResourceProvider: {
    getFacilities: async () => {
      const storedFacilities = await storableStoreHelpers.offlineGet(FACILITIES_COLLECTION_KEY);
      return Object.values(storedFacilities);
    },

    getFacility: async (facilityId: number) => {
      const storedFacilities = await storableStoreHelpers.offlineGetRecord<Facility>(
        FACILITIES_COLLECTION_KEY
      );
      return storedFacilities[facilityId];
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
