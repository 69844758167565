import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, Paper } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { FC, FormEvent, useState } from 'react';
import { exportsService } from 'services';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

export const EventExportPage: FC = () => {
  const [dateStart, setDateStart] = useState<string | null>(null);
  const [dateEnd, setDateEnd] = useState<string | null>(null);
  const [dateStartError, setDateStartError] = useState<string | undefined>();
  const [dateEndError, setDateEndError] = useState<string | undefined>();
  const history = useHistory();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!dateStart) {
      setDateStartError('Champ obligatoire');
    }
    if (!dateEnd) {
      setDateEndError('Champ obligatoire');
    }
    if (dateStart && dateEnd) {
      exportsService.saveEventExportAs({
        userCreationDateStart: dateStart || undefined,
        userCreationDateEnd: dateEnd || undefined,
      });
    }
  }
  function handleDateStartChange(date: MaterialUiPickersDate | null) {
    let dateError;
    let strDate;
    if (date) {
      strDate = date.toString();
      try {
        strDate = date.toISOString();
      } catch (e) {
        dateError = 'Date invalide'; // i18nized
      }
    } else {
      strDate = null;
      dateError = 'Champ obligatoire'; // i18nized
    }
    setDateStart(strDate);
    setDateStartError(dateError);
  }

  function handleDateEndChange(date: MaterialUiPickersDate | null) {
    let dateError;
    let strDate;

    if (date) {
      strDate = date.toString();
      try {
        strDate = date.toISOString();
      } catch (e) {
        dateError = 'Date invalide'; // i18nized
      }
    } else {
      strDate = null;
      dateError = 'Champ obligatoire'; // i18nized
    }
    setDateEnd(strDate);
    setDateEndError(dateError);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit} noValidate>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="center" spacing={4}>
                <Grid item>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-start"
                    label="Date de début"
                    value={dateStart}
                    onChange={handleDateStartChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date start',
                    }}
                    required
                    error={!!dateStartError}
                    helperText={dateStartError}
                    autoOk
                    disableFuture
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-end"
                    label="Date de fin"
                    value={dateEnd}
                    onChange={handleDateEndChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date end',
                    }}
                    required
                    error={!!dateEndError}
                    helperText={dateEndError}
                    autoOk
                    disableFuture
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={4}>
                <Grid item>
                  <Button color="primary" variant="contained" type="submit">
                    Extraire les données
                  </Button>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={history.goBack}>
          Retour
        </Button>
      </Grid>
    </Grid>
  );
};
