import { appStatusStore } from 'stores/appStatus.store';
import { ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { permanentAlgoApi } from '../api';
import {
  PermanentAlgoAdvice,
  PermanentAlgoGroup,
  PermanentAlgoResultAdvices,
  PermanentAlgoResultCallReason,
  PermanentAlgoResultPriority,
  PermanentQuestion as PermanentAlgoQuestion,
  PermanentAlgoResponse,
  PermanentAlgoRoute,
} from '../model/algo/permanent';
import { AlgoCallReason } from 'model/algo';

interface PermanentAlgoResourceMethods {
  fetchAdvicesPage(): Promise<PermanentAlgoAdvice[]>;
  fetchCallReasonsPage(): Promise<AlgoCallReason[]>;
  fetchGroupsPage(): Promise<PermanentAlgoGroup[]>;
  fetchQuestionsPage(): Promise<PermanentAlgoQuestion[]>;
  fetchResponsesPage(): Promise<PermanentAlgoResponse[]>;
  fetchResultAdvicesPage(): Promise<PermanentAlgoResultAdvices[]>;
  fetchResultCallReasonsPage(): Promise<PermanentAlgoResultCallReason[]>;
  fetchResultPrioritiesPage(): Promise<PermanentAlgoResultPriority[]>;
  fetchRoutesPage(): Promise<PermanentAlgoRoute[]>;
  fetchAdviceDocumentBinary(documentId: number): Promise<Blob>;
}

const ALGO_COLLECTION_KEYS = {
  advices: 'permanentAlgo:advices',
  callReasons: 'permanentAlgo:callReasons',
  groups: 'permanentAlgo:groups',
  questions: 'permanentAlgo:questions',
  responses: 'permanentAlgo:responses',
  resultAdvices: 'permanentAlgo:resultAdvices',
  resultCallReasons: 'permanentAlgo:resultCallReasons',
  resultPriorities: 'permanentAlgo:resultPriorities',
  routes: 'permanentAlgo:routes',
  adviceDocument: 'permanentAlgo:adviceDocument',
};
export const permanentAlgoResourceProvider: ResourceProvider<PermanentAlgoResourceMethods> = {
  onlineResourceProvider: {
    fetchAdvicesPage: async () => {
      const response = await permanentAlgoApi.fetchAdvicesPage();

      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.advices,
        PermanentAlgoAdvice,
        response.content
      );
      return response.content;
    },
    fetchCallReasonsPage: async () => {
      const response = await permanentAlgoApi.fetchCallReasonsPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.callReasons,
        AlgoCallReason,
        response.content
      );
      return response.content;
    },
    fetchGroupsPage: async () => {
      const response = await permanentAlgoApi.fetchGroupsPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.groups,
        PermanentAlgoGroup,
        response.content
      );
      return response.content;
    },
    fetchQuestionsPage: async () => {
      const response = await permanentAlgoApi.fetchQuestionsPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.questions,
        PermanentAlgoQuestion,
        response.content
      );
      return response.content;
    },
    fetchResponsesPage: async () => {
      const response = await permanentAlgoApi.fetchResponsesPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.responses,
        PermanentAlgoResponse,
        response.content
      );
      return response.content;
    },
    fetchResultAdvicesPage: async () => {
      const response = await permanentAlgoApi.fetchResultAdvicesPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.resultAdvices,
        PermanentAlgoResultAdvices,
        response.content
      );
      return response.content;
    },
    fetchResultCallReasonsPage: async () => {
      const response = await permanentAlgoApi.fetchResultCallReasonsPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.resultCallReasons,
        PermanentAlgoResultCallReason,
        response.content
      );
      return response.content;
    },
    fetchResultPrioritiesPage: async () => {
      const response = await permanentAlgoApi.fetchResultPrioritiesPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.resultPriorities,
        PermanentAlgoResultPriority,
        response.content
      );
      return response.content;
    },
    fetchRoutesPage: async () => {
      const response = await permanentAlgoApi.fetchRoutesPage();
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.routes,
        PermanentAlgoRoute,
        response.content
      );
      return response.content;
    },
    fetchAdviceDocumentBinary: async (documentId: number) => {
      const response = await permanentAlgoApi.fetchAdviceDocumentBinary(documentId);
      await storableStoreHelpers.offlineSetRecordItem(
        ALGO_COLLECTION_KEYS.adviceDocument,
        Blob,
        `${documentId}`,
        response
      );
      return response;
    },
  },
  offlineResourceProvider: {
    fetchAdvicesPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoAdvice[]>(
        ALGO_COLLECTION_KEYS.advices
      );
      return wrapper.data;
    },
    fetchCallReasonsPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<AlgoCallReason[]>(
        ALGO_COLLECTION_KEYS.callReasons
      );
      return wrapper.data;
    },
    fetchGroupsPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoGroup[]>(
        ALGO_COLLECTION_KEYS.groups
      );
      return wrapper.data;
    },
    fetchQuestionsPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoQuestion[]>(
        ALGO_COLLECTION_KEYS.questions
      );
      return wrapper.data;
    },
    fetchResponsesPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoResponse[]>(
        ALGO_COLLECTION_KEYS.responses
      );
      return wrapper.data;
    },
    fetchResultAdvicesPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoResultAdvices[]>(
        ALGO_COLLECTION_KEYS.resultAdvices
      );
      return wrapper.data;
    },
    fetchResultCallReasonsPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoResultCallReason[]>(
        ALGO_COLLECTION_KEYS.resultCallReasons
      );
      return wrapper.data;
    },
    fetchResultPrioritiesPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoResultPriority[]>(
        ALGO_COLLECTION_KEYS.resultPriorities
      );
      return wrapper.data;
    },
    fetchRoutesPage: async () => {
      const wrapper = await storableStoreHelpers.offlineGet<PermanentAlgoRoute[]>(
        ALGO_COLLECTION_KEYS.routes
      );
      return wrapper.data;
    },
    fetchAdviceDocumentBinary: async (documentId: number) => {
      const stored = await storableStoreHelpers.offlineGetRecord<Blob>(
        ALGO_COLLECTION_KEYS.adviceDocument
      );
      return stored[documentId].data;
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
