import { computed, observable } from 'mobx';
import { serializrErrorTracer } from 'model/model.utilities';
import { custom, date, identifier, list, object, primitive, serializable } from 'serializr';
import { v4 as uuid } from 'uuid';
import { SynchronizableData } from '../../stores/Persistable';
import User from '../User';
import EventVictim from './EventVictim';

export enum EventStatus {
  ACTIVE = 'ACTIVE', // Actif
  INACTIVE = 'INACTIVE', // Inactif
}

export enum EventType {
  ACCIDENT, // Accident
  FIRE, // Incendie
  EARTHQUAKE, // Tremblement de terre
  UNKNOWN, // Inconnu
  OTHER, // Autre
}

export default class Event {
  @serializable(identifier())
  uuid: string = uuid();
  @serializable
  id?: number;
  @serializable(object(User))
  creator?: User;
  @serializable(object(User))
  modifier?: User;
  @serializable(primitive())
  userModificationDate?: string;
  @serializable(primitive())
  userCreationDate?: string;
  @observable
  @serializable(primitive())
  status?: EventStatus;
  @serializable(primitive())
  eventType?: EventType;
  @serializable(primitive())
  eventDate?: string;
  @serializable(primitive())
  title?: string;
  @serializable(primitive())
  descriptionCAN?: string;
  @serializable
  descriptionParamedic?: string;
  @serializable(primitive())
  locationDepartment?: number;
  @serializable(primitive())
  locationAdditionalInfos?: string;
  @serializable(
    list(
      custom(
        (s: EventVictim) => s,
        (jsonValue, context, oldValue, done) => {
          return done(null, jsonValue);
        },
        serializrErrorTracer
      )
    )
  )
  victims: EventVictim[] = [];
  @serializable(primitive())
  updateVictims: boolean = false;
  @serializable
  totalVictims: number = 0;

  @computed
  get isActive(): boolean {
    return this.status === EventStatus.ACTIVE;
  }

  constructor() {
    const now: string = new Date().toISOString();
    this.userCreationDate = now;
    this.userModificationDate = now;
    this.eventDate = now;
    this.status = EventStatus.ACTIVE;
    this.eventType = EventType.UNKNOWN;
  }
}

export class EventSynchronizableData implements SynchronizableData<Event> {
  constructor(isSynchronized: boolean, data: Event) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
    this.markedForDeletion = false;
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(Event))
  data: Event;

  @serializable
  markedForDeletion?: boolean;
}
