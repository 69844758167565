import { formatDistanceToNow, format, parseJSON } from 'date-fns';
import frLocal from 'date-fns/locale/fr';

function getLocal() {
  return frLocal;
}

export function getDistanceInWordsToNow(
  date: Date,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: object;
  }
): string {
  options = options || {};
  options.locale = getLocal();
  return `Il y a ${formatDistanceToNow(date, options)}`;
}

export function formatToLocal(date: Date): string {
  return format(date, 'dd/MM/yyyy HH:mm:ss', {
    locale: getLocal(),
  });
}

export function parseJSONDate(date: string): Date {
  return parseJSON(date);
}
