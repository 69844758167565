import axios from 'axios';
import EventVictim, { OperationType } from 'model/event/EventVictim';
import { IPageResponse } from 'model/IPageResponse';
import IPaginate from 'model/IPaginate';
import ISearch from 'model/ISearch';
import { formatVictimDataForUpdate } from 'utils/victim.utilities';

export interface SearchVictimProps extends IPaginate, ISearch {
  victimCodes?: string[];
}

export async function searchVictims(
  search: Partial<SearchVictimProps> = {
    pageIndex: 0,
    pageSize: 100,
    keywords: '',
  }
): Promise<IPageResponse<EventVictim>> {
  return axios
    .post('/api/eventVictims/search', search)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateVictim(
  eventUuid: string,
  eventVictim: EventVictim
): Promise<IPageResponse<EventVictim>> {
  eventVictim.operation = OperationType.UPSERT;
  const victimData = formatVictimDataForUpdate(eventVictim);
  return axios
    .post(`/api/eventVictims/${eventUuid}/one`, victimData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function deleteVictim(
  eventUuid: string,
  eventVictim: EventVictim
): Promise<IPageResponse<EventVictim>> {
  eventVictim.operation = OperationType.DELETE;
  const victimData = formatVictimDataForUpdate(eventVictim);
  return axios
    .post(`/api/eventVictims/${eventUuid}/one`, victimData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
