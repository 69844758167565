import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
  FORM_VALID_ON_CHANGE,
} from 'utils/forms';
import { isRequired, maxLength } from 'utils/forms/validators';
import { EventStatus } from 'model/event/Event';

export default function eventFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    status: new Field({
      initValue: (initialValues && initialValues.status) || EventStatus.ACTIVE,
      disabled: false,
    }),
    title: new Field({
      initValue: (initialValues && initialValues.title) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired, maxLength(50)],
      label: '',
    }),
    descriptionCAN: new Field({
      initValue: (initialValues && initialValues.descriptionCAN) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(250)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    descriptionParamedic: new Field({
      initValue: (initialValues && initialValues.descriptionParamedic) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(250)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    locationDepartment: new Field({
      initValue: (initialValues && initialValues.locationDepartment) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    locationAdditionalInfos: new Field({
      initValue: (initialValues && initialValues.locationAdditionalInfos) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(100)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    eventDate: new Field({
      initValue: (initialValues && initialValues.eventDate) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
