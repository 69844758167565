import {
  DashboardRowData,
  DashboardRowItem,
  DashboardTotalData,
  HeaderKey,
} from 'components/dashboard/dashboard.component';
import { DashboardData, TriageCount } from 'model/Dashboard';
import { EmergencySort } from 'model/event/EventVictim';

export function dashboardDataToRowItems(
  dashboardData: DashboardData
): { data: DashboardRowItem[]; total: DashboardTotalData[] } {
  const data = dashboardData.page.content.map((row) => {
    const column: Array<{ key: HeaderKey; value: number }> = [];
    Object.values(EmergencySort).forEach((emergencySort) => {
      const value: number = row.details[emergencySort] || 0;
      column.push({ key: emergencySort, value });
    });
    //Add total for current row
    column.push({ key: 'total', value: row.total });

    return {
      rowName: row.facility.name,
      data: column,
    };
  });

  const firstDataRow = data.map(({ data }: DashboardRowItem) => data)[0];
  const total: DashboardTotalData[] = [];
  if (firstDataRow) {
    firstDataRow.forEach(({ key }: DashboardRowData) =>
      total.push({
        key: key,
        value:
          (dashboardData.totalDetails[key as keyof TriageCount] || 0) +
          (dashboardData.totalExtraRows[key as keyof TriageCount] || 0),
      })
    );
    // Add proper total
    total.splice(total.length - 1, 1, { key: 'total', value: dashboardData.total });
  } else {
    Object.values(EmergencySort).forEach((emergencySort) =>
      total.push({
        key: emergencySort,
        value: 0,
      })
    );
  }

  //New extra rows (ie:  "Laissé sur place")
  dashboardData.extraRows.forEach((row) => {
    const column: Array<{ key: HeaderKey; value: number }> = [];
    Object.values(EmergencySort).forEach((emergencySort) => {
      const value: number = row.details[emergencySort] || 0;
      column.push({ key: emergencySort, value });
    });
    //Add total for current row
    column.push({ key: 'total', value: row.total });
    data.push({
      rowName: row.name,
      data: column,
    });
  });

  return { data, total };
}
