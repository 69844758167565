import axios from 'axios';
import Speciality, { SpecialityCreation } from 'model/facilities/Speciality';

export async function getSpeciality(specialityId: string): Promise<any> {
  try {
    const specialityRes = await axios.get<Speciality>(`api/specialities/${specialityId}`);
    return specialityRes.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSpecialities() {
  return axios
    .post('api/specialities/search')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function saveSpeciality(speciality: SpecialityCreation): Promise<Speciality> {
  return axios
    .post('api/specialities', speciality)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateSpeciality(speciality: Speciality): Promise<Speciality> {
  return axios
    .put(`api/specialities/${speciality.id}`, speciality)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function deleteSpeciality(speciality: Speciality): Promise<Speciality> {
  return axios
    .delete(`api/specialities/${speciality.id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
