import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { facilityApi } from 'api';
import { useLocalStore } from 'mobx-react-lite';
import Equipment from 'model/facilities/Equipment';
import Speciality from 'model/facilities/Speciality';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { facilityStore } from 'stores/facility';
import { uiStore } from 'stores/ui.store';
import { FacilityForm } from './facility.form';
import facilityFormBuilder from './facility.form.builder';

export const FacilityCreatePage: React.FC = () => {
  const history = useHistory();
  const localStore = useLocalStore<{
    facilityForm: any;
    formEditing: boolean;
  }>(() => ({
    facilityForm: facilityFormBuilder({}),
    formEditing: true,
  }));

  const handleClickCancel = () => {
    history.goBack();
  };

  const handleClickConfirm = () => {
    localStore.facilityForm.validate();
    if (localStore.facilityForm.isValid) {
      const facilityData = {
        ...localStore.facilityForm.values,
        equipmentsIds: localStore.facilityForm.equipments.value.map((eqp: Equipment) => eqp.id),
        specialities: localStore.facilityForm.specialities.value.map((svc: Speciality) => ({
          specialityId: svc.Id,
        })),
        departmentId: localStore.facilityForm.locationDepartmentId.value,
        cityId: localStore.facilityForm.locationCity.value.id,
      };
      facilityApi
        .createFacility(facilityData)
        .then(() => facilityStore.loadFacilities())
        .then(() => {
          uiStore.pushSuccessNotification('Etablissement créé avec succès');
          history.goBack();
          //reload facilities in store ?
        });
    }
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant="h5">Création d'un établissement</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        <FacilityForm facilityForm={localStore.facilityForm} formEditing={localStore.formEditing} />
      </Grid>
      <Grid item xs={12} sm={10} md={8} container justify="space-between">
        <Button variant="contained" size="large" onClick={handleClickCancel}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={handleClickConfirm}>
          Valider
        </Button>
      </Grid>
    </Grid>
  );
};
