import { observer } from 'mobx-react-lite';
import { UIQuestionsGroup, UIPendingGroup } from 'model/algo';
import React, { FC, useEffect, useState } from 'react';
import { AlgoContainer } from '../../AlgoContainer';
import { AlgoQuestion } from '../AlgoQuestion';
import { QuestionTitle } from './QuestionTitle';
import { SetUserResponseType, GoToPreviousQuestionGroupType } from 'stores/algo/CommonAlgo.store';
import { CommonAlgoResponse } from 'model/algo';

interface SimpleQuestionsGroupProps {
  currentQuestionsGroup: UIQuestionsGroup;
  setUserResponse: SetUserResponseType;
  goToPreviousQuestionGroup: GoToPreviousQuestionGroupType;
  displayPreviousButton: boolean;
  pendingGroupNames?: UIPendingGroup[];
}
export const SimpleQuestionsGroup: FC<SimpleQuestionsGroupProps> = observer(
  ({
    currentQuestionsGroup,
    displayPreviousButton,
    pendingGroupNames = [],
    setUserResponse,
    goToPreviousQuestionGroup,
  }) => {
    const [currentResponses, setCurrentResponses] = useState<Record<number, CommonAlgoResponse[]>>(
      []
    );
    const [answeredQuestions, setAnsweredQuestions] = useState<Record<number, boolean>>({});

    function clearLocalResponsesStates() {
      setCurrentResponses([]);
      setAnsweredQuestions({});
    }

    // Reaction à la completion des réponses du questionsGroup
    useEffect(() => {
      if (
        currentQuestionsGroup &&
        Object.keys(answeredQuestions).length === currentQuestionsGroup.questions.length &&
        Object.keys(answeredQuestions).length !== 0
      ) {
        setUserResponse(currentQuestionsGroup.groupId, Object.values(currentResponses).flat());
        clearLocalResponsesStates();
      }
    }, [setUserResponse, currentResponses, answeredQuestions, currentQuestionsGroup]);

    const handleResponse = (response: CommonAlgoResponse | CommonAlgoResponse[]) => {
      const responses = Array.isArray(response) ? response : [response];
      setCurrentResponses({ ...currentResponses, [responses[0].questionId]: responses });
      setAnsweredQuestions({ ...answeredQuestions, [responses[0].questionId]: true });
    };

    const handlePrevious = () => {
      clearLocalResponsesStates();
      goToPreviousQuestionGroup();
    };

    const onClickPrevious = displayPreviousButton ? handlePrevious : undefined;

    return (
      <>
        {currentQuestionsGroup.questions.length > 0 && (
          <AlgoContainer
            renderTitle={
              <QuestionTitle
                parentTitle={
                  currentQuestionsGroup.isSubGroup ? currentQuestionsGroup.groupName : undefined
                }
                titles={pendingGroupNames}
              />
            }
            onClickPrevious={onClickPrevious}
          >
            {currentQuestionsGroup.questions.map((currentQuestion, index) => (
              <AlgoQuestion
                key={index}
                question={currentQuestion}
                currentResponses={currentResponses}
                handleResponse={handleResponse}
              />
            ))}
          </AlgoContainer>
        )}
      </>
    );
  }
);
