import axios from 'axios';
import {
  NurseAlgoCardDocument,
  NurseAlgoData,
  NurseAlgoDiagnostic,
  NurseAlgoGroup,
  NurseAlgoQuestion,
  NurseAlgoResponse,
  NurseAlgoResultDiagnostic,
  NurseAlgoRoute,
} from 'model/algo/nurse';
import { IPageResponse } from 'model/IPageResponse';

async function fetchCardDocumentsPage(): Promise<NurseAlgoCardDocument[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoCardDocument>>(
      `/api/calls/nurseAlgorithm/cardDocuments`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchDiagnosticsPage(): Promise<NurseAlgoDiagnostic[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoDiagnostic>>(
      `/api/calls/nurseAlgorithm/diagnostics`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchGroupsPage(): Promise<NurseAlgoGroup[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoGroup>>(
      `/api/calls/nurseAlgorithm/groups`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchQuestionsPage(): Promise<NurseAlgoQuestion[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoQuestion>>(
      `/api/calls/nurseAlgorithm/questions`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchResponsesPage(): Promise<NurseAlgoResponse[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoResponse>>(
      `/api/calls/nurseAlgorithm/responses`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchResultDiagnosticsPage(): Promise<NurseAlgoResultDiagnostic[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoResultDiagnostic>>(
      `/api/calls/nurseAlgorithm/resultDiagnostics`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

async function fetchRoutesPage(): Promise<NurseAlgoRoute[]> {
  try {
    const response = await axios.get<IPageResponse<NurseAlgoRoute>>(
      `/api/calls/nurseAlgorithm/routes`
    );
    return response.data.content;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchNurseAlgo(): Promise<NurseAlgoData> {
  const cardDocumentsPromise = fetchCardDocumentsPage();
  const diagnosticsPromise = fetchDiagnosticsPage();
  const groupsPromise = fetchGroupsPage();
  const questionsPromise = fetchQuestionsPage();
  const responsesPromise = fetchResponsesPage();
  const resultDiagnosticsPromise = fetchResultDiagnosticsPage();
  const routesPromise = fetchRoutesPage();

  const [
    cardDocuments,
    diagnostics,
    groups,
    questions,
    responses,
    resultDiagnostics,
    routes,
  ] = await Promise.all([
    cardDocumentsPromise,
    diagnosticsPromise,
    groupsPromise,
    questionsPromise,
    responsesPromise,
    resultDiagnosticsPromise,
    routesPromise,
  ]);

  return {
    cardDocuments,
    diagnostics,
    groups,
    questions,
    responses,
    resultDiagnostics,
    routes,
  };
}

export async function fetchBehaviorDocumentBinary(documentId: number): Promise<Blob> {
  try {
    const response = await axios.get<Blob>(
      `/api/calls/nurseAlgorithm/cardDocuments/${documentId}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}
