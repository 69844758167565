import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './api/client.configuration';
import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { initStorableStores } from 'stores/Persistable';
import { authStore } from 'stores/auth/auth.store';

function render(component: ReactElement) {
  ReactDOM.render(component, document.getElementById('root'));
}

async function startApplication() {
  await authStore.init();
  initStorableStores();

  render(<App />);
}

startApplication();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
