import { PersistableStore } from './Persistable.model';

const storableStores: PersistableStore[] = [];

export function registerStorableStore(store: PersistableStore) {
  storableStores.push(store);
}

export function initStorableStores() {
  storableStores.forEach((store) => {
    store.init();
  });
}
