import Context from 'serializr/lib/core/Context';
import { DeserializationContextArgs } from 'stores/stores.model';
import FacilityStore, { facilityStore } from './facility.store';

export interface WithFacilityStoreDeserializationContextArgs<Store>
  extends DeserializationContextArgs<Store> {
  facilityStore: FacilityStore;
}
export interface WithFacilityStoreDeserializationContext<Store> extends Omit<Context, 'args'> {
  args: WithFacilityStoreDeserializationContextArgs<Store>;
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveSpecialityById(
  id: any,
  callback: (err: any, result: any) => void,
  { args }: WithFacilityStoreDeserializationContext<any>
) {
  const foundedItem = facilityStore.specialities.get(id);

  callback(null, foundedItem?.data);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveEquipmentById(
  id: any,
  callback: (err: any, result: any) => void,
  { args }: WithFacilityStoreDeserializationContext<any>
) {
  const foundedItem = facilityStore.equipments.get(id);
  callback(null, foundedItem?.data);
}

export function resolveFacilityById(
  id: any,
  callback: (err: any, result: any) => void,
  { args }: WithFacilityStoreDeserializationContext<any>
) {
  const foundedItem = facilityStore.facilities.get(id);
  callback(null, foundedItem?.data);
}
