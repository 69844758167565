import { Typography, Grid } from '@material-ui/core';
import {
  DashboardRowData,
  DashboardRowItem,
  DashboardTotalData,
  HeaderKey,
} from 'components/dashboard/dashboard.component';
import { DashboardData, TriageCount } from 'model/Dashboard';
import { EmergencySort } from 'model/event/EventVictim';
import React from 'react';
import { locationStore } from 'stores/location.store';
import { Spacer } from 'components/Spacer';

export function dashboardDataToRowItems(
  dashboardData: DashboardData
): { data: DashboardRowItem[]; total: DashboardTotalData[] } {
  const data = dashboardData.page.content.map((row) => {
    const data: Array<{ key: HeaderKey; value: number | undefined }> = Object.values(
      EmergencySort
    ).map((emergencySort) => {
      const value = row.details[emergencySort] !== undefined ? row.details[emergencySort] : 0;
      return { key: emergencySort, value };
    });
    data.push({ key: 'total', value: row.total });
    const department = row.event?.locationDepartment
      ? locationStore.getDepartmentFromId(row.event?.locationDepartment)
      : undefined;
    return {
      rowName: (
        <Grid container alignItems="center">
          <Typography variant="h6" component="span">
            {row.event.title}
          </Typography>
          <Spacer direction="horizontal" size="1rem" />
          <Typography variant="body2" component="span">
            {department?.name}
          </Typography>
        </Grid>
      ),
      data,
      rowId: row.event.uuid,
    };
  });

  const firstDataRow = data.map(({ data }: DashboardRowItem) => data)[0];
  const total: DashboardTotalData[] = [];
  if (firstDataRow) {
    firstDataRow.forEach(({ key }: DashboardRowData) =>
      total.push({
        key: key,
        value: dashboardData.totalDetails[key as keyof TriageCount] || 0,
      })
    );
    // Add proper total
    total.splice(total.length - 1, 1, { key: 'total', value: dashboardData.total });
  } else {
    Object.values(EmergencySort).forEach((emergencySort) =>
      total.push({
        key: emergencySort,
        value: 0,
      })
    );
    total.push({ key: 'total', value: dashboardData.total });
  }

  return { data, total };
}
