import { createStyles, Theme, IconButton, Popper, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { AlgoCallReason } from 'model/algo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 350,
      zIndex: theme.zIndex.speedDial,
    },
    paper: {
      padding: theme.spacing(1),
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

interface CallReasonsPopperProps {
  callReasons: AlgoCallReason[];
}

export const CallReasonsPopper = ({ callReasons = [] }: CallReasonsPopperProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const handleClic = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
  };

  const handleClickReason = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setAnchorEl(ev.currentTarget);
    setOpen(true);
  };

  const handleClosePopper = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setOpen(false);
    setAnchorEl(null);
  };

  if (callReasons?.length) {
    return (
      <>
        <div onClick={handleClickReason} className={classes.iconContainer}>
          <IconButton>
            <SearchIcon fontSize="small" />
          </IconButton>
        </div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          disablePortal={false}
          className={classes.root}
        >
          <Paper className={classes.paper} onClick={handleClic}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 0 }}>
              <IconButton onClick={handleClosePopper}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <ul style={{ paddingInlineStart: theme.spacing(2) }}>
              {callReasons.map((reason, index) => (
                <li key={index}>{reason.value}</li>
              ))}
            </ul>
          </Paper>
        </Popper>
      </>
    );
  } else {
    return <></>;
  }
};
