import { serializable, identifier } from 'serializr';

type CallReasonCategory = 'SYMPTOM' | 'TYPE_TRAUMA' | 'LOCATION_TRAUMA' | 'SPECIAL_CIRCUMSTANCES';

export class AlgoCallReason {
  @serializable(identifier())
  id?: number;

  @serializable
  value?: string;

  @serializable
  category?: CallReasonCategory;
}
