import { Button, createStyles, Grid, Theme, withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { orange } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import clsx from 'clsx';
import { PriorityIndicator } from 'components/common/Priority/PriorityIndicator';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import StatusChip from 'components/common/StatusChip';
import { Spacer } from 'components/Spacer';
import fr from 'date-fns/locale/fr';
import { Observer, useObserver } from 'mobx-react-lite';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import { Permission_V15 } from 'model/User';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { appStatusStore } from 'stores/appStatus.store';
import { authStore } from 'stores/auth/auth.store';
import { callStore } from 'stores/call';
import { formatToLocal, getDistanceInWordsToNow, parseJSONDate } from 'utils/date.utilities';
import { CallReasonsPopper } from '../../components/CallReasonsPopper';

const CallListPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [canAccessCalls, setCanAccessCalls] = React.useState(false);

  React.useEffect(() => {
    callStore.getCalls();
    setCanAccessCalls(authStore.hasPermission(Permission_V15.CALLS_UPDATE));
    // eslint-disable-next-line
  }, []);

  const handleClickNewCall = () => {
    history.push('/appels/nouveau');
  };

  const handleClickRow = (callUuid: string) => async () => {
    canAccessCalls && history.push(`/appels/preRegulation/${callUuid}`);
  };

  const handleClickExport = () => {
    history.push('/reports');
  };

  const handleClickSearch = () => {
    history.push('/appels/recherche');
  };

  return useObserver(() => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h1">
              Suivi des appels
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} container justify="flex-end" spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <RenderIfAuthorized hasPermission={[Permission_V15.CALLS_SEARCH]}>
                <Tooltip
                  open={!appStatusStore.isConnected}
                  arrow
                  placement="right"
                  title="Recherche non disponible hors-ligne"
                >
                  <SearchButton
                    variant="outlined"
                    startIcon={<SearchIcon />}
                    color="primary"
                    fullWidth
                    disabled={!appStatusStore.isConnected}
                    onClick={handleClickSearch}
                  >
                    Recherche avancée
                  </SearchButton>
                </Tooltip>
              </RenderIfAuthorized>
            </Grid>

            <RenderIfAuthorized hasPermission={[Permission_V15.CALLS_CREATE]}>
              <Grid item xs={12} sm={6} md={5}>
                <NewCallButton color="primary" size="large" fullWidth onClick={handleClickNewCall}>
                  Nouvel Appel
                </NewCallButton>
              </Grid>
            </RenderIfAuthorized>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />

      <Grid item xs={6}>
        <RenderIfAuthorized hasPermission={[Permission_V15.CALLS_EXPORT_MULTI]}>
          <Button variant="contained" startIcon={<GetAppIcon />} onClick={handleClickExport}>
            {t('nav.reports')}
          </Button>
        </RenderIfAuthorized>
      </Grid>

      <Grid item xs={6} container justify="flex-end" alignItems="flex-end">
        {callStore.lastSynchronization && (
          <Typography variant="caption">
            Récupération des appels: {formatToLocal(callStore.lastSynchronization)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: 'transparent', maxHeight: 600 }}
        >
          <Table className={classes.table} aria-label="calls_table" size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Priorité</TableCell>
                <TableCell align="left">Identité de la victime</TableCell>
                <TableCell align="left">Urgence/Lieu</TableCell>
                <TableCell align="left">Statut</TableCell>
                <TableCell align="left">Horodatage</TableCell>
                <TableCell align="left">N° fiche</TableCell>
                <TableCell align="left">Type d'appel</TableCell>
              </TableRow>
            </TableHead>
            <Observer>
              {() => (
                <TableBody>
                  {callStore.calls.size === 0 && (
                    <TableRow hover className={classes.tableRow}>
                      <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                        Aucun appel à traiter
                      </TableCell>
                    </TableRow>
                  )}
                  {Array.from(callStore.calls.values())
                    .slice(0, 50)
                    .map(({ data: call, isSynchronized }) => (
                      <TableRow
                        key={call.uuid}
                        hover={canAccessCalls}
                        className={clsx(
                          classes.tableRow,
                          !isSynchronized && classes.tableRowUnsync
                        )}
                        onClick={handleClickRow(call.uuid)}
                      >
                        <TableCell scope="row">
                          <PriorityIndicator
                            priority={call.priority || PermanentAlgoPriority.LOW}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="left">
                          <PatientName>
                            {call.victimLastName?.toUpperCase()} {call.victimFirstName}
                          </PatientName>
                          {call.victimCode}
                        </TableCell>

                        <TableCell align="left">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CallReasonsPopper callReasons={call.callReasons} />
                            <Typography
                              style={{
                                fontWeight: 500,
                                maxWidth: 250,
                                display: 'inline-block',
                              }}
                              component="div"
                              noWrap
                            >
                              {call.callReasons.map((reason) => reason.value).join(', ')}
                            </Typography>
                          </div>
                          <div>
                            {call.locationCity ||
                              (call.locationDepartment &&
                                t(`departments.${call.locationDepartment}`))}
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          {call.status && <StatusChip status={call.status} />}
                        </TableCell>
                        <TableCell align="left">
                          {getDistanceInWordsToNow(parseJSONDate(call.userCreationDate!), {
                            includeSeconds: true,
                            locale: fr,
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {isSynchronized && call.callNumber}
                          {!isSynchronized && (
                            <Tooltip
                              arrow
                              placement="right"
                              title="L'appel n'a pas été transmis au serveur"
                            >
                              <div
                                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                              >
                                <SyncProblemIcon className={classes.syncIcon} />
                              </div>
                            </Tooltip>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <Chip
                            label={t(`calls.type.${call.callType}`, '')}
                            style={{ textTransform: 'uppercase', fontWeight: 500 }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Observer>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  ));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
      backgroundColor: theme.palette.background.paper,
    },
    tableRow: {
      height: theme.spacing(10),
    },
    tableRowUnsync: {
      backgroundColor: orange[50],
    },
    syncIcon: {
      color: theme.palette.warning.dark,
    },
  })
);

const PatientName: React.FC = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        minWidth: '80px',
        color: 'black',
        fontWeight: 500,
      },
      lastname: {
        textTransform: 'uppercase',
      },
    })
  );
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const NewCallButton = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))((props: any) => (
  <Button variant="contained" startIcon={<CallIcon />} {...props}>
    {props.children}
  </Button>
));

const SearchButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'white',
}));

export default CallListPage;
