import { custom, identifier, list, primitive, reference, serializable } from 'serializr';
import { resolvePermanentGroupById } from 'stores/algo/permanent/permanentAlgo.resolvers';
import {
  AlgoCombinaison,
  algoCombinaisonDeserializer,
  algoCombinaisonSerializer,
} from '../AlgoCombinaison';
import { CommonAlgoRoute } from '../CommonAlgoRoute';
import { PermanentAlgoGroup } from './Group';

export class PermanentAlgoRoute implements CommonAlgoRoute {
  @serializable(identifier())
  private id?: number;

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable(reference(PermanentAlgoGroup, resolvePermanentGroupById as any))
  fromGroup?: PermanentAlgoGroup;

  @serializable(reference(PermanentAlgoGroup, resolvePermanentGroupById as any))
  toGroup?: PermanentAlgoGroup;

  @serializable(reference(PermanentAlgoGroup, resolvePermanentGroupById as any))
  toSubGroup?: PermanentAlgoGroup;

  @serializable(list(primitive()))
  responses?: number[];

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
