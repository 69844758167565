import { makeStyles, Paper, Theme } from '@material-ui/core';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import React, { FC } from 'react';

const PRIORITY_LABEL: { [key in PermanentAlgoPriority]: string } = {
  HIGHT: 'P1',
  MEDIUM: 'P2',
  LOW: 'P3',
  UNKNOWN: '',
};

function getBoxColor(theme: Theme, priority: PermanentAlgoPriority): string {
  switch (priority) {
    case PermanentAlgoPriority.HIGHT:
      return theme.palette.error.main;
    case PermanentAlgoPriority.MEDIUM:
      return theme.palette.warning.main;
    case PermanentAlgoPriority.LOW:
      return theme.palette.success.main;
    default:
      return theme.palette.grey[400];
  }
}

export interface PrioritySelectorProps {
  priority: PermanentAlgoPriority;
  size?: 'normal' | 'small';
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
const usePriorityBoxStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (props: PrioritySelectorProps) =>
      props.active ? getBoxColor(theme, props.priority) : theme.palette.background.default,
    borderColor: (props: PrioritySelectorProps) => getBoxColor(theme, props.priority),
    borderWidth: 2,
    borderStyle: 'solid',
    height: (props: PrioritySelectorProps) => (props.size === 'small' ? '40px' : '50px'),
    width: (props: PrioritySelectorProps) => (props.size === 'small' ? '40px' : '50px'),
    color: (props: PrioritySelectorProps) => (props.active ? 'white' : 'black'),
    fontWeight: 600,
    cursor: 'pointer',
    margin: 4,
  },
}));

export const PrioritySelector: FC<PrioritySelectorProps> = (props) => {
  const classes = usePriorityBoxStyles(props);
  return (
    <Paper elevation={3} classes={classes} onClick={props.onClick}>
      {PRIORITY_LABEL[props.priority]}
    </Paper>
  );
};
