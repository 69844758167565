import { CallStatus, CallType } from 'model/Call';
import { VictimAge, VictimSex } from 'model/Victim';
import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
  FORM_VALID_ON_CHANGE,
  FORM_VALID_ON_LOAD,
} from 'utils/forms';
import { isRequired, maxLength } from 'utils/forms/validators';

export default function callFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    status: new Field({
      initValue: (initialValues && initialValues.status) || CallStatus.TO_REGULATE,
      disabled: true,
    }),
    callNumber: new Field({
      initValue: (initialValues && initialValues.callNumber) || undefined,
      disabled: true,
    }),
    purpose: new Field({
      initValue: (initialValues && initialValues.purpose) || undefined,
      disabled: false,
    }),
    callType: new Field({
      initValue: (initialValues && initialValues.callType) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_CHANGE],
      label: '',
    }),
    callerIsVictim: new Field({
      initValue: (initialValues && initialValues.callerIsVictim) || false,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    callerLastName: new Field({
      initValue: (initialValues && initialValues.callerLastName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    callerFirstName: new Field({
      initValue: (initialValues && initialValues.callerFirstName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    callerPhone: new Field({
      initValue: (initialValues && initialValues.callerPhone) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired, maxLength(8)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    victimCode: new Field({
      initValue: (initialValues && initialValues.victimCode) || undefined,
      disabled: true,
    }),
    victimLastName: new Field({
      initValue: (initialValues && initialValues.victimLastName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    victimFirstName: new Field({
      initValue: (initialValues && initialValues.victimFirstName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    victimAge: new Field({
      initValue: (initialValues && initialValues.victimAge) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    victimAgeRange: new Field({
      initValue: (initialValues && initialValues.victimAgeRange) || VictimAge.NOT_SPECIFIED,
      disabled: mode === FORM_MODE_CONSULT,
      //Validate range with age and vice versa
      validate: [
        (val) =>
          val === VictimAge.FROM_16_TO_49_YEARS
            ? {
                valid: false,
                message: "La tranche d'age 16-49 n'est plus disponible",
              }
            : { valid: true },
      ],
      validateOn: [FORM_VALID_ON_LOAD, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    victimSex: new Field({
      initValue: (initialValues && initialValues.victimSex) || VictimSex.UNKNOWN,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    locationDepartment: new Field({
      initValue: (initialValues && initialValues.locationDepartment) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (val, label) =>
          fields.callType.value !== CallType.HARMFUL
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    locationStreet: new Field({
      initValue: (initialValues && initialValues.locationStreet) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    locationSquare: new Field({
      initValue: (initialValues && initialValues.locationSquare) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    locationCity: new Field({
      initValue: (initialValues && initialValues.locationCity) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (val, label) =>
          fields.callType.value !== CallType.HARMFUL
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      label: '',
    }),
    locationAdditionalInfos: new Field({
      initValue: (initialValues && initialValues.locationAdditionalInfos) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    transferFromFacility: new Field({
      initValue: initialValues && initialValues.transferFromFacility,
      disabled: true,
    }),
    transferToFacility: new Field({
      initValue: initialValues && initialValues.transferToFacility,
      disabled: true,
    }),
    results: new Field({
      initValue: (initialValues && initialValues.results) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
