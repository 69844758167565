import axios from 'axios';

export async function getDepartments() {
  return axios
    .post('api/locations/departments/search')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getDistricts() {
  return axios
    .post('api/locations/districts/search')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getCities() {
  return axios
    .post('api/locations/cities/search')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
