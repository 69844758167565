import { Button, Divider, Grid, styled, Theme, Typography, withStyles } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import TitleIcon from '@material-ui/icons/Title';
import { Loader } from 'components/common/Loader';
import { DangerousHtml } from 'components/DangerousHtml';
import { action, autorun } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { NurseAlgoDiagnostic } from 'model/algo/nurse';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';
import Call, { CallSynchronizableData, CallType } from 'model/Call';
import { NeedConfirmError } from 'model/Errors';
import { VictimAge } from 'model/Victim';
import { SearchFacilityFilterProps } from 'pages/facilities/facilities.list.model';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { mapFormDataToCall } from 'services/call.service';
import { nurseAlgoStore } from 'stores/algo/nurse';
import { callStore, callUIStore } from 'stores/call';
import { NotificationType, uiStore } from 'stores/ui.store';
import { isDiagnosticSeveryUA } from 'utils/call.utilities';
import { getInputFieldProps, getSwitchFieldProps } from 'utils/forms';
import { formatNumberInput, formatPhoneNumber } from 'utils/forms/formatters';
import { convertAgeToAgeSlice } from 'utils/victim.utilities';
import BilanDialog from '../common/BilanDialog';
import { CallerFormFields } from '../common/callFormFields/CallerFormFields';
import { CallTypeFormFields } from '../common/callFormFields/CallTypeFormFields';
import { InfoBloc } from '../common/callFormFields/FormBloc';
import { LocationFormFields } from '../common/callFormFields/LocationFormFields';
import { PageTitle } from '../common/callFormFields/PageTitle';
import { VictimFormFields } from '../common/callFormFields/VictimFormFields';
import { DiagnosticDialog } from '../common/DiagnosticDialog';
import { CallAlgorithmModal } from './call.algorithm.modal';
import { callPostRegulationUiStore } from './call.postRegulation.ui.store';

const CallPostRegulationPage: React.FC = () => {
  const history = useHistory<{ call: CallSynchronizableData } | undefined>();
  const goToFacilitiesHistory = useHistory<{ filters: Partial<SearchFacilityFilterProps> }>();
  const { uuid } = useParams<{ uuid?: string }>();
  const { t } = useTranslation();

  const deactivatePromptRef = React.useRef(true);

  const localStore = useLocalStore<{
    bilanEditing: boolean;
    editing: boolean;
    requiredFieldsFilled: boolean;
    diagnosticDialogOpen: boolean;
    setEditing: any;
    setBilanEditing: any;
    setRequiredFieldsFilled: any;
  }>(() => ({
    bilanEditing: false,
    editing: true,
    requiredFieldsFilled: false,
    diagnosticDialogOpen: false,
    setEditing(editing: boolean) {
      this.editing = editing;
    },
    setBilanEditing(bilanEditing: boolean) {
      this.bilanEditing = bilanEditing;
    },
    setRequiredFieldsFilled(fieldsFilled: boolean) {
      this.requiredFieldsFilled = fieldsFilled;
    },
  }));

  //Load call data
  React.useEffect(
    () => {
      if (!uuid) {
        throw new Error('No uuid present in the link');
      }
      if (!callPostRegulationUiStore.synchronizableCall) {
        callPostRegulationUiStore.loadCall(uuid);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid]
  );

  // Change required fields filled indicator
  React.useEffect(
    () =>
      autorun(() => {
        if (callPostRegulationUiStore.callForm?.callType.value === CallType.EMERGENCY) {
          localStore.setRequiredFieldsFilled(
            !callPostRegulationUiStore.callForm?.callType.isEmpty &&
              !callPostRegulationUiStore.callForm?.locationDepartment.isEmpty &&
              !callPostRegulationUiStore.callForm?.locationCity.isEmpty &&
              !callPostRegulationUiStore.callForm?.callerPhone.isEmpty
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Re-init dialog state on leaving page
  React.useEffect(
    () => () => {
      callUIStore.closeConfirmSaveDialog();
      nurseAlgoStore.resetAlgo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClickCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    if (!callPostRegulationUiStore.synchronizableCall) {
      throw new Error('No call in history!');
    }

    callPostRegulationUiStore.callForm?.validate();
    if (callPostRegulationUiStore.callForm?.isValid) {
      const callData: Call = mapFormDataToCall(
        callPostRegulationUiStore.synchronizableCall,
        callPostRegulationUiStore.callForm
      );
      callData.nurseAlgoDiagnosticId = callData.diagnostic?.Id;
      callData.nurseAlgoFacilityId = callData.facility?.Id;
      try {
        await callStore.nurseSaveCall({
          ...callPostRegulationUiStore.synchronizableCall,
          data: callData,
        });
        uiStore.pushSuccessNotification('Appel créé avec succès');
        deactivatePromptRef.current = true;
        history.goBack();
      } catch (error) {
        if (error instanceof NeedConfirmError) {
          return;
        }
        uiStore.pushNotification("Echec de la création de l'appel", NotificationType.ERROR);
      }
    } else {
      localStore.setEditing(true);
      localStore.setBilanEditing(false);
    }
  };

  const goToFacilities = async () => {
    if (!callPostRegulationUiStore.synchronizableCall) {
      throw new Error('Invalid State - No call available');
    }

    const { diagnostic } = callPostRegulationUiStore.synchronizableCall.data;
    try {
      const filters: Partial<SearchFacilityFilterProps> = {
        selectedEquipmentIds:
          diagnostic?.content?.orientation?.equipments.map((equipment) => equipment.Id) || [],
        selectedSpecialtyIds:
          diagnostic?.content?.orientation?.services.map((service) => service.Id) || [],
        selectedDepartmentIshicode: callPostRegulationUiStore.callForm?.locationDepartment.value,
      };
      callPostRegulationUiStore.filters = filters;
      goToFacilitiesHistory.push(`/appels/postRegulation/${uuid}/etablissements`, { filters });
    } catch (error) {
      if (error instanceof NeedConfirmError) {
        return;
      }
      uiStore.pushNotification(
        "Impossible d'accéder à la sélection de l'établissement",
        NotificationType.ERROR
      );
    }
  };

  const handleClickBilanEditing = () => {
    localStore.setBilanEditing(!localStore.bilanEditing);
  };

  const handleChangeAgeRange = action((e: React.ChangeEvent<any>) => {
    callPostRegulationUiStore.callForm?.victimAge.setValue(''); //clear
  });

  const handleChangeAge = action((e: React.ChangeEvent<any>) => {
    const victimAgeSlice: VictimAge = convertAgeToAgeSlice(e.target.value);
    callPostRegulationUiStore.callForm?.victimAgeRange.setValue(victimAgeSlice);
  });

  return (
    <>
      <Observer>
        {() => (
          <Prompt
            message={() => {
              return callPostRegulationUiStore.callForm?.isTouched && !deactivatePromptRef.current
                ? 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
                : true;
            }}
          />
        )}
      </Observer>

      <Grid container spacing={2} justify="center">
        {/* PAGE TITLE */}
        <Observer>
          {() => (
            <PageTitle
              title="Fiche d'appel"
              callStatus={callPostRegulationUiStore.callForm.status.value}
              callNumber={callPostRegulationUiStore.callForm.callNumber.value}
            />
          )}
        </Observer>
        <Observer>{() => <>{callPostRegulationUiStore.loading && <Loader />}</>}</Observer>
        {/* LEFT COLUMN */}
        <Grid item xs={12} sm={12} lg={7} container spacing={1}>
          <Grid item xs={12}>
            <InfoBloc>
              {/* TYPE APPEL */}
              <Observer>
                {() => (
                  <CallTypeFormFields
                    callTypeInputProps={{
                      ...getInputFieldProps(callPostRegulationUiStore.callForm?.callType),
                      disabled: true,
                    }}
                    purposeInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.purpose
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* APPELANT */}
              <Observer>
                {() => (
                  <CallerFormFields
                    editing={localStore.editing}
                    callerIsVictimInputProps={getSwitchFieldProps(
                      callPostRegulationUiStore.callForm?.callerIsVictim
                    )}
                    callerFirstNameInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.callerFirstName
                    )}
                    callerLastNameInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.callerLastName
                    )}
                    callerPhoneInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.callerPhone,
                      {},
                      { formatter: formatPhoneNumber }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* VICTIME */}
              <Observer>
                {() => (
                  <VictimFormFields
                    editing={localStore.editing}
                    formMode={callPostRegulationUiStore.callForm?.mode}
                    callerIsVictim={callPostRegulationUiStore.callForm?.callerIsVictim.value}
                    victimFirstNameInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.victimFirstName
                    )}
                    victimLastNameInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.victimLastName
                    )}
                    victimSexInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.victimSex
                    )}
                    victimAgeRangeInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.victimAgeRange,
                      {
                        onChange: handleChangeAgeRange,
                      }
                    )}
                    victimAgeInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.victimAge,
                      {
                        onChange: handleChangeAge,
                      },
                      { formatter: formatNumberInput(3) }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* LOCALISATION */}
              <Observer>
                {() => (
                  <LocationFormFields
                    editing={localStore.editing}
                    locationDepartmentInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.locationDepartment
                    )}
                    locationStreetInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.locationStreet
                    )}
                    locationCityInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.locationCity
                    )}
                    locationSquareInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.locationSquare
                    )}
                    locationAdditionalInfosInputProps={getInputFieldProps(
                      callPostRegulationUiStore.callForm?.locationAdditionalInfos
                    )}
                    currentCallType={callPostRegulationUiStore.callForm?.callType.value}
                  />
                )}
              </Observer>
            </InfoBloc>
          </Grid>
          <Grid item xs={12}>
            <InfoBloc>
              <Observer>
                {() => (
                  <InfoSubBloc>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="button">Bilan</Typography>
                      {localStore.editing && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickBilanEditing}
                        >
                          {callPostRegulationUiStore.callForm?.results.value
                            ? 'Afficher'
                            : 'Renseigner'}
                        </Button>
                      )}
                    </Grid>
                    <BilanDialog
                      isOpen={localStore.bilanEditing}
                      onClose={() => localStore.setBilanEditing(false)}
                      onSave={() => {
                        if (callPostRegulationUiStore.synchronizableCall) {
                          callStore
                            .saveCallOutcome(
                              callPostRegulationUiStore.synchronizableCall.data.uuid,
                              callPostRegulationUiStore.callForm?.results.value
                            )
                            .then(() => localStore.setBilanEditing(false));
                        }
                      }}
                      inputProps={getInputFieldProps(callPostRegulationUiStore.callForm?.results)}
                    />
                  </InfoSubBloc>
                )}
              </Observer>
            </InfoBloc>
          </Grid>
        </Grid>

        {/* RIGHT COLUMN */}
        <Grid item xs={12} sm={12} lg={5} container spacing={1}>
          <Grid item xs={12}>
            <Observer>
              {() => (
                <>
                  {!callPostRegulationUiStore.loading && (
                    <DiagnosticBloc diagnostic={callPostRegulationUiStore.selectedDiagnostic} />
                  )}
                </>
              )}
            </Observer>
          </Grid>
          <Grid item xs={12}>
            <InfoBloc>
              <TitleContainer>
                <Typography variant="h5" color="textSecondary">
                  Établissement
                </Typography>
              </TitleContainer>
              <Observer>
                {() => (
                  <>
                    {!callPostRegulationUiStore.loading && (
                      <>
                        <InfoSubBloc>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="button">Information</Typography>
                            </Grid>
                            {!callPostRegulationUiStore.selectedFacility && (
                              <Grid item xs={12} container alignItems="center">
                                <Typography variant="body2">
                                  Aucun établissement sélectionné
                                </Typography>
                              </Grid>
                            )}
                            {callPostRegulationUiStore.selectedFacility && (
                              <>
                                <Grid item xs={12} container spacing={2}>
                                  <Grid item>
                                    <TitleIcon />
                                  </Grid>
                                  <Grid item>
                                    {callPostRegulationUiStore.selectedFacility?.name}
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                  <Grid item>
                                    <LocationCityIcon />
                                  </Grid>
                                  <Grid item>
                                    {callPostRegulationUiStore.selectedFacility?.locationCity?.name}{' '}
                                    (
                                    {
                                      callPostRegulationUiStore.selectedFacility?.locationDepartment
                                        ?.name
                                    }
                                    )
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </InfoSubBloc>
                        <InfoSubBloc>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="button">Acceptation</Typography>
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                              {callPostRegulationUiStore.selectedFacilityAnswer &&
                                t(
                                  `orientations.facilityAnswer.${callPostRegulationUiStore.selectedFacilityAnswer}`
                                )}
                            </Grid>
                          </Grid>
                        </InfoSubBloc>
                        <InfoSubBloc>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="button">Moyens engagés</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {callPostRegulationUiStore.selectedRegulationMeans &&
                                t(
                                  `orientations.regulationMeans.${callPostRegulationUiStore.selectedRegulationMeans}`
                                )}
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                              <Button variant="outlined" color="primary" onClick={goToFacilities}>
                                Modifier l'établissement
                              </Button>
                            </Grid>
                          </Grid>
                        </InfoSubBloc>
                      </>
                    )}
                  </>
                )}
              </Observer>
            </InfoBloc>
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems="flex-end">
            <Grid item>
              <Button variant="contained" size="large" onClick={handleClickCancel}>
                Annuler
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
                Valider
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

interface DefaultStyleProp {
  classes: any;
  children: any;
  style?: any;
}
const InfoSubBloc = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))((props: DefaultStyleProp) => (
  <div className={props.classes.root} style={props.style}>
    {props.children}
  </div>
));

const DiagnosticBloc = ({ diagnostic }: { diagnostic?: NurseAlgoDiagnostic }) => {
  const [isDiagnosticDialogOpen, setDiagnosticDialogOpen] = React.useState(false);
  const [isAlgorithmModalOpen, setAlgorithmModalOpen] = React.useState(false);
  const handleClickRestart = () => {
    nurseAlgoStore.resetAlgo();
    setAlgorithmModalOpen(true);
  };
  return (
    <>
      <InfoBloc>
        <TitleContainer>
          <Typography variant="h5" color="textSecondary">
            Diagnostic
          </Typography>
        </TitleContainer>
        <InfoSubBloc>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={
                  isDiagnosticSeveryUA(diagnostic?.severityDegree)
                    ? { color: 'red', fontWeight: 700, fontSize: 18 }
                    : {}
                }
              >
                {diagnostic?.name}{' '}
                {diagnostic?.bookReferences?.map((bookReference, index) => (
                  <DangerousHtml key={bookReference.Id} html={bookReference.pageNumber || ''} />
                ))}
              </Typography>
            </Grid>
            <Grid item xs={12} container justify="space-between">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDiagnosticDialogOpen(true);
                }}
              >
                Afficher le diagnostic
              </Button>
              <Button variant="outlined" color="default" onClick={handleClickRestart}>
                Recommencer
              </Button>
            </Grid>
          </Grid>
        </InfoSubBloc>
      </InfoBloc>
      <DiagnosticDialog
        call={callPostRegulationUiStore.synchronizableCall?.data}
        algoRunResult={
          callPostRegulationUiStore.selectedDiagnostic
            ? {
                diagnostic: callPostRegulationUiStore.selectedDiagnostic,
              }
            : undefined
        }
        open={isDiagnosticDialogOpen}
        onClose={() => {
          setDiagnosticDialogOpen(false);
        }}
      />
      {!callPostRegulationUiStore.loading && callPostRegulationUiStore.synchronizableCall && (
        <CallAlgorithmModal
          synchronizableCall={callPostRegulationUiStore.synchronizableCall}
          isOpen={isAlgorithmModalOpen}
          onClose={() => setAlgorithmModalOpen(false)}
          onSave={(algoRunResult: UINurseAlgoRunResult) =>
            callPostRegulationUiStore.setNewSelectedDiagnostic(algoRunResult.diagnostic)
          }
        />
      )}
    </>
  );
};
const TitleContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  padding: theme.spacing(2),
}));

export default CallPostRegulationPage;
