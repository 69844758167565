import { authApi } from 'api';
import { refreshLock } from 'api/auth.api';
import { changeLanguage } from '../../i18n';
import { action, observable } from 'mobx';
import { Permission_V15, Profile } from 'model/User';
import { NotificationType, uiStore } from 'stores/ui.store';
import { getTokenStorage, TokenStorage } from './token.storage';

const REFRESH_TOKEN_LOOKUP_INTERVAL_MS = 1000 * 30;

const tokenStorage = getTokenStorage();

type UserTokenClaims = {
  firstName: string;
  lastName: string;
  profile: Profile;
  permissions: Permission_V15[];
  username: string;
  exp: number;
  facilities: number[];
  userLanguage?: string;
};
export type AuthenticatedUser = UserTokenClaims;

export class AuthStore {
  @observable private _currentUser: AuthenticatedUser | null = null;
  @observable private accessToken: string | null = null;

  @observable authStoreInitialized: boolean = false;

  constructor(private tokenStorage: TokenStorage, private api: typeof authApi) {}

  set currentUser(user: AuthenticatedUser | null) {
    this._currentUser = user;
    // Initialize user language
    if (this._currentUser?.userLanguage) {
      changeLanguage(this._currentUser.userLanguage);
    }
  }

  @action('AUTH_STORE:INIT')
  async init() {
    if (this.authStoreInitialized) {
      throw new Error('AuthStore.init() must be called only once');
    }
    try {
      // Try to access user from tokenStorage
      this.accessToken = tokenStorage.getToken();
      this.currentUser = tokenStorage.getDecodedToken<UserTokenClaims>();
      await this.refreshTokenIfNeeded();
    } catch (error) {
      console.error(error);
      this.handleUnauthenticated();
      uiStore.pushNotification('Token invalid', NotificationType.ERROR);
    }

    setInterval(() => {
      this.refreshTokenIfNeeded();
    }, REFRESH_TOKEN_LOOKUP_INTERVAL_MS);

    this.authStoreInitialized = true;
  }

  get authenticatedUser(): Readonly<AuthenticatedUser> | null {
    return this._currentUser;
  }

  get isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  get token(): Readonly<string> | null {
    return this.accessToken;
  }

  @action('AUTH_STORE:LOGIN')
  async login(username: string, password: string) {
    try {
      const response = await this.api.authenticate(username, password);
      const authorizationHeader = response.headers.authorization;

      if (!authorizationHeader) {
        throw new Error('Invalid login request. No "authorization" header in response');
      }
      this.setTokenFromAuthorizationHeader(authorizationHeader);
      this.currentUser = tokenStorage.getDecodedToken<UserTokenClaims>();
    } catch (error) {
      this.handleUnauthenticated();
      throw error;
    }
  }
  @action('AUTH_STORE:LOGOUT')
  async logout() {
    try {
      const token = tokenStorage.getToken();
      if (token) {
        await this.api.logout(token);
      }
    } finally {
      this.handleUnauthenticated();
    }
  }

  @action('AUTH_STORE:SET_TOKEN')
  setTokenFromAuthorizationHeader(authorizationHeader: string) {
    const jwtToken = authorizationHeader.replace('Bearer ', '');
    tokenStorage.setToken(jwtToken);
    this.accessToken = jwtToken;
  }

  hasPermission(permission: Permission_V15): boolean {
    return this._currentUser?.permissions.includes(permission) || false;
  }

  hasProfile(profile: Profile): boolean {
    return this._currentUser?.profile.includes(profile) || false;
  }

  @action('AUTH_STORE:REFRESH_TOKEN_IF_NEEDED')
  private async refreshTokenIfNeeded() {
    try {
      if (this.tokenStorage.willExpireSoon() && refreshLock.status !== 'PENDING') {
        this.refreshToken();
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action('AUTH_STORE:REFRESH_TOKEN')
  async refreshToken() {
    try {
      if (this.accessToken) {
        const jwtToken = await this.api.refreshToken(this.accessToken);
        this.setTokenFromAuthorizationHeader(jwtToken);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action('AUTH_STORE:HANDLE_UNAUTHENTICATED')
  private handleUnauthenticated() {
    this._currentUser = null;
    this.accessToken = null;
    tokenStorage.unset();
  }
}
export const authStore = new AuthStore(getTokenStorage(), authApi);
