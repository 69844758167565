import { action, computed, observable, reaction, runInAction } from 'mobx';
import { UIPendingGroup, UIQuestionsGroup, UIVerticalModeSubGroupContext } from 'model/algo';
import { AlgoCallReason } from 'model/algo/AlgoCallReason';
import {
  PermanentAlgoAdvice,
  PermanentAlgoGroup,
  PermanentAlgoPriority,
  PermanentAlgoResponse,
  PermanentAlgoResultAdvices,
  PermanentAlgoResultCallReason,
  PermanentAlgoResultPriority,
  PermanentAlgoRoute,
  PermanentQuestion,
  UIPermanentAlgoRunResult,
} from 'model/algo/permanent';
import { Permission_V15 } from 'model/User';
import { permanentAlgoResourceProvider } from 'resourceProvider/permanentAlgo.provider';
import { deserialize } from 'serializr';
import { authStore } from 'stores/auth/auth.store';
import { noopCallback } from 'stores/serialize.context';
import { DeserializationContextArgs } from 'stores/stores.model';
import { PersistableStore, registerStorableStore, storableStoreHelpers } from '../../Persistable';
import { CommonAlgo, CommonAlgoStore, UserResponsesType } from '../CommonAlgo.store';
import { responsesMatchCombinaison } from '../CommonAlgo.utilities';
import { GroupsToDisplay } from '../GroupsToDisplay';

export class PermanentAlgoStore implements CommonAlgoStore, PersistableStore {
  @observable groups: PermanentAlgoGroup[] = [];
  @observable routes: PermanentAlgoRoute[] = [];

  @observable questions: PermanentQuestion[] = [];
  @observable responses: PermanentAlgoResponse[] = [];

  @observable advices: PermanentAlgoAdvice[] = [];
  @observable resultAdvices: PermanentAlgoResultAdvices[] = [];

  @observable callReasons: AlgoCallReason[] = [];
  @observable resultCallReasons: PermanentAlgoResultCallReason[] = [];

  @observable resultPriorities: PermanentAlgoResultPriority[] = [];

  @observable userResponses: UserResponsesType<PermanentAlgoResponse> = new Map(); // map groupId => questionId => Responses

  @observable groupsToDisplay = new GroupsToDisplay();

  @observable algoLoaded = false;
  @observable lastSynchronization: Date | undefined;

  constructor() {
    reaction(
      () => this.userResponses.values(),
      () => {
        const lastGroupId = Array.from(this.userResponses.keys()).pop();
        if (lastGroupId) {
          const nextGroups = CommonAlgo.getNextQuestionsGroups(
            lastGroupId,
            this.routes,
            this.userSelectedResponses
          );
          this.groupsToDisplay.addSubGroups(lastGroupId, nextGroups.subs);

          //Cas spécial de la question "Autres symptomes"
          if (this.switchToVerticalMode) {
            if (this.verticalModeSubGroupContext) {
              return;
            } else {
              this.groupsToDisplay.setGroups(nextGroups.parents);
            }
          }

          // Si tous les groupes ne sont pas complet. Il ne faut pas passer au groupsToDisplay suivant
          if (
            CommonAlgo.getGroupToDisplay(this.groupsToDisplay, this.isFulfilledGroup, {
              includeSubGroups: true,
            })
          ) {
            return;
          }

          // Nettoyage des réponses déjà existant afin de pouvoir répondre plusieurs fois aux mêmes réponses
          CommonAlgo.removeAlreadyExistingResponse(nextGroups.parents, this.userResponses);

          this.groupsToDisplay.setGroups(nextGroups.parents);
        } else {
          this.startAlgo();
        }
      }
    );
  }

  init() {
    storableStoreHelpers.reactOnUserChanges(() => this.loadPermanentAlgo());
    storableStoreHelpers.reactOnNetworkChange(() => this.loadPermanentAlgo());
  }

  @action('PERMA:GO_TO_PREVIOUS_QUESTION_GROUP')
  goToPreviousQuestionGroup = () => {
    const respondedGroupIds = Array.from(this.userResponses.keys());
    const lastGroupId = respondedGroupIds.pop();
    const previousGroupId = respondedGroupIds.pop();
    if (lastGroupId) {
      // Suppression des dernières réponses pour nettoyer le résultat
      this.userResponses.delete(lastGroupId);
      // Récupération de la question routée par les réponses de la question N - 2
      const previousGroup = CommonAlgo.getNextQuestionsGroups(
        previousGroupId || lastGroupId,
        this.routes,
        this.userSelectedResponses
      );
      if (previousGroup.parents.length > 0) {
        this.groupsToDisplay.setGroups(previousGroup.parents);
      }
    }
  };
  @action('PERMA:RESET_ALGO')
  resetAlgo() {
    this.userResponses.clear();
  }

  @action('PERMA:START_ALGO')
  startAlgo = () => {
    this.resetAlgo();
    if (!this.algoLoaded) {
      console.warn('[PermanentAlgoStore] Unable to get next question: Algo not loaded');
      return undefined;
    }
    const initialGroup = this.groups.find((group) => group.initial);
    if (!initialGroup) {
      throw new Error('Invalid data: Invalid groups configuration. An initial group must exist');
    }

    this.groupsToDisplay.setGroups([initialGroup]);
  };

  @action('PERMA:LOAD_PERMANENT_ALGO')
  async loadPermanentAlgo() {
    const hasAccess =
      authStore.hasPermission(Permission_V15.CALLS_CREATE) ||
      authStore.hasPermission(Permission_V15.CALLS_UPDATE) ||
      authStore.hasPermission(Permission_V15.VICTIMS_REGULATION_PERM);
    if (!hasAccess) {
      this.algoLoaded = true;
      return;
    }
    const permanentAlgoResource = permanentAlgoResourceProvider.get();
    const contextArgs: DeserializationContextArgs<PermanentAlgoStore> = { store: this };

    const groupsPagePromise = permanentAlgoResource.fetchGroupsPage();

    const questionsPagePromise = permanentAlgoResource.fetchQuestionsPage();
    const responsesPagePromise = permanentAlgoResource.fetchResponsesPage();
    const routesPagePromise = permanentAlgoResource.fetchRoutesPage();
    const resultPrioritiesPagePromise = permanentAlgoResource.fetchResultPrioritiesPage();
    const advicesPagePromise = permanentAlgoResource.fetchAdvicesPage();
    const resultAdvicesPagePromise = permanentAlgoResource.fetchResultAdvicesPage();
    const callReasonsPagePromise = permanentAlgoResource.fetchCallReasonsPage();
    const resultCallReasonsPagePromise = permanentAlgoResource.fetchResultCallReasonsPage();
    const [
      groupsPage,
      questionsPage,
      responsesPage,
      routesPage,
      resultPrioritiesPage,
      advicesPage,
      resultAdvicesPage,
      callReasonsPage,
      resultCallReasonsPage,
    ] = await Promise.all([
      groupsPagePromise,
      questionsPagePromise,
      responsesPagePromise,
      routesPagePromise,
      resultPrioritiesPagePromise,
      advicesPagePromise,
      resultAdvicesPagePromise,
      callReasonsPagePromise,
      resultCallReasonsPagePromise,
    ]);

    runInAction(() => {
      this.groups = deserialize(PermanentAlgoGroup, groupsPage, noopCallback, contextArgs);

      this.questions = deserialize(PermanentQuestion, questionsPage, noopCallback, contextArgs);

      this.responses = deserialize(PermanentAlgoResponse, responsesPage, noopCallback, contextArgs);

      this.routes = deserialize(PermanentAlgoRoute, routesPage, noopCallback, contextArgs);

      this.resultPriorities = deserialize(
        PermanentAlgoResultPriority,
        resultPrioritiesPage,
        noopCallback,
        contextArgs
      );

      this.advices = deserialize(PermanentAlgoAdvice, advicesPage, noopCallback, contextArgs);

      this.resultAdvices = deserialize(
        PermanentAlgoResultAdvices,
        resultAdvicesPage,
        noopCallback,
        contextArgs
      );

      this.callReasons = deserialize(AlgoCallReason, callReasonsPage, noopCallback, contextArgs);

      this.resultCallReasons = deserialize(
        PermanentAlgoResultCallReason,
        resultCallReasonsPage,
        noopCallback,
        contextArgs
      );
      this.algoLoaded = true;
      this.setLastSynchronization();
    });
  }

  @action('PERMA:SET_USER_RESPONSE')
  setUserResponse = (
    groupId: number,
    userResponse: PermanentAlgoResponse | PermanentAlgoResponse[]
  ) => {
    CommonAlgo.setInUserResponses(this.userResponses, groupId, userResponse);
  };

  @computed
  get pendingGroupNames(): UIPendingGroup[] {
    return CommonAlgo.getPendingGroupNames(this.groupsToDisplay, this.isFulfilledGroup);
  }

  @computed
  get switchToVerticalMode(): boolean {
    return !!this.userSelectedResponses.find((response) => response.verticalTargetRoutes);
  }

  @computed
  get verticalModeSubGroupContext(): UIVerticalModeSubGroupContext | undefined {
    return CommonAlgo.getVerticalModeSubGroupContext(
      this.groupsToDisplay,
      this.isFulfilledGroup,
      this.userResponses,
      this.questions,
      this.responses
    );
  }

  @computed
  get algoRunResult(): UIPermanentAlgoRunResult | null {
    if (this.userResponses.size === 0) {
      return null;
    }
    return {
      priority: this.algoRunPriority,
      reasons: this.algoRunCallReasons,
      advices: this.algoRunAdvices,
      responseIds: this.userResponseIds,
    };
  }

  @computed
  get currentQuestionsGroup(): UIQuestionsGroup | undefined {
    const groupToDisplay = CommonAlgo.getGroupToDisplay(
      this.groupsToDisplay,
      this.isFulfilledGroup,
      {
        includeSubGroups: true,
      }
    );
    if (!groupToDisplay) {
      return undefined;
    }
    return {
      groupId: groupToDisplay.group.Id,
      groupName: groupToDisplay.group.name,
      questions: CommonAlgo.getUIWithResponsesQuestionForGroup(
        groupToDisplay.group.Id,
        this.questions,
        this.responses
      ),
      isSubGroup: groupToDisplay.isSubGroup,
      first: groupToDisplay.group.initial,
      isVertical: this.switchToVerticalMode,
      pendingGroups: this.pendingGroupNames,
    };
  }

  @computed
  get allQuestionsGroups(): UIQuestionsGroup[] {
    const groups = this.groupsToDisplay.groupsValues;
    return groups.map((group) => ({
      groupId: group.Id,
      groupName: group.name,
      questions: CommonAlgo.getUIWithResponsesQuestionForGroup(
        group.Id,
        this.questions,
        this.responses
      ),
      isSubGroup: false,
    }));
  }

  private isFulfilledGroup = (
    groupId: number,
    { includeSubGroups }: { includeSubGroups: boolean }
  ): boolean => {
    if (includeSubGroups) {
      const incompleteSubGroup = this.groupsToDisplay
        .getGroupById(groupId)
        ?.subGroups.find(
          (subGroup) => !this.isFulfilledGroup(subGroup.Id, { includeSubGroups: false })
        );

      if (incompleteSubGroup) {
        return false;
      }
    }
    const forGroupQuestions = CommonAlgo.getUIWithResponsesQuestionForGroup(
      groupId,
      this.questions,
      this.responses
    );
    const forGroupResponses = this.userResponses.get(groupId);
    if (!forGroupResponses) {
      return false;
    }

    return forGroupResponses.size === forGroupQuestions.length;
  };

  @computed
  private get userSelectedResponses(): PermanentAlgoResponse[] {
    return Array.from(this.userResponses.values())
      .map((groupResponse) => Array.from(groupResponse.values()).flat())
      .flat();
  }

  @computed
  private get userResponseIds(): number[] {
    return this.userSelectedResponses.map((response) => response.Id);
  }

  @computed
  private get algoRunAdvices(): PermanentAlgoAdvice[] {
    const userResponseIds = this.userResponseIds;
    if (userResponseIds.length === 0) {
      return [];
    }
    let algoAdvices: PermanentAlgoAdvice[] = [];
    for (const resultAdvice of this.resultAdvices) {
      if (
        resultAdvice.advice &&
        responsesMatchCombinaison(resultAdvice, this.userSelectedResponses)
      ) {
        algoAdvices = [...algoAdvices, resultAdvice.advice];
      }
    }
    return algoAdvices;
  }

  @computed
  private get algoRunCallReasons(): AlgoCallReason[] {
    const userResponseIds = this.userResponseIds;

    if (userResponseIds.length === 0) {
      return [];
    }

    let algoReasons: AlgoCallReason[] = [];
    for (const resultCallReason of this.resultCallReasons) {
      if (
        resultCallReason.reason &&
        responsesMatchCombinaison(resultCallReason, this.userSelectedResponses)
      ) {
        algoReasons = [...algoReasons, resultCallReason.reason];
      }
    }
    return algoReasons;
  }

  @computed
  private get algoRunPriority(): PermanentAlgoPriority {
    const userResponses = this.userSelectedResponses;
    const defaultPriority = PermanentAlgoPriority.MEDIUM;
    if (userResponses.length === 0) {
      return defaultPriority;
    }

    let algoPriority: PermanentAlgoPriority | undefined;
    for (const resultPriority of this.resultPriorities) {
      // La prio du resultat doit être supérieur à une prio déja obtenu. Sinon, on ignore
      if (!resultPriority.priority || (algoPriority && algoPriority > resultPriority.priority)) {
        continue;
      }

      if (responsesMatchCombinaison(resultPriority, userResponses)) {
        algoPriority = resultPriority.priority;
      }

      // Si on obtient une priorite haute => pas besoin de continuer à parcourir les results
      if (algoPriority === PermanentAlgoPriority.HIGHT) {
        return algoPriority;
      }
    }
    return algoPriority || defaultPriority; // Priorite moyenne par défaut
  }

  @action
  setLastSynchronization(date: Date = new Date()) {
    this.lastSynchronization = date;
  }
}

export const permanentAlgoStore = new PermanentAlgoStore();

registerStorableStore(permanentAlgoStore);
