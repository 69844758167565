export enum ResponseType {
  BUTTON = 'BUTTON',
  CHOICE = 'CHOICE',
  BODY_CHOICE = 'BODY_CHOICE',
}

export function responseTypeDeserializer(type: string): ResponseType {
  return ResponseType[type as keyof typeof ResponseType];
}

export function responseTypeSerializer(type: ResponseType): string {
  return ResponseType[type];
}
