import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  PaperProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { MaterialAutocomplete } from 'components/common/form/MaterialAutocomplete';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSelectBlocs from 'components/common/form/MaterialSelectBlocs';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import MaterialSwitch from 'components/common/form/MaterialSwitch';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import { Spacer } from 'components/Spacer';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { EmergencySort } from 'model/event/EventVictim';
import { Permission_V15 } from 'model/User';
import { VictimAge, VictimSex } from 'model/Victim';
import * as React from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { authStore } from 'stores/auth/auth.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { uiStore } from 'stores/ui.store';
import { FORM_MODE, getInputFieldProps, getSwitchFieldProps } from 'utils/forms';
import { numbersOnly } from 'utils/input.utilities';
import { convertAgeToAgeSlice } from 'utils/victim.utilities';

interface VictimFormProps {
  victimForm: any;
  formMode: FORM_MODE;
  eventTitle?: string;
  onSubmit: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  onRegulate?: () => void;
}

const VictimForm: React.FC<VictimFormProps> = ({
  eventTitle,
  formMode,
  victimForm,
  onSubmit,
  onCancel,
  onDelete,
  onRegulate,
}) => {
  const { t } = useTranslation();
  const departments = locationStore.departmentsArray;
  const [isQrScanModalOpened, setQrScanModalOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleCodeScan = (data: string | null) => {
    if (data) {
      uiStore.pushSuccessNotification('Code scanné avec succès');
      victimForm.code.setValue(data);
      setQrScanModalOpen(false);
    }
  };

  const handleChangeAgeRange = action((e: React.ChangeEvent<any>) => {
    victimForm.age.setValue(''); //clear
  });

  const handleChangeOrientationFacility = action((e: React.ChangeEvent<any>) => {
    victimForm.stayedThere.setValue(false);
  });

  const handleChangeStayedThere = action((e: React.ChangeEvent<any>) => {
    if (e.target.checked) {
      victimForm.facility.setValue(undefined);
    }
  });

  const handleChangeAge = action((e: React.ChangeEvent<any>) => {
    const victimAgeSlice: VictimAge = convertAgeToAgeSlice(e.target.value);
    victimForm.ageRange.setValue(victimAgeSlice);
  });

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <InfoBloc>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <MaterialInput
                      label="Titre de l'évènement"
                      value={eventTitle}
                      disabled={true}
                      editing={false}
                    />
                  )}
                </Observer>
              </Grid>
              <Spacer direction="vertical" size="1rem" />
              <Grid container item xs={12} alignItems="flex-start">
                <Grid item xs={10} md={6}>
                  <Observer>
                    {() => (
                      <MaterialInput
                        size="small"
                        label="N° de Bracelet"
                        required={victimForm.lastName.isEmpty || victimForm.firstName.isEmpty}
                        maxLength={6}
                        onKeyDown={numbersOnly}
                        {...getInputFieldProps(victimForm.code)}
                      />
                    )}
                  </Observer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Observer>
                    {() => (
                      <MaterialInput
                        size="small"
                        label="Description du patient"
                        multiline
                        rows={2}
                        rowsMax={3}
                        fullWidth
                        {...getInputFieldProps(victimForm.description)}
                      />
                    )}
                  </Observer>
                </Grid>
              </Grid>
              <MobileView>
                <Grid item xs={2}>
                  <Tooltip title="Scanner QR code">
                    <IconButton onClick={() => setQrScanModalOpen(true)}>
                      <PhotoCameraIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </MobileView>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Nom"
                    required={victimForm.code.isEmpty}
                    maxLength={32}
                    fullWidth
                    {...getInputFieldProps(victimForm.lastName)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Prénom"
                    required={victimForm.code.isEmpty}
                    maxLength={32}
                    fullWidth
                    {...getInputFieldProps(victimForm.firstName)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={8}>
              <Observer>
                {() => (
                  <MaterialSelectBlocs
                    label={'Âge approximatif'}
                    options={Object.values(VictimAge)
                      .filter((va) => va !== VictimAge.FROM_16_TO_49_YEARS) //deprecated slice
                      .map((val) => ({
                        id: val,
                        value: t(`forms.call.victim.age.${val}`),
                      }))}
                    {...getInputFieldProps(victimForm.ageRange, {
                      onChange: handleChangeAgeRange,
                    })}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={8} md={4}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Âge"
                    type="number"
                    fullWidth
                    {...getInputFieldProps(victimForm.age, { onChange: handleChangeAge })}
                    inputProps={{ min: 0, max: 140 }}
                    onKeyDown={numbersOnly}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={6}>
              <Observer>
                {() => (
                  <MaterialSelectBlocs
                    label={'Sexe'}
                    options={Object.values(VictimSex)
                      .filter((val) => typeof val === 'string')
                      .map((val) => ({
                        id: val,
                        value: t(`forms.call.victim.sex.${val}`),
                      }))}
                    {...getInputFieldProps(victimForm.sex)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={4}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    fullWidth
                    size="small"
                    label={'Triage'}
                    {...getInputFieldProps(victimForm.emergencySort)}
                  >
                    {Object.keys(EmergencySort).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {t(`victim.triage.${key}`)}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={8}>
              <Observer>
                {() => (
                  <MaterialAutocomplete
                    options={facilityStore.enabledFacilitiesArray}
                    getOptionLabel={(option) => option.name || ''}
                    label="Établissement d'orientation"
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    openOnFocus
                    fullWidth
                    {...getInputFieldProps(victimForm.facility, {
                      onChange: handleChangeOrientationFacility,
                    })}
                  />
                )}
              </Observer>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              container
              alignItems="center"
              style={{ paddingBottom: '12px' }}
            >
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        {...getSwitchFieldProps(victimForm.stayedThere, {
                          onChange: handleChangeStayedThere,
                        })}
                      />
                    }
                    label={'Laissée sur place'}
                  />
                )}
              </Observer>
            </Grid>
            {(authStore.hasPermission(Permission_V15.VICTIMS_CREATE) ||
              authStore.hasPermission(Permission_V15.VICTIMS_DELETE)) && (
              <Grid item xs={12}>
                <Observer>
                  {() => (
                    <MaterialSwitch
                      label="Arrivée à l'hôpital"
                      color="primary"
                      {...getSwitchFieldProps(victimForm.arrivedToFacility)}
                    />
                  )}
                </Observer>
              </Grid>
            )}

            <Grid item xs={12}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Diagnostic médical"
                    multiline
                    rows={2}
                    rowsMax={3}
                    fullWidth
                    {...getInputFieldProps(victimForm.medicalDiagnostic)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Adresse personnelle de la victime</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    size="small"
                    label="Adresse"
                    multiline
                    rows={2}
                    rowsMax={3}
                    fullWidth
                    {...getInputFieldProps(victimForm.address)}
                  />
                )}
              </Observer>
            </Grid>

            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    fullWidth
                    size="small"
                    label={'Département'}
                    {...getInputFieldProps(victimForm.addressDepartment)}
                  >
                    {departments.map((departement, index) => (
                      <MenuItem key={index} value={departement.id}>
                        {departement.name}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
          </Grid>
        </InfoBloc>
      </Grid>
      <Grid item xs={12} container justify="space-between" spacing={1}>
        <Button variant="contained" size="large" onClick={onCancel}>
          Annuler
        </Button>
        <BrowserView>
          {authStore.hasPermission(Permission_V15.VICTIMS_REGULATION_IDE) && (
            <Button variant="contained" size="large" onClick={onRegulate}>
              Réguler la victime
            </Button>
          )}
        </BrowserView>
        {authStore.hasPermission(Permission_V15.VICTIMS_UPDATE) && (
          <Button variant="contained" size="large" color="primary" onClick={onSubmit}>
            Valider la fiche
          </Button>
        )}
      </Grid>
      {onDelete && (
        <RenderIfAuthorized hasPermission={[Permission_V15.VICTIMS_DELETE]}>
          <Grid item xs={12} container justify="center">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={onDelete}
              startIcon={<DeleteIcon />}
            >
              Supprimer
            </Button>
          </Grid>
        </RenderIfAuthorized>
      )}
      <MobileView>
        <Observer>
          {() => (
            <Modal
              open={isQrScanModalOpened && isMobile}
              onClose={() => setQrScanModalOpen(false)}
              disableBackdropClick={true}
            >
              <Paper className={classes.paper}>
                <QrReader
                  delay={1000}
                  style={{
                    width: '100%',
                    marginBottom: 8,
                  }}
                  onError={(err: any) => console.error(err)}
                  onScan={handleCodeScan}
                />
                <Button variant="contained" onClick={() => setQrScanModalOpen(false)}>
                  Annuler
                </Button>
              </Paper>
            </Modal>
          )}
        </Observer>
      </MobileView>
    </Grid>
  );
};

const InfoBloc = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
}))((props: PaperProps) => (
  <Paper classes={props.classes} elevation={0}>
    {props.children}
  </Paper>
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      maxWidth: 300,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      margin: '50px auto 0',
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
    },
  })
);

export default VictimForm;
