import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import Facility, { TransferFacilities } from 'model/facilities/Facility';
import React, { FC, useState, useEffect } from 'react';
import CallStore from 'stores/call/call.store';
import FacilityStore from 'stores/facility/facility.store';
import { AlgoErrorBoundary } from 'components/algorithm/AlgoErrorBoundary';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import { FacilityAutocomplete } from './FacilityAutocomplete';

const List = styled.ul`
  list-style-type: none;
  padding-left: 1rem;
  line-height: 1.4rem;
`;

const ListItem = styled.li``;
const BoldListItem = styled.li`
  margin: 8px;
  font-size: 0.9rem;
  font-weight: bold;
`;

interface TransferFormProps {
  callStore: CallStore;
  facilityStore: FacilityStore;
  transferFacilities?: TransferFacilities;
}

export const TransferForm: FC<TransferFormProps> = observer(
  ({ callStore, facilityStore, transferFacilities }) => {
    const [originFacility, setOriginFacility] = useState<Facility | null>(
      transferFacilities?.transferFromFacility || null
    );
    const [destinationFacility, setDestinationFacility] = useState<Facility | null>(
      transferFacilities?.transferToFacility || null
    );

    useEffect(() => {
      if (originFacility && destinationFacility) {
        callStore.setTransferFacilities({
          transferFromFacility: originFacility,
          transferToFacility: destinationFacility,
        });
      }
      return () => {
        callStore.setTransferFacilities(undefined);
      };
    }, [callStore, originFacility, destinationFacility]);

    return (
      <AlgoErrorBoundary>
        <AlgoContainer title="Transfert de patient">
          <Typography variant="subtitle1">
            Démarche de prise en charge d’un appel pour transfert de patient
          </Typography>
          <List>
            <BoldListItem>
              1. Demandez le Nom, Prénom, et N° de téléphone de l'appelant
            </BoldListItem>
            <ListItem>- Cela vous permettra de le recontacter en cas de besoin</ListItem>
            <ListItem>- Renseignez le formulaire “Appelant” avec des informations</ListItem>
            <BoldListItem>2. Demandez le Nom, Prénom, Âge et Sexe de la victime</BoldListItem>
            <ListItem>- Renseignez le formulaire “Victime” avec des informations</ListItem>
            <BoldListItem>
              3. Renseignez l’établissement où se trouve le patient actuellement et l’établissement
              de destination du transfert à l’aide des deux champs ci-dessous
            </BoldListItem>
          </List>
          <FacilityAutocomplete
            title="Etablissement d'origine"
            value={originFacility}
            facilities={facilityStore.enabledFacilitiesArray}
            onChange={(newValue) => setOriginFacility(newValue)}
          />
          <FacilityAutocomplete
            title="Etablissement de destination"
            value={destinationFacility}
            facilities={facilityStore.enabledFacilitiesArray}
            onChange={(newValue) => setDestinationFacility(newValue)}
          />
        </AlgoContainer>
      </AlgoErrorBoundary>
    );
  }
);
