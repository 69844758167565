import { identifier, object, primitive, serializable, date, list } from 'serializr';
import { SynchronizableData } from 'stores/Persistable';
import Speciality from './Speciality';
import Equipment from './Equipment';
import Department from 'model/location/Department';
import City from 'model/location/City';
import { EditableRessource } from './EditableRessource';

export enum FacilityType {
  ONG = 'ONG',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  MIXTE = 'MIXTE',
  NONE = 'NONE',
}

export enum FacilityCategory {
  UNIVERSITY = 'UNIVERSITY',
  DISTRICT = 'DISTRICT',
  DEPARTMENT = 'DEPARTMENT',
  NONE = 'NONE',
}

export class FacilitySpeciality {
  @serializable(identifier())
  id?: number;

  @serializable(object(Speciality))
  speciality?: Speciality;
}

export default class Facility implements EditableRessource {
  @serializable(identifier())
  id?: number;
  @serializable(primitive())
  name?: string;
  @serializable(primitive())
  phoneNumber?: string;
  @serializable(primitive())
  address?: string;
  @serializable(object(City))
  locationCity?: City;
  @serializable(object(Department))
  locationDepartment?: Department;
  @serializable(list(object(FacilitySpeciality)))
  specialities?: FacilitySpeciality[];
  @serializable(list(object(Equipment)))
  equipments?: Equipment[];
  @serializable(primitive())
  sufficientBloodLevel?: boolean;
  @serializable(primitive())
  sufficientOxygenLevel?: boolean;
  @serializable(primitive())
  type?: FacilityType;
  @serializable(primitive())
  category?: FacilityCategory;
  @serializable(primitive())
  deactivatable: boolean = true;
  @serializable(primitive())
  updatable: boolean = true;
  @serializable(primitive())
  deletable: boolean = true;
  @serializable(primitive())
  enabled: boolean = true;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}

export class FacilitySynchronizableData implements SynchronizableData<Facility> {
  constructor(isSynchronized: boolean, data: Facility) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(Facility))
  data: Facility;
}

export type TransferFacilities = {
  transferFromFacility: Facility;
  transferToFacility: Facility;
};

export type FacilityUpdate = {
  name?: string;
  address?: string;
  equipmentsIds?: number[];
  specialities?: Array<{ specialityId: number }>;
  departmentId?: number;
  cityId?: number;
};
