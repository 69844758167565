export function isValid(fields: Array<any>) {
  return fields.every((field) => field.isValid);
}

export function setTouched(values: Array<any>) {
  values.forEach((field) => field.markAsTouch());
}

function mergeHandler(
  handler: (...args: any[]) => any,
  optHandler?: (...args: any[]) => any
): () => any {
  if (!optHandler) {
    return handler;
  } else {
    return (...args) => {
      optHandler(...args);
      handler(...args);
    };
  }
}

function withFormatter(value: any, formatter: (val: any) => any) {
  if (!formatter) {
    return value;
  } else {
    return formatter(value);
  }
}

export type InputFieldProps = {
  others?: any;
  value: any;
  onChange: (...args: any[]) => any;
  error: boolean;
  onBlur: () => any;
  disabled: any;
  helperText: any;
};

export function getInputFieldProps(
  field: any,
  {
    onChange,
    onBlur,
    ...others
  }: { onChange?: (...args: any[]) => any; onBlur?: (...args: any[]) => any; others?: any } = {},
  { formatter }: { formatter?: any } = {}
): InputFieldProps {
  return (
    field && {
      value: withFormatter(field.value, formatter),
      onChange: mergeHandler(field.onChange, onChange),
      error: !!field.errorMessage,
      onBlur: mergeHandler(field.onBlur, onBlur),
      disabled: field.disabled,
      helperText: field.errorMessage,
      // errorMessage: field.errorMessage,
      ...others,
    }
  );
}

export type SwitchFieldProps = {
  others?: any;
  checked: any;
  onChange: () => any;
  error: boolean;
  onBlur: () => any;
  disabled: any;
  helperText: any;
};
/**
 * Add function for Switch Field
 * Note: onChange function is defined for Material-UI
 * @param field
 * @param param1
 * @param param2
 */
export function getSwitchFieldProps(
  field: any,
  {
    onChange,
    onBlur,
    ...others
  }: { onChange?: (...args: any[]) => any; onBlur?: (...args: any[]) => any; others?: any } = {}
): SwitchFieldProps {
  return (
    field && {
      checked: field.value,
      onChange: mergeHandler((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        field.onChangeValue(checked, event);
      }, onChange),
      error: !!field.errorMessage,
      onBlur: mergeHandler(field.onBlur, onBlur),
      disabled: field.disabled,
      helperText: field.errorMessage,
      // errorMessage: field.errorMessage,
      ...others,
    }
  );
}

export function getFileUploadFieldProps(
  field: any,
  {
    onChange,
    onBlur,
    ...others
  }: { onChange?: (...args: any[]) => any; onBlur?: (...args: any[]) => any; others?: any } = {}
) {
  return {
    value: field.value,
    onChange: mergeHandler(field.onChange, onChange),
    error: field.errorMessage,
    onBlur: mergeHandler(field.markAsTouch, onBlur),
    disabled: field.disabled,
    onProgress: field.setProgress,
    ...others,
  };
}

export function getSwitchProps(field: any) {
  return {
    checked: field.value,
    onChange: field.onChange,
    onBlur: field.markAsTouch,
    disabled: field.disabled,
  };
}

// TODO: convert to enum
export const FORM_MODE_CONSULT = 'consult';
export const FORM_MODE_EDIT = 'edit';
export const FORM_MODE_CREATE = 'create';

export const FORM_VALID_ON_LOAD = 'onLoad';
export const FORM_VALID_ON_BLUR = 'onBlur';
export const FORM_VALID_ON_CHANGE = 'onChange';
export const FORM_VALID_ON_SUBMIT = 'onSubmit';

export enum FORM_MODE {
  CONSULT = 'consult',
  EDIT = 'edit',
  CREATE = 'create',
}

/**
 * Validate that mode is either EDIT or CREATE
 */
export function isCreationMode(mode: string): boolean {
  return mode === FORM_MODE_CREATE;
}
export function isEditionMode(mode: string): boolean {
  return mode === FORM_MODE_EDIT;
}
export function isConsultationMode(mode: string): boolean {
  return mode === FORM_MODE_CONSULT;
}
export function isEditionOrConsultationMode(mode: string): boolean {
  return mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT;
}

export function isEditionOrCreationMode(mode: string): boolean {
  return mode === FORM_MODE_EDIT || mode === FORM_MODE_CREATE;
}

/**
 * Returns mode if valid or returns CONSULTATION mode.
 */
export function validateMode(mode: string): string {
  if (mode === FORM_MODE_CREATE || mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT) {
    return mode;
  } else {
    return FORM_MODE_CONSULT;
  }
}

export { default as Field } from './Field';
export { default as FieldArray } from './FieldArray';
export * from './FieldsAggregator';
