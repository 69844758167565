import React, { Component, ErrorInfo } from 'react';

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}
export class AlgoErrorBoundary extends Component<{}, State> {
  public state: State = {};

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    if (this.state.error) {
      return <div> Un erreur s'est produite :(</div>;
    }
    return this.props.children;
  }
}
