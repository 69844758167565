import { serializable, identifier, primitive, list, custom, object } from 'serializr';
import Facility from './facilities/Facility';

export enum Profile {
  ADMIN = 'ADMIN',
  INFIRMIER = 'INFIRMIER',
  PERMANENCIER = 'PERMANENCIER',
  PARAMEDIC = 'PARAMEDIC',
  FACILITY_PERSONAL = 'FACILITY_PERSONAL',
  NONE = 'NONE',
}

export enum Permission_V15 {
  ADMIN_USERS_SEARCH = 'ADMIN_USERS_SEARCH',
  ADMIN_USERS_CREATE = 'ADMIN_USERS_CREATE',
  ADMIN_USERS_UPDATE = 'ADMIN_USERS_UPDATE',
  ADMIN_USERS_DELETE = 'ADMIN_USERS_DELETE',
  ADMIN_USERS_EXPORT_MULTI = 'ADMIN_USERS_EXPORT_MULTI',
  CALLS_HOME = 'CALLS_HOME',
  CALLS_SEARCH = 'CALLS_SEARCH',
  CALLS_CREATE = 'CALLS_CREATE',
  CALLS_UPDATE = 'CALLS_UPDATE',
  CALLS_REGULATION_PERM = 'CALLS_REGULATION_PERM',
  CALLS_REGULATION_IDE = 'CALLS_REGULATION_IDE',
  CALLS_DELETE = 'CALLS_DELETE',
  CALLS_EXPORT_MULTI = 'CALLS_EXPORT_MULTI',
  FACILITIES_SEARCH = 'FACILITIES_SEARCH',
  FACILITIES_ADMIN = 'FACILITIES_ADMIN',
  FACILITIES_DASHBOARD = 'FACILITIES_DASHBOARD',
  EVENTS_DASHBOARD = 'EVENTS_DASHBOARD',
  EVENTS_SEARCH = 'EVENTS_SEARCH',
  EVENTS_CREATE = 'EVENTS_CREATE',
  EVENTS_UPDATE = 'EVENTS_UPDATE',
  EVENTS_DELETE = 'EVENTS_DELETE',
  EVENTS_EXPORT_MULTI = 'EVENTS_EXPORT_MULTI',
  VICTIMS_SEARCH = 'VICTIMS_SEARCH',
  VICTIMS_CREATE = 'VICTIMS_CREATE',
  VICTIMS_UPDATE = 'VICTIMS_UPDATE',
  VICTIMS_REGULATION_PERM = 'VICTIMS_REGULATION_PERM',
  VICTIMS_REGULATION_IDE = 'VICTIMS_REGULATION_IDE',
  VICTIMS_DELETE = 'VICTIMS_DELETE',
  VICTIMS_EXPORT_MULTI = 'VICTIMS_EXPORT_MULTI',
}

export default class User {
  @serializable(identifier())
  id?: string;
  @serializable(primitive())
  firstName?: string;
  @serializable(primitive())
  lastName?: string;
  @serializable(primitive())
  username?: string;
  @serializable(primitive())
  profile?: Profile | 'SUPER_ADMIN' = Profile.PERMANENCIER;
  @serializable(primitive())
  email?: string;
  @serializable(primitive())
  phone?: string;
  @serializable(primitive())
  enabled: boolean = true;
  @serializable(list(custom(userPermissionDeserializer, userPermissionSerializer)))
  permissions?: Array<Permission_V15> = [];
  @serializable(list(object(Facility)))
  facilities: Facility[] = [];
}

export function userPermissionSerializer(permission: Permission_V15): string {
  return Permission_V15[permission];
}

export function userPermissionDeserializer(type: string): Permission_V15 {
  return Permission_V15[type as keyof typeof Permission_V15];
}
