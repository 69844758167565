import styled from '@emotion/styled';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { SeverityDegree } from 'model/Diagnostic';
import React, { FC, ReactNode } from 'react';
import { isDiagnosticSeveryUA } from 'utils/call.utilities';

const ActionsContainer = styled.div`
  display: flex;
  flex: 0;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem;
`;

const Content = styled.div`
  align-items: 'center';
  padding: 0.5rem 1rem;
  font-size: 13px;
  overflow-y: auto;
  height: 100%;
`;

const useContainerStyles = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
    flexDirection: 'column',
    maxHeight: 610,
  },
});

const useTitleStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    padding: '1rem',
  },
}));

interface PermanentAlgoQuestionGroupProps {
  title?: String;
  renderTitle?: ReactNode;
  renderActions?: ReactNode;
  onClickPrevious?: () => void;
  diagnosticSeverity?: SeverityDegree;
}

export const AlgoContainer: FC<PermanentAlgoQuestionGroupProps> = ({
  title,
  renderTitle,
  renderActions,
  onClickPrevious,
  diagnosticSeverity,
  children,
}) => {
  const containerClasses = useContainerStyles();
  const titleClasses = useTitleStyles();
  const backgroundColorSeverityUA = (color: string, severityDegree?: SeverityDegree) => {
    return isDiagnosticSeveryUA(severityDegree)
      ? {
          backgroundColor: color,
        }
      : {};
  };

  return (
    <Paper
      classes={containerClasses}
      style={backgroundColorSeverityUA('rgba(255,101,80,0.20)', diagnosticSeverity)}
      elevation={0}
    >
      <div
        className={titleClasses.root}
        style={backgroundColorSeverityUA('red', diagnosticSeverity)}
      >
        {title && (
          <Typography
            variant="h5"
            color="textSecondary"
            style={isDiagnosticSeveryUA(diagnosticSeverity) ? { color: 'white' } : {}}
          >
            {title}
          </Typography>
        )}
        {renderTitle}
      </div>
      <Content>{children}</Content>
      <ActionsContainer>
        {onClickPrevious && (
          <Button onClick={onClickPrevious} variant="outlined">
            Précédent
          </Button>
        )}
        {renderActions}
      </ActionsContainer>
    </Paper>
  );
};
