import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { Spacer } from 'components/Spacer';
import { action, reaction } from 'mobx';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import { CallStatus, FacilityAnswer, RegulationMeans } from 'model/Call';
import Facility, {
  FacilityCategory,
  FacilitySpeciality,
  FacilityType,
} from 'model/facilities/Facility';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FacilitySearch, SearchFacilityFilters } from 'services/facilitySearch.service';
import { callStore } from 'stores/call';
import { nurseCallStore } from 'stores/call/nurseCall.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { NotificationType, uiStore } from 'stores/ui.store';
import { DiagnosticDialog } from '../common/DiagnosticDialog';
import { FacilityAnswerDialog } from './FacilityAnswerDialog';
import { SearchFacilityFilterProps } from './SelectFacility.model';
import { RegulationMeansAnswerDialog } from './RegulationMeansAnswerDialog';

const URGENCY_SPECIALTY_ID = 1;

interface LocalStoreProps extends SearchFacilityFilterProps {
  facilities: Facility[];
  diagnosticDialogOpen: boolean;
  facilityAnswerDialogOpen: boolean;
  regulationMeansAnswerDialogOpen: boolean;
  facilityAnswer: FacilityAnswer;
  selectedFacilityId?: number;
  noMatchingFacilitiesInDepartment: boolean;
  noMatchingCriteriaFacilities: boolean;
}
type RestoreMessageFlag = { restoreMessage: boolean };

function localSoreToSearchFacilityFilters(localStore: LocalStoreProps): SearchFacilityFilters {
  return {
    search: localStore.search,
    departmentIshicode:
      localStore.selectedDepartmentIshicode !== ''
        ? localStore.selectedDepartmentIshicode
        : undefined,
    category: localStore.selectedCategory !== '' ? localStore.selectedCategory : undefined,
    specialtyIds: localStore.selectedSpecialtyIds,
    equipmentIds: localStore.selectedEquipmentIds,
    requiredBloodLevel: localStore.sufficientBloodLevel,
    requiredOxygenLevel: localStore.sufficientOxygenLevel,
    type: localStore.selectedType || undefined,
  };
}
/**
 * Duplicated from "facility.list.page.tsx" for planning reasons. TODO => Factorize
 * Sorry :|
 */
export const SelectFacilityPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory<{ filters: SearchFacilityFilterProps } | undefined>();
  const { t } = useTranslation();

  const localStore = useLocalStore<LocalStoreProps>(() => ({
    facilities: [],
    selectedDepartmentIshicode: '',
    selectedType: '',
    selectedCategory: '',
    selectedSpecialtyIds: [],
    selectedEquipmentIds: [],
    sufficientBloodLevel: false,
    sufficientOxygenLevel: false,
    search: '',
    diagnosticDialogOpen: false,
    facilityAnswer: FacilityAnswer.NOT_SPECIFIED,
    facilityAnswerDialogOpen: false,
    regulationMeansAnswerDialogOpen: false,
    noMatchingFacilitiesInDepartment: false,
    noMatchingCriteriaFacilities: false,
    ...history.location.state?.filters,
  }));

  const doSearch = React.useCallback(
    ({ restoreMessage }: RestoreMessageFlag = { restoreMessage: true }) => {
      if (restoreMessage) {
        localStore.noMatchingCriteriaFacilities = false;
        localStore.noMatchingFacilitiesInDepartment = false;
      }
      localStore.facilities = FacilitySearch.search(localSoreToSearchFacilityFilters(localStore));
    },
    [localStore]
  );

  React.useEffect(() => {
    localStore.facilities = facilityStore.enabledFacilitiesArray;

    // Initial search
    const patientDepartmentId = localStore.selectedDepartmentIshicode;
    doSearch({ restoreMessage: false });
    //Si aucun résultat ne remonte, alors supprimer le critère “Département de localisation du patient” et afficher uniquement les résultats correspondant aux services d’orientation requis.
    if (localStore.facilities.length === 0) {
      localStore.selectedDepartmentIshicode = '';
      localStore.noMatchingFacilitiesInDepartment = true;
      doSearch({ restoreMessage: false });
    }

    //Si aucun résultat ne remonte alors afficher uniquement les établissements disposant des critères suivant ("Département de localisation" "Service d’urgence")
    if (localStore.facilities.length === 0) {
      localStore.selectedDepartmentIshicode = patientDepartmentId;
      localStore.noMatchingCriteriaFacilities = true;
      localStore.noMatchingFacilitiesInDepartment = false;

      localStore.selectedSpecialtyIds = [URGENCY_SPECIALTY_ID];
      doSearch({ restoreMessage: false });
    }

    if (localStore.facilities.length === 0) {
      localStore.noMatchingCriteriaFacilities = false;
    }
  }, [localStore, doSearch]);

  React.useEffect(() => {
    /* Using reaction instead of autorun in order to have fine-grained
     * control over what triggers the search and when.
     */
    const keywordsReaction = reaction(
      () => localStore.search,
      () => doSearch(),
      {
        delay: 800,
        fireImmediately: false,
      }
    );
    const departmentReaction = reaction(
      () => localStore.selectedDepartmentIshicode,
      () => doSearch()
    );
    const categoryReaction = reaction(
      () => localStore.selectedCategory,
      () => doSearch()
    );
    const typeReaction = reaction(
      () => localStore.selectedType,
      () => doSearch()
    );
    const servicesReaction = reaction(
      () => localStore.selectedSpecialtyIds,
      () => doSearch()
    );
    const equipmentsReaction = reaction(
      () => localStore.selectedEquipmentIds,
      () => doSearch()
    );

    return () => {
      keywordsReaction();
      departmentReaction();
      categoryReaction();
      typeReaction();
      servicesReaction();
      equipmentsReaction();
    };
  }, [localStore, doSearch, history]);

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.search = event.target.value;
  };

  const handleSelectSpeciality = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedSpecialtyIds = event.target.value;
  };

  const handleSelectEquipment = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedEquipmentIds = event.target.value;
  };

  const handleSelectType = (event: React.ChangeEvent<{ value: FacilityType }>) => {
    localStore.selectedType = event.target.value;
  };

  const handleSelectCategory = (event: React.ChangeEvent<{ value: FacilityCategory }>) => {
    localStore.selectedCategory = event.target.value;
  };

  const handleSelectDepartment = action((event: React.ChangeEvent<{ value: string }>) => {
    localStore.selectedDepartmentIshicode = event.target.value;
  });

  const handleSelectFacility = (facilityId: number) => {
    localStore.selectedFacilityId = facilityId;
    localStore.facilityAnswerDialogOpen = true;
  };

  const handleSelectFacilityAnswer = (facilityAnswer: FacilityAnswer) => {
    localStore.facilityAnswer = facilityAnswer;
    localStore.facilityAnswerDialogOpen = false;
    localStore.regulationMeansAnswerDialogOpen = true;
  };

  const handleFinishRegulation = async (regulationMeansAnswer?: RegulationMeans) => {
    const pendingCall = nurseCallStore.pendingCall?.synchronizableCall;
    const nurseAlgoFacilityId = localStore.selectedFacilityId;

    try {
      if (!pendingCall) {
        throw new Error('Mandatory "facilityId" or "pendingCall" are missing');
      }
      await callStore.nurseSaveCall({
        ...pendingCall,
        data: {
          ...pendingCall.data,
          nurseAlgoFacilityId,
          facilityAnswer: localStore.facilityAnswer,
          regulationMeans: regulationMeansAnswer,
          status: CallStatus.REGULATED,
        },
      });
      history.push('/appels');
    } catch (error) {
      console.error(error);
      uiStore.pushNotification("Une erreur s'est produite", NotificationType.ERROR);
    }
  };

  return useObserver(() => (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={8}>
            <Typography variant="h4" component="h1">
              Orienter la victime
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                localStore.diagnosticDialogOpen = true;
              }}
              variant="outlined"
              color="primary"
            >
              Afficher le diagnostique
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth={true}
              variant="outlined"
              placeholder="Rechercher un établissement"
              value={localStore.search}
              onChange={handleSearchBarChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Département'}
                onChange={handleSelectDepartment}
                value={localStore.selectedDepartmentIshicode}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Array.from(locationStore.departments.values()).map(({ data }) => (
                  <MenuItem key={data.id} value={data.ihsiCode}>
                    {data.name}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            </Grid>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Type'}
                onChange={handleSelectType}
                value={localStore.selectedType}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityType).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.types.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            </Grid>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Catégorie'}
                onChange={handleSelectCategory}
                value={localStore.selectedCategory}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityCategory).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.categories.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            </Grid>

            <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Service(s)'}
                multiple
                renderValue={(value: any) =>
                  facilityStore.specialitiesArray
                    .filter((speciality) => value.includes(speciality.Id))
                    .map((specialty) => specialty.name)
                    .join(', ')
                }
                onChange={handleSelectSpeciality}
                value={localStore.selectedSpecialtyIds}
              >
                {Array.from(facilityStore.specialitiesArray).map((specialty) => (
                  <MenuItem key={specialty.Id} value={specialty.Id}>
                    <Checkbox checked={localStore.selectedSpecialtyIds?.includes(specialty.Id)} />
                    <ListItemText primary={specialty.name} />
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            </Grid>

            <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialSelectInput
                required
                fullWidth
                multiple
                size="small"
                label={'Équipement(s)'}
                renderValue={(value: any) =>
                  facilityStore.equipmentsArray
                    .filter((equipment) => value.includes(equipment.Id))
                    .map((equipment) => equipment.name)
                    .join(', ')
                }
                onChange={handleSelectEquipment}
                value={localStore.selectedEquipmentIds}
              >
                {facilityStore.equipmentsArray
                  .filter((equipment) => equipment.enabled)
                  .map((equipment) => (
                    <MenuItem key={equipment.Id} value={equipment.Id}>
                      <Checkbox checked={localStore.selectedEquipmentIds?.includes(equipment.Id)} />
                      <ListItemText primary={equipment.name} />
                    </MenuItem>
                  ))}
              </MaterialSelectInput>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {localStore.noMatchingFacilitiesInDepartment && (
          <>
            <Spacer direction="vertical" size="8px" />
            <Typography>
              <WarningIcon fontSize="small" color="secondary" /> Aucun établissement correspondant
              n’existe dans le département de la victime
            </Typography>
          </>
        )}
        {localStore.noMatchingCriteriaFacilities && (
          <>
            <Spacer direction="vertical" size="8px" />
            <Typography>
              <WarningIcon fontSize="small" color="secondary" />
              Aucun établissement correspondant n’existe sur le pays, voici les services d’urgence
              les plus proches
            </Typography>
          </>
        )}
        {localStore.facilities.length === 0 && (
          <>
            <Spacer direction="vertical" size="8px" />
            <Typography>
              Aucun établissement ne répond à l’ensemble de ces critères. Veuillez modifier votre
              recherche
            </Typography>
          </>
        )}
        {localStore.facilities.map((facility, index) => {
          return (
            <Paper key={index} variant="outlined" className={classes.card}>
              <Grid container justify="space-between" className={classes.paper}>
                <Grid item xs={6}>
                  <Typography variant="h6">{facility.name}</Typography>
                  <Typography variant="overline">{facility.type}</Typography>
                  <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
                    {facility.address}
                    <Typography component="div" variant="subtitle1">
                      {facility.locationCity?.name} ({facility.locationDepartment?.name})
                    </Typography>
                  </div>
                </Grid>
                <Grid container item xs={2} alignContent="flex-end">
                  <Button
                    variant={
                      localStore.selectedFacilityId === facility.Id ? 'contained' : 'outlined'
                    }
                    color="primary"
                    onClick={() => handleSelectFacility(facility.Id)}
                  >
                    Selectionner
                  </Button>
                </Grid>
              </Grid>

              <Divider />
              <Grid container className={classes.paper}>
                <Grid item xs={12}>
                  <Typography component="div" variant="subtitle2" className={classes.subtitle}>
                    Services
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {facility.specialities
                    ?.map((faspe: FacilitySpeciality) => faspe?.speciality?.name)
                    .join(', ')}
                </Grid>
              </Grid>

              <Divider />
              <div className={classes.paper}>
                <Typography variant="overline" className={classes.equipments}>
                  {facility.equipments?.map((eq) => eq.name).join(', ')}
                </Typography>
              </div>
            </Paper>
          );
        })}
      </Grid>
      <StickyButtonContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={action(() => {
            localStore.selectedFacilityId
              ? (localStore.facilityAnswerDialogOpen = true)
              : (localStore.regulationMeansAnswerDialogOpen = true);
          })}
        >
          <CheckIcon /> Terminer la régulation
        </Button>
      </StickyButtonContainer>

      <DiagnosticDialog
        call={nurseCallStore.pendingCall?.synchronizableCall?.data}
        algoRunResult={nurseCallStore.pendingCall?.algoRunResult}
        open={localStore.diagnosticDialogOpen}
        onClose={() => {
          localStore.diagnosticDialogOpen = false;
        }}
      />
      <FacilityAnswerDialog
        open={localStore.facilityAnswerDialogOpen}
        onValidate={handleSelectFacilityAnswer}
        onClose={() => {
          localStore.facilityAnswerDialogOpen = false;
        }}
      />
      <RegulationMeansAnswerDialog
        open={localStore.regulationMeansAnswerDialogOpen}
        onValidate={handleFinishRegulation}
        onClose={() => {
          localStore.selectedFacilityId = undefined;
          localStore.regulationMeansAnswerDialogOpen = false;
        }}
      />
    </Grid>
  ));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
    },
    card: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    subtitle: {
      fontSize: '1rem',
    },
    equipments: {
      fontSize: '.8rem',
      fontWeight: 500,
    },
  })
);
const StickyButtonContainer = styled.div`
  position: sticky;
  bottom: 0.5rem;
  margin-left: auto;
  padding-top: 0.5rem;
`;
