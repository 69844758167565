import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { userApi } from 'api';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import SearchBar from 'components/common/SearchBar';
import { reaction } from 'mobx';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import { IPageResponse } from 'model/IPageResponse';
import User, { Permission_V15 } from 'model/User';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deserialize } from 'serializr';

type Order = 'asc' | 'desc';

interface HeadCell {
  id: keyof User;
  sortable: boolean;
  disablePadding: boolean;
  label: string;
}

const headCells: HeadCell[] = [
  { id: 'lastName', sortable: true, disablePadding: false, label: 'Nom' },
  { id: 'firstName', sortable: true, disablePadding: false, label: 'Prénom' },
  { id: 'username', sortable: true, disablePadding: false, label: 'Login' },
  { id: 'profile', sortable: true, disablePadding: false, label: 'Profil' },
  { id: 'enabled', sortable: true, disablePadding: false, label: 'Compte actif' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof User) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof User) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface UserLocaStore {
  pagination: IPageResponse<User>;
  setPagination: (pagination: IPageResponse<User>) => void;
  displayEnabled: boolean | undefined;
  keywords: string;
  setKeywords: (keywords: string) => void;
}

interface PaginationLocalStore {
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: keyof User;
  setPage: (page: number) => void;
  setRowsPerPage: (arg: number) => void;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof User) => void;
}

const UsersPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const store = useLocalStore<UserLocaStore>(() => ({
    pagination: {
      content: [],
      totalPages: 0,
      totalElements: 0,
      last: false,
      size: 0,
      first: false,
      numberOfElements: 0,
      sort: { sorted: false, unsorted: false, empty: true },
      number: 0,
      empty: true,
    },
    setPagination(pagination: IPageResponse<User>) {
      this.pagination = pagination;
    },
    displayEnabled: true,
    keywords: '',
    setKeywords(keywords: string) {
      this.keywords = keywords;
    },
  }));

  const paginationStore = useLocalStore<PaginationLocalStore>(() => ({
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: 'lastName',
    setPage(page: number) {
      this.page = page;
    },
    setRowsPerPage(arg: number) {
      this.rowsPerPage = arg;
    },
    setOrder(order: Order) {
      this.order = order;
    },
    setOrderBy(orderBy: keyof User) {
      this.orderBy = orderBy;
    },
  }));

  const doSearch = React.useCallback(() => {
    const sort: Partial<Record<keyof User, 'ASC' | 'DESC'>> = {
      [paginationStore.orderBy]: paginationStore.order.toUpperCase(),
    };
    userApi
      .searchUsers({
        pageIndex: paginationStore.page,
        pageSize: paginationStore.rowsPerPage,
        keywords: store.keywords,
        sort,
        enabled: store.displayEnabled,
      })
      .then((pagination: IPageResponse<User>) => {
        const users: Array<User> = deserialize(User, pagination.content);
        pagination.content = users;
        store.setPagination(pagination);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [store, paginationStore]);

  React.useEffect(() => {
    // if (!appStatusStore.isConnected) {
    //   history.push('/utilisateurs');
    // } else {
    /* Using reaction instead of autorun in order to have fine-grained
     * control over what triggers the search and when.
     */
    const keywordsReaction = reaction(() => store.keywords, doSearch, {
      delay: 800,
      fireImmediately: true,
    });
    const rowsReaction = reaction(() => paginationStore.rowsPerPage, doSearch);
    const pageNumberReaction = reaction(() => paginationStore.page, doSearch);
    const orderReaction = reaction(() => paginationStore.order, doSearch);
    const orderByReaction = reaction(() => paginationStore.orderBy, doSearch);
    const enabledReaction = reaction(() => store.displayEnabled, doSearch);

    return () => {
      keywordsReaction();
      rowsReaction();
      pageNumberReaction();
      orderReaction();
      orderByReaction();
      enabledReaction();
    };
    // }
  }, [store, paginationStore, doSearch, history]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    paginationStore.setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    paginationStore.setPage(newPage);
  };

  const handleSearch = (terms: string) => {
    store.setKeywords(terms);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof User) => {
    const isAsc = paginationStore.orderBy === property && paginationStore.order === 'asc';
    paginationStore.setOrder(isAsc ? 'desc' : 'asc');
    paginationStore.setOrderBy(property);
  };

  const handleClickUser = (username: string) => () => {
    history.push('/admin/utilisateurs/' + username);
  };

  const handleDisplayUserStatus = (
    event: React.ChangeEvent<{ name?: string; value: undefined | boolean }>
  ) => {
    store.displayEnabled = event.target.value;
  };

  return useObserver(() => (
    <div>
      <div className={classes.pageHeader}>
        <Typography variant="h4" component="h1">
          Utilisateurs
        </Typography>
        <RenderIfAuthorized hasPermission={[Permission_V15.ADMIN_USERS_CREATE]}>
          <NewCallButton
            variant="outlined"
            startIcon={<PersonAddIcon />}
            size="large"
            onClick={() => {
              history.push('/admin/utilisateurs/nouveau');
            }}
          >
            Nouvel utilisateur
          </NewCallButton>
        </RenderIfAuthorized>
      </div>
      <div className={classes.inputsBloc}>
        <div>
          <SearchBar
            fullWidth={true}
            placeholder="Rechercher un utilisateur"
            onSearch={handleSearch}
            className={classes.searchWrapper}
          />
        </div>
        <div style={{ padding: '0 8px', display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <MaterialSelectInput
            label="Statut"
            displayEmpty
            onChange={handleDisplayUserStatus}
            value={store.displayEnabled !== undefined ? store.displayEnabled.toString() : undefined}
          >
            <MenuItem value={undefined}>Tous</MenuItem>
            <MenuItem value={'true'}>{'Actif'}</MenuItem>
            <MenuItem value={'false'}>{'Inactif'}</MenuItem>
          </MaterialSelectInput>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            order={paginationStore.order}
            orderBy={paginationStore.orderBy}
            onRequestSort={handleRequestSort}
            rowCount={store.pagination.totalElements}
          />
          <TableBody>
            {store.pagination.content.map((user: User) => {
              return (
                <TableRow hover key={user.id} onClick={handleClickUser(user.username!)}>
                  <TableCell>
                    <PatientName>{user.lastName}</PatientName>
                  </TableCell>
                  <TableCell align="left">{user.firstName}</TableCell>
                  <TableCell align="left">{user.username}</TableCell>
                  <TableCell align="left">{t(`users.profiles.${user.profile}`)}</TableCell>
                  <TableCell align="left">
                    {user.enabled && <CheckIcon color="primary" />}
                    {!user.enabled && <CancelIcon color="secondary" />}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelRowsPerPage="lignes"
        labelDisplayedRows={({ from, to, count }) =>
          `${from} à ${to === -1 ? count : to} de ${count !== -1 ? count : `plus de ${to}`}`
        }
        count={store.pagination.totalElements}
        rowsPerPage={paginationStore.rowsPerPage}
        page={store.pagination.number}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  ));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem 0',
    },
    table: {
      minWidth: 650,
    },
    inputsBloc: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: '80px',
      padding: '10px 0',
    },
    searchWrapper: {
      display: 'flex',
      minWidth: '50vw',
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

const PatientName: React.FC = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        minWidth: '80px',
        color: 'black',
        fontWeight: 500,
      },
      lastname: {
        textTransform: 'uppercase',
      },
    })
  );
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const NewCallButton = styled(Button)({
  backgroundColor: 'white',
  padding: '20px',
});

export default UsersPage;
