import { Departments } from 'constants/index';
import {
  identifier,
  object,
  primitive,
  serializable,
  date,
  alias,
  reference,
  list,
  custom,
  optional,
} from 'serializr';
import { v4 as uuid } from 'uuid';
import { SynchronizableData } from '../stores/Persistable';
import User from './User';
import { VictimAge, VictimSex } from './Victim';
import {
  PermanentAlgoAdvice,
  PermanentAlgoPriority,
  permanentAlgoPriorityDeserializer,
  permanentAlgoPrioritySerializer,
  UIPermanentAlgoRunResult,
} from './algo/permanent';
import {
  resolveCallReasonById,
  resolveAdviceById,
} from 'stores/algo/permanent/permanentAlgo.resolvers';
import Facility from './facilities/Facility';
import { AlgoCallReason } from './algo';
import { NurseAlgoDiagnostic } from './algo/nurse';
import { resolveNurseAlgoDiagnosticById } from 'stores/algo/nurse/nurseAlgo.resolvers';
import { resolveFacilityById } from 'stores/facility/facility.resolvers';

export enum CallStatus {
  TO_REGULATE = 'TO_REGULATE', // A réguler
  REGULATED = 'REGULATED', // Résulé
}

export enum CallType {
  EMERGENCY = 'EMERGENCY', // Urgence
  INFO = 'INFO', // Info
  HARMFUL = 'HARMFUL', // Nuisible
  TRANSFER = 'TRANSFER', // Transfert
}

export enum FacilityAnswer {
  ACCEPT = 'ACCEPT',
  UNAVAILABLE = 'UNAVAILABLE',
  REFUSE = 'REFUSE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  NONE = 'NONE',
}

export enum RegulationMeans {
  NECESSARY_SENT = 'NECESSARY_SENT',
  NECESSARY_NOT_AVAILABLE = 'NECESSARY_NOT_AVAILABLE',
  NOT_NECESSARY = 'NOT_NECESSARY',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum CallMotif {
  DELIVERY = 'DELIVERY',
  ROAD_ACCIDENT = 'ROAD_ACCIDENT',
  GUNSHOT_WOUND = 'GUNSHOT_WOUND',
  STAB_WOUND = 'STAB_WOUND',
  OTHER = 'OTHER',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export default class Call {
  @serializable(identifier())
  uuid: string = uuid();
  @serializable(object(User))
  creator?: User;
  @serializable(object(User))
  modifier?: User;
  @serializable(primitive())
  userModificationDate?: string;
  @serializable(primitive())
  userCreationDate?: string;
  @serializable(primitive())
  status?: CallStatus;
  @serializable(optional(reference(Facility, resolveFacilityById as any)))
  transferFromFacility?: Facility;
  @serializable(optional(reference(Facility, resolveFacilityById as any)))
  transferToFacility?: Facility;
  @serializable(primitive())
  purpose?: string;
  @serializable(primitive())
  callType?: CallType;
  @serializable(primitive())
  callNumber?: string;
  @serializable(primitive())
  callerIsVictim?: boolean;
  @serializable(primitive())
  callerLastName?: string;
  @serializable(primitive())
  callerFirstName?: string;
  @serializable(primitive())
  callerPhone?: string;
  @serializable(primitive())
  victimCode?: string;
  @serializable(primitive())
  victimLastName?: string;
  @serializable(primitive())
  victimFirstName?: string;
  @serializable(primitive())
  victimSex?: VictimSex;
  @serializable(primitive())
  victimAgeRange?: VictimAge;
  @serializable(primitive())
  victimAge?: number;
  @serializable(primitive())
  locationDepartment?: Departments;
  @serializable(primitive())
  locationCity?: string;
  @serializable(primitive())
  locationStreet?: string;
  @serializable(primitive())
  locationSquare?: string;
  @serializable(primitive())
  locationAdditionalInfos?: string;
  @serializable(primitive())
  results?: string;

  @serializable(custom(permanentAlgoPriorityDeserializer, permanentAlgoPrioritySerializer))
  priority?: PermanentAlgoPriority;

  @serializable(list(object(AlgoCallReason)))
  callReasons: AlgoCallReason[] = [];

  @serializable(
    alias(
      'permanentAlgoCallReasonsIds',
      list(reference(AlgoCallReason, resolveCallReasonById as any))
    )
  )
  permanentAlgoCallReasons?: AlgoCallReason[];

  @serializable(list(reference(PermanentAlgoAdvice, resolveAdviceById as any)))
  permanentAlgoWaitingAdvices?: PermanentAlgoAdvice[];

  @serializable(list(primitive()))
  permanentAlgoResponsesIds?: number[];

  @serializable
  nurseAlgoDiagnosticId?: number;

  @serializable
  nurseAlgoFacilityId?: number;

  @serializable
  facilityAnswer?: FacilityAnswer;

  @serializable(primitive())
  regulationMeans?: RegulationMeans;

  @serializable(optional(reference(NurseAlgoDiagnostic, resolveNurseAlgoDiagnosticById as any)))
  diagnostic?: NurseAlgoDiagnostic;

  @serializable(optional(reference(Facility, resolveFacilityById as any)))
  facility?: Facility;

  @serializable
  motif: CallMotif;

  constructor() {
    const now: string = new Date().toISOString();
    this.userCreationDate = now;
    this.userModificationDate = now;
    this.victimSex = VictimSex.UNKNOWN;
    this.victimAgeRange = VictimAge.NOT_SPECIFIED;
    this.regulationMeans = RegulationMeans.NOT_SPECIFIED;
    this.motif = CallMotif.NOT_SPECIFIED;
  }
}

export class CallSynchronizableData implements SynchronizableData<Call> {
  constructor(isSynchronized: boolean, data: Call) {
    this.isSynchronized = isSynchronized;
    this.data = data;
    if (isSynchronized) {
      this.lastSynchronizedOn = new Date();
    }
  }

  @serializable
  isSynchronized: boolean;

  @serializable(date())
  lastSynchronizedOn?: Date;

  @serializable(date())
  storedAt?: Date;

  @serializable(object(Call))
  data: Call;
}

export function toPermanentAlgoRunResult(call: Call): UIPermanentAlgoRunResult | undefined {
  if (
    !call.permanentAlgoCallReasons?.length ||
    !call.permanentAlgoWaitingAdvices?.length ||
    !call.priority ||
    !call.permanentAlgoResponsesIds?.length
  ) {
    return undefined;
  }
  return {
    priority: call.priority,
    advices: call.permanentAlgoWaitingAdvices,
    reasons: call.permanentAlgoCallReasons,
    responseIds: call.permanentAlgoResponsesIds,
  };
}
