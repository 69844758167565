import { Box, Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { facilityApi } from 'api';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import { runInAction } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import Equipment from 'model/facilities/Equipment';
import Facility, { FacilityUpdate } from 'model/facilities/Facility';
import Speciality from 'model/facilities/Speciality';
import { Permission_V15 } from 'model/User';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { authStore } from 'stores/auth/auth.store';
import { facilityStore } from 'stores/facility';
import { uiStore } from 'stores/ui.store';
import { FORM_MODE } from 'utils/forms';
import { FacilityForm } from './facility.form';
import facilityFormBuilder from './facility.form.builder';

export const FacilityDetailsPage: React.FC = () => {
  const history = useHistory();
  const { facilityId } = useParams<{ facilityId?: string }>();
  const localStore = useLocalStore<{
    facility?: Facility;
    facilityForm: any;
    formEditing: boolean;
    displayDeletionConfirmationDialog: boolean;
    loadFacilityById: (facilityId: string) => void;
  }>(() => ({
    facilityForm: facilityFormBuilder({ mode: FORM_MODE.CREATE }),
    formEditing: true,
    displayDeletionConfirmationDialog: false,
    loadFacilityById: (facilityId: string) => {
      runInAction(() => {
        const facility = facilityStore.getFacilityById(parseInt(facilityId, 10));
        localStore.facility = facility;
        localStore.facilityForm = facilityFormBuilder({
          initialValues: {
            ...facility,
            specialities: facility?.specialities?.map((spe) => spe.speciality),
          },
          mode: authStore.hasPermission(Permission_V15.FACILITIES_ADMIN)
            ? FORM_MODE.EDIT
            : FORM_MODE.CONSULT,
        });
      });
    },
  }));

  React.useEffect(() => {
    if (facilityId) {
      localStore.loadFacilityById(facilityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const handleClickCancel = () => {
    history.goBack();
  };

  const handleClickConfirm = () => {
    localStore.facilityForm.validate();
    if (facilityId && localStore.facilityForm.isValid) {
      const facilityData: FacilityUpdate = {
        ...localStore.facilityForm.values,
        equipmentsIds: localStore.facilityForm.equipments.value.map((eqp: Equipment) => eqp.id),
        specialities: localStore.facilityForm.specialities.value.map((svc: Speciality) => ({
          specialityId: svc.Id,
        })),
        departmentId: localStore.facilityForm.locationDepartmentId.value,
        cityId: localStore.facilityForm.locationCity.value.id,
      };
      facilityApi
        .updateFacility(facilityId, facilityData)
        .then(() => facilityStore.loadFacilities())
        .then(() => {
          uiStore.pushSuccessNotification('Modifications enregistrées avec succès');
          history.goBack();
        });
    }
  };

  // const handleClickDelete = () => {
  //   runInAction(() => {
  //     localStore.displayDeletionConfirmationDialog = true;
  //   });
  // };
  const handleCloseConfirmDialog = () => {
    runInAction(() => {
      localStore.displayDeletionConfirmationDialog = false;
    });
  };

  const handleSetFacilityEnabled = (enable: boolean) => {
    const { facility } = localStore;
    if (facility) {
      const apiAction = enable ? facilityApi.enableFacility : facilityApi.disableFacility;
      apiAction(`${facility.Id}`)
        .then(() => facilityStore.loadFacilities())
        .then(() => localStore.loadFacilityById(`${facility.Id}`))
        .then(() => {
          uiStore.pushSuccessNotification('Modifications enregistrées avec succès');
        });
    }
  };

  const handleDelete = () => {
    if (facilityId) {
      facilityApi
        .deleteFacility(facilityId)
        .then(() => facilityStore.loadFacilities())
        .then(() => {
          uiStore.pushSuccessNotification('Établissement supprimé avec succès');
          handleCloseConfirmDialog();
          history.goBack();
        })
        .catch(() => {
          uiStore.pushSuccessNotification('Suppression impossible');
        });
    }
  };

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item xs={12} sm={10} md={8}>
        <Typography variant="h5">Consultation d'un établissement</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        <Observer>
          {() => (
            <FacilityForm
              facilityForm={localStore.facilityForm}
              formEditing={localStore.formEditing}
            />
          )}
        </Observer>
      </Grid>
      <Grid item xs={12} sm={10} md={8} container justify="space-between">
        <Button variant="contained" size="large" onClick={handleClickCancel}>
          Annuler
        </Button>
        <Observer>
          {() => (
            <Box display="flex" alignItems="flex-end">
              <RenderIfAuthorized
                hasPermission={[Permission_V15.FACILITIES_ADMIN]}
                otherCheck={!!facilityId && localStore.facility?.deactivatable}
              >
                <Box paddingRight={1}>
                  <Button
                    variant="outlined"
                    color={localStore.facility?.enabled ? 'secondary' : 'primary'}
                    startIcon={
                      localStore.facility?.enabled ? <BlockIcon /> : <CheckCircleOutlineIcon />
                    }
                    onClick={() => handleSetFacilityEnabled(!localStore.facility?.enabled)}
                  >
                    {localStore.facility?.enabled ? 'Désactiver' : 'Activer'}
                  </Button>
                </Box>
              </RenderIfAuthorized>
              <RenderIfAuthorized
                hasPermission={[Permission_V15.FACILITIES_ADMIN]}
                otherCheck={!!facilityId && localStore.facility?.deletable}
              >
                <>
                  {/* <Box paddingRight={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={handleClickDelete}
                    >
                      Supprimer
                    </Button>
                  </Box> */}
                </>
              </RenderIfAuthorized>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleClickConfirm}
                disabled={
                  !authStore.hasPermission(Permission_V15.FACILITIES_ADMIN) &&
                  !localStore.facility?.updatable
                }
              >
                Valider
              </Button>
              {localStore.displayDeletionConfirmationDialog && (
                <ConfirmationDialog
                  title="Confirmation de suppression"
                  message="Confirmez-vous la suppression de l'établissement ?"
                  onConfirm={handleDelete}
                  onCancel={handleCloseConfirmDialog}
                />
              )}
            </Box>
          )}
        </Observer>
      </Grid>
    </Grid>
  );
};
