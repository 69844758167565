import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ErrorMessages from 'components/common/errors';
import { useObserver } from 'mobx-react-lite';
import { mainTheme } from 'layout/MainLayout';
import passwordResetFormBuilder from './passwordResetRequest.form.builder';
import PasswordResetForm from './passwordResetRequest.form';
import { resetPasswordRequest } from 'api/auth.api';
import { uiStore } from 'stores/ui.store';

const PasswordResetRequestPage: React.FC = () => {
  const classes = useStyles(mainTheme);
  const history = useHistory();
  const { t } = useTranslation();
  const form = passwordResetFormBuilder({});

  const handleCancel = () => {
    history.push('/login');
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    form.username.validate();
    if (form.username.isValid) {
      resetPasswordRequest(form.username.value)
        .then((res) => {
          if (res.status === 200) {
            history.push('/password-reset/confirm/' + form.username.value);
            uiStore.pushSuccessNotification(t('login.notifications.reset.request'));
          }
        })
        .catch((err) => {
          console.error('err.response', err.response);
          if (err.response && err.response.status === 404) {
            form.username.setValidation({
              valid: false,
              message: t('login.errors.reset.user.unknown'),
            });
          }
        });
    }
  };

  return useObserver(() => (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <Grid container justify="center" alignItems="center" className={classes.title}>
          <Grid item xs={12} md={10}>
            <Typography component="h1" variant="h1" align="center" color="primary">
              {t('app.title')}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {t('app.description')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" align="center">
              {/* {t('login.signin')} */}
              Réinitialisation du mot de passe
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="textSecondary">
              Pour réinitialiser votre mot de passe, veuillez saisir un identifiant.
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12}>
            <ErrorMessages errors={uiStore.errors} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordResetForm
              username={form.username}
              // form={localStore.form}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  ));
};

const useStyles = makeStyles((theme) => ({
  container: {
    // paddingTop: theme.spacing(6),
  },
  title: {
    margin: theme.spacing(8, 0),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
}));

export default PasswordResetRequestPage;
