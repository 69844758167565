import Facility, { FacilityCategory, FacilityType } from 'model/facilities/Facility';
import { facilityStore } from 'stores/facility';

export interface SearchFacilityFilters {
  search?: string;
  requiredBloodLevel: boolean;
  requiredOxygenLevel: boolean;
  departmentIshicode?: string;
  type?: FacilityType;
  category?: FacilityCategory;
  specialtyIds?: number[];
  equipmentIds?: number[];
}

function removeAccents(str: string) {
  const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  return str
    .split('')
    .map((letter: string, index: number) => {
      const accentIndex = accents.indexOf(letter);
      return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
    })
    .join('');
}

function cleanString(str: string): string {
  return removeAccents(str.trim().toLowerCase());
}

function matchSearch(facility: Facility, search?: string): boolean {
  if (!search || search.length === 0) {
    return true;
  }
  const words = search
    .split(' ')
    .filter((word) => word.length)
    .map(cleanString);
  return words.every((word) => facility.name && cleanString(facility.name).includes(word));
}

function matchByDepartmentIshicode(facility: Facility, departmentIshicode?: string): boolean {
  if (!departmentIshicode) {
    return true;
  }
  return facility.locationDepartment?.ihsiCode === departmentIshicode;
}
function matchByType(facility: Facility, type?: FacilityType): boolean {
  if (!type) {
    return true;
  }
  return facility.type === type;
}
function matchByCategory(facility: Facility, category?: FacilityCategory): boolean {
  if (!category) {
    return true;
  }
  return facility.category === category;
}
function matchBySpecialties(facility: Facility, specialtyIds?: number[]): boolean {
  if (!specialtyIds || specialtyIds.length === 0) {
    return true;
  }
  return specialtyIds.every((specialtyId) =>
    facility.specialities?.some(
      (facilitySpecialty) => facilitySpecialty.speciality?.Id === specialtyId
    )
  );
}
function matchByEquipment(facility: Facility, equipmentIds?: number[]): boolean {
  if (!equipmentIds || equipmentIds.length === 0) {
    return true;
  }
  return equipmentIds.every((equipmentId) =>
    facility.equipments?.some((facilityEquipment) => facilityEquipment.Id === equipmentId)
  );
}

export const FacilitySearch = {
  search(filters: SearchFacilityFilters): Facility[] {
    return facilityStore.facilitiesArray
      .filter((facility) => matchSearch(facility, filters.search))
      .filter((facility) => matchByDepartmentIshicode(facility, filters.departmentIshicode))
      .filter((facility) => matchByType(facility, filters.type))
      .filter((facility) => matchByCategory(facility, filters.category))
      .filter((facility) => matchBySpecialties(facility, filters.specialtyIds))
      .filter((facility) => matchByEquipment(facility, filters.equipmentIds))
      .filter((facility) => facility.enabled);
  },
};
