import React, { ReactNode } from 'react';
import { styled, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { TriageKey } from 'model/Dashboard';

export type HeaderKey = TriageKey;
export type DashboardRowData = {
  key: HeaderKey;
  value: string | number | ReactNode;
};

export type DashboardRowItem = {
  rowId?: string;
  rowName: string | number | ReactNode;
  data: DashboardRowData[];
};

export type DashboardTotalData = {
  key: HeaderKey;
  value: number;
};

type DashboardProps = {
  headerNames: string[];
  data: DashboardRowItem[];
  total: DashboardTotalData[];
  onClickRow?: (rowUuid: string) => void;
};

const Table = styled('table')({
  tableLayout: 'fixed',
  width: '100%',
  borderCollapse: 'collapse',
});

const HeaderItem = styled('th')({
  padding: '1rem',
  textAlign: 'center',
  width: '10%',
});

const Body = styled('tbody')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Row = styled('tr')<Theme, { clickable: boolean }>(({ theme, clickable }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderLeft: 0,
  borderRight: 0,
  cursor: clickable ? 'pointer' : undefined,
  '&:hover': clickable
    ? {
        backgroundColor: theme.palette.grey[100],
      }
    : undefined,
}));

const RowName = styled('th')(({ theme }) => ({
  textAlign: 'left',
  padding: '0.5rem',
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const TotalsRow = styled('tr')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
}));

const DataItem = styled('td')(({ theme }) => ({
  padding: '1rem 0',
  textAlign: 'center',
  '&:last-child': {
    backgroundColor: theme.palette.grey[300],
  },
}));

export function Dashboard({ headerNames, data, total, onClickRow }: DashboardProps) {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th scope="col">{/* Row name */}</th>
            {headerNames.map((headerName) => {
              return <HeaderItem scope="col">{headerName}</HeaderItem>;
            })}
          </tr>
        </thead>
        <Body>
          {data.map((row) => {
            return (
              <Row
                clickable={!!onClickRow && row.rowId !== undefined}
                onClick={() => {
                  if (onClickRow && row.rowId) {
                    onClickRow(row.rowId);
                  }
                }}
              >
                <RowName scope="row">{row.rowName}</RowName>
                {row.data.map((rowCell) => (
                  <DataItem>{rowCell.value}</DataItem>
                ))}
              </Row>
            );
          })}
          <TotalsRow>
            <RowName scope="row">
              <Typography variant="h6">Total</Typography>
            </RowName>
            {total.map((rowCell) => (
              <DataItem>{rowCell.value}</DataItem>
            ))}
          </TotalsRow>
        </Body>
      </Table>
    </div>
  );
}
