import { identifier, serializable } from 'serializr';
import { CommonAlgoGroup } from '../CommonAlgoGroup';

export class PermanentAlgoGroup implements CommonAlgoGroup {
  @serializable(identifier())
  private id?: number;

  @serializable
  name?: string;

  @serializable
  initial?: boolean;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
