import axios from 'axios';
import { UpdateProfileLanguage, UpdateProfilePassword } from 'model/Profile';

export async function updatePassword(data: UpdateProfilePassword): Promise<void> {
  try {
    await axios.post('/api/userProfile', data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
export async function updateLanguage(data: UpdateProfileLanguage): Promise<void> {
  try {
    await axios.post('/api/userProfile', data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
