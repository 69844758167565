import { action, computed, observable, toJS, decorate } from 'mobx';
import { FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE, FORM_VALID_ON_LOAD } from '.';

interface FieldProps {
  initValue?: any;
  label?: string;
  validate?: Array<any>;
  disabled?: boolean;
  computeValue?: (value: any) => any;
  validateOn?: Array<string>;
}

class Field {
  readonly _initialValue: any | undefined;
  _value: any | undefined;
  _interacted: boolean = false;
  opts = {};
  _isValidFn: Array<(val: any, label?: string) => any> = [];
  _validateOn: Array<string> = [];
  disabled: boolean = false;
  label: string = '';
  computeValue?: (value: any) => any;
  _validation?: any;

  reset = action(() => {
    this._value = this._initialValue;
  });

  clear = action(() => {
    this._value = undefined;
  });

  setDisabled = action('FIELD_SET_DISABLED', (value: any) => {
    this.disabled = value;
  });

  markAsTouch = action('FIELD_MARK_AS_TOUCH', () => {
    if (!this._interacted) {
      this._interacted = true;
    }
  });

  markAsPristine = action('FIELD_MARK_AS_PRISTINE', () => {
    if (this._interacted) {
      this._interacted = false;
    }
  });

  get value() {
    if (this.computeValue) {
      return this.computeValue(this._value);
    }
    return this._value;
  }

  setValue = action('SET_FIELD_VALUE', (val: any, interract: boolean = true) => {
    if (interract && !this._interacted) {
      this._interacted = true;
    }
    this._value = val;
  });

  onChange = (evt: any, value: any) => {
    if (value != null) {
      this.setValue(value);
    } else if (evt.target && evt.target.value !== this._value) {
      this.setValue(evt.target.value);
    }
    if (this._validateOn.includes(FORM_VALID_ON_CHANGE)) {
      this.validate();
    }
  };

  onChangeValue = (value: any, evt: any) => {
    if (value != null && value !== this._value) {
      this.setValue(value);
    }
    if (this._validateOn.includes(FORM_VALID_ON_CHANGE)) {
      this.validate();
    }
  };

  onBlur = (evt: any) => {
    this.markAsTouch();
    if (this._validateOn.includes(FORM_VALID_ON_BLUR)) {
      this.validate();
    }
  };

  onLoad = () => {
    if (this._validateOn.includes(FORM_VALID_ON_LOAD)) {
      this.validate();
    }
  };

  setValidation = action(
    'SET_FIELD_VALIDATION',
    (validation: { message: string; valid: boolean }) => {
      this._validation = validation;
    }
  );

  get errorMessage() {
    // if (!this._interacted) {
    //   return '';
    // }
    return this._validation.message;
  }

  get isValid() {
    return this._validation.valid;
  }

  get isTouched() {
    return this._interacted;
  }

  get isEmpty() {
    return this._value == null || this._value === '';
  }

  jsValue() {
    return toJS(this._value);
  }

  validate = async () => {
    let lastRes;
    // FIXME: better code
    // if (this.value && this.value.map) {
    //   this.value.map(() => null);
    // }

    for (let i = 0; i < this._isValidFn.length; i++) {
      const isValidFn = this._isValidFn[i];
      let res = isValidFn(this._value, this.label);
      if (typeof res === 'function') {
        // Async validation must occure in a second function in order to let mobx detect used variables
        res = await res();
      }

      if (res === null || res === 'undefined' || !(typeof res === 'object')) {
        throw new Error('Invalid validator result');
      }
      lastRes = res;
      if (!res.valid || res.stopValidation) {
        break;
      }
    }

    if (lastRes) {
      this.setValidation(lastRes);
    }
    // console.debug(`validating field ${this.label}: ${lastRes && lastRes.valid}`);
  };

  constructor({
    initValue = undefined,
    validate = [],
    label = '',
    // debounce = false,
    disabled = false,
    computeValue = undefined,
    validateOn = [],
  }: FieldProps) {
    this.disabled = disabled;
    this.label = label;
    this.computeValue = computeValue;
    this.setValue(initValue, false);
    this._initialValue = initValue;
    if (validate) {
      if (validate instanceof Array) {
        this._isValidFn = validate;
      } else {
        this._isValidFn = [validate];
      }
    }
    if (validateOn) {
      if (validateOn instanceof Array) {
        this._validateOn = validateOn;
      } else {
        this._validateOn = [validateOn];
      }
    }

    if (this._isValidFn.length) {
      this._validation = { valid: false };
      // autorun(this.validate, { delay: debounce ? 1000 : 0 });
    } else {
      this._validation = { valid: true };
    }
    this.onLoad();
  }
}

export default decorate<Field>(Field, {
  _value: observable,
  _interacted: observable,
  _validation: observable,
  disabled: observable,
  value: computed,
  errorMessage: computed,
  isValid: computed,
  isTouched: computed,
  isEmpty: computed,
});
