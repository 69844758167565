import { Grid, Typography } from '@material-ui/core';
import EventVictim, { OrientationStatus } from 'model/event/EventVictim';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FORM_MODE } from 'utils/forms';
import { mapFormDataToVictim } from 'utils/victim.utilities';
import VictimForm from '../victim.form';
import victimFormBuilder from '../victim.form.builder';
import { victimApi } from 'api';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

const VictimSearchDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<{
    eventVictim?: EventVictim;
    mode?: FORM_MODE;
  }>();
  const { eventVictim, mode } = history.location.state;
  const [victimForm] = React.useState(
    React.useMemo(
      () =>
        victimFormBuilder({
          initialValues: { ...eventVictim, ...eventVictim?.victim },
          mode: mode,
        }),
      [eventVictim, mode]
    )
  );
  const [
    isVictimDeletionConfirmationDialogOpen,
    setVictimDeletionConfirmationDialogOpen,
  ] = React.useState(false);

  const handleSubmit = () => {
    victimForm.validate();
    if (victimForm.isValid) {
      if (!eventVictim) {
        throw new Error('No victim in history!');
      }
      const victimData: EventVictim = mapFormDataToVictim(eventVictim, victimForm);
      // Update victim orientation status according to selected values
      victimData.orientationStatus =
        victimData.facility || victimData.stayedThere
          ? OrientationStatus.ORIENTED
          : OrientationStatus.TO_ORIENT;
      //Update or create the Victim in the current Event
      if (mode === FORM_MODE.EDIT) {
        victimApi.updateVictim(eventVictim.event.uuid, victimData).then(history.goBack);
      }
    }
  };

  const handleClickDeleteVictim = () => {
    setVictimDeletionConfirmationDialogOpen(true);
  };

  const handleDeleteVictim = () => {
    if (eventVictim) {
      victimApi.deleteVictim(eventVictim.event.uuid, eventVictim).then(history.goBack);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" component="h2" style={{ textTransform: 'uppercase' }}>
          {t(`forms.victim.mode.${mode}`)} d'une victime
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <VictimForm
          eventTitle={eventVictim?.event.title}
          formMode={mode || FORM_MODE.CONSULT}
          victimForm={victimForm}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onDelete={handleClickDeleteVictim}
        />
      </Grid>
      {!!isVictimDeletionConfirmationDialogOpen && (
        <ConfirmationDialog
          title="Confirmation de suppression"
          message="Confirmez-vous la suppression de la victime ?"
          onConfirm={handleDeleteVictim}
          onCancel={() => setVictimDeletionConfirmationDialogOpen(false)}
        />
      )}
    </Grid>
  );
};

export default VictimSearchDetailsPage;
