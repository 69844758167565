import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { appStatusStore } from 'stores/appStatus.store';
import { OfflineChip } from './OfflineChip';
import { OnlineChip } from './OnlineChip';

export const ConnectivityIndicator: FC = observer(() => {
  const connected = appStatusStore.isConnected;
  const offlineSince = appStatusStore.offlineSince;
  return (
    <>
      {connected && <OnlineChip />}
      {!connected && <OfflineChip offlineSince={offlineSince} />}
    </>
  );
});
