import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Collapse, IconButton } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';

import { ErrorMessage } from 'stores/ui.store';
import { useLocation } from 'react-router-dom';
import { uiStore } from 'stores/ui.store';

interface ErrorMessagesProps {
  errors: Array<ErrorMessage>;
}
export default function ErrorMessages({ errors }: ErrorMessagesProps) {
  const location = useLocation();
  function onClickDismissError(error: ErrorMessage) {
    return () => uiStore.dismissError(error);
  }
  const getErrorsOnPath = () => {
    return errors.filter((err) => err.path === location.pathname);
  };
  return useObserver(() => (
    <Collapse in={getErrorsOnPath().length > 0}>
      {getErrorsOnPath().map((error: ErrorMessage) => (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClickDismissError(error)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{error.title}</AlertTitle>
          {error.message}
        </Alert>
      ))}
    </Collapse>
  ));
}
