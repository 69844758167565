import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SearchIcon from '@material-ui/icons/Search';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';
import { Spacer } from 'components/Spacer';
import { action, reaction, toJS } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import Facility, {
  FacilityCategory,
  FacilitySpeciality,
  FacilityType,
} from 'model/facilities/Facility';
import { Permission_V15, Profile } from 'model/User';
import * as React from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FacilitySearch, SearchFacilityFilters } from 'services/facilitySearch.service';
import { authStore } from 'stores/auth/auth.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { SearchFacilityFilterProps } from './facilities.list.model';

interface LocalStoreProps {
  facilities: Facility[];
}

const FacilityListPage: React.FC = () => {
  const history = useHistory<{ filters: SearchFacilityFilterProps } | undefined>();

  const localStore = useLocalStore<LocalStoreProps>(() => ({
    facilities: [],
  }));

  const doSearch = action((filters: SearchFacilityFilterProps) => {
    let searchData: SearchFacilityFilters = {
      search: filters.search,
      departmentIshicode:
        filters.selectedDepartmentIshicode !== '' ? filters.selectedDepartmentIshicode : undefined,
      category: filters.selectedCategory !== '' ? filters.selectedCategory : undefined,
      specialtyIds: filters.selectedSpecialtyIds,
      equipmentIds: filters.selectedEquipmentIds,
      requiredBloodLevel: filters.sufficientBloodLevel,
      requiredOxygenLevel: filters.sufficientOxygenLevel,
      type: filters.selectedType || undefined,
    };
    localStore.facilities = FacilitySearch.search(searchData);
  });

  const goToFacilityDashboard = (facilityId: number) => {
    history.push(`/etablissements/${facilityId}/dashboard`);
  };

  const handleClickNewFacility = () => {
    history.push('/etablissements/nouveau');
  };

  const handleClickEditFacility = (facilityId: number) => {
    history.push(`/etablissements/${facilityId}/consultation`);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              Établissements
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />
      <Grid
        item
        xs={12}
        container
        justify="flex-end"
        style={{ marginBottom: !isMobile ? '-45px' : 'inherit' }}
      >
        <RenderIfAuthorized hasPermission={[Permission_V15.FACILITIES_ADMIN]}>
          <Button variant="contained" color="primary" size="large" onClick={handleClickNewFacility}>
            Nouvel établissement
          </Button>
        </RenderIfAuthorized>
      </Grid>
      <Grid item xs={12}>
        <FacilitySearchFiltersView
          onSearch={doSearch}
          initialFilterValues={history.location.state?.filters}
        />
      </Grid>
      <Grid item xs={12}>
        <Observer>
          {() => (
            <FacilityListView
              facilities={localStore.facilities}
              onClickDashboard={goToFacilityDashboard}
              onClickItem={handleClickEditFacility}
            />
          )}
        </Observer>
      </Grid>
    </Grid>
  );
};

const FacilityListView = ({
  facilities,
  onClickItem,
  onClickDashboard,
}: {
  facilities: Facility[];
  onClickDashboard: (facilityId: number) => void;
  onClickItem: (facilityId: number) => void;
}) => {
  const handleClickDashboard = (event: React.MouseEvent<HTMLButtonElement>, facilityId: number) => {
    event.stopPropagation();
    onClickDashboard(facilityId);
  };

  const classes = useStyles();
  return (
    <div className={classes.cardListWrapper}>
      {facilities.length === 0 && (
        <>
          <Spacer direction="vertical" size="8px" />
          <Typography>
            Aucun établissement ne répond à l’ensemble de ces critères. Veuillez modifier votre
            recherche
          </Typography>
        </>
      )}
      {facilities.map((facility, index) => (
        <Paper
          key={index}
          variant="outlined"
          className={classes.card}
          onClick={() => onClickItem(facility.Id)}
          title="Editer l'établissement"
        >
          <Grid container justify="space-between" className={classes.paper}>
            <Grid item xs={6}>
              <Typography component="div" variant="h6">
                {facility.name}
              </Typography>
              <Typography variant="overline">{facility.type}</Typography>
              <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
                {facility.address}
                <Typography component="div" variant="subtitle1">
                  {facility.locationCity?.name} ({facility.locationDepartment?.name})
                </Typography>
              </div>
            </Grid>
            {authStore.hasPermission(Permission_V15.FACILITIES_DASHBOARD) &&
              (!authStore.hasProfile(Profile.FACILITY_PERSONAL) ||
                authStore.authenticatedUser?.facilities.find((facId) => facId === facility.Id)) && (
                <BrowserView renderWithFragment={true}>
                  <Grid container item xs={3} justify="flex-end" alignContent="flex-start">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={(event) => handleClickDashboard(event, facility.Id)}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Tableau de bord
                    </Button>
                  </Grid>
                </BrowserView>
              )}
          </Grid>

          <Divider />
          <Grid container className={classes.paper}>
            <Grid item xs={12}>
              <Typography component="div" variant="subtitle2" className={classes.subtitle}>
                Services
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {facility.specialities
                ?.map((faspe: FacilitySpeciality) => faspe?.speciality?.name)
                .join(', ')}
            </Grid>
          </Grid>

          <Divider />
          <div className={classes.paper}>
            <Typography variant="overline" className={classes.equipments}>
              {facility.equipments?.map((eq) => eq.name).join(', ')}
            </Typography>
          </div>
        </Paper>
      ))}
    </div>
  );
};

const FacilitySearchFiltersView: React.FC<{
  onSearch: (filters: SearchFacilityFilterProps) => void;
  initialFilterValues?: SearchFacilityFilterProps | undefined;
}> = ({ onSearch, initialFilterValues }) => {
  const { t } = useTranslation();
  const localStore = useLocalStore<SearchFacilityFilterProps>(() => ({
    selectedDepartmentIshicode: initialFilterValues?.selectedDepartmentIshicode || '',
    selectedType: initialFilterValues?.selectedType || '',
    selectedCategory: initialFilterValues?.selectedCategory || '',
    selectedSpecialtyIds: initialFilterValues?.selectedSpecialtyIds || [],
    selectedEquipmentIds: initialFilterValues?.selectedEquipmentIds || [],
    sufficientBloodLevel: initialFilterValues?.sufficientBloodLevel || false,
    sufficientOxygenLevel: initialFilterValues?.sufficientOxygenLevel || false,
    search: initialFilterValues?.search || '',
  }));

  const handleSearch = () => {
    onSearch({
      selectedDepartmentIshicode: localStore.selectedDepartmentIshicode,
      selectedType: localStore.selectedType,
      selectedCategory: localStore.selectedCategory,
      selectedSpecialtyIds: toJS(localStore.selectedSpecialtyIds),
      selectedEquipmentIds: toJS(localStore.selectedEquipmentIds),
      sufficientBloodLevel: localStore.sufficientBloodLevel,
      sufficientOxygenLevel: localStore.sufficientOxygenLevel,
      search: localStore.search,
    });
  };
  React.useEffect(() => {
    /*
     * Using reaction instead of autorun in order to have fine-grained
     * control over what triggers the search and when.
     */
    const keywordsReaction = reaction(() => localStore.search, handleSearch, {
      delay: 800,
      fireImmediately: true,
    });
    const departmentReaction = reaction(() => localStore.selectedDepartmentIshicode, handleSearch);
    const categoryReaction = reaction(() => localStore.selectedCategory, handleSearch);
    const typeReaction = reaction(() => localStore.selectedType, handleSearch);
    const servicesReaction = reaction(() => localStore.selectedSpecialtyIds, handleSearch, {
      delay: 400,
    });
    const equipmentsReaction = reaction(() => localStore.selectedEquipmentIds, handleSearch, {
      delay: 400,
    });

    return () => {
      keywordsReaction();
      departmentReaction();
      categoryReaction();
      typeReaction();
      servicesReaction();
      equipmentsReaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.search = event.target.value;
  };

  const handleSelectSpeciality = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedSpecialtyIds = event.target.value;
  };

  const handleSelectEquipment = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedEquipmentIds = event.target.value;
  };

  const handleSelectType = (event: React.ChangeEvent<{ value: FacilityType }>) => {
    localStore.selectedType = event.target.value;
  };

  const handleSelectCategory = (event: React.ChangeEvent<{ value: FacilityCategory }>) => {
    localStore.selectedCategory = event.target.value;
  };

  const handleSelectDepartment = action((event: React.ChangeEvent<{ value: string }>) => {
    localStore.selectedDepartmentIshicode = event.target.value;
  });

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} sm={5}>
        <Observer>
          {() => (
            <TextField
              fullWidth={true}
              variant="outlined"
              placeholder="Rechercher un établissement"
              value={localStore.search}
              onChange={handleSearchBarChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Observer>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Département'}
                onChange={handleSelectDepartment}
                value={localStore.selectedDepartmentIshicode}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Array.from(locationStore.departments.values()).map(({ data }) => (
                  <MenuItem key={data.id} value={data.ihsiCode}>
                    {data.name}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Type'}
                onChange={handleSelectType}
                value={localStore.selectedType}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityType).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.types.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Catégorie'}
                onChange={handleSelectCategory}
                value={localStore.selectedCategory}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityCategory).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.categories.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>

        <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Service(s)'}
                multiple
                renderValue={(value: any) =>
                  facilityStore.specialitiesArray
                    .filter((speciality) => value.includes(speciality.Id))
                    .map((specialty) => specialty.name)
                    .join(', ')
                }
                onChange={handleSelectSpeciality}
                value={localStore.selectedSpecialtyIds}
              >
                {Array.from(facilityStore.specialitiesArray).map((specialty) => (
                  <MenuItem key={specialty.Id} value={specialty.Id}>
                    <Checkbox checked={localStore.selectedSpecialtyIds?.includes(specialty.Id)} />
                    <ListItemText primary={specialty.name} />
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>

        <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                multiple
                size="small"
                label={'Équipement(s)'}
                renderValue={(value: any) =>
                  facilityStore.equipmentsArray
                    .filter((equipment) => value.includes(equipment.Id))
                    .map((equipment) => equipment.name)
                    .join(', ')
                }
                onChange={handleSelectEquipment}
                value={localStore.selectedEquipmentIds}
              >
                {facilityStore.equipmentsArray
                  .filter((equipment) => equipment.enabled)
                  .map((equipment) => (
                    <MenuItem key={equipment.Id} value={equipment.Id}>
                      <Checkbox checked={localStore.selectedEquipmentIds?.includes(equipment.Id)} />
                      <ListItemText primary={equipment.name} />
                    </MenuItem>
                  ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
    },
    cardListWrapper: {
      display: 'flex',
      overflow: 'hidden',
      overflowY: 'auto',
      flexDirection: 'column',
      maxHeight: 'calc(100vh - 270px)',
      paddingRight: 8,
    },
    card: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      cursor: 'pointer',
      '&:first-child': { marginTop: 0 },
      '&:hover': { border: `solid 1px ${theme.palette.grey[500]}` },
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    subtitle: {
      fontSize: '1rem',
    },
    equipments: {
      fontSize: '.8rem',
      fontWeight: 500,
    },
  })
);

export default FacilityListPage;
