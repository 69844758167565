import axios from 'axios';
import { IPageResponse } from 'model/IPageResponse';
import IPaginate from 'model/IPaginate';
import ISearch from 'model/ISearch';
import { deserialize } from 'serializr';
import Event, { EventStatus } from 'model/event/Event';
import { formatVictimDataForUpdate } from 'utils/victim.utilities';
import EventVictim from 'model/event/EventVictim';
import { DashboardSearch } from 'model/event/SearchEvent';

export async function createEvent(eventData: Event): Promise<Event> {
  return axios
    .post<Event>('/api/events', {
      ...eventData,
      victims: eventData.victims.map((eventVictim) => ({
        ...eventVictim,
        victim: {
          ...eventVictim.victim,
          addressDepartment: eventVictim.victim?.addressDepartment?.id,
        },
      })),
    })
    .then((res) => {
      return deserialize(Event, res.data);
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateEvent(eventId: string, eventData: Event): Promise<Event> {
  const formattedEventData = {
    ...eventData,
    victims: eventData.victims.map(formatVictimDataForUpdate),
  };
  return axios
    .put(`/api/events/${eventId}`, formattedEventData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function getEvent(eventId: string): Promise<Event> {
  try {
    const eventlRes = await axios.get<Event>(`/api/events/${eventId}`);

    return deserialize(Event, eventlRes.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteEvent(eventId: string): Promise<Event> {
  try {
    const eventlRes = await axios.delete<Event>(`/api/events/${eventId}`);

    return deserialize(Event, eventlRes.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEventVictims(eventId: string): Promise<IPageResponse<EventVictim>> {
  try {
    const eventVictimsRes = await axios.get<IPageResponse<EventVictim>>(
      `/api/events/${eventId}/victims`
    );
    return {
      ...eventVictimsRes.data,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface SearchEventProps extends IPaginate, ISearch {
  status: EventStatus[];
  userCreationDateStart: string;
  userCreationDateEnd: string;
  loadVictims?: boolean;
}

export async function searchEvents(
  search: Partial<SearchEventProps> = {
    pageIndex: 0,
    pageSize: 10,
    keywords: '',
    status: [],
    loadVictims: false,
  }
): Promise<IPageResponse<Event>> {
  return axios
    .post('/api/events/search', search)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getEvents(eventStatuses: EventStatus[] = [EventStatus.ACTIVE]) {
  return searchEvents({
    pageIndex: 0,
    pageSize: 50,
    keywords: '',
    status: eventStatuses,
    loadVictims: false,
  });
}

export async function exportEvents(search: Partial<SearchEventProps>) {
  const filters = {
    sort: { userCreationDate: 'ASC' },
    ...search,
  };
  return axios
    .post('/api/events/export', filters)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getDashboard(search: DashboardSearch) {
  try {
    const response = await axios.post(`/api/events/dashboard`, search);
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
