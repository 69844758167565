import { UpdateAlgo } from 'api/call.api';
import { serialize } from 'serializr';
import { appStatusStore } from 'stores/appStatus.store';
import { IDBRecord, ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { callApi } from '../api';
import Call, { CallSynchronizableData } from '../model/Call';

function callsArrayToRecord(calls: Call[]): IDBRecord<Call> {
  return calls.reduce<IDBRecord<Call>>((record, call) => {
    record[call.uuid] = call;
    return record;
  }, {});
}

export interface CallResourceMethods {
  getCalls: () => Promise<CallSynchronizableData[]>;
  getCall: (uuid: string) => Promise<CallSynchronizableData>;
  createCall: (
    synchronizableCall: CallSynchronizableData,
    updateAlgo: UpdateAlgo
  ) => Promise<CallSynchronizableData>;
  updateCall: (
    uuid: string,
    synchronizableCall: CallSynchronizableData,
    updateAlgo: UpdateAlgo
  ) => Promise<CallSynchronizableData>;
  saveCallOutcome: (calluuid: string, outcome: string) => Promise<CallSynchronizableData>;
}

const CALLS_COLLECTION_KEY = 'calls:list';

export const callResourceProvider: ResourceProvider<CallResourceMethods> = {
  onlineResourceProvider: {
    getCalls: async () => {
      const response = await callApi.getCalls();
      const callsRecord = callsArrayToRecord(response.content);
      const storedCalls: IDBRecord<CallSynchronizableData> = await storableStoreHelpers.onlineSetRecords(
        CALLS_COLLECTION_KEY,
        Call,
        callsRecord
      );
      return Object.values(storedCalls);
    },
    createCall: async (synchronizableCall: CallSynchronizableData, updateAlgo: UpdateAlgo) => {
      const call = synchronizableCall.data;
      const response = await callApi.createCall(serialize(Call, call), updateAlgo);
      synchronizableCall.data = response;
      const stored = await storableStoreHelpers.onlineSetRecordItem(
        CALLS_COLLECTION_KEY,
        response.uuid,
        synchronizableCall
      );
      return stored;
    },
    updateCall: async (
      uuid: string,
      synchronizableCall: CallSynchronizableData,
      updateAlgo: UpdateAlgo
    ) => {
      const call = synchronizableCall.data;
      const response = await callApi.updateCall(uuid, serialize(Call, call), updateAlgo);
      synchronizableCall.data = response;
      const stored = await storableStoreHelpers.onlineSetRecordItem(
        CALLS_COLLECTION_KEY,
        response.uuid,
        synchronizableCall
      );

      return stored;
    },
    getCall: async (uuid: string) => {
      const response = await callApi.getCall(uuid);
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        CALLS_COLLECTION_KEY,
        Call,
        response.uuid,
        response
      );
      return stored;
    },
    saveCallOutcome: async (callUuid: string, outcome: string) => {
      const response = await callApi.saveCallOutcome(callUuid, outcome);
      const storedCalls = await storableStoreHelpers.offlineGetRecord<Call>(callUuid);
      const synchronizableCall = storedCalls[callUuid];
      synchronizableCall.isSynchronized = true;
      synchronizableCall.lastSynchronizedOn = new Date();
      const stored = await storableStoreHelpers.onlineSetRecordItem(
        CALLS_COLLECTION_KEY,
        response.uuid,
        synchronizableCall
      );

      return stored;
    },
  },
  offlineResourceProvider: {
    getCalls: async () => {
      const storedCalls = await storableStoreHelpers.offlineGetRecord<Call>(CALLS_COLLECTION_KEY);
      return Object.values(storedCalls);
    },
    createCall: async (synchronizableCall: CallSynchronizableData) => {
      const stored = await storableStoreHelpers.setUnsynchronizedRecordItem(
        CALLS_COLLECTION_KEY,
        synchronizableCall.data.uuid,
        synchronizableCall
      );
      return stored;
    },
    updateCall: async (uuid: string, synchronizableCall: CallSynchronizableData) => {
      const stored = await storableStoreHelpers.setUnsynchronizedRecordItem(
        CALLS_COLLECTION_KEY,
        synchronizableCall.data.uuid,
        synchronizableCall
      );
      return stored;
    },
    getCall: async (uuid: string) => {
      const storedCalls = await storableStoreHelpers.offlineGetRecord<Call>(CALLS_COLLECTION_KEY);
      return storedCalls[uuid];
    },
    saveCallOutcome: async (callUuid: string, outcome: string) => {
      const storedCalls = await storableStoreHelpers.offlineGetRecord<Call>(CALLS_COLLECTION_KEY);
      const callSynchronizable = storedCalls[callUuid];
      callSynchronizable.data.results = outcome;
      const stored = await storableStoreHelpers.setUnsynchronizedRecordItem(
        CALLS_COLLECTION_KEY,
        callUuid,
        callSynchronizable
      );
      return stored;
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
