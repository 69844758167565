import { Box, Button, Dialog, DialogActions } from '@material-ui/core';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import { observer } from 'mobx-react-lite';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';
import Call, { CallType } from 'model/Call';
import Facility from 'model/facilities/Facility';
import React, { FC } from 'react';
import { facilityStore } from 'stores/facility';
import { NurseAlgoResult } from '../NurseAlgo/NurseAlgoResult/NurseAlgoResult';
import { FacilityAutocomplete } from '../TransfertForm/FacilityAutocomplete';

type DiagnosticDialogProps = {
  open: boolean;
  onClose: () => void;
  algoRunResult?: UINurseAlgoRunResult;
  call?: Call;
};

export const DiagnosticDialog: FC<DiagnosticDialogProps> = observer(
  ({ open, onClose, algoRunResult, call }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        {algoRunResult && <NurseAlgoResult algoRunResult={algoRunResult} />}
        {!algoRunResult && call && call.callType === CallType.TRANSFER && (
          <RenderTransferInfo
            originFacility={call.transferFromFacility}
            destinationFacility={call.transferToFacility}
          />
        )}
        {!algoRunResult && !call && 'ERREUR'}
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const RenderTransferInfo: React.FC<{
  originFacility: Facility | undefined;
  destinationFacility: Facility | undefined;
}> = ({ originFacility, destinationFacility }) => (
  <Box minWidth={400}>
    <AlgoContainer title="Transfert de patient">
      <Box paddingTop={2}>
        <FacilityAutocomplete
          title="Etablissement d'origine"
          value={originFacility || null}
          facilities={facilityStore.enabledFacilitiesArray}
          disabled={true}
          onChange={() => null}
        />
        <FacilityAutocomplete
          title="Etablissement de destination"
          value={destinationFacility || null}
          facilities={facilityStore.enabledFacilitiesArray}
          disabled={true}
          onChange={() => null}
        />
      </Box>
    </AlgoContainer>
  </Box>
);
