import axios from 'axios';
import User from 'model/User';
import { IPageResponse } from 'model/IPageResponse';
import IPaginate from 'model/IPaginate';
import ISearch from 'model/ISearch';

export async function retrieveUser(
  username: string,
  loadFacilities: boolean = false
): Promise<any> {
  return axios
    .get(`/api/users/${username}`, { params: { loadFacilities } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateUser(username: string, user: User): Promise<any> {
  return axios
    .put('/api/users/' + username, formatUserDataForUpdate(user))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function saveUser(user: User): Promise<any> {
  return axios
    .post('/api/users', formatUserDataForUpdate(user))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export interface SearchUsersProps extends IPaginate, ISearch {
  enabled: boolean | undefined;
  sort?: Partial<Record<keyof User, 'ASC' | 'DESC'>>;
}

export async function searchUsers(
  search: Partial<SearchUsersProps> = {
    pageIndex: 0,
    pageSize: 20,
    keywords: '',
    enabled: true,
    sort: { lastName: 'ASC' },
  }
): Promise<IPageResponse<User>> {
  try {
    const response = await axios.post(`/api/users/search`, search);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function resetUserPassword(username: string): Promise<void> {
  return axios
    .put(`/api/users/${username}/resetPassword`)
    .then((res) => {
      if (res.status !== 200) {
        return Promise.reject();
      }
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

function formatUserDataForUpdate(updatedUser: User): unknown {
  const userData = {
    ...updatedUser,
    facilitiesIds: updatedUser.facilities.map((fac) => fac.id),
    updateFacilities: true,
  };
  return userData;
}
