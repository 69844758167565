import axios from 'axios';
import { IPageResponse } from 'model/IPageResponse';
import { Language } from 'model/Language';

export async function fetchLanguages(): Promise<Language[]> {
  try {
    const response = await axios.get<IPageResponse<Language>>('/api/languages');
    return response.data.content;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
