import { decorate, observable, action } from 'mobx';
import { IObservableArray } from 'mobx/lib/internal';
import md5 from 'md5';

export interface ErrorMessage {
  id: string;
  path: string;
  title: string;
  message: string;
}

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
  duration: number;
}
class UIStore {
  errors: IObservableArray<ErrorMessage> = observable.array([], { deep: false });
  notifications: IObservableArray<Notification> = observable.array([], { deep: false });

  pushSuccessNotification(message: string): void {
    this.pushNotification(message, NotificationType.SUCCESS);
  }

  pushErrorNotification(message: string): void {
    this.pushNotification(message, NotificationType.ERROR);
  }

  /**
   * Ajoute une notification qui sera affiché à l'utilisateur.
   * La notification sera automatiquement supprimée
   * suivant la durée d'affichage indiquée.
   *
   * @param {string} message
   * @param {NotificationType} [type=NotificationType.SUCCESS]
   * @param {number} [duration=2000]
   * @memberof UIStore
   */
  pushNotification(
    message: string,
    type: NotificationType = NotificationType.SUCCESS,
    duration: number = 2000
  ): void {
    const notificationId = `${message}-${type}`;
    if (!this.notifications.some((notification) => notification.id === notificationId)) {
      this.notifications.push({ id: notificationId, message, type, duration });
    }
  }

  addError(path: string, title: string, message: string): void {
    const errorId = md5(`${path}-${title}-${message}`);
    if (!this.errors.some((error) => error.id === errorId)) {
      this.errors.push({
        id: md5(`${path}-${title}-${message}`),
        path,
        title,
        message: message,
      });
    }
  }

  /**
   * Remove error from errors array.
   *
   * @param {ErrorMessage} error
   * @memberof UIStore
   */
  dismissError(error: ErrorMessage) {
    this.errors.remove(error);
  }
  dismissErrorsOnPath(path: string) {
    this.errors.replace(this.errors.filter((err) => err.path !== path));
  }
}

decorate(UIStore, {
  errors: observable,
  notifications: observable,
  pushNotification: action,
  pushSuccessNotification: action,
  addError: action,
  dismissError: action,
  dismissErrorsOnPath: action,
});

export default UIStore;

export const uiStore = new UIStore();
