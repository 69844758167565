import { Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { languageApi, profileApi } from 'api';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { Loader } from 'components/common/Loader';
import { Spacer } from 'components/Spacer';
import { observer } from 'mobx-react-lite';
import { Language } from 'model/Language';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'stores/auth/auth.store';
import { storableStoreHelpers } from 'stores/Persistable';

type UpdateLanguageFormProps = {
  disabled: boolean;
};
export const UpdateLanguageForm: FC<UpdateLanguageFormProps> = observer(({ disabled }) => {
  const { t, i18n } = useTranslation();

  const [languages, setLanguages] = useState<Language[] | undefined>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    languageApi.fetchLanguages().then((languages) => {
      setLanguages(languages);
    });
  }, []);

  function handleLanguageChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedLanguage(event.target.value);
  }

  const handleSubmit = async () => {
    await profileApi.updateLanguage({
      updateLanguage: true,
      language: {
        languageCode: selectedLanguage,
        useDefaultLanguage: false,
      },
    });
    // On refresh le token pour en avoir un qui contient la nouvelle langue utilisateur
    await authStore.refreshToken();
    // On change la langue dans la lib d'internationalization. Permet en autre de setter la nouvelle valeur dans le localstorage
    i18n.changeLanguage(selectedLanguage);
    // Nettoyage des données de la base de donnée local
    await storableStoreHelpers.clearOfflineStorage();
    window.location.reload();
  };

  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('account.title.changeLanguage')}
      </Typography>
      <Spacer direction="vertical" size="1rem" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {languages === undefined && <Loader />}
              {languages !== undefined && (
                <MaterialSelectInput
                  label={t('account.language.label.long')}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  disabled={disabled}
                >
                  {languages.map(({ id, code }) => (
                    <MenuItem key={id} value={code}>
                      {t(`account.language.value.${code}`)}
                    </MenuItem>
                  ))}
                </MaterialSelectInput>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-end" alignItems="center">
          <Button
            type="submit"
            size="large"
            variant="outlined"
            color="primary"
            onClick={handleSubmit}
            disabled={disabled || selectedLanguage === undefined || languages === undefined}
          >
            {t('account.button.save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});
