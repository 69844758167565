import React, { useState, FC } from 'react';
import { useInterval } from '../../hooks';
import { Typography, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Alert } from '@material-ui/lab';

const minutes = (nb: number) => nb * 60 * 1000;

const fetchVersion = () =>
  fetch('/VERSION')
    .then((response) => response.text())
    .catch(() => '');

const RMC_APP_VERSION_KEY = 'RMC_APP_VERSION';

const getLocalStorageVersion = () => {
  return window.localStorage.getItem(RMC_APP_VERSION_KEY);
};

const setLocalStorageVersion = (value: string) => {
  return window.localStorage.setItem(RMC_APP_VERSION_KEY, value);
};

export const UpdateAlert: FC = () => {
  const [deployed, setDeployed] = useState<string>('');
  const [outdated, setOutdated] = useState(false);

  async function testIfOutdated() {
    if (!navigator.onLine) {
      return;
    }
    const installedVersion = getLocalStorageVersion();
    const remoteVersion = await fetchVersion();
    const isOutdated = installedVersion !== remoteVersion;
    const isFirstInstall = !installedVersion && remoteVersion;
    // Lors de la première installation on met à jour silencieusement le localStorage
    if (isFirstInstall) {
      setLocalStorageVersion(remoteVersion);
    } else if (isOutdated) {
      // Affichage du bandeau
      setOutdated(true);
      setDeployed(remoteVersion);
    }
  }
  useInterval(
    () => {
      testIfOutdated();
    },
    minutes(1),
    true
  );

  async function reload() {
    if (window.confirm('En rechargeant vous perdrez les modifications non enregistrées')) {
      // Unregister serviceWorker
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        registration.unregister();
      }
      setLocalStorageVersion(deployed);
      window.location.reload(true);
    }
  }

  return outdated ? (
    <Alert
      severity="warning"
      action={
        <IconButton onClick={reload} aria-label="Reload" color="inherit" size="small">
          <RefreshIcon />
        </IconButton>
      }
    >
      <Typography>Votre version de l'application n'est plus à jour, Veuillez recharger.</Typography>
    </Alert>
  ) : null;
};
