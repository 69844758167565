import { serializable, identifier } from 'serializr';
import User from 'model/User';

export class NurseAlgoCardDocument {
  @serializable(identifier())
  id?: number;

  @serializable
  contentType?: string;

  @serializable
  description?: string;

  @serializable
  encoding?: string;

  @serializable
  filename?: string;

  @serializable
  name?: string;

  @serializable
  size?: number;

  @serializable
  creationDate?: string;

  @serializable
  modificationDate?: string;

  @serializable
  deletionDate?: string;

  @serializable
  creator?: User;

  @serializable
  modifier?: User;

  @serializable
  deletor?: User;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
