import { extendObservable, observable } from 'mobx';
import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
  FORM_VALID_ON_CHANGE,
} from 'utils/forms';
import { isRequired, maxLength } from 'utils/forms/validators';

export default function facilityFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    name: new Field({
      initValue: (initialValues && initialValues.name) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
    }),
    phoneNumber: new Field({
      initValue: (initialValues && initialValues.phoneNumber) || '',
      validate: [maxLength(8)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    address: new Field({
      initValue: (initialValues && initialValues.address) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [maxLength(150)],
      label: '',
    }),
    locationDepartmentId: new Field({
      initValue: (initialValues && initialValues.locationDepartment.id) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    locationCity: new Field({
      initValue: (initialValues && initialValues.locationCity) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    type: new Field({
      initValue: (initialValues && initialValues.type) || '',
      disabled: mode === FORM_MODE_CONSULT,
      // validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    category: new Field({
      initValue: (initialValues && initialValues.category) || '',
      disabled: mode === FORM_MODE_CONSULT,
      // validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    sufficientBloodLevel: new Field({
      initValue: (initialValues && initialValues.sufficientBloodLevel) || false,
      disabled: mode === FORM_MODE_CONSULT,
      // validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    sufficientOxygenLevel: new Field({
      initValue: (initialValues && initialValues.sufficientOxygenLevel) || false,
      disabled: mode === FORM_MODE_CONSULT,
      // validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    specialities: new Field({
      initValue: (initialValues && initialValues.specialities) || [],
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    equipments: new Field({
      initValue: (initialValues && initialValues.equipments) || [],
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
  };
  const aggregatedFields = fieldsAggregatorBuilder({
    fields,
  });
  return extendObservable(
    aggregatedFields,
    {
      mode: mode,
    },
    {
      mode: observable,
    }
  );
}
