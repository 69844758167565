import { CommonAlgoResponse } from 'model/algo/CommonAlgoResponse';
import React, { FC } from 'react';
import { QuestionButton } from './QuestionButton';
import { QuestionChoice } from './QuestionChoice';
import { ResponseType } from 'model/ResponseType';
import { UIWithResponsesQuestion } from 'model/algo';
import { HumanBodyQuestion } from './HumanBodyQuestion';
import { isMobile } from 'react-device-detect';

type QuestionComponentType = 'button' | 'choice' | 'humanBody';

function getComponentType(responseType?: ResponseType): QuestionComponentType {
  if (!responseType) {
    throw new Error('Missing responseType in question');
  }

  if (responseType === ResponseType.BUTTON) return 'button';
  if (
    responseType === ResponseType.CHOICE ||
    (responseType === ResponseType.BODY_CHOICE && isMobile)
  )
    /* Pas d'affichage du composant HumanBody sur mobile */
    return 'choice';
  if (responseType === ResponseType.BODY_CHOICE && !isMobile) return 'humanBody';

  throw new Error(
    `Unable to defined which Question component to display with responseType = ${responseType}, isMobile=${isMobile}`
  );
}
interface AlgoQuestionProps {
  question: UIWithResponsesQuestion;
  currentResponses: Record<number, CommonAlgoResponse[]>;
  handleResponse: (response: CommonAlgoResponse | CommonAlgoResponse[]) => void;
}

export const AlgoQuestion: FC<AlgoQuestionProps> = ({
  question,
  currentResponses,
  handleResponse,
}) => {
  const componentType = getComponentType(question.responseType);
  return (
    <>
      {componentType === 'button' && (
        <QuestionButton
          question={question}
          selected={
            currentResponses[question.Id]?.find(
              (response) => response.questionId === question.Id
            ) || null
          }
          onResponse={handleResponse}
        />
      )}

      {}
      {componentType === 'choice' && (
        <QuestionChoice
          question={question}
          selected={currentResponses[question.Id]}
          onResponse={handleResponse}
        />
      )}
      {componentType === 'humanBody' && (
        <HumanBodyQuestion
          question={question}
          selected={currentResponses[question.Id]}
          onResponse={handleResponse}
        />
      )}
    </>
  );
};
