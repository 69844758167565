import ConnectionStatusListener from 'components/common/ConnectionStatusListener';
import React from 'react';
import Routes from './routes';
import NotificationsContainer from './layout/NotificationsContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, sentryReactErrorHandler } from 'errors/errors.handler';
import { mainTheme } from 'layout/MainLayout';
import { ThemeProvider } from '@material-ui/core';

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={mainTheme}>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={sentryReactErrorHandler}>
      <ConnectionStatusListener />
      <Routes />
      <NotificationsContainer />
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
