import axios from 'axios';
import {
  PermanentAlgoAdvice,
  PermanentAlgoResultAdvices,
  PermanentAlgoResultPriority,
  PermanentAlgoGroup,
  PermanentQuestion,
  PermanentAlgoResponse,
  PermanentAlgoResultCallReason,
  PermanentAlgoRoute,
} from 'model/algo/permanent';
import { IPageResponse } from 'model/IPageResponse';
import { AlgoCallReason } from 'model/algo';

export async function fetchAdvicesPage(): Promise<IPageResponse<PermanentAlgoAdvice>> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoAdvice>>(
      `/api/calls/permanentAlgorithm/waitingAdvices`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchCallReasonsPage(): Promise<IPageResponse<AlgoCallReason>> {
  try {
    const response = await axios.get<IPageResponse<AlgoCallReason>>(
      `/api/calls/permanentAlgorithm/callReasons`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchGroupsPage(): Promise<IPageResponse<PermanentAlgoGroup>> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoGroup>>(
      `/api/calls/permanentAlgorithm/groups`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchQuestionsPage(): Promise<IPageResponse<PermanentQuestion>> {
  try {
    const response = await axios.get<IPageResponse<PermanentQuestion>>(
      `/api/calls/permanentAlgorithm/questions`
    );
    return response.data;
  } catch (error) {
    // Use mobs addError action
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchResponsesPage(): Promise<IPageResponse<PermanentAlgoResponse>> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoResponse>>(
      `/api/calls/permanentAlgorithm/responses`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchResultAdvicesPage(): Promise<IPageResponse<PermanentAlgoResultAdvices>> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoResultAdvices>>(
      `/api/calls/permanentAlgorithm/resultWaitingAdvices`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchResultCallReasonsPage(): Promise<
  IPageResponse<PermanentAlgoResultCallReason>
> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoResultCallReason>>(
      `/api/calls/permanentAlgorithm/resultCallReasons`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchResultPrioritiesPage(): Promise<
  IPageResponse<PermanentAlgoResultPriority>
> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoResultPriority>>(
      `/api/calls/permanentAlgorithm/resultPriorities`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchRoutesPage(): Promise<IPageResponse<PermanentAlgoRoute>> {
  try {
    const response = await axios.get<IPageResponse<PermanentAlgoRoute>>(
      `/api/calls/permanentAlgorithm/routes`
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}

export async function fetchAdviceDocumentBinary(documentId: number) {
  try {
    const response = await axios.get<Blob>(
      `/api/calls/permanentAlgorithm/waitingAdviceDocumentBinary/${documentId}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Handle correctly this error', error);
    throw error;
  }
}
