import { Grid, Typography } from '@material-ui/core';
import { userApi } from 'api';
import User from 'model/User';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { userService } from 'services';
import { NotificationType, uiStore } from 'stores/ui.store';
import { FORM_MODE_CREATE } from 'utils/forms';
import UserForm from './user.form';
import userFormBuilder from './user.form.builder';

const CreateUserPage: React.FC = () => {
  const [formMode] = React.useState(FORM_MODE_CREATE);
  const userForm = userFormBuilder({ initialValues: new User(), mode: formMode });
  const history = useHistory();

  const handleCancel = () => {
    history.push('/admin/utilisateurs');
  };

  const handleSubmit = () => {
    userForm.validate();
    if (userForm.isValid) {
      const userData = userService.prepareSubmitData(userForm);
      userApi
        .saveUser(userData)
        .then(() => {
          uiStore.pushSuccessNotification('Utilisateur créé avec succès');
          history.push('/admin/utilisateurs');
        })
        .catch((err) => {
          console.error(err);
          uiStore.pushNotification("Echec de la création de l'utilisateur", NotificationType.ERROR);
        });
    }
    return false;
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} sm={10} lg={8}>
        <Typography variant="h4" component="h1">
          Nouvel utilisateur
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10} lg={8}>
        <UserForm form={userForm} mode={formMode} onCancel={handleCancel} onSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
};

export default CreateUserPage;
