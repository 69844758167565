import { List, ListItem, ListItemText, Typography, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { PermanentAlgoAdvice, AdviceDocument } from 'model/algo/permanent';
import React, { FC, useMemo } from 'react';
import { permanentAlgoResourceProvider } from 'resourceProvider/permanentAlgo.provider';

interface PermanentAlgoResultAdviceDocumentProps {
  adviceDocument: AdviceDocument;
  onClickDocumentAdvice: (documentId: number) => void;
}
const PermanentAlgoResultAdviceDocument: FC<PermanentAlgoResultAdviceDocumentProps> = ({
  adviceDocument,
  onClickDocumentAdvice,
}) => {
  return (
    <Button
      startIcon={<DescriptionIcon />}
      onClick={() => onClickDocumentAdvice(adviceDocument.id)}
      variant="outlined"
      color="primary"
    >
      {adviceDocument.name}
    </Button>
  );
};

interface PermanentAlgoResultAdvicesProps {
  advices: PermanentAlgoAdvice[];
}
export const PermanentAlgoResultAdvices: FC<PermanentAlgoResultAdvicesProps> = ({ advices }) => {
  const handleOpenAdviceDocument = async (documentId: number) => {
    const adviceBinary = await permanentAlgoResourceProvider
      .get()
      .fetchAdviceDocumentBinary(documentId);
    const objectUrl = URL.createObjectURL(adviceBinary);
    window.open(objectUrl);
  };

  // Deduplication des document advices
  const dedupedDocumentAdvices = useMemo(() => {
    let existingDocumentIds: number[] = [];
    let result: PermanentAlgoAdvice[] = [];
    for (const advice of advices) {
      const { document, ...withoutDocument } = advice;
      if (document) {
        // Si le document n'est pas déjà présent. On l'ajoute à la liste des documents existant
        if (!existingDocumentIds.includes(document.id)) {
          existingDocumentIds.push(document.id);
          result.push(advice);
        } else {
          // Si le document est déjà présent. On ajoute l'advice sans document
          result.push(withoutDocument);
        }
      } else {
        result.push(advice);
      }
    }
    return result;
  }, [advices]);

  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Conseils
      </Typography>
      <List dense={true}>
        {dedupedDocumentAdvices.map((advice, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={advice.value}
              secondary={
                advice.document && (
                  <PermanentAlgoResultAdviceDocument
                    adviceDocument={advice.document}
                    onClickDocumentAdvice={handleOpenAdviceDocument}
                  />
                )
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
