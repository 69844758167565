export class Defer<T> {
  private _promise: Promise<T>;
  private _resolve?: (value?: T) => void;
  private _reject?: (reason?: any) => void;

  status: 'PENDING' | 'RESOLVED' | 'REJECTED' = 'PENDING';

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  get promise() {
    return this._promise;
  }
  resolve() {
    if (!this._resolve) {
      throw new Error('No promise resolve in defer');
    }
    this._resolve();
    this.status = 'RESOLVED';
  }
  reject() {
    if (!this._reject) {
      throw new Error('No promise reject in defer');
    }
    this._reject();
    this.status = 'REJECTED';
  }
}
