import { identifier, serializable } from 'serializr';
import { AlgoBodySelection, WithScoreAlgoResponse } from '../CommonAlgoResponse';

export class NurseAlgoResponse implements WithScoreAlgoResponse {
  @serializable(identifier())
  private id?: number;

  @serializable
  private question?: number;

  @serializable
  description?: string;

  @serializable
  excludeOthers?: boolean;

  @serializable
  label?: string;

  @serializable
  orderValue?: number;

  @serializable
  score?: number;

  @serializable
  verticalTargetRoutes?: boolean;

  @serializable
  bodyKey?: AlgoBodySelection;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }

  get questionId() {
    if (this.question === undefined) {
      throw new Error('Undefined field "question"');
    }
    return this.question;
  }
}
