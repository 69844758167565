import {
  Button,
  createStyles,
  Grid,
  Theme,
  withStyles,
  Hidden,
  isWidthDown,
  Modal,
  MenuItem,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import fr from 'date-fns/locale/fr';
import { Observer, useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { eventStore } from 'stores/event';
import { getDistanceInWordsToNow, parseJSONDate, formatToLocal } from 'utils/date.utilities';
import GetAppIcon from '@material-ui/icons/GetApp';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import clsx from 'clsx';
import { orange } from '@material-ui/core/colors';
import { useWidth } from 'utils/components.utilities';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { locationStore } from 'stores/location.store';
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import { action } from 'mobx';
import VictimSearchModal from './victims/search/victim.search.modal';
import { Permission_V15 } from 'model/User';
import { authStore } from 'stores/auth/auth.store';
import { EventStatus } from 'model/event/Event';
import { useTranslation } from 'react-i18next';
import { appStatusStore } from 'stores/appStatus.store';
import RenderIfAuthorized from 'components/common/RenderIfAuthorized';

const EventListPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const width = useWidth();
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [isSearchModalOpen, setSearchModalOpen] = React.useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = React.useState<EventStatus>(EventStatus.ACTIVE);

  React.useEffect(() => {
    locationStore.getDepartments();
    setModalOpen(!eventStore.selectedDepartment);
  }, []);

  React.useEffect(() => {
    eventStore.getEvents([selectedStatus]);
  }, [selectedStatus]);

  const handleCloseVictimSearchModal = () => {
    setSearchModalOpen(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClickNewEvent = () => {
    eventStore.createNewEvent().then((event) => {
      history.push('/evenements/nouveau', {
        event,
      });
    });
  };

  const handleClickSearchVictim = () => {
    setSearchModalOpen(true);
  };

  const handleClickRow = (eventId: string) => () => {
    eventStore.getEvent(eventId).then((event) => {
      if (event) {
        history.push('/evenements/consultation', {
          event,
        });
      }
    });
  };

  const handleClickChangeDepartment = action(() => {
    eventStore.selectedDepartment = undefined;
    setModalOpen(true);
  });

  const handleChangeSelectedStatus = (e: any) => {
    setSelectedStatus(e.target.value);
  };

  const handleClickExport = () => {
    history.push('/evenements/export');
  };

  return useObserver(() => (
    <Grid container spacing={2}>
      <VictimSearchModal
        isOpen={isSearchModalOpen}
        onCancel={handleCloseVictimSearchModal}
        onClose={handleCloseVictimSearchModal}
      />
      <Hidden smUp>
        <DepartementSelectionModal isOpened={isModalOpen} onConfirm={handleCloseModal} />
      </Hidden>
      {/* <Grid item xs={12} container spacing={2}> */}
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">
          Suivi des évènements actifs
        </Typography>
      </Grid>
      <Grid item xs={6} container>
        {authStore.hasPermission(Permission_V15.VICTIMS_SEARCH) && (
          <ContainedActionButton
            size="medium"
            onClick={handleClickSearchVictim}
            fullWidth={isWidthDown('xs', width)}
            startIcon={<SearchIcon />}
            disabled={!appStatusStore.isConnected}
          >
            Recherche victime
          </ContainedActionButton>
        )}
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        {authStore.hasPermission(Permission_V15.EVENTS_CREATE) && (
          <ContainedActionButton
            size="large"
            color="primary"
            onClick={handleClickNewEvent}
            fullWidth={isWidthDown('xs', width)}
          >
            Déclarer
          </ContainedActionButton>
        )}
      </Grid>
      <Hidden smUp>
        <Grid item xs={2}>
          <TuneIcon onClick={handleClickChangeDepartment} />
        </Grid>
      </Hidden>
      <Grid item xs={10} md={12} container justify="space-between" alignItems="flex-end">
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <MaterialSelectInput
            label={'Status recherché'}
            displayEmpty={false}
            value={selectedStatus}
            onChange={handleChangeSelectedStatus}
            noHelper
          >
            {Object.keys(EventStatus).map((key) => (
              <MenuItem key={key} value={key}>
                {t(`event.status.${key}`)}
              </MenuItem>
            ))}
          </MaterialSelectInput>
          <div style={{ marginBottom: 6, marginLeft: 16 }}>
            <RenderIfAuthorized hasPermission={[Permission_V15.EVENTS_EXPORT_MULTI]}>
              <Button variant="contained" startIcon={<GetAppIcon />} onClick={handleClickExport}>
                {t('nav.reports')}
              </Button>
            </RenderIfAuthorized>
          </div>
        </div>
        {eventStore.lastSynchronization && (
          <Typography variant="caption">
            Dernière actualisation: {formatToLocal(eventStore.lastSynchronization)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        {isWidthDown('xs', width) && (
          <>
            {Array.from(eventStore.events.values())
              .filter(
                (sEvent) => sEvent.data.locationDepartment === eventStore.selectedDepartment?.id
              )
              .slice(0, 50)
              .map(({ data: event, isSynchronized }) => (
                <Paper
                  key={event.uuid}
                  className={classes.paper}
                  onClick={handleClickRow(event.uuid)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{event.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{event.victims.length} victime(s)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {getDistanceInWordsToNow(parseJSONDate(event.userCreationDate!), {
                        includeSeconds: true,
                        locale: fr,
                      })}
                    </Grid>
                  </Grid>
                </Paper>
              ))}
          </>
        )}
        <Hidden only="xs">
          <TableContainer
            component={Paper}
            style={{ backgroundColor: 'transparent', maxHeight: 600 }}
          >
            <Table className={classes.table} aria-label="eventss_table" size="medium" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell align="left">Département</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Nombre de patient(s)</TableCell>
                </TableRow>
              </TableHead>
              <Observer>
                {() => (
                  <TableBody>
                    {eventStore.events.size === 0 && (
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                          Aucun évènement actif
                        </TableCell>
                      </TableRow>
                    )}
                    {Array.from(eventStore.events.values())
                      .slice(0, 50)
                      .map(({ data: event, isSynchronized }) => (
                        <TableRow
                          key={event.uuid}
                          hover
                          className={clsx(
                            classes.tableRow,
                            !isSynchronized && classes.tableRowUnsync
                          )}
                          onClick={handleClickRow(event.uuid)}
                        >
                          <TableCell align="left">{event.title}</TableCell>

                          <TableCell align="left">
                            <Typography
                              style={{
                                fontWeight: 500,
                                maxWidth: 250,
                                display: 'inline-block',
                              }}
                              component="div"
                              noWrap
                            >
                              {
                                locationStore.getDepartmentFromId(event.locationDepartment || 0)
                                  ?.name
                              }
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {getDistanceInWordsToNow(parseJSONDate(event.userCreationDate!), {
                              includeSeconds: true,
                              locale: fr,
                            })}
                          </TableCell>
                          <TableCell align="left">
                            {isSynchronized && event.totalVictims}
                            {!isSynchronized && (
                              <Tooltip
                                arrow
                                placement="right"
                                title="L'appel n'a pas été transmis au serveur"
                              >
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <SyncProblemIcon className={classes.syncIcon} />
                                </div>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
              </Observer>
            </Table>
          </TableContainer>
        </Hidden>
      </Grid>
    </Grid>
  ));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
      backgroundColor: theme.palette.background.paper,
    },
    tableRow: {
      height: theme.spacing(10),
    },
    tableRowUnsync: {
      backgroundColor: orange[50],
    },
    syncIcon: {
      color: theme.palette.warning.dark,
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(0.5),
    },
  })
);

const ContainedActionButton = withStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
}))((props: any) => (
  <Button variant="contained" {...props}>
    {props.children}
  </Button>
));

function DepartementSelectionModal({
  isOpened,
  onConfirm,
}: {
  isOpened: boolean;
  onConfirm: () => void;
}) {
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState<number>(
    locationStore.departmentsArray[0].id || 0
  );
  const handleClickConfirm = () => {
    const selectedDepartment = locationStore.getDepartmentFromId(selectedDepartmentId);
    eventStore.setSelectedDepartment(selectedDepartment);
    onConfirm();
  };
  const handleSelectValue = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDepartmentId(event.target.value as number);
  };
  return useObserver(() => (
    <Modal open={isOpened}>
      <div
        style={{
          minHeight: 150,
          width: '100vw',
          maxWidth: '90%',
          margin: 'auto',
          backgroundColor: 'white',
          padding: 8,
          marginTop: '50vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} justify="space-evenly" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center">Veuillez sélectionner un département:</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <MaterialSelectInput
              required
              fullWidth
              size="small"
              label={'Département'}
              value={selectedDepartmentId}
              onChange={handleSelectValue}
            >
              {locationStore.departmentsArray.map(({ id, name }, index) => (
                <MenuItem key={index} value={id}>
                  {name}
                </MenuItem>
              ))}
            </MaterialSelectInput>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button color="primary" variant="contained" onClick={handleClickConfirm}>
              Accepter
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  ));
}

export default EventListPage;
