import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HumanFace,
  isPermanencierHumanBodyZone,
  PermanencierHumanBodyZone,
  PERMANENCIER_HUMAN_BODY_ZONES,
} from './HumanBody.model';
import { ReactComponent as HumanBodyFrontPerma } from './human-body-front-permanencier.svg';
import { ReactComponent as HumanBodyBackPerma } from './human-body-back-permanencier.svg';
import { Button, Typography } from '@material-ui/core';
import { HumanBodyZoneList } from './HumanBodyZoneList.component';

const ZONES_CSS_SELECTOR = PERMANENCIER_HUMAN_BODY_ZONES.map((zone) => `g#${zone}`).join(', ');
const ZONES_TITLE_CSS_SELECTOR = PERMANENCIER_HUMAN_BODY_ZONES.map(
  (zone) => `g#${zone} > title`
).join(', ');

const zonesCss = css`
  svg.human-body {
    fill: #e6e6e6;
    transition: fill 0.2s ease-out;
    &.selected {
      fill: #f7897c;
    }
    ${ZONES_CSS_SELECTOR} {
      &.selected {
        fill: #f7897c;
      }

      title {
        &.label {
          display: none;
        }
      }
    }
    &.main {
      ${ZONES_CSS_SELECTOR} {
        cursor: pointer;

        stroke: transparent;
        fill: #e6e6e6;
        transition: stroke 0.2s ease-in-out, fill 0.2s ease-out;
        &:hover {
          stroke: red;
        }
        &.selected {
          fill: #f7897c;
        }
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Figures = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;
const RightColumn = styled.div`
  height: 100%;
  width: 23%;
  display: flex;
  flex-direction: column;
`;
const WorkingFaceContainer = styled.div`
  width: 55%;
  text-align: center;
`;
const PreviewFaceContainer = styled.div`
  cursor: pointer;
  &:hover {
    border: 1px solid lightgray;
  }
  text-align: center;
`;

type HumanBodyProps = {
  initialSelected: PermanencierHumanBodyZone[];
  onResponse: (zoneIds: PermanencierHumanBodyZone[]) => void;
};

export function HumanBody({ initialSelected, onResponse }: HumanBodyProps) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<PermanencierHumanBodyZone[]>(initialSelected);
  const [mainFace, setMainFace] = useState<HumanFace>('front');

  const svgRef = useRef<SVGSVGElement | null>(null);
  const svgPreviewRef = useRef<SVGSVGElement | null>(null);

  const clickOnZoneHandler = useCallback(
    (event: Event) => {
      let zoneId: string | null = null;
      let group: HTMLElement | SVGElement | null = null;

      if (event.target instanceof SVGPathElement) {
        group = event.target?.parentElement;
      } else if (event.target instanceof SVGElement) {
        group = event.target;
      }

      if (group) {
        zoneId = group.getAttribute('id');
        if (zoneId && isPermanencierHumanBodyZone(zoneId)) {
          const newSelectedZone = selected.includes(zoneId)
            ? selected.filter((val) => val !== zoneId)
            : [...selected, zoneId];
          setSelected(newSelectedZone);
        }
      }
    },
    [selected]
  );

  useEffect(() => {
    const svg = svgRef.current;

    function manageSelectedClassOnElem(elem: Element) {
      const elemId = elem.getAttribute('id');
      if (!elemId || !isPermanencierHumanBodyZone(elemId)) {
        return;
      }
      const isSelected = selected.includes(elemId);
      if (isSelected) {
        elem.classList.add('selected');
      } else {
        elem.classList.remove('selected');
      }
    }
    svg?.querySelectorAll(ZONES_CSS_SELECTOR).forEach((elem) => {
      // Set selected class on the SVG zone if it's id is in "selected" component state
      manageSelectedClassOnElem(elem);
      elem.addEventListener('click', clickOnZoneHandler);
    });

    // Bind "click" event listener on each interactive svg groups
    svgPreviewRef.current?.querySelectorAll(ZONES_CSS_SELECTOR).forEach((elem) => {
      manageSelectedClassOnElem(elem);
    });

    // Unbind event listener if component state change
    return () => {
      svg?.querySelectorAll(ZONES_CSS_SELECTOR).forEach((elem) => {
        elem.removeEventListener('click', clickOnZoneHandler);
      });
    };
  }, [mainFace, clickOnZoneHandler, selected]);

  useEffect(() => {
    svgRef.current?.querySelectorAll(ZONES_TITLE_CSS_SELECTOR).forEach((elem) => {
      const translateKey = elem.getAttribute('data-translate-id');
      if (translateKey) {
        elem.textContent = t(translateKey);
      }
    });
  }, [t, mainFace]);

  const handleSendResponse = () => {
    onResponse(selected);
  };

  return (
    <Content>
      <Global styles={zonesCss} />
      <Figures>
        <WorkingFaceContainer>
          <Typography variant="subtitle2">{t(`humanBody.title.${mainFace}`)}</Typography>

          {mainFace === 'front' && (
            <HumanBodyFrontPerma
              ref={svgRef}
              height="auto"
              width="100%"
              className="main human-body"
            />
          )}
          {mainFace === 'back' && (
            <HumanBodyBackPerma
              ref={svgRef}
              height="auto"
              width="100%"
              className="main human-body"
            />
          )}
        </WorkingFaceContainer>

        <RightColumn>
          <PreviewFaceContainer
            onClick={() => {
              const newWorkingFace = mainFace === 'front' ? 'back' : 'front';
              setMainFace(newWorkingFace);
            }}
          >
            <Typography variant="subtitle2">
              {t(`humanBody.title.${mainFace === 'front' ? 'back' : 'front'}`)}
            </Typography>
            {mainFace !== 'front' && (
              <HumanBodyFrontPerma
                ref={svgPreviewRef}
                height="auto"
                width="100%"
                className="human-body"
              />
            )}
            {mainFace !== 'back' && (
              <HumanBodyBackPerma
                ref={svgPreviewRef}
                height="auto"
                width="100%"
                className="human-body"
              />
            )}
          </PreviewFaceContainer>
          <div>
            <HumanBodyZoneList selected={selected} onSetSelectedZones={setSelected} />
          </div>

          <Button
            onClick={handleSendResponse}
            color="primary"
            variant="contained"
            disabled={selected.length === 0}
          >
            Valider
          </Button>
        </RightColumn>
      </Figures>
    </Content>
  );
}
