import * as React from 'react';
import { Observer } from 'mobx-react-lite';
import { Button, Grid, Typography } from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import { getInputFieldProps } from 'utils/forms';
import { useTranslation } from 'react-i18next';
import { updatePasswordFormBuilder } from './updatePassword.form.builder';
import { profileApi } from 'api';
import { uiStore } from 'stores/ui.store';
import { useLocation } from 'react-use';
import { Spacer } from 'components/Spacer';

type UpdatePasswordFormProps = {
  disabled: boolean;
};
export const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({ disabled }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const form = updatePasswordFormBuilder(disabled);

  const handleSubmit = async () => {
    form.validate();
    if (form.isValid) {
      try {
        await profileApi.updatePassword({
          updatePassword: true,
          oldPassword: form.currentPassword.value,
          newPassword: form.newPassword.value,
        });
        uiStore.pushSuccessNotification('Mot de passe modifié avec succès');
      } catch (err) {
        console.error(err);
        console.debug(err.response);
        uiStore.addError(
          location.pathname || '',
          'Erreur',
          err.response && err.response.data && err.response.data.message
        );
      }
    }
  };
  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('account.title.changePassword')}
      </Typography>
      <Spacer direction="vertical" size="1rem" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    type="password"
                    placeholder={t('forms.profile.currentPassword.placeholder')}
                    label={t('forms.profile.currentPassword.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.currentPassword)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item md={6} />
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    type="password"
                    placeholder={t('forms.profile.newPassword.placeholder')}
                    label={t('forms.profile.newPassword.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.newPassword)}
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Observer>
                {() => (
                  <MaterialInput
                    type="password"
                    label={t('forms.profile.newPasswordConfirm.label')}
                    required
                    fullWidth
                    {...getInputFieldProps(form.newPasswordConfirm)}
                  />
                )}
              </Observer>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-end" alignItems="center">
          <Observer>
            {() => (
              <Button
                type="submit"
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                disabled={disabled || !form.isValid}
              >
                {t('account.button.save')}
              </Button>
            )}
          </Observer>
        </Grid>
      </Grid>
    </div>
  );
};
