import { appStatusStore } from 'stores/appStatus.store';
import {
  IDBRecord,
  ResourceProvider,
  storableStoreHelpers,
  SynchronizableData,
} from 'stores/Persistable';
import { specialityApi } from '../api';
import Speciality, { SpecialityCreation } from 'model/facilities/Speciality';

function specialitiesArrayToRecord(specialities: Speciality[]): IDBRecord<Speciality> {
  return specialities.reduce<IDBRecord<Speciality>>((record, speciality) => {
    if (speciality.id) {
      record[speciality.id] = speciality;
    }
    return record;
  }, {});
}

export interface SpecialityResourceMethods {
  getSpecialities: () => Promise<SynchronizableData<Speciality>[]>;
  getSpeciality: (uuid: string) => Promise<SynchronizableData<Speciality>>;
  saveSpeciality: (speciality: SpecialityCreation) => Promise<SynchronizableData<Speciality>>;
  updateSpeciality: (speciality: Speciality) => Promise<SynchronizableData<Speciality>>;
  deleteSpeciality: (speciality: Speciality) => Promise<SynchronizableData<Speciality>>;
}

const SPECIALITIES_COLLECTION_KEY = 'specialities:list';

export const specialityResourceProvider: ResourceProvider<SpecialityResourceMethods> = {
  onlineResourceProvider: {
    getSpecialities: async () => {
      const response = await specialityApi.getSpecialities();
      const callsRecord = specialitiesArrayToRecord(response.content);

      const storedSpecialities: IDBRecord<SynchronizableData<
        Speciality
      >> = await storableStoreHelpers.onlineSetRecords(
        SPECIALITIES_COLLECTION_KEY,
        Speciality,
        callsRecord
      );

      return Object.values(storedSpecialities);
    },
    getSpeciality: async (id: string) => {
      const response = await specialityApi.getSpeciality(id);
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        SPECIALITIES_COLLECTION_KEY,
        Speciality,
        response.id,
        response
      );

      return stored;
    },
    saveSpeciality: async (speciality) => {
      const response = await specialityApi.saveSpeciality(speciality);
      if (!response.id) {
        throw new Error('Invalid data - New speciality has no "id"');
      }
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        SPECIALITIES_COLLECTION_KEY,
        Speciality,
        String(response.id),
        response
      );
      return stored;
    },
    updateSpeciality: async (speciality: Speciality) => {
      const response = await specialityApi.updateSpeciality(speciality);
      if (!response.id) {
        throw new Error('Invalid data - New speciality has no "id"');
      }
      const stored = await storableStoreHelpers.offlineSetRecordItem(
        SPECIALITIES_COLLECTION_KEY,
        Speciality,
        String(response.id),
        response
      );
      return stored;
    },
    deleteSpeciality: async (speciality: Speciality) => {
      const response = await specialityApi.deleteSpeciality(speciality);
      if (!response.id) {
        throw new Error('Invalid data - New speciality has no "id"');
      }
      const record = await storableStoreHelpers.offlineGetRecord<Speciality>(
        SPECIALITIES_COLLECTION_KEY
      );
      const syncSpeciality = record[response.id];
      syncSpeciality.data = response;
      const stored = await storableStoreHelpers.offlineDeleteRecordItem(
        SPECIALITIES_COLLECTION_KEY,
        String(response.id),
        syncSpeciality
      );
      return stored;
    },
  },
  offlineResourceProvider: {
    getSpecialities: async () => {
      const storedSpecialities = await storableStoreHelpers.offlineGet(SPECIALITIES_COLLECTION_KEY);
      return Object.values(storedSpecialities);
    },

    getSpeciality: async (uuid: string) => {
      const storedSpecialities = await storableStoreHelpers.offlineGetRecord<Speciality>(
        SPECIALITIES_COLLECTION_KEY
      );
      return storedSpecialities[uuid];
    },
    saveSpeciality: () => {
      throw new Error("'saveSpeciality': Not available offline");
    },
    updateSpeciality: async (speciality: Speciality) => {
      throw new Error('Fonctionnalité non disponible offline');
    },
    deleteSpeciality: async (speciality: Speciality) => {
      throw new Error('Fonctionnalité non disponible offline');
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
