import React, { FC, ChangeEvent } from 'react';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Facility from 'model/facilities/Facility';

interface FacilityAutocompleteProps extends Partial<AutocompleteRenderInputParams> {
  title: string;
  value: Facility | null;
  facilities: Facility[];
  onChange: (newValue: Facility | null) => void;
}
export const FacilityAutocomplete: FC<FacilityAutocompleteProps> = ({
  title,
  value,
  facilities,
  onChange,
  disabled = false,
}) => {
  const handleChange = (
    _event: ChangeEvent<{}>,
    value: Facility | null,
    _reason: AutocompleteChangeReason
  ) => {
    onChange(value);
  };
  return (
    <Autocomplete
      value={value}
      options={facilities}
      getOptionLabel={(facility) => facility.name || ''}
      renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
      onChange={handleChange}
      openOnFocus
      disabled={disabled}
    />
  );
};
