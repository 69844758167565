import { Typography } from '@material-ui/core';
import { DangerousHtml } from 'components/DangerousHtml';
import { Spacer } from 'components/Spacer';
import React, { FC, ReactNode } from 'react';

interface QuestionContainerProps {
  renderLabel?: ReactNode;
  description?: string;
}

export const QuestionContainer: FC<QuestionContainerProps> = ({
  renderLabel,
  description,
  children,
}) => {
  return (
    <>
      {renderLabel && (
        <>
          <Typography variant="h6" color="textSecondary">
            {renderLabel}
          </Typography>
          <Spacer direction="vertical" size="1rem" />
        </>
      )}
      {description && (
        <>
          <Typography variant="body2">
            <DangerousHtml html={description} />
          </Typography>
          <Spacer direction="vertical" size="5px" />
        </>
      )}
      {children}
    </>
  );
};
