import { Button, Chip, createStyles, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Spacer } from 'components/Spacer';
import { Observer } from 'mobx-react-lite';
import { EventStatus } from 'model/event/Event';
import EventVictim from 'model/event/EventVictim';
import { Permission_V15 } from 'model/User';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { authStore } from 'stores/auth/auth.store';
import { formatToLocal } from 'utils/date.utilities';
import { FORM_MODE } from 'utils/forms';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { searchVictims } from 'api/victim.api';
import { deserialize } from 'serializr';

const VictimSearchPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { code } = useParams<{ code?: string }>();
  const history = useHistory();
  const victimDetailHistory = useHistory<{
    eventVictim?: EventVictim;
    mode: FORM_MODE;
  }>();

  const [eventVictims, setEventVictims] = React.useState<EventVictim[]>([]);

  const doSearch = React.useCallback(
    async (code: string) => {
      const result = await searchVictims({ victimCodes: [code] });
      if (result.numberOfElements) {
        const eventVictims: EventVictim[] = deserialize(EventVictim, result.content);

        if (eventVictims.length === 1) {
          victimDetailHistory.replace('/victimes/consultation', {
            eventVictim: eventVictims[0],
            mode: FORM_MODE.EDIT,
          });
        } else {
          setEventVictims(eventVictims);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (code) {
      doSearch(code);
    }
  }, [code, doSearch]);

  const handleClickVictim = (eventVictim: EventVictim) => (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    victimDetailHistory.push('/victimes/consultation', {
      eventVictim,
      mode: authStore.hasPermission(Permission_V15.VICTIMS_UPDATE)
        ? FORM_MODE.EDIT
        : FORM_MODE.CONSULT,
    });
  };

  const handleClickReturn = () => {
    history.push('/evenements');
  };

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">
          Victime(s) associée(s) au bracelet n° <b>{code}</b>
        </Typography>
        <Spacer direction="vertical" size="1rem" />
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Hidden smUp>
          {eventVictims.length === 0 && (
            <Typography variant="body2">
              Aucune victime ne correspond au n° de bracelet fourni
            </Typography>
          )}
          {eventVictims.map((eVictim: EventVictim) => {
            const { victim, event } = eVictim;
            return (
              <Grid key={victim.uuid} item xs={12} md={4}>
                <Card onClick={handleClickVictim(eVictim)}>
                  <CardContent>
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        <table>
                          <tr>
                            <td>
                              <b>Événement</b>
                            </td>
                            <td>
                              <Chip
                                color={
                                  event?.status === EventStatus.ACTIVE ? 'secondary' : 'primary'
                                }
                                style={{ marginRight: 8 }}
                                label={
                                  <Typography variant="button" style={{ fontSize: 12 }}>
                                    {t(`event.status.${event?.status}`)}
                                  </Typography>
                                }
                              />
                              {event?.title}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <b>Date</b>
                            </td>
                            <td>
                              {victim.userCreationDate && formatToLocal(victim.userCreationDate)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Nom</b>
                            </td>
                            <td>{victim.lastName || '-'}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Prénom</b>
                            </td>
                            <td>{victim.firstName || '-'}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Sexe</b>
                            </td>
                            <td>{t(`victim.sex.${victim.sex}`)}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Age</b>
                            </td>
                            <td>{t(`victim.age.${victim.ageRange}`)}</td>
                          </tr>
                        </table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Hidden>
        <Hidden smDown>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: 'transparent', maxHeight: 600 }}
          >
            <Table className={classes.table} aria-label="events_table" size="medium" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Événement</TableCell>
                  <TableCell align="left">Nom</TableCell>
                  <TableCell align="left">Prénom</TableCell>
                  <TableCell align="left">Sexe</TableCell>
                  <TableCell align="left">Age</TableCell>
                </TableRow>
              </TableHead>
              <Observer>
                {() => (
                  <TableBody>
                    {eventVictims.length === 0 && (
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                          Aucune victime ne correspond au n° de bracelet fourni
                        </TableCell>
                      </TableRow>
                    )}
                    {eventVictims.map((eVictim) => {
                      const { victim, event } = eVictim;
                      return (
                        <TableRow
                          key={victim.uuid}
                          hover
                          className={classes.tableRow}
                          onClick={handleClickVictim(eVictim)}
                        >
                          <TableCell align="left">
                            {victim.userCreationDate && formatToLocal(victim.userCreationDate)}
                          </TableCell>
                          <TableCell align="left">
                            <Chip
                              color={event?.status === EventStatus.ACTIVE ? 'secondary' : 'primary'}
                              style={{ marginRight: 8 }}
                              label={
                                <Typography variant="button" style={{ fontSize: 12 }}>
                                  {t(`event.status.${event?.status}`)}
                                </Typography>
                              }
                            />
                            {event?.title}
                          </TableCell>
                          <TableCell align="left">{victim.lastName || '-'}</TableCell>
                          <TableCell align="left">{victim.firstName || '-'}</TableCell>
                          <TableCell align="left">{t(`victim.sex.${victim.sex}`)}</TableCell>
                          <TableCell align="left">
                            {victim.age || t(`victim.age.${victim.ageRange}`)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Observer>
            </Table>
          </TableContainer>
        </Hidden>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={handleClickReturn}>
          Retour aux évènements
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
      backgroundColor: theme.palette.background.paper,
    },
    tableRow: {
      height: theme.spacing(10),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(0.5),
    },
  })
);

export default VictimSearchPage;
