import * as React from 'react';
import {
  withStyles,
  Theme,
  FormControl,
  FormLabel,
  makeStyles,
  createStyles,
  FormHelperText,
  Typography,
} from '@material-ui/core';

interface MaterialSelectBlocsProp {
  disabled?: boolean;
  options?: any;
  onChange?: (event: any, value: any) => void;
  value: any;
  label?: string;
  error?: any;
  required?: boolean;
  helperText?: string;
  size?: 'small' | 'large';
  editing?: boolean;
}
const MaterialSelectBlocs: React.FC<MaterialSelectBlocsProp> = ({
  options,
  onChange,
  value,
  disabled = false,
  label,
  error,
  required = false,
  helperText,
  size = 'small',
  editing = true,
}) => {
  const classes = useStyles();
  const [selectedBloc, setSelectedBloc] = React.useState(value);
  const handleClickBloc = (value: React.SetStateAction<string>) => (event: React.MouseEvent) => {
    if (onChange && !disabled) onChange(event, value);
  };

  React.useEffect(() => {
    setSelectedBloc(value);
  }, [value]);

  const displayValue = (value: any) => {
    const selectedValue = options.find(({ id }: { id: string }) => id === value);
    return (selectedValue && selectedValue.value) || 'N/R';
  };
  return (
    <FormControl
      className={classes.formControl}
      error={error}
      disabled={disabled}
      required={required}
      size="small"
    >
      <FormLabel className={classes.label} disabled={disabled}>
        {label}
      </FormLabel>
      {editing && (
        <>
          <div style={{ display: 'flex' }}>
            <div className={classes.table}>
              {options.map((option: any, index: number) => (
                <SelectBloc
                  key={index}
                  disabled={disabled}
                  onClick={handleClickBloc(option.id)}
                  selected={selectedBloc === option.id}
                  error={error}
                  size={size}
                >
                  {option.value}
                </SelectBloc>
              ))}
            </div>
          </div>
          <FormHelperText className={classes.helperText} variant="outlined">
            {(!!error && helperText) || ' '}
          </FormHelperText>
        </>
      )}
      {!editing && <Typography>{displayValue(value)}</Typography>}
    </FormControl>
  );
};

interface SelectBlocProps {
  classes?: any;
  children?: any;
  onClick?: any;
  selected: boolean;
  disabled: boolean;
  error: boolean;
  size: string;
}
const SelectBloc = withStyles((theme: Theme) => ({
  root: {
    display: 'table-cell',
    padding: (props: SelectBlocProps) =>
      props.size === 'small' ? theme.spacing(1) : theme.spacing(2.4, 1.6),
    border: (props: SelectBlocProps) => `1px solid ${props.error ? 'red' : '#bdc3c7'}`,
    textAlign: 'center',
    verticalAlign: 'center',
    cursor: (props: SelectBlocProps) => (props.disabled ? 'inrehit' : 'pointer'),
    '&:hover': {
      backgroundColor: (props: SelectBlocProps) =>
        props.disabled ? 'inrehit' : theme.palette.primary.light,
    },
    backgroundColor: (props: SelectBlocProps) => {
      if (props.selected) {
        if (props.disabled) {
          return theme.palette.primary.light;
        }
        return theme.palette.primary.main;
      } else {
        return 'transparent';
      }
    },
    color: (props: SelectBlocProps) =>
      props.selected ? 'white' : props.disabled ? theme.palette.text.disabled : 'inherit',
  },
}))(({ classes, disabled = false, onClick, selected = false, ...props }: SelectBlocProps) => {
  return (
    <div className={classes.root} onClick={onClick}>
      {props.children}
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      background: 'none',
      minWidth: 120,
    },
    table: {
      display: 'table',
      minHeight: theme.spacing(5),
      borderCollapse: 'collapse',
    },
    input: {
      backgroundColor: 'white',
    },
    label: {
      paddingLeft: '1px',
      marginBottom: theme.spacing(0.5),
    },
    helperText: {
      margin: 0,
      marginTop: 0,
      fontSize: '11px',
      marginLeft: '1px',
    },
  })
);

export default MaterialSelectBlocs;
