import {
  Field,
  FORM_VALID_ON_BLUR,
  fieldsAggregatorBuilder,
  FORM_MODE_CREATE,
  FORM_VALID_ON_CHANGE,
} from 'utils/forms';
import { isRequired, maxLength, minLength } from 'utils/forms/validators';

export default function passwordResetConfirmFormBuilder({
  initialValues = null,
  mode = FORM_MODE_CREATE,
}) {
  const fields = {
    username: new Field({
      initValue: initialValues && initialValues.username,
      disabled: true,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
    }),
    validationKey: new Field({
      initValue: undefined,
      disabled: false,
      validate: [isRequired, minLength(10), maxLength(10)],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
