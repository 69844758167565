import { Button, Divider, Grid, Paper, Theme, Typography, withStyles } from '@material-ui/core';
import { Spacer } from 'components/Spacer';
import { Observer, useLocalStore } from 'mobx-react-lite';
import Call, { CallMotif, CallStatus, CallSynchronizableData, CallType } from 'model/Call';
import { NeedConfirmError } from 'model/Errors';
import { SearchFacilityFilterProps } from 'pages/facilities/facilities.list.model';
import * as React from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { nurseAlgoStore } from 'stores/algo/nurse';
import { callStore, callUIStore } from 'stores/call';
import { NotificationType, uiStore } from 'stores/ui.store';
import { getInputFieldProps, getSwitchFieldProps } from 'utils/forms';
import callFormBuilder from '../common/call.form.builder';
import { CallConfirmSaveDialog } from '../common/CallConfirmSaveDialog';
import { CallerFormFields } from '../common/callFormFields/CallerFormFields';
import { CallTypeFormFields } from '../common/callFormFields/CallTypeFormFields';
import { LocationFormFields } from '../common/callFormFields/LocationFormFields';
import { PageTitle } from '../common/callFormFields/PageTitle';
import { VictimFormFields } from '../common/callFormFields/VictimFormFields';
import { NurseAlgoDispatcher } from '../common/NurseAlgo/NurseAlgoDispatcher';
import { nurseCallStore } from 'stores/call/nurseCall.store';
import { formatPhoneNumber, formatNumberInput } from 'utils/forms/formatters';
import { action, autorun, reaction } from 'mobx';
import { VictimAge } from 'model/Victim';
import { InfoBloc } from '../common/callFormFields/FormBloc';
import BilanDialog from '../common/BilanDialog';
import { convertAgeToAgeSlice } from 'utils/victim.utilities';
import { useTranslation } from 'react-i18next';

function mapFormToCallData(synchronizableCall: CallSynchronizableData, callForm: any) {
  const callData: Call = {
    ...synchronizableCall.data,
    status: callForm.status.value,
    purpose: callForm.purpose.value,
    callNumber: callForm.callNumber.value,
    callType: callForm.callType.value,
    callerIsVictim: callForm.callerIsVictim.value,
    callerLastName: callForm.callerLastName.value,
    callerFirstName: callForm.callerFirstName.value,
    callerPhone: callForm.callerPhone.value,
    victimCode: callForm.victimCode.value,
    victimLastName: callForm.victimLastName.value,
    victimFirstName: callForm.victimFirstName.value,
    victimSex: callForm.victimSex.value,
    victimAgeRange: callForm.victimAgeRange.value,
    locationDepartment: callForm.locationDepartment.value,
    locationCity: callForm.locationCity.value,
    locationStreet: callForm.locationStreet.value,
    locationSquare: callForm.locationSquare.value,
    locationAdditionalInfos: callForm.locationAdditionalInfos.value,
  };
  if (callData.callerIsVictim) {
    callData.victimFirstName = callData.callerFirstName;
    callData.victimLastName = callData.callerLastName;
  }
  return callData;
}
interface ILocalStore {
  synchronizableCall: CallSynchronizableData | undefined;
  callForm: any;
  bilanEditing: boolean;
  editing: boolean;
  requiredFieldsFilled: boolean;
  isSubmitButtonDisabled: boolean;
  setSynchronizableCall: (call: CallSynchronizableData | undefined) => void;
  setEditing: any;
  setBilanEditing: any;
  setRequiredFieldsFilled: any;
  setSubmitButtonDisabled: any;
  updateSynchronizableCall: (call: CallSynchronizableData) => void;
}

const CallPreRegulationNursePage: React.FC = () => {
  const history = useHistory<{ call: CallSynchronizableData } | undefined>();
  const { uuid } = useParams<{ uuid?: string }>();
  const { t } = useTranslation();
  const goToFacilitiesHistory = useHistory<{ filters: Partial<SearchFacilityFilterProps> }>();
  const deactivatePromptRef = React.useRef(true);

  const localStore = useLocalStore<ILocalStore>(() => ({
    synchronizableCall: undefined,
    callForm: callFormBuilder({}),
    bilanEditing: false,
    editing: true,
    requiredFieldsFilled: false,
    isSubmitButtonDisabled: false,
    setSynchronizableCall(call: CallSynchronizableData | undefined) {
      this.synchronizableCall = call;
      if (call) {
        this.callForm = callFormBuilder({
          initialValues: call.data,
        });
      }
    },
    setEditing(editing: boolean) {
      this.editing = editing;
    },
    setBilanEditing(bilanEditing: boolean) {
      this.bilanEditing = bilanEditing;
    },
    setRequiredFieldsFilled(fieldsFilled: boolean) {
      this.requiredFieldsFilled = fieldsFilled;
    },
    setSubmitButtonDisabled(isDisabled: boolean) {
      this.isSubmitButtonDisabled = isDisabled;
    },
    updateSynchronizableCall(call: CallSynchronizableData) {
      this.synchronizableCall = call;
    },
  }));

  React.useEffect(
    () => {
      if (!uuid) {
        callStore.createNewCall().then(localStore.setSynchronizableCall);
      } else {
        callStore.getCall(uuid).then(localStore.setSynchronizableCall);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid]
  );

  // Change required fields filled indicator
  React.useEffect(
    () =>
      autorun(() => {
        if (localStore.callForm?.callType.value === CallType.EMERGENCY) {
          localStore.setRequiredFieldsFilled(
            !localStore.callForm?.callType.isEmpty &&
              !localStore.callForm?.locationDepartment.isEmpty &&
              !localStore.callForm?.locationCity.isEmpty &&
              !localStore.callForm?.callerPhone.isEmpty
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Changement du status de l'appel en fonction de son type
  React.useEffect(
    () =>
      reaction(
        () => localStore.callForm?.callType.value,
        () => {
          if (
            [CallType.TRANSFER, CallType.INFO, CallType.HARMFUL].includes(
              localStore.callForm?.callType.value
            )
          ) {
            localStore.callForm?.status.setValue(CallStatus.REGULATED);
          } else {
            localStore.callForm?.status.setValue(CallStatus.TO_REGULATE);
          }
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Re-init dialog state on leaving page
  React.useEffect(
    () => () => {
      callUIStore.closeConfirmSaveDialog();
      nurseAlgoStore.resetAlgo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = async (disableDialog = false) => {
    if (!localStore.synchronizableCall) {
      throw new Error('No call in history!');
    }
    localStore.setSubmitButtonDisabled(true);
    localStore.callForm?.validate();
    if (!localStore.callForm?.isValid) {
      localStore.setEditing(true);
      localStore.setBilanEditing(false);
      localStore.setSubmitButtonDisabled(false);
    } else {
      const callData: Call = mapFormToCallData(localStore.synchronizableCall, localStore.callForm);
      try {
        await callStore.nurseSaveCall({ ...localStore.synchronizableCall, data: callData });
        uiStore.pushSuccessNotification('Appel créé avec succès');
        deactivatePromptRef.current = true;
        history.push('/appels');
      } catch (error) {
        localStore.setSubmitButtonDisabled(false);
        if (error instanceof NeedConfirmError) {
          return;
        }
        uiStore.pushNotification("Echec de la création de l'appel", NotificationType.ERROR);
      }
    }
  };

  const goToFacilities = async () => {
    if (!localStore.synchronizableCall) {
      throw new Error('No call in history!');
    }
    localStore.callForm?.validate();
    if (!localStore.callForm?.isValid) {
      return;
    }

    const algoRunResult = nurseAlgoStore.algoRunResult;
    if (!algoRunResult) {
      uiStore.pushNotification('Veuillez déterminer le diagnostique', NotificationType.WARNING);
      return;
    }

    const callData: Call = mapFormToCallData(localStore.synchronizableCall, localStore.callForm);
    const nurseAlgoDiagnosticId = algoRunResult.diagnostic.Id;

    try {
      nurseCallStore.setPendingCall({
        synchronizableCall: {
          ...localStore.synchronizableCall,
          data: { ...callData, nurseAlgoDiagnosticId },
        },
        algoRunResult,
      });

      const filters: Partial<SearchFacilityFilterProps> = {
        selectedEquipmentIds:
          algoRunResult.diagnostic.content?.orientation?.equipments.map(
            (equipment) => equipment.Id
          ) || [],
        selectedSpecialtyIds:
          algoRunResult.diagnostic.content?.orientation?.services.map((service) => service.Id) ||
          [],
        selectedDepartmentIshicode: localStore.callForm?.locationDepartment.value,
      };

      goToFacilitiesHistory.push('/appels/etablissements', { filters });
    } catch (error) {
      if (error instanceof NeedConfirmError) {
        return;
      }
      uiStore.pushNotification("Echec de l'enregistrement de l'appel", NotificationType.ERROR);
    }
  };

  const handleClickBilanEditing = () => {
    localStore.setBilanEditing(!localStore.bilanEditing);
  };

  const handleChangeAgeRange = action((e: React.ChangeEvent<any>) => {
    localStore.callForm?.victimAge.setValue(''); //clear
  });

  const handleChangeAge = action((e: React.ChangeEvent<any>) => {
    const victimAgeSlice: VictimAge = convertAgeToAgeSlice(e.target.value);
    localStore.callForm?.victimAgeRange.setValue(victimAgeSlice);
  });

  const handleSelectMotif = action((callMotif: CallMotif) => {
    if (localStore.synchronizableCall?.data) {
      localStore.updateSynchronizableCall({
        ...localStore.synchronizableCall,
        data: {
          ...localStore.synchronizableCall.data,
          motif: callMotif,
        },
      });
    }
  });

  return (
    <>
      <Observer>
        {() => (
          <Prompt
            message={() => {
              return localStore.callForm?.isTouched && !deactivatePromptRef.current
                ? 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
                : true;
            }}
          />
        )}
      </Observer>
      <Grid container spacing={2}>
        {/* PAGE TITLE */}
        <Observer>
          {() => (
            <PageTitle
              title="Nouvelle fiche d'appel"
              callStatus={localStore.callForm?.status.value}
              callNumber={localStore.callForm?.callNumber.value}
            />
          )}
        </Observer>
        {/* LEFT COLUMN */}
        <Grid item xs={12} lg={7} container spacing={1}>
          <Grid item xs={12}>
            <InfoBloc>
              {/* TYPE APPEL */}
              <Observer>
                {() => (
                  <CallTypeFormFields
                    callTypeInputProps={getInputFieldProps(localStore.callForm?.callType)}
                    purposeInputProps={getInputFieldProps(localStore.callForm?.purpose)}
                  />
                )}
              </Observer>
              <Divider />
              {/* APPELANT */}
              <Observer>
                {() => (
                  <CallerFormFields
                    editing={localStore.editing}
                    callerIsVictimInputProps={getSwitchFieldProps(
                      localStore.callForm?.callerIsVictim
                    )}
                    callerFirstNameInputProps={getInputFieldProps(
                      localStore.callForm?.callerFirstName
                    )}
                    callerLastNameInputProps={getInputFieldProps(
                      localStore.callForm?.callerLastName
                    )}
                    callerPhoneInputProps={getInputFieldProps(
                      localStore.callForm?.callerPhone,
                      {},
                      { formatter: formatPhoneNumber }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* VICTIME */}
              <Observer>
                {() => (
                  <VictimFormFields
                    editing={localStore.editing}
                    formMode={localStore.callForm?.mode}
                    callerIsVictim={localStore.callForm?.callerIsVictim.value}
                    victimFirstNameInputProps={getInputFieldProps(
                      localStore.callForm?.victimFirstName
                    )}
                    victimLastNameInputProps={getInputFieldProps(
                      localStore.callForm?.victimLastName
                    )}
                    victimSexInputProps={getInputFieldProps(localStore.callForm?.victimSex)}
                    victimAgeRangeInputProps={getInputFieldProps(
                      localStore.callForm?.victimAgeRange,
                      {
                        onChange: handleChangeAgeRange,
                      }
                    )}
                    victimAgeInputProps={getInputFieldProps(
                      localStore.callForm?.victimAge,
                      {
                        onChange: handleChangeAge,
                      },
                      { formatter: formatNumberInput(3) }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* LOCALISATION */}
              <Observer>
                {() => (
                  <LocationFormFields
                    editing={localStore.editing}
                    locationDepartmentInputProps={getInputFieldProps(
                      localStore.callForm?.locationDepartment
                    )}
                    locationStreetInputProps={getInputFieldProps(
                      localStore.callForm?.locationStreet
                    )}
                    locationCityInputProps={getInputFieldProps(localStore.callForm?.locationCity)}
                    locationSquareInputProps={getInputFieldProps(
                      localStore.callForm?.locationSquare
                    )}
                    locationAdditionalInfosInputProps={getInputFieldProps(
                      localStore.callForm?.locationAdditionalInfos
                    )}
                    currentCallType={localStore.callForm?.callType.value}
                  />
                )}
              </Observer>
            </InfoBloc>
          </Grid>
          <Grid item xs={12}>
            <InfoBloc>
              <Observer>
                {() => (
                  <InfoSubBloc>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="button">Bilan</Typography>
                      {localStore.editing && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickBilanEditing}
                        >
                          {localStore.callForm?.results.value ? 'Afficher' : 'Renseigner'}
                        </Button>
                      )}
                    </Grid>
                    <BilanDialog
                      isOpen={localStore.bilanEditing}
                      onClose={() => localStore.setBilanEditing(false)}
                      onSave={() => {
                        if (localStore.synchronizableCall) {
                          callStore
                            .saveCallOutcome(
                              localStore.synchronizableCall.data.uuid,
                              localStore.callForm?.results.value
                            )
                            .then(() => localStore.setBilanEditing(false));
                        }
                      }}
                      inputProps={getInputFieldProps(localStore.callForm?.results)}
                    />
                  </InfoSubBloc>
                )}
              </Observer>
            </InfoBloc>
          </Grid>
        </Grid>

        {/* RIGHT COLUMN */}
        <Grid container item xs={12} lg={5} direction="column">
          <Observer>
            {() => {
              return localStore.synchronizableCall?.data.motif ? (
                <>
                  <Grid item>
                    <Paper style={{ padding: 8 }} elevation={0}>
                      <Grid container justify="space-between" item xs={12}>
                        <Typography variant="button">Raison de l'appel: </Typography>
                        <Typography>
                          {t(`calls.motif.${localStore.synchronizableCall?.data.motif}`)}
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Spacer direction="vertical" size="0.5rem" />
                </>
              ) : (
                <></>
              );
            }}
          </Observer>
          <Observer>
            {() => (
              <>
                {localStore.synchronizableCall && (
                  <NurseAlgoDispatcher
                    nurseAlgoStore={nurseAlgoStore}
                    call={localStore.synchronizableCall.data}
                    formCallType={localStore.callForm?.callType.value}
                    algoAvailable={localStore.requiredFieldsFilled}
                    onMotifSelection={handleSelectMotif}
                  />
                )}
              </>
            )}
          </Observer>
          <Spacer direction="vertical" size="1rem" />
          <Observer>
            {() => (
              <CallMainButton
                callType={localStore.callForm?.callType.value}
                onValidate={handleSubmit}
                onConsultFacilities={goToFacilities}
              />
            )}
          </Observer>
        </Grid>
      </Grid>
      <Observer>
        {() => (
          <CallConfirmSaveDialog
            open={callUIStore.confirmSaveDialogOpen}
            onClickSave={() => {
              handleSubmit(true);
            }}
            onClose={() => {
              callUIStore.closeConfirmSaveDialog();
            }}
          />
        )}
      </Observer>
    </>
  );
};

interface DefaultStyleProp {
  classes: any;
  children: any;
  style?: any;
}
const InfoSubBloc = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))((props: DefaultStyleProp) => (
  <div className={props.classes.root} style={props.style}>
    {props.children}
  </div>
));

type CallMainButtonProps = {
  callType?: CallType;
  onValidate: () => void;
  onConsultFacilities: () => void;
};

const CallMainButton = function ({
  callType,
  onValidate,
  onConsultFacilities,
}: CallMainButtonProps) {
  return (
    <div>
      {callType !== CallType.EMERGENCY && (
        <Button variant="contained" size="large" color="primary" onClick={onValidate} fullWidth>
          Valider la fiche
        </Button>
      )}
      {callType === CallType.EMERGENCY && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={onConsultFacilities}
          fullWidth
        >
          Orienter la victime et Valider
        </Button>
      )}
    </div>
  );
};

export default CallPreRegulationNursePage;
