import React, { ChangeEvent } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Spacer } from 'components/Spacer';

export type FacilityDashboardFiltersType = {
  active: boolean;
  dateStart?: string;
  dateEnd?: string;
};

type FiltersProps = {
  filters: FacilityDashboardFiltersType;
  total: number;
  setFilters: (value: FacilityDashboardFiltersType) => void;
};

const useFormStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export function FacilityDashboardFilters({ filters, total, setFilters }: FiltersProps) {
  const handleActiveFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, active: event.target.checked });
  };

  function handleDateStartChange(date: MaterialUiPickersDate | null) {
    if (date) {
      setFilters({ ...filters, dateStart: date.toISOString() });
    } else {
      setFilters({ ...filters, dateStart: undefined });
    }
  }

  function handleDateEndChange(date: MaterialUiPickersDate | null) {
    if (date) {
      setFilters({ ...filters, dateEnd: date.toISOString() });
    } else {
      setFilters({ ...filters, dateEnd: undefined });
    }
  }
  const formClasses = useFormStyles();
  return (
    <Grid classes={formClasses} container item xs={12}>
      <Grid item xs={8}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.active}
                  onChange={handleActiveFilterChange}
                  name="checkedActive"
                  color="primary"
                />
              }
              label="Evénement actif"
            />

            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-start"
              label="Date de début"
              value={filters.dateStart || null}
              onChange={handleDateStartChange}
              KeyboardButtonProps={{
                'aria-label': 'change date start',
              }}
              inputVariant="outlined"
            />
            <Spacer direction="horizontal" size="1rem" />
            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-end"
              label="Date de fin"
              value={filters.dateEnd || null}
              onChange={handleDateEndChange}
              KeyboardButtonProps={{
                'aria-label': 'change date start',
              }}
              inputVariant="outlined"
            />
          </FormGroup>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item xs={4} justify="flex-end" alignItems="center">
        <Typography variant="subtitle1">{`${total} victime(s) dans l'établissement correspondant à la recherche`}</Typography>
      </Grid>
    </Grid>
  );
}
