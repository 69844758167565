import {
  AppBar,
  Divider,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Hidden,
  IconButton,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, styled, withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ConnectivityIndicator } from 'components/ConnectivityIndicator';
import { Spacer } from 'components/Spacer';
import { useObserver } from 'mobx-react-lite';
import { Permission_V15 } from 'model/User';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { authStore, AuthenticatedUser } from 'stores/auth/auth.store';
import AppMenuDrawer from 'components/AppMenuDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: theme.palette.primary.main,
      color: 'black',
      '@media print': {
        display: 'none',
      },
    },
    contentRight: {
      display: 'flex',
      flex: 1,
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    menuButton: {
      color: theme.palette.common.white,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      color: theme.palette.common.white,
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.primary.main,
      fontWeight: 500,
    },
    toolBar: {
      backgroundColor: 'white',
      display: 'flex',
    },
  })
);

const cleanPath = (pathname: string) => pathname.split('/')[1];

export default function MainAppBar() {
  const classes = useStyles();
  const history = useHistory();

  const { location } = history;
  const { t } = useTranslation();
  const [path, setPath] = React.useState<string>(cleanPath(location.pathname));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDrawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setPath(cleanPath(location.pathname));
  }, [location.pathname]);

  const handleDrawer = (isOpen: boolean) => () => {
    setDrawerOpen(isOpen);
  };
  const isSelected = (path: string) => history.location.pathname === path;
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function handleClickLogout() {
    handleMenuClose();
    await authStore.logout();
    history.push('/login');
  }

  const goTo = (to: string) => () => {
    history.push(to);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={'account-menu'}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          history.push('/account');
        }}
      >
        {t('appBarMenu.myAccount')}
      </MenuItem>
      <MenuItem onClick={handleClickLogout}>{t('appBarMenu.logout')}</MenuItem>
    </Menu>
  );

  return useObserver(() => (
    <div className={classes.root}>
      <AppMenuDrawer
        handleDrawer={handleDrawer}
        isMenuOpen={isDrawerOpen}
        isCurrentPath={isSelected}
        onLogout={handleClickLogout}
        onClickLink={goTo}
        user={authStore.authenticatedUser}
      />
      <AppBar position="fixed" className={clsx('printHide', classes.appBar)}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={handleDrawer(true)}
            // disabled={!isAuthenticated}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            RMC
          </Typography>
        </Toolbar>
      </AppBar>
      <Hidden only="xs">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar variant="dense" disableGutters className={classes.toolBar}>
            <Typography variant="h5" className={classes.title} noWrap>
              RMC
            </Typography>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              value={path}
              aria-label="App bar navigation tabs"
            >
              {[
                (authStore.hasPermission(Permission_V15.CALLS_HOME) ||
                  authStore.hasPermission(Permission_V15.CALLS_SEARCH) ||
                  authStore.hasPermission(Permission_V15.CALLS_EXPORT_MULTI)) && (
                  <LinkTab
                    key="toCall"
                    label={t('nav.calls')}
                    href="/appels"
                    value="appels"
                    {...a11yProps(0)}
                  />
                ),
                authStore.hasPermission(Permission_V15.FACILITIES_SEARCH) && (
                  <LinkTab
                    key="toFacilities"
                    label={t('nav.facilities')}
                    href="/etablissements"
                    value={'etablissements'}
                    {...a11yProps(1)}
                  />
                ),
                authStore.hasPermission(Permission_V15.EVENTS_SEARCH) && (
                  <LinkTab
                    key="toEvents"
                    label={t('nav.events')}
                    href="/evenements"
                    value={'evenements'}
                    {...a11yProps(2)}
                  />
                ),
                authStore.hasPermission(Permission_V15.ADMIN_USERS_SEARCH) && (
                  <LinkTab
                    key="toAdmin"
                    label={t('nav.admin')}
                    href="/admini"
                    value="admin"
                    {...a11yProps(4)}
                  />
                ),
              ]}
            </Tabs>

            <div className={classes.contentRight}>
              <ConnectivityIndicator />
              <Spacer direction="horizontal" size="1rem" />
              <Divider orientation="vertical" flexItem />
              <UserIdentity user={authStore.authenticatedUser} onClickIcon={handleMenuOpen} />
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </Hidden>
    </div>
  ));
}

interface UserIdentityProps {
  classes: any;
  user: AuthenticatedUser | null;
  onClickIcon: (event: React.MouseEvent<HTMLElement>) => void;
}

const UserIdentity = withStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
    },
    iconWrapper: {
      display: 'flex',
      padding: theme.spacing(0, 1),
      cursor: 'pointer',
    },
  })
)(({ classes, user, onClickIcon }: UserIdentityProps) => {
  const { t } = useTranslation();
  return useObserver(() => (
    <div className={classes.wrapper}>
      <Typography variant="body2" noWrap>
        <UpperCase>{user?.lastName}</UpperCase> {user?.firstName} -{' '}
        {t(`users.profiles.${user?.profile}`)}
      </Typography>

      <div onClick={onClickIcon} className={classes.iconWrapper}>
        <AccountCircleIcon />
      </div>
    </div>
  ));
});

const UpperCase = styled('span')({ textTransform: 'uppercase' });

const LinkTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        // color: '#40a9ff',
        backgroundColor: 'rgba(165,190,90,0.1)',
        opacity: 1,
      },
      '&$selected': {
        color: 'black',
        // color: '#1890ff',
        // backgroundColor: 'rgba(255,255,255,0.1)',
        // fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        // color: '#40a9ff',
      },
    },
    selected: {
      // color: '#40a9ff',
      // backgroundColor: 'rgba(255,255,255,0.1)',
    },
  })
)((props: StyledTabProps) => {
  const history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push(props.href!);
      }}
      {...props}
    />
  );
});

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

interface StyledTabProps {
  label: string;
  href?: string;
  value?: string;
}
