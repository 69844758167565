import Context from 'serializr/lib/core/Context';
import { PermanentAlgoStore, permanentAlgoStore } from './algo/permanent';

export interface DeserializationContextArgs<Store> {
  store: Store;
}
export interface DeserializationContext<Store> extends Omit<Context, 'args'> {
  args: DeserializationContextArgs<Store>;
}

export function getPermanentAlgoDeserializationContextArgs(): DeserializationContextArgs<
  PermanentAlgoStore
> {
  return { store: permanentAlgoStore };
}
