import { AdditionalPropArgs } from 'serializr';

export const serializrErrorTracer: AdditionalPropArgs = {
  afterDeserialize: function (
    callback,
    error,
    newValue,
    jsonValue,
    jsonParentValue,
    propNameOrIndex,
    context,
    propDef
  ) {
    if (!error) {
      callback(null, newValue);
    } else {
      console.error('deserialization error with prop', propNameOrIndex, 'value', jsonValue, error);
      callback(error, newValue);
    }
  },
};
