import { DeserializationContext } from 'stores/stores.model';
import { PermanentAlgoStore } from './PermanentAlgo.store';

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveItemById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store } }: DeserializationContext<PermanentAlgoStore>
) {
  const foundedQuestion = store.questions.find((question) => question.Id === id);
  callback(null, foundedQuestion);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolvePermanentGroupById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store } }: DeserializationContext<PermanentAlgoStore>
) {
  const foundedItem = store.groups.find((group) => group.Id === id);
  callback(null, foundedItem);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveResponseById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store } }: DeserializationContext<PermanentAlgoStore>
) {
  const foundedResponse = store.responses.find((response) => response.Id === id);
  callback(null, foundedResponse);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveAdviceById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store } }: DeserializationContext<PermanentAlgoStore>
) {
  const foundedAdvices = store.advices.find((advice) => advice.id === id);
  callback(null, foundedAdvices);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveCallReasonById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store } }: DeserializationContext<PermanentAlgoStore>
) {
  const foundedCallReason = store.callReasons.find((callReason) => callReason.id === id);
  callback(null, foundedCallReason);
}
