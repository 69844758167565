import {
  createStyles,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Autocomplete, AutocompleteProps, Value } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type MaterialAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  label?: string;
  error?: boolean;
  required?: boolean;
  editing?: boolean;
  helperText?: string;
  renderReadonlyValue?: (value?: Value<T, Multiple, DisableClearable, FreeSolo>) => ReactNode;
};

export const MaterialAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  label,
  error,
  required,
  editing = true,
  helperText,
  renderReadonlyValue,
  ...autocompleteProps
}: MaterialAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <FormControl
      className={classes.formControl}
      error={error}
      disabled={autocompleteProps.disabled}
      required={required}
      size="small"
    >
      <FormLabel className={classes.label} disabled={autocompleteProps.disabled}>
        {label}
      </FormLabel>
      {editing && (
        <>
          <Autocomplete
            {...autocompleteProps}
            noOptionsText={t(`forms.inputs.autocomplete.noOptionText`)}
            loadingText={t(`forms.inputs.autocomplete.loadingText`)}
          />
          <FormHelperText className={classes.helperText} variant="outlined">
            {(!!error && helperText) || ' '}
          </FormHelperText>
        </>
      )}
      {!editing && (
        <Typography>
          {renderReadonlyValue
            ? renderReadonlyValue(autocompleteProps.value)
            : autocompleteProps.value}
        </Typography>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      background: 'none',
      minWidth: 120,
      width: '100%',
    },
    label: {
      paddingLeft: '1px',
      lineHeight: '16px',
    },
    helperText: {
      margin: 0,
      marginTop: 0,
      fontSize: '11px',
      marginLeft: '1px',
      lineHeight: '16px',
    },
  })
);
