import {
  NurseAlgoCardDocument,
  NurseAlgoData,
  NurseAlgoDiagnostic,
  NurseAlgoGroup,
  NurseAlgoQuestion,
  NurseAlgoResponse,
  NurseAlgoResultDiagnostic,
  NurseAlgoRoute,
} from 'model/algo/nurse';
import { appStatusStore } from 'stores/appStatus.store';
import { ResourceProvider, storableStoreHelpers } from 'stores/Persistable';
import { nurseAlgoApi } from '../api';

interface NurseAlgoResourceMethods {
  fetchAlgo: () => Promise<NurseAlgoData>;
  fetchBehaviorDocumentBinary: (documentId: number) => Promise<Blob>;
}

const ALGO_COLLECTION_KEYS = {
  cardDocuments: 'nurseAlgo:cardDocuments',
  diagnostics: 'nurseAlgo:diagnostics',
  groups: 'nurseAlgo:groups',
  questions: 'nurseAlgo:questions',
  responses: 'nurseAlgo:responses',
  resultDiagnostics: 'nurseAlgo:resultDiagnostics',
  routes: 'nurseAlgo:routes',
  behaviorDocument: 'nurseAlgo:behaviorDocument',
};
export const nurseAlgoResourceProvider: ResourceProvider<NurseAlgoResourceMethods> = {
  onlineResourceProvider: {
    fetchAlgo: async () => {
      const response = await nurseAlgoApi.fetchNurseAlgo();
      const {
        cardDocuments,
        diagnostics,
        groups,
        questions,
        responses,
        resultDiagnostics,
        routes,
      } = response;

      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.cardDocuments,
        NurseAlgoCardDocument,
        cardDocuments
      );

      await storableStoreHelpers.offlineSet(ALGO_COLLECTION_KEYS.groups, NurseAlgoGroup, groups);

      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.diagnostics,
        NurseAlgoDiagnostic,
        diagnostics
      );
      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.questions,
        NurseAlgoQuestion,
        questions
      );

      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.responses,
        NurseAlgoResponse,
        responses
      );

      await storableStoreHelpers.offlineSet(
        ALGO_COLLECTION_KEYS.resultDiagnostics,
        NurseAlgoResultDiagnostic,
        resultDiagnostics
      );

      await storableStoreHelpers.offlineSet(ALGO_COLLECTION_KEYS.routes, NurseAlgoRoute, routes);

      return response;
    },
    fetchBehaviorDocumentBinary: async (documentId) => {
      const response = await nurseAlgoApi.fetchBehaviorDocumentBinary(documentId);
      await storableStoreHelpers.offlineSetRecordItem(
        ALGO_COLLECTION_KEYS.behaviorDocument,
        Blob,
        `${documentId}`,
        response
      );
      return response;
    },
  },
  offlineResourceProvider: {
    fetchAlgo: async () => {
      const { data: cardDocuments } = await storableStoreHelpers.offlineGet<
        NurseAlgoCardDocument[]
      >(ALGO_COLLECTION_KEYS.cardDocuments);

      const { data: groups } = await storableStoreHelpers.offlineGet<NurseAlgoGroup[]>(
        ALGO_COLLECTION_KEYS.groups
      );

      const { data: diagnostics } = await storableStoreHelpers.offlineGet<NurseAlgoDiagnostic[]>(
        ALGO_COLLECTION_KEYS.diagnostics
      );

      const { data: questions } = await storableStoreHelpers.offlineGet<NurseAlgoQuestion[]>(
        ALGO_COLLECTION_KEYS.questions
      );

      const { data: responses } = await storableStoreHelpers.offlineGet<NurseAlgoResponse[]>(
        ALGO_COLLECTION_KEYS.responses
      );

      const { data: resultDiagnostics } = await storableStoreHelpers.offlineGet<
        NurseAlgoResultDiagnostic[]
      >(ALGO_COLLECTION_KEYS.resultDiagnostics);

      const { data: routes } = await storableStoreHelpers.offlineGet<NurseAlgoRoute[]>(
        ALGO_COLLECTION_KEYS.routes
      );

      return {
        cardDocuments,
        diagnostics,
        groups,
        questions,
        responses,
        resultDiagnostics,
        routes,
      };
    },
    fetchBehaviorDocumentBinary: async (documentId) => {
      const stored = await storableStoreHelpers.offlineGetRecord<Blob>(
        ALGO_COLLECTION_KEYS.behaviorDocument
      );
      return stored[documentId].data;
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
