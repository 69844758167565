import { action, decorate, observable, computed } from 'mobx';
import { deserialize } from 'serializr';
import { noopCallback } from './serialize.context';
import { PersistableStore, registerStorableStore, storableStoreHelpers } from 'stores/Persistable';
import Department, { DepartmentSynchronizableData } from 'model/location/Department';
import { locationResourceProvider } from '../resourceProvider/location.provider';
import { CitySynchronizableData } from 'model/location/City';

export default class LocationStore implements PersistableStore {
  departments = observable.map(new Map<number, DepartmentSynchronizableData>());
  cities = observable.map(new Map<number, CitySynchronizableData>());
  lastSynchronization: Date | undefined; // FIXME Attention 2 types de données mais une seule date de synchro
  dataLoaded = false;

  init() {
    storableStoreHelpers.reactOnUserChanges(() => this.loadLocations());
    storableStoreHelpers.reactOnNetworkChange(() => this.loadLocations());
  }

  async loadLocations() {
    await this.getDepartments();
    await this.getCities();
    this.setLastSynchronization();
    this.setDataLoaded(true);
  }

  setLastSynchronization(date: Date = new Date()) {
    this.lastSynchronization = date;
  }

  setDataLoaded(isLoaded: boolean) {
    this.dataLoaded = isLoaded;
  }

  setDepartments(newDepartments: DepartmentSynchronizableData[]) {
    this.departments.clear();
    this.departments.merge(newDepartments.map((department) => [department.data.id, department]));
  }

  setCities(newCities: CitySynchronizableData[]) {
    this.cities.clear();
    this.cities.merge(newCities.map((city) => [city.data.id, city]));
  }

  getDepartmentFromId(departmentId: number): Department | undefined {
    return this.departmentsArray.find((department) => department.id === departmentId);
  }

  getDepartmentFromIhsiCode(departmentIhsiCode: string): Department | undefined {
    return this.departmentsArray.find((department) => department.ihsiCode === departmentIhsiCode);
  }

  async getDepartments() {
    const result = await locationResourceProvider.get().getDepartments();

    const departments = deserialize(DepartmentSynchronizableData, result, noopCallback);
    this.setDepartments(departments);
    this.setLastSynchronization();
  }

  async getCities() {
    const result = await locationResourceProvider.get().getCities();
    const cities = deserialize(CitySynchronizableData, result, noopCallback);
    this.setCities(cities);
    this.setLastSynchronization();
  }

  get departmentsArray(): Department[] {
    return Array.from(this.departments.values()).map(
      (synchronizableDepartment) => synchronizableDepartment.data
    );
  }

  get citiesArray() {
    return Array.from(this.cities.values()).map((synchronizableCity) => synchronizableCity.data);
  }
}

decorate(LocationStore, {
  departments: observable,
  lastSynchronization: observable,
  dataLoaded: observable,
  setDepartments: action,
  setDataLoaded: action,
  setLastSynchronization: action,
  departmentsArray: computed,
  citiesArray: computed,
});

export const locationStore = new LocationStore();

registerStorableStore(locationStore);
