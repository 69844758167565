import {
  WithScoreAlgoResponse,
  WithScoreMatchCombinaison,
  AlgoCombinaison,
  ScoreInclusion,
} from 'model/algo';
/**
 * Function logic works for both algorithms (Perm & Nurse)
 * Implementation might need rework.
 */
export function responsesMatchCombinaison(
  { combinaison, responses, scoreMin, scoreMax, scoreInclusion }: WithScoreMatchCombinaison,
  userResponses: WithScoreAlgoResponse[]
): boolean {
  if (!combinaison || !responses) {
    return false;
  }
  const userResponseIds = userResponses.map((response) => response.Id);
  let match = false;
  if (combinaison === AlgoCombinaison.OR) {
    if (responses.some((responseId) => userResponseIds.includes(responseId))) {
      const userScore = calculateScore(userResponses, responses);
      if (matchScore(userScore, scoreMin, scoreMax, scoreInclusion)) {
        match = true;
      }
    } else {
      match = responses.some((responseId) => userResponseIds.includes(responseId));
    }
  }
  if (combinaison === AlgoCombinaison.AND) {
    if (responses.every((responseId) => userResponseIds.includes(responseId))) {
      const userScore = calculateScore(userResponses, responses);
      if (matchScore(userScore, scoreMin, scoreMax, scoreInclusion)) {
        match = true;
      }
    } else {
      match = responses.every((responseId) => userResponseIds.includes(responseId));
    }
  }

  return match;
}

function matchScore(
  userScore: number,
  min?: number,
  max?: number,
  scoreInclusion: ScoreInclusion = ScoreInclusion.BOTH
): boolean {
  switch (scoreInclusion) {
    case ScoreInclusion.NONE: {
      const matchMin = min !== undefined ? userScore > min : true;
      const matchMax = max !== undefined ? userScore < max : true;
      return matchMin && matchMax;
    }
    case ScoreInclusion.MIN: {
      const matchMin = min !== undefined ? userScore >= min : true;
      const matchMax = max !== undefined ? userScore < max : true;
      return matchMin && matchMax;
    }
    case ScoreInclusion.MAX: {
      const matchMin = min !== undefined ? userScore > min : true;
      const matchMax = max !== undefined ? userScore <= max : true;
      return matchMin && matchMax;
    }
    case ScoreInclusion.BOTH:
    default: {
      const matchMin = min !== undefined ? userScore >= min : true;
      const matchMax = max !== undefined ? userScore <= max : true;
      return matchMin && matchMax;
    }
  }
}

export function calculateScore(
  userResponses: WithScoreAlgoResponse[],
  scoreResponseIds: number[]
): number {
  const scoreResponses = userResponses.filter((response) => scoreResponseIds.includes(response.Id));
  return scoreResponses.reduce((aggr, { score = 0 }) => {
    return aggr + score;
  }, 0);
}
