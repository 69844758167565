import Call, { CallSynchronizableData } from 'model/Call';

export function mapFormDataToCall(synchronizableCall: CallSynchronizableData, callForm: any) {
  const callData: Call = {
    ...synchronizableCall.data,
    status: callForm.status.value,
    purpose: callForm.purpose.value,
    callNumber: callForm.callNumber.value,
    callType: callForm.callType.value,
    callerIsVictim: callForm.callerIsVictim.value,
    callerLastName: callForm.callerLastName.value,
    callerFirstName: callForm.callerFirstName.value,
    callerPhone: callForm.callerPhone.value,
    victimCode: callForm.victimCode.value,
    victimLastName: callForm.victimLastName.value,
    victimFirstName: callForm.victimFirstName.value,
    victimSex: callForm.victimSex.value,
    victimAgeRange: callForm.victimAgeRange.value,
    locationDepartment: callForm.locationDepartment.value,
    locationCity: callForm.locationCity.value,
    locationStreet: callForm.locationStreet.value,
    locationSquare: callForm.locationSquare.value,
    locationAdditionalInfos: callForm.locationAdditionalInfos.value,
    results: callForm.results.value,
  };
  if (callData.callerIsVictim) {
    callData.victimFirstName = callData.callerFirstName;
    callData.victimLastName = callData.callerLastName;
  }
  return callData;
}
