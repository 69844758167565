import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { ReactComponent as IosInstallIcon } from '../assets/icons/ios-install-icon.svg';

const ApplicationInstallBanneriOS: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && (window.navigator as any).standalone;

    if (!isInStandaloneMode()) {
      setOpen(true);
    }
  }, [setOpen]);

  return (
    <>
      {isOpen && (
        <Alert variant="filled" severity="warning">
          <AlertTitle>Attention</AlertTitle>
          Pour une utilisation optimale, veuillez installer l'application en cliquant sur l'icône{' '}
          <IosInstallIcon width={32} /> de votre navigateur
        </Alert>
      )}
    </>
  );
};

export default ApplicationInstallBanneriOS;
