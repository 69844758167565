import {
  Badge,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { QuestionButton } from 'components/algorithm/Question';
import { observer } from 'mobx-react-lite';
import { CommonAlgoResponse, UIQuestionsGroup } from 'model/algo';
import React, { FC, ReactNode, useState } from 'react';
import { AlgoContainer } from '../../AlgoContainer';
import { AlgoQuestion } from '../AlgoQuestion';
import { UIVerticalModeSubGroupContext } from 'model/algo';
import { SetUserResponseType, GoToPreviousQuestionGroupType } from 'stores/algo/CommonAlgo.store';

interface MultiQuestionsGroupsProps {
  questionsGroups: UIQuestionsGroup[];
  subGroup: UIVerticalModeSubGroupContext | undefined;
  setUserResponse: SetUserResponseType;
  goToPreviousQuestionGroup: GoToPreviousQuestionGroupType;
}

const usePanelDetailsStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});

export const MultiQuestionsGroups: FC<MultiQuestionsGroupsProps> = observer(
  ({ questionsGroups, subGroup, setUserResponse, goToPreviousQuestionGroup }) => {
    const [expandedPanelId, setExpandedPanelId] = useState<number>();

    const handleChangeExpansionPanel = (panelId: number) => {
      const newPanelId = expandedPanelId !== panelId ? panelId : undefined;
      setExpandedPanelId(newPanelId);
    };

    const handleResponse = (
      groupId: number,
      response: CommonAlgoResponse | CommonAlgoResponse[]
    ) => {
      setUserResponse(groupId, response);
    };

    const panelDetailsStyles = usePanelDetailsStyles();

    let questionContent: ReactNode;
    if (subGroup) {
      questionContent = subGroup.subGroup.questions.map((question, index) => (
        <>
          <Typography variant="h6">
            {subGroup.parentGroup.name} > {subGroup.parentGroupResponseLabel}
          </Typography>
          <AlgoQuestion
            key={index}
            question={question}
            currentResponses={[]}
            handleResponse={(response) => handleResponse(subGroup.subGroup.groupId, response)}
          />
        </>
      ));
    } else {
      questionContent = questionsGroups.map((questionsGroup) => (
        <ExpansionPanel
          key={questionsGroup.groupId}
          expanded={expandedPanelId === questionsGroup.groupId}
          onChange={() => handleChangeExpansionPanel(questionsGroup.groupId)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${questionsGroup.groupId}-content`}
            id={`${questionsGroup.groupId}-content`}
          >
            <Badge color="primary">
              <Typography>{questionsGroup.groupName}</Typography>
            </Badge>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={panelDetailsStyles}>
            {questionsGroup.questions.map((currentQuestion, index) => (
              <QuestionButton
                key={index}
                withoutLabel={true}
                selected={null}
                question={currentQuestion}
                onResponse={(responses) => handleResponse(questionsGroup.groupId, responses)}
              />
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ));
    }
    return (
      <AlgoContainer title="Autre symptôme médical" onClickPrevious={goToPreviousQuestionGroup}>
        {questionContent}
      </AlgoContainer>
    );
  }
);
