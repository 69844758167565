import { Button, Grid, Modal, Typography } from '@material-ui/core';
import { userApi } from 'api';
import MaterialSwitch from 'components/common/form/MaterialSwitch';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import User, { Permission_V15 } from 'model/User';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { deserialize } from 'serializr';
import { userService } from 'services';
import { authStore } from 'stores/auth/auth.store';
import { NotificationType, uiStore } from 'stores/ui.store';
import { FORM_MODE_CONSULT, FORM_MODE_EDIT, getSwitchFieldProps } from 'utils/forms';
import UserForm from './user.form';
import userFormBuilder from './user.form.builder';

interface RouteParams {
  username: string;
  mode: string | undefined;
}

interface LocalStore {
  userForm: any;
  username: string;
  statusModalOpened: boolean;
  passwordResetModalOpened: boolean;
  setUsername: any;
  setStatusModalOpen: (bo: boolean) => void;
}
const DetailUserPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();

  const store = useLocalStore<LocalStore>(() => ({
    userForm: userFormBuilder({ mode: FORM_MODE_CONSULT }),
    username: '',
    statusModalOpened: false,
    passwordResetModalOpened: false,
    setUsername(un: string) {
      this.username = un;
    },
    setStatusModalOpen(isOpen: boolean): void {
      this.statusModalOpened = isOpen;
    },
  }));

  React.useEffect(() => {
    if (params.mode === 'edit') {
      store.userForm = userFormBuilder({ mode: FORM_MODE_EDIT });
    }
    userApi
      .retrieveUser(params.username, true)
      .then((userData) => {
        const user: User = deserialize(User, userData);
        store.userForm.updateData(user);
      })
      .catch((err) => console.error('detailuserpage error', err));
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    if (store.userForm.mode === FORM_MODE_CONSULT) {
      history.push('/admin/utilisateurs');
    } else {
      store.userForm = userFormBuilder({
        initialValues: store.userForm.values,
        mode: FORM_MODE_CONSULT,
      });
    }
  };

  const handleClickResetPasswordButton = () => {
    // opens modal
    store.passwordResetModalOpened = true;
  };

  const handleSubmit = () => {
    switch (store.userForm.mode) {
      case FORM_MODE_EDIT:
        store.userForm.validate();
        if (store.userForm.isValid) {
          userApi
            .updateUser(params.username, userService.prepareSubmitData(store.userForm))
            .then((data) => {
              store.userForm = userFormBuilder({
                initialValues: store.userForm.values,
                mode: FORM_MODE_CONSULT,
              });
              uiStore.pushSuccessNotification('Utilisateur modifié avec succès');
            })
            .catch((err) => {
              console.error(err);
              uiStore.pushNotification(
                "Echec de la modification de l'utilisateur",
                NotificationType.ERROR
              );
            });
        }
        break;
      case FORM_MODE_CONSULT:
      default:
        store.userForm.setMode(FORM_MODE_EDIT);
    }
    return false;
  };

  const handleClickActivationStatus = (e: React.MouseEvent) => {
    store.setStatusModalOpen(true);
  };

  const handleDefineUserActivationStatus = (activated: boolean) => {
    if (activated === store.userForm.enabled.value) {
      store.userForm.enabled.setValue(activated);
      userApi
        .updateUser(params.username, userService.prepareSubmitData(store.userForm))
        .then((data) => {
          uiStore.pushSuccessNotification('Utilisateur modifié avec succès');
        })
        .catch((err) => {
          uiStore.pushNotification(
            "Echec de la modification de l'utilisateur",
            NotificationType.ERROR
          );
        });
    } else {
      store.userForm.enabled.setValue(activated);
    }
    store.setStatusModalOpen(false);
  };
  return useObserver(() => (
    <Grid container spacing={2} justify="center">
      <UserActivationStatusModal
        isUserActive={store.userForm.enabled.value}
        isOpened={store.statusModalOpened}
        onCloseModal={handleDefineUserActivationStatus}
      />
      <PasswordResetModal
        isOpened={store.passwordResetModalOpened}
        onConfirm={() => {
          userApi
            .resetUserPassword(params.username)
            .then(() => {
              uiStore.pushSuccessNotification('Le mot de passe a été réinitalisé avec succès');
            })
            .catch(() => {
              uiStore.pushSuccessNotification('Echec de la réinitialisation du mot de passe');
            })
            .finally(() => {
              // In any case, we close the modal
              store.passwordResetModalOpened = false;
            });
        }}
        onCloseModal={() => {
          store.passwordResetModalOpened = false;
        }}
      />
      <Grid item xs={12} sm={10} lg={8}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item>
            <Typography variant="h4" component="h1">
              {store.userForm.firstName.value} {store.userForm.lastName.value}
            </Typography>
            <Typography color="textSecondary">[{params.username}]</Typography>
          </Grid>

          <Grid item>
            <MaterialSwitch
              label="Inactif"
              label2="Actif"
              color="primary"
              onClick={handleClickActivationStatus}
              {...getSwitchFieldProps(store.userForm.enabled)}
              disabled={!authStore.hasPermission(Permission_V15.ADMIN_USERS_UPDATE)}
            />
          </Grid>

          <Grid item xs={12}>
            <UserForm
              form={store.userForm}
              mode={store.userForm.mode}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onClickResetPassword={handleClickResetPasswordButton}
              userCanEdit={authStore.hasPermission(Permission_V15.ADMIN_USERS_UPDATE)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));
};

function PasswordResetModal({
  isOpened,
  onConfirm,
  onCloseModal,
}: {
  isOpened: boolean;
  onConfirm: () => void;
  onCloseModal: () => void;
}) {
  return useObserver(() => (
    <Modal open={isOpened}>
      <div
        style={{
          minHeight: 150,
          width: 500,
          maxWidth: '90%',
          margin: 'auto',
          backgroundColor: 'white',
          padding: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} justify="space-evenly" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center">
              Êtes-vous certain de vouloir réinitialiser le mot de passe ?
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={onCloseModal}>
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button color="primary" variant="contained" onClick={onConfirm}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  ));
}

function UserActivationStatusModal({
  isOpened,
  isUserActive,
  onCloseModal,
}: {
  isOpened: boolean;
  isUserActive: boolean;
  onCloseModal: (st: boolean) => void;
}) {
  return useObserver(() => (
    <Modal open={isOpened}>
      <div
        style={{
          minHeight: 150,
          width: 500,
          maxWidth: '90%',
          margin: 'auto',
          backgroundColor: 'white',
          padding: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} justify="space-evenly" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center">
              Êtes-vous certain de vouloir {isUserActive ? 'activer' : 'désactiver'} l'utilisateur ?
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => onCloseModal(!isUserActive)}>
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onCloseModal(isUserActive)}
              disabled={!authStore.hasPermission(Permission_V15.ADMIN_USERS_UPDATE)}
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  ));
}

export default DetailUserPage;
