import { observer } from 'mobx-react-lite';
import { UIQuestionsGroup, UIVerticalModeSubGroupContext } from 'model/algo';
import { CommonAlgoResponse } from 'model/algo/CommonAlgoResponse';
import React, { FC, ReactNode, useEffect } from 'react';
import { AlgoErrorBoundary } from './AlgoErrorBoundary';
import { MultiQuestionsGroups } from './Question/MultiQuestionsGroups';
import { SimpleQuestionsGroup } from './Question/SimpleQuestionsGroup';

interface CommonAlgoProps {
  currentUiQuestionsGroup: UIQuestionsGroup | undefined;
  algoLoaded: boolean;
  startAlgo: () => void;
  goToPreviousQuestionGroup: () => void;
  allQuestionsGroups: UIQuestionsGroup[];
  uiVerticalModeSubGroupContext: UIVerticalModeSubGroupContext | undefined;
  setUserResponse: (
    groupId: number,
    userResponse: CommonAlgoResponse | CommonAlgoResponse[]
  ) => void;
}

export const CommonAlgo: FC<CommonAlgoProps> = observer(
  ({
    algoLoaded,
    allQuestionsGroups,
    currentUiQuestionsGroup,
    goToPreviousQuestionGroup,
    setUserResponse,
    startAlgo,
    uiVerticalModeSubGroupContext,
  }) => {
    // Récupération de la question initiale
    useEffect(() => {
      if (algoLoaded) {
        startAlgo();
      }
    }, [algoLoaded, startAlgo]);

    function renderQuestion(): ReactNode {
      if (currentUiQuestionsGroup?.isVertical) {
        return (
          <MultiQuestionsGroups
            questionsGroups={allQuestionsGroups}
            subGroup={uiVerticalModeSubGroupContext}
            setUserResponse={(groupId, response) => setUserResponse(groupId, response)}
            goToPreviousQuestionGroup={() => goToPreviousQuestionGroup()}
          />
        );
      }
      if (currentUiQuestionsGroup) {
        return (
          <SimpleQuestionsGroup
            currentQuestionsGroup={currentUiQuestionsGroup}
            displayPreviousButton={!currentUiQuestionsGroup.first}
            pendingGroupNames={currentUiQuestionsGroup.pendingGroups}
            setUserResponse={(groupId, userResponses) => setUserResponse(groupId, userResponses)}
            goToPreviousQuestionGroup={() => goToPreviousQuestionGroup()}
          />
        );
      }
      return null;
    }
    return <AlgoErrorBoundary>{renderQuestion()}</AlgoErrorBoundary>;
  }
);
