import * as React from 'react';
import { FormControl, makeStyles, createStyles, Theme, FormHelperText } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

interface MaterialDatePickerProps extends KeyboardDatePickerProps {
  classes?: any;
  editing?: boolean;
}
const MaterialDatePicker: React.FC<MaterialDatePickerProps> = ({
  id,
  label = '',
  placeholder,
  fullWidth = false,
  error = false,
  disabled = false,
  type = 'text',
  required = false,
  value = undefined,
  onChange,
  onBlur,
  helperText,
  name,
  autoFocus = false,
  size,
  editing = true,
  format = 'dd/MM/yyyy',
  views = ['year', 'month', 'date'],
  disableFuture = false,
  ...rest
}) => {
  const classes = useStyles();
  // const displayValue = (value: any) => value || 'N/R';
  return (
    <FormControl
      className={classes.formControl}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
      required={required}
      size={size}
    >
      <FormLabel className={classes.label} disabled={disabled} required={required && editing}>
        {label}
      </FormLabel>
      {/* {!editing && (
        <Tooltip title={label || ''} placement="top-start">
          <Typography>{displayValue(value)}</Typography>
        </Tooltip>
      )} */}
      {/* {editing && (
        <> */}
      <KeyboardDatePicker
        name={name}
        className={classes.input}
        id={id}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        inputProps={{
          onBlur: onBlur,
        }}
        autoFocus={autoFocus}
        rows={rest.rows}
        rowsMax={rest.rowsMax}
        multiline={rest.multiline}
        format={format}
        views={views}
        inputVariant="outlined"
        disableFuture={disableFuture}
        disabled={disabled}
      />
      <FormHelperText className={classes.helperText} variant="outlined">
        {(!!error && helperText) || ' '}
      </FormHelperText>
      {/* </>
      )} */}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      background: 'none',
    },
    input: {
      backgroundColor: 'white',
    },
    label: {
      paddingLeft: '1px',
      marginBottom: theme.spacing(0.5),
    },
    helperText: {
      margin: 0,
      marginTop: 0,
      fontSize: '11px',
      marginLeft: '1px',
    },
  })
);
export default MaterialDatePicker;
