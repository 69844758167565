import { UpdateAlgo } from 'api/call.api';
import { action, observable } from 'mobx';
import Call, { CallSynchronizableData, CallType } from 'model/Call';
import {
  CallPriorityRequiredError,
  NeedConfirmError,
  NeedTransferFacilitiesError,
} from 'model/Errors';
import { TransferFacilities } from 'model/facilities/Facility';
import { Profile } from 'model/User';
import { callResourceProvider } from 'resourceProvider/call.provider';
import { deserialize } from 'serializr';
import { sortCalls } from 'utils/call.utilities';
import { permanentAlgoStore } from '../algo/permanent';
import { appStatusStore } from '../appStatus.store';
import { authStore } from '../auth/auth.store';
import { noopCallback } from '../serialize.context';
import { getPermanentAlgoDeserializationContextArgs } from '../stores.model';
import { callUIStore } from './callUI.store';

const DATA_REFRESH_INTERVAL = 10000;

async function saveOrUpdateCall(call: CallSynchronizableData, updateAlgo: UpdateAlgo) {
  if (!call.lastSynchronizedOn) {
    return callResourceProvider.get().createCall(call, updateAlgo);
  } else {
    return callResourceProvider.get().updateCall(call.data.uuid, call, updateAlgo);
  }
}
export default class CallStore {
  @observable
  calls = observable.map(new Map<String, CallSynchronizableData>());
  @observable
  lastSynchronization: Date | undefined;

  private transferFacilities: TransferFacilities | undefined = undefined;

  @action
  addCall(callUuid: string, newCall: CallSynchronizableData) {
    this.calls.set(callUuid, newCall);
  }

  @action
  setCalls(newCalls: CallSynchronizableData[]) {
    const sortedCalls: CallSynchronizableData[] = sortCalls(newCalls);
    // replace was not working properly, so combination of clear + merge instead
    this.calls.clear();
    this.calls.merge(sortedCalls.map((call) => [call.data.uuid, call]));
  }

  createNewCall(): Promise<CallSynchronizableData> {
    const newCall = new Call();
    return Promise.resolve(new CallSynchronizableData(false, newCall));
  }

  @action
  async permanentSaveCall(
    toSaveCall: CallSynchronizableData,
    disableDialog = false
  ): Promise<CallSynchronizableData> {
    const algoResult = permanentAlgoStore.algoRunResult;
    const existingQuestion = permanentAlgoStore.currentQuestionsGroup;

    // Si une question reste à poser. Et qu'aucune circonstance n'est renseignée. On affiche la modale
    if (!disableDialog && existingQuestion && !toSaveCall.data.purpose) {
      callUIStore.openConfirmSaveDialog();
      throw new NeedConfirmError('No algoResult or purpose');
    }

    // Le parcours de l'algorithme est obligatoire pour le permanencier
    if (
      toSaveCall.data.callType === CallType.EMERGENCY &&
      !toSaveCall.data.priority &&
      !algoResult
    ) {
      throw new CallPriorityRequiredError('Priority missing');
    }

    // Si appel de type transfert et les facilities ne sont pas renseignés
    if (toSaveCall.data.callType === CallType.TRANSFER && !this.transferFacilities) {
      throw new NeedTransferFacilitiesError('Facilities are mandatory for TRANSFER call');
    }

    const callAlgoResult = algoResult && {
      priority: algoResult.priority,
      permanentAlgoCallReasons: algoResult.reasons,
      permanentAlgoResponsesIds: algoResult.responseIds,
      permanentAlgoWaitingAdvices: algoResult.advices,
    };

    const call = await saveOrUpdateCall(
      {
        ...toSaveCall,
        data: {
          ...toSaveCall.data,
          ...this.transferFacilities,
          ...callAlgoResult,
        },
      },
      { updatePermanentAlgo: true, updateNurseAlgo: false }
    );
    const withResultCall = {
      ...call,
      data: {
        ...call.data,
        ...callAlgoResult,
      },
    };
    this.addCall(call.data.uuid, withResultCall);

    return withResultCall;
  }

  @action
  async nurseSaveCall(toSaveCall: CallSynchronizableData) {
    // Si appel de type transfert et les facilities ne sont pas renseignés
    if (toSaveCall.data.callType === CallType.TRANSFER && !this.transferFacilities) {
      throw new NeedTransferFacilitiesError('Facilities are mandatory for TRANSFER call');
    }

    const updateNurseAlgo = toSaveCall.data.callType === CallType.EMERGENCY;
    const call = await saveOrUpdateCall(
      {
        ...toSaveCall,
        data: {
          ...toSaveCall.data,
          ...this.transferFacilities,
        },
      },

      {
        updatePermanentAlgo: false,
        updateNurseAlgo,
      }
    );

    this.addCall(call.data.uuid, call);

    return call;
  }

  @action
  async getCalls() {
    const result = await callResourceProvider.get().getCalls();

    const calls = deserialize(
      CallSynchronizableData,
      result,
      noopCallback,
      getPermanentAlgoDeserializationContextArgs()
    );
    this.setCalls(calls);
    this.lastSynchronization = new Date();
  }

  @action
  setTransferFacilities(facilities: TransferFacilities | undefined) {
    this.transferFacilities = facilities;
  }

  async getCall(uuid: string): Promise<CallSynchronizableData | undefined> {
    const call = await callResourceProvider.get().getCall(uuid);
    return deserialize(
      CallSynchronizableData,
      call,
      noopCallback,
      getPermanentAlgoDeserializationContextArgs()
    );
  }

  @action
  async synchronizeCalls() {
    const unSynchronizedCalls = Array.from(this.calls.values()).filter(
      (call) => !call.isSynchronized
    );
    if (unSynchronizedCalls.length > 0) {
      const updatePermanentAlgo = authStore.hasProfile(Profile.PERMANENCIER);
      // Synchronize call with server
      const synchronizationResults = await Promise.allSettled(
        unSynchronizedCalls.map((call) =>
          saveOrUpdateCall(call, { updatePermanentAlgo, updateNurseAlgo: !updatePermanentAlgo })
        )
      );
      // Update store calls
      synchronizationResults.forEach(
        action((result: PromiseSettledResult<CallSynchronizableData>) => {
          if (result.status === 'fulfilled') {
            const synchronizableCall = result.value;
            this.calls.set(synchronizableCall.data.uuid, synchronizableCall);
          }
        })
      );
    }
    // Retrieve calls
    this.getCalls();
  }

  async saveCallOutcome(callUuid: string, outcome: string) {
    const synchronizableCall = await callResourceProvider.get().saveCallOutcome(callUuid, outcome);
    this.addCall(callUuid, synchronizableCall);
  }

  constructor() {
    setInterval(() => {
      // Synchronisation des appels lors de la reconnexion
      if (authStore.isAuthenticated && appStatusStore.isConnected) {
        this.synchronizeCalls();
      }
    }, DATA_REFRESH_INTERVAL);
  }
}

export const callStore = new CallStore();
