export enum AlgoCombinaison {
  OR = 'OR',
  AND = 'AND',
}

export enum ScoreInclusion {
  MIN = 'MIN',
  MAX = 'MAX',
  BOTH = 'BOTH',
  NONE = 'NONE',
}

export function algoCombinaisonSerializer(type: AlgoCombinaison): string {
  return AlgoCombinaison[type];
}

export function algoCombinaisonDeserializer(type: string): AlgoCombinaison {
  return AlgoCombinaison[type as keyof typeof AlgoCombinaison];
}

export function scoreInclusionSerializer(type: ScoreInclusion): string {
  return ScoreInclusion[type];
}

export function scoreInclusionDeserializer(type: string): ScoreInclusion {
  return ScoreInclusion[type as keyof typeof ScoreInclusion];
}
