import { primitive, serializable } from 'serializr';

export enum SeverityDegree {
  UA = 'UA',
  UR = 'UR',
  UA_UR_CONDITIONAL = 'UA_UR_CONDITIONAL',
  UR_CONDITIONAL = 'UR_CONDITIONAL',
  UD = 'UD',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export default class Diagnostic {
  @serializable(primitive())
  name?: string;
  @serializable(primitive())
  description?: string;
  content?: any;
  severityDegree?: SeverityDegree;
}
