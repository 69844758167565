import { appStatusStore } from 'stores/appStatus.store';
import { ResourceProvider } from 'stores/Persistable';
import { permissionsApi } from '../api';
import { set, get } from 'idb-keyval';
import { Permission_V15, Profile } from 'model/User';
import { Module } from 'constants/index';

export interface PermissionResourceMethods {
  getPermissions: () => Promise<Permission_V15[]>;
  getModules: () => Promise<Record<Module, Permission_V15[]>>;
  getProfiles: () => Promise<Record<Profile, Record<string, Permission_V15[]>>>;
}

const PERMISSIONS_COLLECTION_KEY = 'permissions:list';
const MODULES_COLLECTION_KEY = 'permissions:modules:list';
const PROFILES_COLLECTION_KEY = 'permissions:profiles:list';

export const permissionResourceProvider: ResourceProvider<PermissionResourceMethods> = {
  onlineResourceProvider: {
    getPermissions: async () => {
      const response = await permissionsApi.getPermissions();
      const permissions = response?.content || [];
      set(PERMISSIONS_COLLECTION_KEY, permissions);
      return permissions;
    },
    getModules: async () => {
      const response = await permissionsApi.getModules();
      const modules = response?.content[0] || {};
      set(MODULES_COLLECTION_KEY, modules);
      return modules;
    },
    getProfiles: async () => {
      const response = await permissionsApi.getProfiles();
      const profiles = response?.content[0] || {};
      set(PROFILES_COLLECTION_KEY, profiles);
      return profiles;
    },
  },
  offlineResourceProvider: {
    getPermissions: async () => {
      const storedPermissions = await get(PERMISSIONS_COLLECTION_KEY);
      return storedPermissions as Permission_V15[];
    },
    getModules: async () => {
      const storedModules = await get(MODULES_COLLECTION_KEY);
      return storedModules as Record<Module, Permission_V15[]>;
    },
    getProfiles: async () => {
      const storedProfiles = await get(PROFILES_COLLECTION_KEY);
      return storedProfiles as Record<Profile, Record<string, Permission_V15[]>>;
    },
  },
  get() {
    return appStatusStore.isConnected ? this.onlineResourceProvider : this.offlineResourceProvider;
  },
};
