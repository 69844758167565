import { Field, FORM_VALID_ON_BLUR, fieldsAggregatorBuilder, FORM_MODE_CREATE } from 'utils/forms';
import { isRequired } from 'utils/forms/validators';

export default function passwordResetForm({ initialValues = {}, mode = FORM_MODE_CREATE }) {
  const fields = {
    username: new Field({
      initValue: undefined,
      disabled: false,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR],
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
