import React, { FC } from 'react';

interface ArrowCurvedProps {
  fillColor: string;
  textLines: string[];
  width?: number;
}

export const Arrow: FC<ArrowCurvedProps> = ({ width = 230, fillColor, textLines }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 60" width={width}>
      <path fill={fillColor} d="M190.063 31.098L172-.345V6.51L.017 5.717.5 51.343l171.5.374V58z" />
      {textLines.map((textLine, index, array) => {
        const y = array.length > 1 ? 20 * (index + 1) : 30;
        return (
          <text
            key={index}
            y={y}
            x="3"
            style={{ lineHeight: 1.25 }}
            fontWeight="400"
            fontSize="11"
            fontFamily="sans-serif"
          >
            <tspan y={y} x="3">
              {textLine}
            </tspan>
          </text>
        );
      })}
    </svg>
  );
};

export const ArrowCurved: FC<ArrowCurvedProps> = ({ width = 250, fillColor, textLines }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 75" width={width}>
      <path
        fill={fillColor}
        d="M125.557 54.976l8.967-.107 21.46 19.763 21.298-21.123 9.547.018-30.846 21.105zm30.426 19.656l21.3-21.123-.585-24.803c-.69-19.51-.702-19.362-25.49-19.772L-1.31 9.037l.18 37.013 116.689.5c18.51.24 18.466.203 18.964 8.319z"
      />
      {textLines.map((textLine, index, array) => {
        const y = array.length > 1 ? 20 * (index + 1) : 30;
        return (
          <text
            key={index}
            y={y}
            x="3"
            style={{ lineHeight: 1.25 }}
            fontWeight="400"
            fontSize="10"
            fontFamily="sans-serif"
          >
            <tspan y={y} x="3">
              {textLine}
            </tspan>
          </text>
        );
      })}
    </svg>
  );
};
