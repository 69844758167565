import Event, { EventStatus, EventSynchronizableData } from 'model/event/Event';
import { compareDesc } from 'date-fns';
import { parseJSONDate } from './date.utilities';
import EventVictim, { OperationType } from 'model/event/EventVictim';
import { deserialize } from 'serializr';

export function sortEvents(events: EventSynchronizableData[]): EventSynchronizableData[] {
  return events.sort((event1, event2) =>
    compareDesc(
      parseJSONDate(event1.data.userCreationDate!),
      parseJSONDate(event2.data.userCreationDate!)
    )
  );
}

export function mapFormDataToEvent(synchronizableEvent: EventSynchronizableData, eventForm: any) {
  const eventData: Partial<Event> = {
    ...synchronizableEvent.data,
    title: eventForm.title.value,
    descriptionCAN: eventForm.descriptionCAN.value,
    descriptionParamedic: eventForm.descriptionParamedic.value,
    eventDate: new Date().toISOString(),
    locationDepartment: eventForm.locationDepartment.value,
    locationAdditionalInfos: eventForm.locationAdditionalInfos.value,
    status: eventForm.status.value ? EventStatus.ACTIVE : EventStatus.INACTIVE,
  };

  return deserialize(Event, eventData);
}

export function deleteVictim(
  synchronizableEvent: EventSynchronizableData,
  victimUuid: string
): boolean {
  const victimForDeletion: EventVictim | undefined = synchronizableEvent.data.victims.find(
    (vic) => vic.victim.uuid === victimUuid
  );
  if (victimForDeletion) {
    victimForDeletion.operation = OperationType.DELETE;
  }
  return !!victimForDeletion;
}
