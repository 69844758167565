import { identifier, primitive, serializable, object, date, custom } from 'serializr';
import { v4 as uuid } from 'uuid';
import VictimRegulation from './VictimRegulation';
import Department from './location/Department';
import { serializrErrorTracer } from './model.utilities';
import Event from '../model/event/Event';

export enum VictimSex {
  MALE = 'MALE', // Masculin
  FEMALE = 'FEMALE', // Féminin
  UNKNOWN = 'UNKNOWN', // Inconnu
  // NOT_SPECIFIED = 'NOT_SPECIFIED', // NC
}

export enum VictimAge {
  FROM_0_TO_5_YEARS = 'FROM_0_TO_5_YEARS',
  FROM_6_TO_15_YEARS = 'FROM_6_TO_15_YEARS',
  FROM_16_TO_49_YEARS = 'FROM_16_TO_49_YEARS', //deprecated slice
  FROM_16_TO_25_YEARS = 'FROM_16_TO_25_YEARS',
  FROM_26_TO_35_YEARS = 'FROM_26_TO_35_YEARS',
  FROM_36_TO_49_YEARS = 'FROM_36_TO_49_YEARS',
  FROM_50_65_YEARS = 'FROM_50_65_YEARS',
  FROM_66 = 'FROM_66',
  NOT_SPECIFIED = 'NOT_SPECIFIED', // NC
}

export default class Victim {
  @serializable(identifier())
  uuid: string = uuid();
  @serializable(primitive(serializrErrorTracer))
  code?: string;
  @serializable(primitive(serializrErrorTracer))
  lastName?: string;
  @serializable(primitive(serializrErrorTracer))
  firstName?: string;
  @serializable(primitive(serializrErrorTracer))
  sex: VictimSex = VictimSex.UNKNOWN;
  @serializable(primitive(serializrErrorTracer))
  ageRange: VictimAge = VictimAge.NOT_SPECIFIED;
  @serializable(primitive(serializrErrorTracer))
  age?: number;
  @serializable(primitive(serializrErrorTracer))
  address?: string;
  @serializable(object(Department))
  addressDepartment?: Department;
  @serializable(primitive(serializrErrorTracer))
  isRegulated: boolean = false;
  @serializable(object(VictimRegulation))
  regulation?: VictimRegulation;
  @serializable(date(serializrErrorTracer))
  userCreationDate?: Date = new Date();
  @serializable(date(serializrErrorTracer))
  userModificationDate?: Date = new Date();
  @serializable(
    custom(
      (s: Event) => s,
      (jsonValue, context, oldValue, done) => {
        return done(null, jsonValue);
      },
      serializrErrorTracer
    )
  )
  lastActiveEvent?: Event;
}
