import { useRef, useEffect } from 'react';

export function useInterval(callback: Function, delay: number, immediately?: boolean) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (immediately) {
      tick();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [immediately, delay]);
}
