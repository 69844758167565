import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';
import { RegulationMeans } from 'model/Call';

type RegulationMeansAnswerDialogProps = {
  open: boolean;
  onClose: () => void;
  onValidate: (regulationMeans: RegulationMeans) => void;
};

export const RegulationMeansAnswerDialog: FC<RegulationMeansAnswerDialogProps> = ({
  open,
  onClose,
  onValidate,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<RegulationMeans>();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Moyens engagés</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup value={selectedAnswer}>
            <RegulationMeansAnswerRadio
              regulationMeansAnswer={RegulationMeans.NOT_NECESSARY}
              color="primary"
              onClick={() => setSelectedAnswer(RegulationMeans.NOT_NECESSARY)}
            />
            <RegulationMeansAnswerRadio
              regulationMeansAnswer={RegulationMeans.NECESSARY_SENT}
              color="primary"
              onClick={() => setSelectedAnswer(RegulationMeans.NECESSARY_SENT)}
            />

            <RegulationMeansAnswerRadio
              regulationMeansAnswer={RegulationMeans.NECESSARY_NOT_AVAILABLE}
              color="secondary"
              onClick={() => setSelectedAnswer(RegulationMeans.NECESSARY_NOT_AVAILABLE)}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          disabled={!selectedAnswer}
          onClick={() => {
            if (selectedAnswer) {
              onValidate(selectedAnswer);
            }
          }}
          color="primary"
          variant="contained"
        >
          Terminer la régulation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type RegulationMeansAnswerRadioProps = {
  regulationMeansAnswer: RegulationMeans;
  color?: 'primary' | 'secondary';
  onClick: () => void;
};
export function RegulationMeansAnswerRadio({
  regulationMeansAnswer,
  color,
  onClick,
}: RegulationMeansAnswerRadioProps) {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      value={regulationMeansAnswer}
      control={<Radio color={color || 'default'} />}
      label={
        <Typography color={color}>
          {t(`orientations.regulationMeans.${regulationMeansAnswer}`)}
        </Typography>
      }
      onChange={() => onClick()}
    />
  );
}
