import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { authStore } from 'stores/auth/auth.store';
import { uiStore, NotificationType } from 'stores/ui.store';
import { refreshLock } from './auth.api';

async function authenticationRequestInterceptor(config: AxiosRequestConfig) {
  await refreshLock.pending;
  //add JWT token in request headers
  const authToken = authStore.token;
  if (authToken) {
    config.headers.authorization = `Bearer ${authToken}`;
  }

  return config;
}
function authenticationResponseInterceptor(response: AxiosResponse): AxiosResponse {
  const authorizationHeader = response.headers.authorization;
  if (authorizationHeader) {
    authStore.setTokenFromAuthorizationHeader(authorizationHeader);
  }
  return response;
}
function requestErrorInterceptor(error: any) {
  const status = error.response && error.response.status;
  // eslint-disable-next-line
  if (status == 401 || status == 403) {
    // afficher un message indiquant la nécessité de se reconnecter
    uiStore.pushNotification("Erreur d'authentification", NotificationType.ERROR);
    setTimeout(() => authStore.logout(), 800);
  } else {
    uiStore.pushNotification('Erreur de connexion avec le serveur', NotificationType.ERROR, 4000);
  }
  return Promise.reject(error);
}

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(authenticationRequestInterceptor, requestErrorInterceptor);
axios.interceptors.response.use(authenticationResponseInterceptor, requestErrorInterceptor);
