import { Chip, Grid, Typography, useTheme } from '@material-ui/core';
import { CallStatus } from 'model/Call';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PageTitleProps = {
  title: string;
  callStatus: CallStatus;
  callNumber: number;
};
export const PageTitle: FC<PageTitleProps> = ({ callStatus, callNumber, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container item xs={12} alignItems="center" justify="space-between" spacing={2}>
      <Grid container item xs={12} md={8} alignItems="center" spacing={2}>
        <Typography variant="h5">{title}</Typography>
        <Chip
          color={callStatus === CallStatus.TO_REGULATE ? 'secondary' : 'primary'}
          style={{ marginLeft: theme.spacing(2), fontSize: 14, textTransform: 'uppercase' }}
          label={<Typography variant="button">{t(`forms.call.status.${callStatus}`)}</Typography>}
        />
      </Grid>
      <Grid item>
        <Typography color="textSecondary">{callNumber && `n° ${callNumber}`}</Typography>
      </Grid>
    </Grid>
  );
};
