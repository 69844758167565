import { Button, Dialog, DialogActions } from '@material-ui/core';
import { Observer } from 'mobx-react-lite';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';
import { CallSynchronizableData } from 'model/Call';
import React from 'react';
import { nurseAlgoStore } from 'stores/algo/nurse';
import { NurseAlgoDispatcher } from '../common/NurseAlgo/NurseAlgoDispatcher';

export const CallAlgorithmModal: React.FC<{
  synchronizableCall: CallSynchronizableData;
  isOpen: boolean;
  onClose: VoidFunction;
  onSave: (result: UINurseAlgoRunResult) => void;
}> = ({ synchronizableCall, isOpen = false, onClose, onSave }) => {
  const handleClickCancel = () => {
    nurseAlgoStore.resetAlgo();
    onClose();
  };
  const handleClickSave = () => {
    if (nurseAlgoStore.algoRunResult) {
      onSave(nurseAlgoStore.algoRunResult);
      onClose();
    } else {
    }
  };
  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
        <div style={{ minHeight: 500 }}>
          <NurseAlgoDispatcher
            nurseAlgoStore={nurseAlgoStore}
            call={synchronizableCall.data}
            formCallType={synchronizableCall.data.callType}
            algoAvailable={true}
          />
        </div>
        <DialogActions>
          <Button variant="contained" onClick={handleClickCancel}>
            Annuler
          </Button>
          <Observer>
            {() => (
              <Button
                variant="contained"
                color="primary"
                disabled={!nurseAlgoStore.algoRunResult}
                onClick={handleClickSave}
              >
                Confirmer
              </Button>
            )}
          </Observer>
        </DialogActions>
      </Dialog>
    </>
  );
};
