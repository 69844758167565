import styled from '@emotion/styled';
import { Checkbox, FormControl, FormControlLabel, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PermanencierHumanBodyZone, PERMANENCIER_HUMAN_BODY_ZONES } from './HumanBody.model';

const useStyles = makeStyles({
  icon: {
    padding: '2px',
  },
});

const CheckboxLabel = styled.span`
  font-size: 12px;
`;

type HumanBodyZoneListProps = {
  selected: PermanencierHumanBodyZone[];
  onSetSelectedZones: (selected: PermanencierHumanBodyZone[]) => void;
};

export function HumanBodyZoneList({ selected, onSetSelectedZones }: HumanBodyZoneListProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleChange = (zoneId: PermanencierHumanBodyZone, checked: boolean) => {
    if (!checked && selected.includes(zoneId)) {
      const newZones = selected.filter((existingZone) => existingZone !== zoneId);
      onSetSelectedZones(newZones);
    } else if (checked && !selected.includes(zoneId)) {
      const newZones = [...selected, zoneId];
      onSetSelectedZones(newZones);
    }
  };

  return (
    <FormControl fullWidth={true} margin="dense" size="small">
      {PERMANENCIER_HUMAN_BODY_ZONES.map((zoneId, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              className={classes.icon}
              color="primary"
              checked={selected.includes(zoneId)}
              onChange={(_event, checked) => handleChange(zoneId, checked)}
              name={zoneId}
              size="small"
            />
          }
          label={<CheckboxLabel>{t(`humanBody.zones.${zoneId}`)}</CheckboxLabel>}
        />
      ))}
    </FormControl>
  );
}
