import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

interface CallConfirmSaveDialogProps {
  open: boolean;
  onClickSave: () => void;
  onClose: () => void;
}
export const CallConfirmSaveDialog: FC<CallConfirmSaveDialogProps> = ({
  open,
  onClickSave,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Vous n'avez pas de priorité</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Veuillez renseigner les circonstances de l'urgence</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClickSave}>
          Enregistrer
        </Button>
        <Button autoFocus onClick={onClose} variant="outlined" color="primary">
          Renseigner les circonstances
        </Button>
      </DialogActions>
    </Dialog>
  );
};
