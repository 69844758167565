import { Grid, Typography } from '@material-ui/core';
import EventVictim, { OrientationStatus } from 'model/event/EventVictim';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { eventStore } from 'stores/event';
import { FORM_MODE } from 'utils/forms';
import { mapFormDataToVictim } from 'utils/victim.utilities';
import VictimForm from './victim.form';
import victimFormBuilder from './victim.form.builder';
import { victimDetailsStore } from './victim.details.store';

function copyObjectProperty<T, K extends keyof T>(source: T, value: T[K], property: K) {
  source[property] = value;
}

const VictimDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<{
    eventUuid: string;
    mode?: FORM_MODE;
  }>();
  const victimRegulationHistory = useHistory<{ mode?: FORM_MODE }>();
  const { mode } = history.location.state;
  const [victimForm] = React.useState(
    React.useMemo(
      () =>
        victimFormBuilder({
          initialValues: { ...victimDetailsStore.victim, ...victimDetailsStore.victim?.victim },
          mode: mode,
        }),
      [mode]
    )
  );

  const handleSubmit = async () => {
    victimForm.validate();
    if (victimForm.isValid) {
      if (!victimDetailsStore.victim) {
        throw new Error('No victim in history!');
      }

      const victimData: EventVictim = mapFormDataToVictim(victimDetailsStore.victim, victimForm);

      // Update victim orientation status according to selected values
      victimData.orientationStatus =
        victimData.facility || victimData.stayedThere
          ? OrientationStatus.ORIENTED
          : OrientationStatus.TO_ORIENT;

      if (victimDetailsStore.victimEvent) {
        //Update or create the Victim in the current Event
        if (mode === FORM_MODE.CREATE) {
          victimDetailsStore.victimEvent.data?.victims.push(victimData);
        } else if (mode === FORM_MODE.EDIT) {
          const existingVictim = victimDetailsStore.victimEvent.data?.victims.find(
            (eVictim) => eVictim?.victim?.uuid === victimDetailsStore.victim?.victim?.uuid
          );
          if (existingVictim) {
            Object.keys(victimData).forEach((key: string) => {
              copyObjectProperty(
                existingVictim,
                victimData[key as keyof EventVictim],
                key as keyof EventVictim
              );
            });
          }
        }
        // Save the current (now updated with new victim data) Event
        eventStore.saveEvent(victimDetailsStore.victimEvent, true).then(history.goBack);
      }
    }
  };

  const handleClickRegulate = () => {
    if (victimDetailsStore.victim) {
      const victimData: EventVictim = mapFormDataToVictim(victimDetailsStore.victim, victimForm);
      victimDetailsStore.setVictim(victimData);
      victimRegulationHistory.replace('/evenements/victimes/regulation', {
        mode: history.location.state.mode,
      });
    }
  };

  const handleClickCancel = () => {
    victimDetailsStore.clearData();
    history.goBack();
  };

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" component="h2" style={{ textTransform: 'uppercase' }}>
          {t(`forms.victim.mode.${mode}`)} d'une victime
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <VictimForm
          eventTitle={victimDetailsStore.victimEvent?.data?.title}
          formMode={mode || FORM_MODE.CONSULT}
          victimForm={victimForm}
          onSubmit={handleSubmit}
          onCancel={handleClickCancel}
          onRegulate={handleClickRegulate}
        />
      </Grid>
    </Grid>
  );
};

export default VictimDetailsPage;
