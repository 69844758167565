import {
  createStyles,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
  TableSortLabel,
  TextField,
  Theme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { callApi } from 'api';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { PriorityIndicator } from 'components/common/Priority/PriorityIndicator';
import { PrioritySelector } from 'components/common/Priority/PrioritySelector';
import StatusChip from 'components/common/StatusChip';
import { Spacer } from 'components/Spacer';
import fr from 'date-fns/locale/fr';
import parseJSON from 'date-fns/parseJSON';
import { observable, reaction } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import Call, { CallStatus, CallType } from 'model/Call';
import { IPageResponse } from 'model/IPageResponse';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deserialize } from 'serializr';
import { nurseAlgoDataStore, NurseAlgoDataStore } from 'stores/algo/nurse/NurseAlgoData.store';
import { appStatusStore } from 'stores/appStatus.store';
import { facilityStore } from 'stores/facility';
import { WithFacilityStoreDeserializationContextArgs } from 'stores/facility/facility.resolvers';
import { noopCallback } from 'stores/serialize.context';
import { getDistanceInWordsToNow } from 'utils/date.utilities';
import { callPostRegulationUiStore } from './PostRegulation/call.postRegulation.ui.store';

type Order = 'asc' | 'desc';

interface LocalStoreProps {
  pagination: IPageResponse<Call>;
  setPagination: (pagination: IPageResponse<Call>) => void;
  calls: Call[];
  setCalls: (calls: Call[]) => void;
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: string;
  selectedPriorities: Record<PermanentAlgoPriority, boolean>;
  selectedStatus: CallStatus | '';
  selectedCallType: CallType | '';
  keywords: string;
  setKeywords: (kw: string) => void;
}

const CallSearchPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const store = useLocalStore<LocalStoreProps>(() => ({
    pagination: {
      content: [],
      totalPages: 0,
      totalElements: 0,
      last: false,
      size: 0,
      first: false,
      numberOfElements: 0,
      sort: { sorted: false, unsorted: false, empty: true },
      number: 0,
      empty: true,
    },
    page: 0,
    rowsPerPage: 5,
    order: 'desc',
    orderBy: 'userCreationDate',
    setPagination(pagination: IPageResponse<Call>) {
      this.pagination = pagination;
    },
    calls: [],
    setCalls(calls) {
      this.calls.length = 0;
      this.calls = this.calls.concat(calls);
    },
    selectedPriorities: observable.object({
      [PermanentAlgoPriority.HIGHT]: true,
      [PermanentAlgoPriority.MEDIUM]: true,
      [PermanentAlgoPriority.LOW]: true,
      [PermanentAlgoPriority.UNKNOWN]: true,
    }),
    selectedStatus: '',
    selectedCallType: '',
    keywords: '',
    setKeywords(kw: string) {
      this.keywords = kw;
    },
  }));

  const doSearch = React.useCallback(() => {
    const priorities: PermanentAlgoPriority[] = Object.entries(store.selectedPriorities)
      .filter(([k, v]) => v)
      .map(([k, v]) => k as PermanentAlgoPriority);
    const statuses = store.selectedStatus ? [store.selectedStatus] : [];
    const callTypes = store.selectedCallType ? [store.selectedCallType] : [];
    const sort: Partial<Record<keyof Call, 'ASC' | 'DESC'>> = {
      [store.orderBy]: store.order.toUpperCase(),
    };
    const contextArgs: WithFacilityStoreDeserializationContextArgs<NurseAlgoDataStore> = {
      store: nurseAlgoDataStore,
      facilityStore: facilityStore,
    };
    callApi
      .searchCalls({
        callType: callTypes,
        priority: priorities,
        status: statuses,
        pageIndex: store.page,
        pageSize: store.rowsPerPage,
        keywords: store.keywords,
        sort,
      })
      .then((pagination: IPageResponse<Call>) => {
        const calls: Array<Call> = deserialize(Call, pagination.content, noopCallback, contextArgs);
        store.setPagination(pagination);
        store.setCalls(calls);
      });
  }, [store]);

  /*
   * Clean up post regulation call store.
   * It should be done when unmounting CallPostRegulationPage component but the logic is problematic when navigating
   * to the facilities pages, for example... So yeah. In the mean time <3 ...
   */
  React.useEffect(() => {
    callPostRegulationUiStore.clear();
  }, []);

  React.useEffect(() => {
    if (!appStatusStore.isConnected) {
      history.push('/appels');
    } else {
      /* Using reaction instead of autorun in order to have fine-grained
       * control over what triggers the search and when.
       */
      const keywordsReaction = reaction(() => store.keywords, doSearch, {
        delay: 800,
        fireImmediately: true,
      });
      const rowsReaction = reaction(() => store.rowsPerPage, doSearch);
      const pageNumberReaction = reaction(() => store.page, doSearch);
      const prioritiesReaction = reaction(() => Object.values(store.selectedPriorities), doSearch);
      const statusesReaction = reaction(() => store.selectedStatus, doSearch);
      const callTypesReaction = reaction(() => store.selectedCallType, doSearch);
      const orderReaction = reaction(() => store.order, doSearch);
      const orderByReaction = reaction(() => store.orderBy, doSearch);

      return () => {
        keywordsReaction();
        rowsReaction();
        pageNumberReaction();
        prioritiesReaction();
        statusesReaction();
        callTypesReaction();
        orderReaction();
        orderByReaction();
      };
    }
  }, [store, doSearch, history]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    store.rowsPerPage = rowsPerPage;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    store.page = newPage;
  };

  const handleSelectPriority = (priority: PermanentAlgoPriority) => () => {
    store.selectedPriorities = {
      ...store.selectedPriorities,
      [priority]: !store.selectedPriorities[priority],
    };
  };

  const handleSelectStatus = (
    event: React.ChangeEvent<{ name?: string; value: '' | CallStatus }>
  ) => {
    store.selectedStatus = event.target.value;
  };

  const handleSelectCallType = (
    event: React.ChangeEvent<{ name?: string; value: '' | CallType }>
  ) => {
    store.selectedCallType = event.target.value;
  };

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setKeywords(event.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = store.orderBy === property && store.order === 'asc';
    store.order = isAsc ? 'desc' : 'asc';
    store.orderBy = property;
  };

  const handleClickRow = (callUuid: string, isRegulated: boolean = false) => async () => {
    // const call = await callStore.getCall(callUuid);
    history.push(`/appels/${isRegulated ? 'post' : 'pre'}Regulation/${callUuid}`);
  };

  const handleClickReturn = () => {
    history.push('/appels');
  };

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Call;
    label: string;
    sortable: boolean;
  }

  const headCells: HeadCell[] = [
    { id: 'priority', sortable: true, disablePadding: false, label: 'Priorité' },
    { id: 'victimCode', sortable: false, disablePadding: false, label: 'Identité de la victime' },
    { id: 'locationDepartment', sortable: false, disablePadding: false, label: 'Urgence/Lieu' },
    { id: 'status', sortable: true, disablePadding: false, label: 'Statut' },
    { id: 'userCreationDate', sortable: true, disablePadding: true, label: 'Horodatage' },
    { id: 'callNumber', sortable: true, disablePadding: false, label: 'N° fiche' },
    { id: 'callType', sortable: true, disablePadding: true, label: "Type d'appel" },
  ];

  const createSortHandler = (property: keyof Call) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={8}>
            <Typography variant="h4" component="h1">
              Recherche des appels
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />
      <Grid item xs={12}>
        <Observer>
          {() => (
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Rechercher un appel"
                  value={store.keywords}
                  onChange={handleSearchBarChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <PrioritySelector
                  priority={PermanentAlgoPriority.LOW}
                  size="small"
                  active={store.selectedPriorities[PermanentAlgoPriority.LOW]}
                  onClick={handleSelectPriority(PermanentAlgoPriority.LOW)}
                />
                <PrioritySelector
                  priority={PermanentAlgoPriority.MEDIUM}
                  size="small"
                  active={store.selectedPriorities[PermanentAlgoPriority.MEDIUM]}
                  onClick={handleSelectPriority(PermanentAlgoPriority.MEDIUM)}
                />
                <PrioritySelector
                  priority={PermanentAlgoPriority.HIGHT}
                  size="small"
                  active={store.selectedPriorities[PermanentAlgoPriority.HIGHT]}
                  onClick={handleSelectPriority(PermanentAlgoPriority.HIGHT)}
                />
              </Grid>
              <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <MaterialSelectInput
                  label="Statut"
                  displayEmpty
                  onChange={handleSelectStatus}
                  value={store.selectedStatus}
                >
                  <MenuItem value="">Tous</MenuItem>
                  {Object.keys(CallStatus).map((key) => (
                    <MenuItem key={key} value={key}>
                      {t(`calls.status.${key}`)}
                    </MenuItem>
                  ))}
                </MaterialSelectInput>
              </Grid>
              <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <MaterialSelectInput
                  label="Type d'appel"
                  displayEmpty
                  onChange={handleSelectCallType}
                  value={store.selectedCallType}
                >
                  <MenuItem value="">Tous</MenuItem>
                  {Object.keys(CallType).map((key) => (
                    <MenuItem key={key} value={key}>
                      {t(`calls.type.${key}`)}
                    </MenuItem>
                  ))}
                </MaterialSelectInput>
              </Grid>
            </Grid>
          )}
        </Observer>
      </Grid>

      <Grid item xs={12}>
        <TableContainer style={{ backgroundColor: 'transparent', maxHeight: 600 }}>
          <Observer>
            {() => (
              <Table className={classes.table} aria-label="calls_table" size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={store.orderBy === headCell.id ? store.order : false}
                      >
                        {headCell.sortable && (
                          <TableSortLabel
                            active={store.orderBy === headCell.id}
                            direction={store.orderBy === headCell.id ? store.order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {store.orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {store.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        )}
                        {!headCell.sortable && headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <Observer>
                  {() => (
                    <TableBody component={Paper}>
                      {store.calls.length === 0 && (
                        <TableRow hover className={classes.tableRow}>
                          <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                            Aucun appel
                          </TableCell>
                        </TableRow>
                      )}
                      {store.calls.map((call: Call) => (
                        <TableRow
                          key={call.uuid}
                          hover
                          className={classes.tableRow}
                          onClick={handleClickRow(call.uuid, call.status === CallStatus.REGULATED)}
                        >
                          <TableCell component="th" scope="row">
                            <PriorityIndicator
                              priority={call.priority || PermanentAlgoPriority.LOW}
                              size="normal"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <PatientName>
                              {call.victimLastName?.toUpperCase()} {call.victimFirstName}
                            </PatientName>
                            {call.victimCode}
                          </TableCell>
                          <TableCell align="left">
                            <Typography style={{ fontWeight: 500, maxWidth: 250 }} noWrap>
                              {call.diagnostic
                                ? call.diagnostic.name
                                : call.callReasons?.length
                                ? call.callReasons[0].value
                                : ''}
                            </Typography>
                            <div>
                              {call.locationCity ||
                                (call.locationDepartment &&
                                  t(`departments.${call.locationDepartment}`))}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {call.status && <StatusChip status={call.status} />}
                          </TableCell>
                          <TableCell align="left">
                            {getDistanceInWordsToNow(parseJSON(call.userCreationDate!), {
                              includeSeconds: true,
                              locale: fr,
                            })}
                          </TableCell>
                          <TableCell align="left">{call.callNumber}</TableCell>

                          <TableCell align="left">
                            <Chip
                              label={t(`calls.type.${call.callType}`, '')}
                              style={{ textTransform: 'uppercase', fontWeight: 500 }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Observer>
              </Table>
            )}
          </Observer>
        </TableContainer>
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Observer>
          {() => (
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              disabled={!appStatusStore.isConnected}
              onClick={handleClickReturn}
            >
              Retour au suivi
            </Button>
          )}
        </Observer>
        <Observer>
          {() => (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              labelRowsPerPage="lignes"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} à ${to === -1 ? count : to} de ${count !== -1 ? count : `plus de ${to}`}`
              }
              count={store.pagination.totalElements}
              rowsPerPage={store.rowsPerPage}
              page={store.pagination.number}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Observer>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
    },
    tableRow: {
      height: theme.spacing(10),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

const PatientName: React.FC = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        minWidth: '80px',
        color: 'black',
        fontWeight: 500,
      },
      lastname: {
        textTransform: 'uppercase',
      },
    })
  );
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

export default CallSearchPage;
