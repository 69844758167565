import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { SeverityDegree } from 'model/Diagnostic';
import React, { FC } from 'react';
import { isDiagnosticSeveryUA } from 'utils/call.utilities';

interface NurseAlgoResultTitleProps {
  diagnosticSeverity?: SeverityDegree;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NurseAlgoResultTitle: FC<NurseAlgoResultTitleProps> = (props) => {
  return (
    <Container>
      <Typography
        variant="h4"
        color="textSecondary"
        style={isDiagnosticSeveryUA(props.diagnosticSeverity) ? { color: 'white' } : {}}
      >
        Hypothèse de diagnostique
      </Typography>
    </Container>
  );
};
