import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import french from './fr.json';
import haitienCreole from './ht.json';

export const APP_I18N_CODE_KEY = 'i18n';

// translation catalog
const resources = {
  fr: french,
  ht: haitienCreole,
};

const languageDetector = new LanguageDetector(null, {
  order: ['localStorage'],
  lookupLocalStorage: APP_I18N_CODE_KEY,
  caches: ['localStorage'],
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'fr',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
export function changeLanguage(languageCode: string) {
  i18n.changeLanguage(languageCode);
}
