import React from 'react';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormLabel } from '@material-ui/core';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface MaterialSwitchProps extends SwitchProps {
  label: string;
  label2?: string;
  error?: boolean;
  onBlur: (event: React.FocusEvent<HTMLButtonElement>) => void;
  helperText?: string;
}

export default function MaterialSwitch({
  label,
  label2,
  error = false,
  helperText,
  ...rest
}: MaterialSwitchProps) {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <FormLabel>{label}</FormLabel>
        </Grid>
        <Grid item>
          <Switch {...rest} />
        </Grid>
        {label2 && <FormLabel>{label2}</FormLabel>}
      </Grid>
    </Typography>
  );
}
