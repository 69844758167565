import { DeserializationContext } from 'stores/stores.model';
import { nurseAlgoDataStore, NurseAlgoDataStore } from './NurseAlgoData.store';

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveNurseGroupById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store = nurseAlgoDataStore } }: DeserializationContext<NurseAlgoDataStore>
) {
  const foundedItem = store.groups.find((group) => group.Id === id);
  callback(null, foundedItem);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveNurseQuestionById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store = nurseAlgoDataStore } }: DeserializationContext<NurseAlgoDataStore>
) {
  const foundedItem = store.questions.find((question) => question.Id === id);
  callback(null, foundedItem);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveNurseResponseById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store = nurseAlgoDataStore } }: DeserializationContext<NurseAlgoDataStore>
) {
  const foundedItem = store.responses.find((response) => response.Id === id);
  callback(null, foundedItem);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveNurseAlgoDiagnosticById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store = nurseAlgoDataStore } }: DeserializationContext<NurseAlgoDataStore>
) {
  const foundedItem = store.diagnostics.find((diagnostic) => diagnostic.Id === id);
  callback(null, foundedItem);
}

/**
 *  Typage de l'id en "any" afin d'être compatible avec le typage en "string" de serializr
 */
export function resolveNurseAlgoCardDocumentById(
  id: any,
  callback: (err: any, result: any) => void,
  { args: { store = nurseAlgoDataStore } }: DeserializationContext<NurseAlgoDataStore>
) {
  const foundedItem = store.cardDocuments.find((cardDocument) => cardDocument.id === id);
  callback(null, foundedItem);
}
