import axios from 'axios';
import { IPageResponse } from 'model/IPageResponse';
import IPaginate from 'model/IPaginate';
import ISearch from 'model/ISearch';
import Facility, { FacilityUpdate } from 'model/facilities/Facility';
import { deserialize } from 'serializr';

export async function getFacility(facilityId: number): Promise<Facility> {
  return axios
    .get<Facility>(`api/facilities/${facilityId}`)
    .then((res) => {
      return deserialize(Facility, res.data);
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export interface SearchFacilityProps extends IPaginate, ISearch {
  departments: any;
  specialities: any;
  equipments: any;
  category?: any;
  types: any;
  bloodLevel: boolean;
  oxygenLevel: boolean;
  algorithme?: boolean;
  sort?: Partial<Record<keyof Facility, 'ASC' | 'DESC'>>;
}

export async function searchFacilities(
  search: Partial<SearchFacilityProps> = {
    pageIndex: 0,
    pageSize: 150,
    keywords: '',
    departments: [],
    specialities: [],
    equipments: [],
    category: '',
    types: [],
    bloodLevel: false,
    oxygenLevel: false,
    algorithme: false,
    sort: {},
  }
): Promise<IPageResponse<Facility>> {
  return axios
    .post('api/facilities/search', { ...search })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getFacilities() {
  return searchFacilities({
    pageIndex: 0,
    pageSize: 150,
  });
}

export async function createFacility(newFacility: any): Promise<Facility> {
  return axios
    .post('api/facilities', newFacility)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateFacility(id: string, facilityData: FacilityUpdate): Promise<Facility> {
  return axios
    .put(`api/facilities/${id}`, facilityData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function enableFacility(id: string): Promise<Facility> {
  return axios
    .put(`api/facilities/${id}/enable`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
export async function disableFacility(id: string): Promise<Facility> {
  return axios
    .put(`api/facilities/${id}/disable`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function deleteFacility(id: string): Promise<void> {
  return axios
    .delete(`api/facilities/${id}`)
    .then(() => {})
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
