import { Chip, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { FC, MouseEvent, useState } from 'react';
import { formatToLocal, getDistanceInWordsToNow } from 'utils/date.utilities';

const useClickableChipStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

const usePopoverStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

export const OfflineChip: FC<{ offlineSince?: Date }> = ({ offlineSince }) => {
  const offlineDistanceFromNow = offlineSince ? getDistanceInWordsToNow(offlineSince) : undefined;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const chipClasses = useClickableChipStyles();
  const popoverClasses = usePopoverStyles();

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <div onClick={handlePopoverOpen}>
        <Chip
          classes={chipClasses}
          icon={<WarningIcon />}
          label={`Déconnecté `}
          color="secondary"
          variant="outlined"
        />
      </div>

      {offlineSince && (
        <Popover
          id="offline-since-popover"
          classes={popoverClasses}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="div">
            <div>Hors-ligne depuis {offlineDistanceFromNow}</div>
          </Typography>
          <Typography component="div" color="textSecondary" variant="body2">
            <div>Dernière connexion le: {formatToLocal(offlineSince)}</div>
          </Typography>
        </Popover>
      )}
    </>
  );
};
