import { action, computed, observable, reaction } from 'mobx';
import { UIPendingGroup, UIQuestionsGroup, UIVerticalModeSubGroupContext } from 'model/algo';
import { NurseAlgoDiagnostic, NurseAlgoGroup } from 'model/algo/nurse';
import { NurseAlgoResponse } from 'model/algo/nurse/Response';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';
import {
  CommonAlgo,
  CommonAlgoStore,
  NextQuestionsGroups,
  UserResponsesType,
} from '../CommonAlgo.store';
import { calculateScore, responsesMatchCombinaison } from '../CommonAlgo.utilities';
import { GroupsToDisplay } from '../GroupsToDisplay';
import { nurseAlgoDataStore } from './NurseAlgoData.store';

type NurseAlgoContextStage = 'VITAL_DISTRESS' | 'SELECT_REASON' | 'QUESTIONNAIRE';
type NurseAlgoContext = {
  stage: NurseAlgoContextStage;
  permanentAlgoCallReasonIds?: ReadonlyArray<number>;
};

const INITIAL_NURSE_ALGO_CONTEXT: NurseAlgoContext = {
  stage: 'VITAL_DISTRESS',
};

type NextQuestionsGroupsState = {
  nextQuestionsGroups: NextQuestionsGroups;
  nextStage: NurseAlgoContextStage;
};
export class NurseAlgoStore implements CommonAlgoStore {
  // Other data
  @observable userResponses: UserResponsesType<NurseAlgoResponse> = new Map(); // map groupId => questionId => Responses
  @observable groupsToDisplay = new GroupsToDisplay<NurseAlgoGroup>();

  nurseAlgoContext: NurseAlgoContext = INITIAL_NURSE_ALGO_CONTEXT;
  constructor() {
    reaction(
      () => this.userResponses.values(),
      () => {
        const lastGroupId = Array.from(this.userResponses.keys()).pop();

        if (lastGroupId) {
          const nextGroupsChanges = this.getNextNurseQuestionsGroups(lastGroupId);
          // Nettoyage des réponses déjà existant afin de pouvoir répondre plusieurs fois aux mêmes réponses
          const userResponsesRemoved = CommonAlgo.removeAlreadyExistingResponse(
            nextGroupsChanges.nextQuestionsGroups.parents,
            this.userResponses
          );
          if (userResponsesRemoved) {
            return; // On skip puisque la suppression d'une réponse re-trigger la reaction
          }

          this.groupsToDisplay.addSubGroups(
            lastGroupId,
            nextGroupsChanges.nextQuestionsGroups.subs
          );

          //Cas spécial de la question "Autres symptomes"
          if (this.isPreviousGroupVerticalMode) {
            this.nurseAlgoContext.stage = nextGroupsChanges.nextStage;
            this.groupsToDisplay.setGroups(nextGroupsChanges.nextQuestionsGroups.parents);
          }

          // Si tous les groupes ne sont pas complet. Il ne faut pas passer au groupsToDisplay suivant
          if (!this.isFulfilledGroupsToDisplay()) {
            return;
          }

          this.nurseAlgoContext.stage = nextGroupsChanges.nextStage;
          this.groupsToDisplay.setGroups(nextGroupsChanges.nextQuestionsGroups.parents);
        } else {
          this.startAlgo();
        }
      }
    );
  }

  private getNextNurseQuestionsGroups(groupId: number): NextQuestionsGroupsState {
    const nextQuestionsGroups = CommonAlgo.getNextQuestionsGroups(
      groupId,
      nurseAlgoDataStore.routes,
      this.userResponsesFlat
    );

    const reasonSelectorGroup = nextQuestionsGroups.parents.find(
      (group: NurseAlgoGroup) => group.selectCallReason
    );

    if (this.nurseAlgoContext.stage === 'VITAL_DISTRESS') {
      const permanentAlgoCallReasonIds = this.nurseAlgoContext.permanentAlgoCallReasonIds;

      // VITAL_DISTRESS => SELECT_REASON
      if (permanentAlgoCallReasonIds === undefined && !!reasonSelectorGroup) {
        const callReasonSelectorGroup = this.fromVitalDistressToSelectReason();
        return {
          nextQuestionsGroups: { parents: [callReasonSelectorGroup], subs: [] },
          nextStage: 'SELECT_REASON',
        };
      }
      // VITAL_DISTRESS => QUESTIONNAIRE
      if (permanentAlgoCallReasonIds !== undefined && !!reasonSelectorGroup) {
        let nextGroup = this.fromVitalDistressToQuestionnaire(permanentAlgoCallReasonIds);
        if (!nextGroup) {
          // Si pas de group correspondant au permanent call reason => on redirige vers le choix de la thématique
          return {
            nextQuestionsGroups: { parents: [this.fromVitalDistressToSelectReason()], subs: [] },
            nextStage: 'SELECT_REASON',
          };
        }
        return {
          nextQuestionsGroups: { parents: [nextGroup], subs: [] },
          nextStage: 'QUESTIONNAIRE',
        };
      }
    }

    // SELECT_REASON => QUESTIONNAIRE
    if (
      this.nurseAlgoContext.stage === 'SELECT_REASON' &&
      nextQuestionsGroups.parents.length === 0 &&
      (this.isPreviousGroupVerticalMode || this.isFulfilledGroupsToDisplay()) // A l'exception du mode vertical. Si tous les groups en cours n'ont pas de réponse. Il ne faut pas passer à l'étape 'QUESTIONNAIRE'
    ) {
      // On cherche le callReason dans la route qui match
      const nextGroup = this.fromSelectReasonToQuestionnaire();
      return {
        nextQuestionsGroups: { parents: [nextGroup], subs: [] },
        nextStage: 'QUESTIONNAIRE',
      };
    }

    // QUESTIONNAIRE -> SELECT_REASON si le diagnostic perma n'est pas confirmé
    if (this.nurseAlgoContext.stage === 'QUESTIONNAIRE' && !!reasonSelectorGroup) {
      return {
        nextQuestionsGroups,
        nextStage: 'SELECT_REASON',
      };
    }
    // Si aucun état de cas de changement de stage est observé. On renvoit le nextQuestionsGroups classique
    return { nextQuestionsGroups, nextStage: this.nurseAlgoContext.stage };
  }

  /**
   * From stage "VITAL_DISTRESS" to stage "QUESTIONNAIRE"
   */
  private fromVitalDistressToQuestionnaire(
    permanentAlgoCallReasonIds: ReadonlyArray<number>
  ): NurseAlgoGroup | undefined {
    const nextGroup = this.getGroupForCallReasons(permanentAlgoCallReasonIds);

    return nextGroup;
  }
  /**
   * From stage "VITAL_DISTRESS" to stage "SELECT_REASON"
   */
  private fromVitalDistressToSelectReason(): NurseAlgoGroup {
    const callReasonSelectorGroup = nurseAlgoDataStore.groups.find(
      (group) => group.selectCallReason
    );
    if (!callReasonSelectorGroup) {
      throw new Error('Unable to found group with "selectCallReason" = true');
    }

    return callReasonSelectorGroup;
  }
  /**
   * From stage "SELECT_REASON" to stage "QUESTIONNAIRE"
   */
  private fromSelectReasonToQuestionnaire(): NurseAlgoGroup {
    const matchingCallReasons: number[] = [];
    for (const route of nurseAlgoDataStore.routes) {
      if (responsesMatchCombinaison(route, this.userResponsesFlat) && route.toCallReason) {
        matchingCallReasons.push(route.toCallReason);
      }
    }
    const nextGroup = this.getGroupForCallReasons(matchingCallReasons);

    if (!nextGroup) {
      throw new Error(`Unable to found a group for callReason "${matchingCallReasons}"`);
    }

    return nextGroup;
  }

  private getGroupForCallReasons(callReasonIds: ReadonlyArray<number>): NurseAlgoGroup | undefined {
    return (
      nurseAlgoDataStore.groups
        .filter(
          (group) =>
            group.callReason && group.callReason.id && callReasonIds.includes(group.callReason.id)
        )
        // Groupe le plus prioritaire => groupe avec "priority" le plus faible
        .sort(({ priority: prio1 = 100 }, { priority: prio2 = 100 }) => prio1 - prio2)
        .shift()
    );
  }

  @action('NURSE:START_ALGO')
  startAlgo = (permanentAlgoCallReasonIds?: number[]) => {
    this.resetAlgo();

    if (!nurseAlgoDataStore.algoLoaded) {
      console.warn('[NurseAlgoStore] Unable to get next question: Algo not loaded');
      return undefined;
    }

    this.nurseAlgoContext = {
      stage: 'VITAL_DISTRESS',
      permanentAlgoCallReasonIds,
    };

    const initialGroup = nurseAlgoDataStore.groups.find(
      (group) =>
        group.initial === true && group.selectCallReason === false && group.callReason === undefined
    );
    if (initialGroup) {
      this.groupsToDisplay.setGroups([initialGroup]);
    }

    if (this.groupsToDisplay.groupsValues.length === 0) {
      throw new Error('Invalid data: Invalid groups configuration. An initial group must exist');
    }
  };

  @action('NURSE:RESET_ALGO')
  resetAlgo() {
    this.userResponses.clear();
    this.nurseAlgoContext = { ...this.nurseAlgoContext, stage: 'VITAL_DISTRESS' };
  }

  @action('NURSE:SET_USER_RESPONSE')
  setUserResponse = (groupId: number, userResponse: NurseAlgoResponse | NurseAlgoResponse[]) => {
    CommonAlgo.setInUserResponses(this.userResponses, groupId, userResponse);
  };

  @action('NURSE:GO_TO_PREVIOUS_QUESTION_GROUP')
  goToPreviousQuestionGroup = () => {
    const respondedGroupIds = Array.from(this.userResponses.keys());
    const minus1GroupId = respondedGroupIds.pop();
    const minus2GroupId = respondedGroupIds.pop();
    const minus3GroupId = respondedGroupIds.pop();
    if (minus1GroupId) {
      // Synchronisation de l'étape du context
      const minus2Group = nurseAlgoDataStore.groups.find((group) => group.Id === minus2GroupId);
      if (minus2Group?.initial) {
        // si le precedent groupe est le groupe initial. On repasse à l'etape 'VITAL_DISTRESS'
        this.nurseAlgoContext.stage = 'VITAL_DISTRESS';
      }

      if (minus2Group?.selectCallReason) {
        // si le precedent groupe est le groupe de selection de la thèmatique. On repasse à l'etape 'SELECT_REASON'
        this.nurseAlgoContext.stage = 'SELECT_REASON';
      }
      // Suppression des dernières réponses pour nettoyer le résultat
      this.userResponses.delete(minus1GroupId);

      if (minus3GroupId && this.switchToVerticalModeForGroup(minus3GroupId)) {
        // Parce que en fait, le mode vertical doit etre traité comme un stage 'SELECT_REASON'
        this.nurseAlgoContext.stage = 'SELECT_REASON';
        return;
      }
      // Récupération de la question routée par les réponses de la question N - 2
      const previousQuestionsGroups = this.getNextNurseQuestionsGroups(
        minus2GroupId || minus1GroupId
      );

      this.groupsToDisplay.setGroups(previousQuestionsGroups.nextQuestionsGroups.parents);
    }
  };

  @computed
  private get userResponsesFlat(): NurseAlgoResponse[] {
    const groupResponses = Array.from(this.userResponses.values());
    return groupResponses.map((groupResponse) => Array.from(groupResponse.values()).flat()).flat();
  }

  @computed
  get pendingGroupNames(): UIPendingGroup[] {
    return CommonAlgo.getPendingGroupNames(this.groupsToDisplay, this.isFulfilledGroup);
  }

  @computed
  get currentQuestionsGroup(): UIQuestionsGroup | undefined {
    const groupToDisplay = CommonAlgo.getGroupToDisplay(
      this.groupsToDisplay,
      this.isFulfilledGroup,
      {
        includeSubGroups: true,
      }
    );

    if (!groupToDisplay) {
      return undefined;
    }
    const { group, isSubGroup } = groupToDisplay;
    return {
      groupId: group.Id,
      groupName: group.name,
      questions: CommonAlgo.getUIWithResponsesQuestionForGroup(
        group.Id,
        nurseAlgoDataStore.questions,
        nurseAlgoDataStore.responses
      ),
      isSubGroup,
      first: !!group.initial,
      isVertical: this.isCurrentGroupVerticalMode,
      pendingGroups: this.pendingGroupNames,
    };
  }

  @computed
  private get userSelectedResponses(): NurseAlgoResponse[] {
    return Array.from(this.userResponses.values())
      .map((groupResponse) => Array.from(groupResponse.values()).flat())
      .flat();
  }

  private userSelectedResponsesForGroup(groupId: number): NurseAlgoResponse[] {
    const userResponsesForGroup = this.userResponses.get(groupId)!;
    if (!userResponsesForGroup) {
      return [];
    }
    return Array.from(userResponsesForGroup.values())
      .map((groupResponse) => Array.from(groupResponse.values()).flat())
      .flat();
  }

  switchToVerticalModeForGroup(groupId: number): boolean {
    return this.userSelectedResponsesForGroup(groupId).some(
      (response) => response.verticalTargetRoutes
    );
  }
  @computed
  get isCurrentGroupVerticalMode() {
    const lastGroupId = Array.from(this.userResponses.keys()).pop();
    if (lastGroupId === undefined) {
      return false;
    }
    return this.switchToVerticalModeForGroup(lastGroupId);
  }
  @computed
  get isPreviousGroupVerticalMode() {
    const userResponseIds = Array.from(this.userResponses.keys());
    const previousGroupId = userResponseIds[userResponseIds.length - 2];
    if (previousGroupId === undefined) {
      return false;
    }
    return this.switchToVerticalModeForGroup(previousGroupId);
  }

  @computed
  get verticalModeSubGroupContext(): UIVerticalModeSubGroupContext | undefined {
    return CommonAlgo.getVerticalModeSubGroupContext(
      this.groupsToDisplay,
      this.isFulfilledGroup,
      this.userResponses,
      nurseAlgoDataStore.questions,
      nurseAlgoDataStore.responses
    );
  }

  private isFulfilledGroup = (
    groupId: number,
    { includeSubGroups }: { includeSubGroups: boolean }
  ): boolean => {
    if (includeSubGroups) {
      const incompleteSubGroup = this.groupsToDisplay
        .getGroupById(groupId)
        ?.subGroups.find(
          (subGroup) => !this.isFulfilledGroup(subGroup.Id, { includeSubGroups: false })
        );

      if (incompleteSubGroup) {
        return false;
      }
    }
    const forGroupQuestions = CommonAlgo.getUIWithResponsesQuestionForGroup(
      groupId,
      nurseAlgoDataStore.questions,
      nurseAlgoDataStore.responses
    );
    const forGroupResponses = this.userResponses.get(groupId);
    if (!forGroupResponses) {
      return false;
    }

    return forGroupResponses.size === forGroupQuestions.length;
  };

  private isFulfilledGroupsToDisplay = (): boolean => {
    return !CommonAlgo.getGroupToDisplay(this.groupsToDisplay, this.isFulfilledGroup, {
      includeSubGroups: true,
    });
  };

  @computed
  get allQuestionsGroups(): UIQuestionsGroup[] {
    const groups = this.groupsToDisplay.groupsValues;
    return groups.map((group) => ({
      groupId: group.Id,
      groupName: group.name,
      questions: CommonAlgo.getUIWithResponsesQuestionForGroup(
        group.Id,
        nurseAlgoDataStore.questions,
        nurseAlgoDataStore.responses
      ),
      isSubGroup: false,
    }));
  }

  @computed
  get diagnostic(): NurseAlgoDiagnostic | undefined {
    if (this.userResponses.size === 0 || this.currentQuestionsGroup) {
      return undefined;
    }
    const resultDiagnostic = nurseAlgoDataStore.resultDiagnostics.find((resultDiagnostic) =>
      responsesMatchCombinaison(resultDiagnostic, this.userSelectedResponses)
    );
    return resultDiagnostic?.diagnostic;
  }

  @computed
  get score(): number | undefined {
    const allUserResponses = this.userResponsesFlat;
    if (!this.userResponsesFlat.some((userResponse) => userResponse.score !== undefined)) {
      // Si aucune des réponse de l'utilisateur ne comprend un score. On ne calcul rien
      return undefined;
    }
    return calculateScore(
      allUserResponses,
      allUserResponses.map(({ Id }) => Id) // Calcul du score sur l'ensemble des réponses de l'utilisateur
    );
  }

  @computed
  get algoRunResult(): UINurseAlgoRunResult | undefined {
    if (!this.diagnostic) {
      return undefined;
    }
    return {
      diagnostic: this.diagnostic,
      score: this.score,
    };
  }

  get algoLoaded() {
    return nurseAlgoDataStore.algoLoaded;
  }
}

export const nurseAlgoStore = new NurseAlgoStore();
