import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import { AlgoErrorBoundary } from 'components/algorithm/AlgoErrorBoundary';
import { Spacer } from 'components/Spacer';
import React, { FC } from 'react';
import { Arrow, ArrowCurved } from './Arrrow';

const Quote = styled.span`
  font-style: italic;
`;

const List = styled.ul`
  margin: 0 0.5rem;
`;

const ListItem = styled.li`
  line-height: 1.2rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`;
const GreenText = styled.span`
  font-size: 1.2rem;
  color: #b7d6a3;
  font-weight: bold;
`;

const OrangeText = styled.span`
  font-size: 1.2rem;
  color: orange;
  font-weight: bold;
`;
interface PermanentAlgoWelcomeProps {
  algoActivated: boolean;
  onStartAlgo: () => void;
}
export const PermanentAlgoWelcome: FC<PermanentAlgoWelcomeProps> = ({
  algoActivated,
  onStartAlgo,
}) => {
  return (
    <AlgoErrorBoundary>
      <AlgoContainer
        title="Démarche de la prise en charge d'un appel"
        renderActions={
          <ButtonContainer>
            <Button
              variant="contained"
              color={'secondary'}
              disabled={!algoActivated}
              onClick={onStartAlgo}
            >
              Identifier la priorité de l'appel
            </Button>
          </ButtonContainer>
        }
      >
        <div>
          <Typography variant="subtitle2">
            <b>1. Présentez-vous</b>
          </Typography>
          <Quote>« Bonjour je suis [votre prénom] du CAN, je vais m'occuper de votre appel »</Quote>
        </div>

        <div>
          <Typography variant="subtitle2">
            <b>2. Demander les raisons de l'appel</b>
          </Typography>
          <List>
            <ListItem>
              Prenez des notes de ce que dit l'appelant dans le champs{' '}
              <Quote>« circonstance de l'appel »</Quote>
            </ListItem>
            <ListItem>
              Selectionner le type d'appel correspondant en fonction de la nature de l'appel
            </ListItem>
          </List>
          <ArrowContainer>
            <Arrow fillColor="#b7d6a3" textLines={["Demande d'information", 'Appel nuisible']} />
            <Spacer direction="horizontal" size="1rem" />
            <GreenText>Valider la fiche d'appel</GreenText>
          </ArrowContainer>
          <ArrowContainer>
            <Arrow fillColor="orange" textLines={['Transfert de patient']} />
            <Spacer direction="horizontal" size="1rem" />
            <OrangeText>Saisir info transfert</OrangeText>
          </ArrowContainer>
          <ArrowContainer>
            <ArrowCurved fillColor="#adcdea" textLines={["Appel d'urgence"]} />
          </ArrowContainer>
        </div>

        <div>
          <Typography variant="subtitle2">
            <b>3. Demander le nom , Prénom et Nº de téléphone de l'appelant</b>
          </Typography>
          <List>
            <ListItem>Cela vous permettera de le recontacter en cas de besoin</ListItem>
            <ListItem>
              Renseigner le formulaire <Quote>« Appelant »</Quote> avec ces informations
            </ListItem>
          </List>
        </div>
        <div>
          <Typography variant="subtitle2">
            <b>
              4. Si l'appelant est lui-même la victime, cochez la case{' '}
              <Quote>« est la victime »</Quote>
            </b>
          </Typography>
          <List>
            <ListItem>Cela vous évitera de saisir le nom et prénom de la victime</ListItem>
          </List>
        </div>
        <div>
          <Typography variant="subtitle2">
            <b>5. Demandez le Nom, Prénom, Âge et Sexe de la victime</b>
          </Typography>
          <List>
            <ListItem>
              Renseigner le formulaire <Quote>« Victime »</Quote> avec ces informtions
            </ListItem>
          </List>
        </div>
        <div>
          <Typography variant="subtitle2">
            <b>6. Demandez l'addresse où se trouve la victime</b>
          </Typography>
          <List>
            <ListItem>Permet au véhicule ambulanciers de trouver la victime</ListItem>
            <ListItem>
              Renseignez le formulaire <Quote>« Localisation »</Quote>. Soyez le plus précis
              possible.
            </ListItem>
          </List>
        </div>
        <div>
          <Typography variant="subtitle2">
            <b>7. Identifiez la priorité de l'appel</b>
          </Typography>
          <List>
            <ListItem>
              Cliquez sur le bouton ci-dessous, répondez aux questions puis validez la fiche d'appel
            </ListItem>
          </List>
        </div>
      </AlgoContainer>
    </AlgoErrorBoundary>
  );
};
