import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { Spacer } from 'components/Spacer';
import { action, reaction } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { FacilityAnswer, RegulationMeans } from 'model/Call';
import Facility, {
  FacilityCategory,
  FacilitySpeciality,
  FacilityType,
} from 'model/facilities/Facility';
import { DiagnosticDialog } from 'pages/calls/common/DiagnosticDialog/DiagnosticDialog';
import { FacilityAnswerDialog } from 'pages/calls/SelectFacility/FacilityAnswerDialog';
import { RegulationMeansAnswerDialog } from 'pages/calls/SelectFacility/RegulationMeansAnswerDialog';
import { SearchFacilityFilterProps } from 'pages/facilities/facilities.list.model';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FacilitySearch, SearchFacilityFilters } from 'services/facilitySearch.service';
import { nurseCallStore } from 'stores/call/nurseCall.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { FORM_MODE } from 'utils/forms';
import { victimDetailsStore } from './victim.details.store';

const URGENCY_SPECIALTY_ID = 1;

interface LocalStoreProps extends SearchFacilityFilterProps {
  facilities: Facility[];
  diagnosticDialogOpen: boolean;
  facilityAnswerDialogOpen: boolean;
  regulationMeansAnswerDialogOpen: boolean;
  facilityAnswer: FacilityAnswer;
  selectedFacilityId?: number;
  noMatchingFacilitiesInDepartment: boolean;
  noMatchingCriteriaFacilities: boolean;
}
type RestoreMessageFlag = { restoreMessage: boolean };

function localSoreToSearchFacilityFilters(store: LocalStoreProps): SearchFacilityFilters {
  return {
    search: store.search,
    departmentIshicode:
      store.selectedDepartmentIshicode !== '' ? store.selectedDepartmentIshicode : undefined,
    category: store.selectedCategory !== '' ? store.selectedCategory : undefined,
    specialtyIds: store.selectedSpecialtyIds,
    equipmentIds: store.selectedEquipmentIds,
    requiredBloodLevel: store.sufficientBloodLevel,
    requiredOxygenLevel: store.sufficientOxygenLevel,
    type: store.selectedType || undefined,
  };
}
/**
 * Duplicated from "facility.list.page.tsx" for planning reasons. TODO => Factorize
 * Sorry :|
 */
export const SelectFacilityPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory<{
    filters: Partial<SearchFacilityFilterProps>;
    mode: FORM_MODE;
  }>();
  const { t } = useTranslation();

  const store = useLocalStore<LocalStoreProps>(() => ({
    facilities: [],
    selectedDepartmentIshicode: '',
    selectedType: '',
    selectedCategory: '',
    selectedSpecialtyIds: [],
    selectedEquipmentIds: [],
    sufficientBloodLevel: false,
    sufficientOxygenLevel: false,
    search: '',
    diagnosticDialogOpen: false,
    facilityAnswer: FacilityAnswer.NOT_SPECIFIED,
    facilityAnswerDialogOpen: false,
    regulationMeansAnswerDialogOpen: false,
    noMatchingFacilitiesInDepartment: false,
    noMatchingCriteriaFacilities: false,
    ...history.location.state?.filters,
  }));

  const doSearch = React.useCallback(
    ({ restoreMessage }: RestoreMessageFlag = { restoreMessage: true }) => {
      if (restoreMessage) {
        store.noMatchingCriteriaFacilities = false;
        store.noMatchingFacilitiesInDepartment = false;
      }
      store.facilities = FacilitySearch.search(localSoreToSearchFacilityFilters(store));
    },
    [store]
  );

  React.useEffect(() => {
    store.facilities = facilityStore.enabledFacilitiesArray;

    // Initial search
    const patientDepartmentId = store.selectedDepartmentIshicode;
    doSearch({ restoreMessage: false });
    //Si aucun résultat ne remonte, alors supprimer le critère “Département de localisation du patient” et afficher uniquement les résultats correspondant aux services d’orientation requis.
    if (store.facilities.length === 0) {
      store.selectedDepartmentIshicode = '';
      store.noMatchingFacilitiesInDepartment = true;
      doSearch({ restoreMessage: false });
    }

    //Si aucun résultat ne remonte alors afficher uniquement les établissements disposant des critères suivant ("Département de localisation" "Service d’urgence")
    if (store.facilities.length === 0) {
      store.selectedDepartmentIshicode = patientDepartmentId;
      store.noMatchingCriteriaFacilities = true;
      store.noMatchingFacilitiesInDepartment = false;

      store.selectedSpecialtyIds = [URGENCY_SPECIALTY_ID];
      doSearch({ restoreMessage: false });
    }

    if (store.facilities.length === 0) {
      store.noMatchingCriteriaFacilities = false;
    }
  }, [store, doSearch]);

  React.useEffect(() => {
    /* Using reaction instead of autorun in order to have fine-grained
     * control over what triggers the search and when.
     */
    const keywordsReaction = reaction(
      () => store.search,
      () => doSearch(),
      {
        delay: 800,
        fireImmediately: false,
      }
    );
    const departmentReaction = reaction(
      () => store.selectedDepartmentIshicode,
      () => doSearch()
    );
    const categoryReaction = reaction(
      () => store.selectedCategory,
      () => doSearch()
    );
    const typeReaction = reaction(
      () => store.selectedType,
      () => doSearch()
    );
    const servicesReaction = reaction(
      () => store.selectedSpecialtyIds,
      () => doSearch()
    );
    const equipmentsReaction = reaction(
      () => store.selectedEquipmentIds,
      () => doSearch()
    );

    return () => {
      keywordsReaction();
      departmentReaction();
      categoryReaction();
      typeReaction();
      servicesReaction();
      equipmentsReaction();
    };
  }, [store, doSearch, history]);

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.search = event.target.value;
  };

  const handleSelectSpeciality = (event: React.ChangeEvent<{ value: number[] }>) => {
    store.selectedSpecialtyIds = event.target.value;
  };

  const handleSelectEquipment = (event: React.ChangeEvent<{ value: number[] }>) => {
    store.selectedEquipmentIds = event.target.value;
  };

  const handleSelectType = (event: React.ChangeEvent<{ value: FacilityType }>) => {
    store.selectedType = event.target.value;
  };

  const handleSelectCategory = (event: React.ChangeEvent<{ value: FacilityCategory }>) => {
    store.selectedCategory = event.target.value;
  };

  const handleSelectDepartment = action((event: React.ChangeEvent<{ value: string }>) => {
    store.selectedDepartmentIshicode = event.target.value;
  });

  const handleSelectFacility = (facilityId: number) => {
    store.selectedFacilityId = facilityId;
    store.facilityAnswerDialogOpen = true;
    if (victimDetailsStore.victim) {
      victimDetailsStore.victim.facility = facilityStore.getFacilityById(facilityId);
    }
  };

  const handleSelectFacilityAnswer = (facilityAnswer: FacilityAnswer) => {
    store.facilityAnswer = facilityAnswer;
    store.facilityAnswerDialogOpen = false;
    store.regulationMeansAnswerDialogOpen = true;
  };

  const handleFinishRegulation = async (regulationMeansAnswer?: RegulationMeans) => {
    history.replace(
      `/evenements/victimes/${
        history.location.state.mode === FORM_MODE.CREATE ? 'nouveau' : 'consultation'
      }`,
      history.location.state
    );
  };

  const handleClickCancel = () => {
    history.replace(
      `/evenements/victimes/${
        history.location.state.mode === FORM_MODE.CREATE ? 'nouveau' : 'consultation'
      }`,
      history.location.state
    );
  };

  const handleClickFinishRegulation = () => {
    if (store.selectedFacilityId) {
      store.facilityAnswerDialogOpen = true;
    } else {
      store.regulationMeansAnswerDialogOpen = true;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={8}>
            <Typography variant="h4" component="h1">
              Orienter la victime
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                store.diagnosticDialogOpen = true;
              }}
              variant="outlined"
              color="primary"
            >
              Afficher le diagnostique
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={5}>
            <Observer>
              {() => (
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Rechercher un établissement"
                  value={store.search}
                  onChange={handleSearchBarChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Observer>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    required
                    fullWidth
                    size="small"
                    label={'Département'}
                    onChange={handleSelectDepartment}
                    value={store.selectedDepartmentIshicode}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <i>Aucune sélection</i>
                    </MenuItem>
                    {Array.from(locationStore.departments.values()).map(({ data }) => (
                      <MenuItem key={data.id} value={data.ihsiCode}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    required
                    fullWidth
                    size="small"
                    label={'Type'}
                    onChange={handleSelectType}
                    value={store.selectedType}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <i>Aucune sélection</i>
                    </MenuItem>
                    {Object.entries(FacilityType).map(([label, value], index) => (
                      <MenuItem key={index} value={value}>
                        {t(`facilities.types.${label}`)}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
            <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    required
                    fullWidth
                    size="small"
                    label={'Catégorie'}
                    onChange={handleSelectCategory}
                    value={store.selectedCategory}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <i>Aucune sélection</i>
                    </MenuItem>
                    {Object.entries(FacilityCategory).map(([label, value], index) => (
                      <MenuItem key={index} value={value}>
                        {t(`facilities.categories.${label}`)}
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>

            <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    required
                    fullWidth
                    size="small"
                    label={'Service(s)'}
                    multiple
                    renderValue={(value: any) =>
                      facilityStore.specialitiesArray
                        .filter((speciality) => value.includes(speciality.Id))
                        .map((specialty) => specialty.name)
                        .join(', ')
                    }
                    onChange={handleSelectSpeciality}
                    value={store.selectedSpecialtyIds}
                  >
                    {Array.from(facilityStore.specialitiesArray).map((specialty) => (
                      <MenuItem key={specialty.Id} value={specialty.Id}>
                        <Checkbox checked={store.selectedSpecialtyIds?.includes(specialty.Id)} />
                        <ListItemText primary={specialty.name} />
                      </MenuItem>
                    ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>

            <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Observer>
                {() => (
                  <MaterialSelectInput
                    required
                    fullWidth
                    multiple
                    size="small"
                    label={'Équipement(s)'}
                    renderValue={(value: any) =>
                      facilityStore.equipmentsArray
                        .filter((equipment) => value.includes(equipment.Id))
                        .map((equipment) => equipment.name)
                        .join(', ')
                    }
                    onChange={handleSelectEquipment}
                    value={store.selectedEquipmentIds}
                  >
                    {facilityStore.equipmentsArray
                      .filter((equipment) => equipment.enabled)
                      .map((equipment) => (
                        <MenuItem key={equipment.Id} value={equipment.Id}>
                          <Checkbox checked={store.selectedEquipmentIds?.includes(equipment.Id)} />
                          <ListItemText primary={equipment.name} />
                        </MenuItem>
                      ))}
                  </MaterialSelectInput>
                )}
              </Observer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Observer>
          {() => (
            <>
              {store.noMatchingFacilitiesInDepartment && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    <WarningIcon fontSize="small" color="secondary" /> Aucun établissement
                    correspondant n’existe dans le département de la victime
                  </Typography>
                </>
              )}
              {store.noMatchingCriteriaFacilities && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    <WarningIcon fontSize="small" color="secondary" />
                    Aucun établissement correspondant n’existe sur le pays, voici les services
                    d’urgence les plus proches
                  </Typography>
                </>
              )}
              {store.facilities.length === 0 && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    Aucun établissement ne répond à l’ensemble de ces critères. Veuillez modifier
                    votre recherche
                  </Typography>
                </>
              )}
              {store.facilities.map((facility, index) => {
                return (
                  <Paper key={index} variant="outlined" className={classes.card}>
                    <Grid container justify="space-between" className={classes.paper}>
                      <Grid item xs={6}>
                        <Typography variant="h6">{facility.name}</Typography>
                        <Typography variant="overline">{facility.type}</Typography>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
                          {facility.address}
                          <Typography component="div" variant="subtitle1">
                            {facility.locationCity?.name} ({facility.locationDepartment?.name})
                          </Typography>
                        </div>
                      </Grid>
                      <Grid container item xs={2} alignContent="flex-end">
                        <Button
                          variant={
                            store.selectedFacilityId === facility.Id ? 'contained' : 'outlined'
                          }
                          color="primary"
                          onClick={() => handleSelectFacility(facility.Id)}
                        >
                          Selectionner
                        </Button>
                      </Grid>
                    </Grid>

                    <Divider />
                    <Grid container className={classes.paper}>
                      <Grid item xs={12}>
                        <Typography
                          component="div"
                          variant="subtitle2"
                          className={classes.subtitle}
                        >
                          Services
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {facility.specialities
                          ?.map((faspe: FacilitySpeciality) => faspe?.speciality?.name)
                          .join(', ')}
                      </Grid>
                    </Grid>

                    <Divider />
                    <div className={classes.paper}>
                      <Typography variant="overline" className={classes.equipments}>
                        {facility.equipments?.map((eq) => eq.name).join(', ')}
                      </Typography>
                    </div>
                  </Paper>
                );
              })}
            </>
          )}
        </Observer>
      </Grid>
      <StickyButtonContainer>
        <Button variant="contained" onClick={handleClickCancel}>
          Annuler
        </Button>
        <Spacer direction="horizontal" size="1rem" />
        <Button color="primary" variant="contained" onClick={handleClickFinishRegulation}>
          <CheckIcon /> Terminer la régulation
        </Button>
      </StickyButtonContainer>
      <Observer>
        {() => (
          <DiagnosticDialog
            algoRunResult={nurseCallStore.pendingCall?.algoRunResult}
            open={store.diagnosticDialogOpen}
            onClose={() => {
              store.diagnosticDialogOpen = false;
            }}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <FacilityAnswerDialog
            open={store.facilityAnswerDialogOpen}
            onValidate={handleSelectFacilityAnswer}
            onClose={() => {
              store.facilityAnswerDialogOpen = false;
            }}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <RegulationMeansAnswerDialog
            open={store.regulationMeansAnswerDialogOpen}
            onValidate={handleFinishRegulation}
            onClose={() => {
              store.selectedFacilityId = undefined;
              store.regulationMeansAnswerDialogOpen = false;
            }}
          />
        )}
      </Observer>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
    },
    card: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    subtitle: {
      fontSize: '1rem',
    },
    equipments: {
      fontSize: '.8rem',
      fontWeight: 500,
    },
  })
);
const StickyButtonContainer = styled.div`
  position: sticky;
  bottom: 0.5rem;
  margin-left: auto;
  padding-top: 0.5rem;
  display: flex;
`;
