import React, { FC } from 'react';
import { Stepper, Step, StepLabel, Typography, makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';

interface QuestionTitleProps {
  parentTitle?: string;
  titles: { name?: string; answered: boolean; active: boolean }[];
}
const QuestionTitleContainer = styled.div`
  overflow-x: auto;
`;

const useStepperStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: '0',
  },
}));

export const QuestionTitle: FC<QuestionTitleProps> = ({ parentTitle, titles }) => {
  const stepperStyles = useStepperStyles();

  if (titles.length === 1) {
    return (
      <Typography variant="h5" color="textSecondary">
        {titles[0].name}
      </Typography>
    );
  }
  const activeStep = titles.findIndex((title) => title.active);
  return (
    <QuestionTitleContainer>
      <Stepper activeStep={activeStep} classes={stepperStyles} alternativeLabel>
        {titles.map((title) => (
          <Step key={title.name} completed={title.answered}>
            <StepLabel>
              <Typography variant="subtitle2" color="textSecondary">
                {title.name}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Typography variant="h5" color="textSecondary">
        {parentTitle}
      </Typography>
    </QuestionTitleContainer>
  );
};
