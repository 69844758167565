import { custom, identifier, list, primitive, reference, serializable } from 'serializr';
import { resolveNurseAlgoDiagnosticById } from 'stores/algo/nurse/nurseAlgo.resolvers';
import {
  AlgoCombinaison,
  algoCombinaisonDeserializer,
  algoCombinaisonSerializer,
  ScoreInclusion,
  scoreInclusionDeserializer,
  scoreInclusionSerializer,
} from '../AlgoCombinaison';
import { WithScoreMatchCombinaison } from '../MatchCombinaison';
import { NurseAlgoDiagnostic } from './Diagnostic';

export class NurseAlgoResultDiagnostic implements WithScoreMatchCombinaison {
  @serializable(identifier())
  private id?: number;

  @serializable(list(primitive()))
  responses?: number[];

  @serializable(custom(algoCombinaisonDeserializer, algoCombinaisonSerializer))
  combinaison?: AlgoCombinaison;

  @serializable
  scoreMax?: number;

  @serializable
  scoreMin?: number;

  @serializable(custom(scoreInclusionDeserializer, scoreInclusionSerializer))
  scoreInclusion?: ScoreInclusion;

  @serializable(reference(NurseAlgoDiagnostic, resolveNurseAlgoDiagnosticById as any)) // Super le typing de serializr :(
  diagnostic?: NurseAlgoDiagnostic;

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
