import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';
import { FacilityAnswer } from 'model/Call';

type FacilityAnswerDialogProps = {
  open: boolean;
  onClose: () => void;
  onValidate: (facilityAnswer: FacilityAnswer) => void;
};

export const FacilityAnswerDialog: FC<FacilityAnswerDialogProps> = ({
  open,
  onClose,
  onValidate,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<FacilityAnswer>();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Acceptation de l'établissement</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup value={selectedAnswer}>
            <FacilityAnswerRadio
              facilityAnswer={FacilityAnswer.ACCEPT}
              color="primary"
              onClick={() => setSelectedAnswer(FacilityAnswer.ACCEPT)}
            />
            <FacilityAnswerRadio
              facilityAnswer={FacilityAnswer.REFUSE}
              color="secondary"
              onClick={() => setSelectedAnswer(FacilityAnswer.REFUSE)}
            />

            <FacilityAnswerRadio
              facilityAnswer={FacilityAnswer.UNAVAILABLE}
              color="secondary"
              onClick={() => setSelectedAnswer(FacilityAnswer.UNAVAILABLE)}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          disabled={!selectedAnswer}
          onClick={() => {
            if (selectedAnswer) {
              onValidate(selectedAnswer);
            }
          }}
          color="primary"
          variant="contained"
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FacilityAnswerRadioProps = {
  facilityAnswer: FacilityAnswer;
  color?: 'primary' | 'secondary';
  onClick: () => void;
};
export function FacilityAnswerRadio({ facilityAnswer, color, onClick }: FacilityAnswerRadioProps) {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      value={facilityAnswer}
      control={<Radio color={color || 'default'} />}
      label={
        <Typography color={color}>{t(`orientations.facilityAnswer.${facilityAnswer}`)}</Typography>
      }
      onChange={() => onClick()}
    />
  );
}
