import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import MaterialSelectInput from 'components/common/form/MaterialSelectInput';
import { Spacer } from 'components/Spacer';
import { action, reaction, toJS } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import { NurseAlgoDiagnostic } from 'model/algo/nurse';
import { RegulationMeans } from 'model/Call';
import Facility, {
  FacilityCategory,
  FacilitySpeciality,
  FacilityType,
} from 'model/facilities/Facility';
import { Permission_V15, Profile } from 'model/User';
import { FacilityAnswer } from 'model/VictimRegulation';
import * as React from 'react';
import { BrowserView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FacilitySearch, SearchFacilityFilters } from 'services/facilitySearch.service';
import { authStore } from 'stores/auth/auth.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { NotificationType, uiStore } from 'stores/ui.store';
import { DiagnosticDialog } from '../common/DiagnosticDialog';
import { FacilityAnswerDialog } from '../SelectFacility/FacilityAnswerDialog';
import { RegulationMeansAnswerDialog } from '../SelectFacility/RegulationMeansAnswerDialog';
import { SearchFacilityFilterProps } from '../SelectFacility/SelectFacility.model';
import { callPostRegulationUiStore } from './call.postRegulation.ui.store';

interface LocalStoreProps {
  facilities: Facility[];
  diagnosticDialogOpen: boolean;
  facilityAnswerDialogOpen: boolean;
  regulationMeansAnswerDialogOpen: boolean;
  facilityAnswer: FacilityAnswer;
  selectedFacilityId?: number;
  noMatchingFacilitiesInDepartment: boolean;
  noMatchingCriteriaFacilities: boolean;
  selectedFacility?: Facility;
  selectedDiagnostic?: NurseAlgoDiagnostic;
}

const CallPostRegulationSelectFacilityPage: React.FC = () => {
  const history = useHistory<{ filters: SearchFacilityFilterProps } | undefined>();
  const params = useParams<{ uuid: string }>();

  const localStore = useLocalStore<LocalStoreProps>(() => ({
    facilities: [],
    diagnosticDialogOpen: false,
    facilityAnswerDialogOpen: false,
    regulationMeansAnswerDialogOpen: false,
    facilityAnswer: FacilityAnswer.NOT_SPECIFIED,
    selectedFacilityId: undefined,
    noMatchingFacilitiesInDepartment: false,
    noMatchingCriteriaFacilities: false,
    selectedDiagnostic: undefined,
    selectedFacility: undefined,
  }));

  React.useEffect(
    () => {
      if (!callPostRegulationUiStore.synchronizableCall) {
        console.error(
          'Cannot access page direclty - Must go through post regulation call management page'
        );
        history.replace(`/appels/postRegulation/${params.uuid}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const doSearch = action((filters: SearchFacilityFilterProps) => {
    let searchData: SearchFacilityFilters = {
      search: filters.search,
      departmentIshicode:
        filters.selectedDepartmentIshicode !== '' ? filters.selectedDepartmentIshicode : undefined,
      category: filters.selectedCategory !== '' ? filters.selectedCategory : undefined,
      specialtyIds: filters.selectedSpecialtyIds,
      equipmentIds: filters.selectedEquipmentIds,
      requiredBloodLevel: filters.sufficientBloodLevel,
      requiredOxygenLevel: filters.sufficientOxygenLevel,
      type: filters.selectedType || undefined,
    };
    // FacilitySearch.searchAsync(searchData).then((results) => (localStore.facilities = results));
    localStore.facilities = FacilitySearch.search(searchData);
  });

  const handleSelectFacility = action((facilityId: number) => {
    localStore.selectedFacilityId = facilityId;
    localStore.facilityAnswerDialogOpen = true;
  });

  const handleSelectFacilityAnswer = action((facilityAnswer: FacilityAnswer) => {
    localStore.facilityAnswer = facilityAnswer;
    localStore.facilityAnswerDialogOpen = false;
    localStore.regulationMeansAnswerDialogOpen = true;
  });

  const handleFinishRegulation = async (regulationMeansAnswer?: RegulationMeans) => {
    const nurseAlgoFacilityId = localStore.selectedFacilityId;

    try {
      if (!callPostRegulationUiStore.synchronizableCall) {
        throw new Error('Mandatory "call" is missing');
      }
      if (!localStore.facilityAnswer) {
        throw new Error('Mandatory facility answer');
      }
      if (!regulationMeansAnswer) {
        throw new Error('Mandatory regulation means selection');
      }
      if (nurseAlgoFacilityId) {
        callPostRegulationUiStore.synchronizableCall.data.facility = facilityStore.getFacilityById(
          nurseAlgoFacilityId
        );
        callPostRegulationUiStore.setNewSelectedFacility(nurseAlgoFacilityId);
        callPostRegulationUiStore.setNewSelectedFacilityAnswer(localStore.facilityAnswer);
      }
      callPostRegulationUiStore.setNewSelectedRegulationMeans(regulationMeansAnswer);
      history.goBack();
    } catch (error) {
      console.error(error);
      uiStore.pushNotification("Une erreur s'est produite", NotificationType.ERROR);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={8}>
            <Typography variant="h4" component="h1">
              Établissements
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Spacer direction="vertical" size="20px" />
      <Grid item xs={12}>
        <FacilitySearchFiltersView
          onSearch={doSearch}
          initialFilterValues={history.location.state?.filters}
        />
      </Grid>

      <Grid item xs={12}>
        <Observer>
          {() => (
            <>
              {localStore.noMatchingFacilitiesInDepartment && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    <WarningIcon fontSize="small" color="secondary" /> Aucun établissement
                    correspondant n’existe dans le département de la victime
                  </Typography>
                </>
              )}
              {localStore.noMatchingCriteriaFacilities && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    <WarningIcon fontSize="small" color="secondary" />
                    Aucun établissement correspondant n’existe sur le pays, voici les services
                    d’urgence les plus proches
                  </Typography>
                </>
              )}
              {localStore.facilities.length === 0 && (
                <>
                  <Spacer direction="vertical" size="8px" />
                  <Typography>
                    Aucun établissement ne répond à l’ensemble de ces critères. Veuillez modifier
                    votre recherche
                  </Typography>
                </>
              )}
              <FacilityListView
                facilities={localStore.facilities}
                onClickItem={handleSelectFacility}
              />
            </>
          )}
        </Observer>
      </Grid>

      <StickyButtonContainer>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box marginRight={1}>
            <Button variant="contained" onClick={history.goBack}>
              Annuler
            </Button>
          </Box>
          <Button
            color="primary"
            variant="contained"
            onClick={action(() => {
              localStore.selectedFacilityId
                ? (localStore.facilityAnswerDialogOpen = true)
                : (localStore.regulationMeansAnswerDialogOpen = true);
            })}
          >
            <CheckIcon /> Terminer la régulation
          </Button>
        </Box>
      </StickyButtonContainer>

      <Observer>
        {() => (
          <>
            {localStore.selectedDiagnostic && (
              <DiagnosticDialog
                algoRunResult={{ diagnostic: localStore.selectedDiagnostic }}
                open={localStore.diagnosticDialogOpen}
                onClose={() => {
                  localStore.diagnosticDialogOpen = false;
                }}
              />
            )}
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <FacilityAnswerDialog
            open={localStore.facilityAnswerDialogOpen}
            onValidate={handleSelectFacilityAnswer}
            onClose={() => {
              localStore.facilityAnswerDialogOpen = false;
            }}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <RegulationMeansAnswerDialog
            open={localStore.regulationMeansAnswerDialogOpen}
            onValidate={handleFinishRegulation}
            onClose={() => {
              localStore.selectedFacilityId = undefined;
              localStore.regulationMeansAnswerDialogOpen = false;
            }}
          />
        )}
      </Observer>
    </Grid>
  );
};

const FacilityListView = ({
  facilities,
  onClickItem,
}: {
  facilities: Facility[];
  onClickItem: (facilityId: number) => void;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.cardListWrapper}>
      {facilities.length === 0 && (
        <>
          <Spacer direction="vertical" size="8px" />
          <Typography>
            Aucun établissement ne répond à l’ensemble de ces critères. Veuillez modifier votre
            recherche
          </Typography>
        </>
      )}
      {facilities.map((facility, index) => (
        <Paper key={index} variant="outlined" className={classes.card}>
          <Grid container justify="space-between" className={classes.paper}>
            <Grid item xs={6}>
              <Typography component="div" variant="h6">
                {facility.name}
              </Typography>
              <Typography variant="overline">{facility.type}</Typography>
              <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
                {facility.address}
                <Typography component="div" variant="subtitle1">
                  {facility.locationCity?.name} ({facility.locationDepartment?.name})
                </Typography>
              </div>
            </Grid>
            {authStore.hasPermission(Permission_V15.FACILITIES_DASHBOARD) &&
              (!authStore.hasProfile(Profile.FACILITY_PERSONAL) ||
                authStore.authenticatedUser?.facilities.find((facId) => facId === facility.Id)) && (
                <BrowserView renderWithFragment={true}>
                  <Grid container item xs={3} justify="flex-end" alignContent="flex-start">
                    <Button
                      variant={
                        callPostRegulationUiStore.selectedFacility?.Id === facility.Id
                          ? 'contained'
                          : 'outlined'
                      }
                      color="primary"
                      onClick={() => onClickItem(facility.Id)}
                    >
                      Selectionner
                    </Button>
                  </Grid>
                </BrowserView>
              )}
          </Grid>

          <Divider />
          <Grid container className={classes.paper}>
            <Grid item xs={12}>
              <Typography component="div" variant="subtitle2" className={classes.subtitle}>
                Services
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {facility.specialities
                ?.map((faspe: FacilitySpeciality) => faspe?.speciality?.name)
                .join(', ')}
            </Grid>
          </Grid>

          <Divider />
          <div className={classes.paper}>
            <Typography variant="overline" className={classes.equipments}>
              {facility.equipments?.map((eq) => eq.name).join(', ')}
            </Typography>
          </div>
        </Paper>
      ))}
    </div>
  );
};

const FacilitySearchFiltersView: React.FC<{
  onSearch: (filters: SearchFacilityFilterProps) => void;
  initialFilterValues?: SearchFacilityFilterProps | undefined;
}> = ({ onSearch, initialFilterValues }) => {
  const { t } = useTranslation();
  const localStore = useLocalStore<SearchFacilityFilterProps>(() => ({
    selectedDepartmentIshicode: initialFilterValues?.selectedDepartmentIshicode || '',
    selectedType: initialFilterValues?.selectedType || '',
    selectedCategory: initialFilterValues?.selectedCategory || '',
    selectedSpecialtyIds: initialFilterValues?.selectedSpecialtyIds || [],
    selectedEquipmentIds: initialFilterValues?.selectedEquipmentIds || [],
    sufficientBloodLevel: initialFilterValues?.sufficientBloodLevel || false,
    sufficientOxygenLevel: initialFilterValues?.sufficientOxygenLevel || false,
    search: initialFilterValues?.search || '',
  }));

  const handleSearch = () => {
    onSearch({
      selectedDepartmentIshicode: localStore.selectedDepartmentIshicode,
      selectedType: localStore.selectedType,
      selectedCategory: localStore.selectedCategory,
      selectedSpecialtyIds: toJS(localStore.selectedSpecialtyIds),
      selectedEquipmentIds: toJS(localStore.selectedEquipmentIds),
      sufficientBloodLevel: localStore.sufficientBloodLevel,
      sufficientOxygenLevel: localStore.sufficientOxygenLevel,
      search: localStore.search,
    });
  };
  React.useEffect(() => {
    /*
     * Using reaction instead of autorun in order to have fine-grained
     * control over what triggers the search and when.
     */
    const keywordsReaction = reaction(() => localStore.search, handleSearch, {
      delay: 800,
      fireImmediately: true,
    });
    const departmentReaction = reaction(() => localStore.selectedDepartmentIshicode, handleSearch);
    const categoryReaction = reaction(() => localStore.selectedCategory, handleSearch);
    const typeReaction = reaction(() => localStore.selectedType, handleSearch);
    const servicesReaction = reaction(() => localStore.selectedSpecialtyIds, handleSearch, {
      delay: 400,
    });
    const equipmentsReaction = reaction(() => localStore.selectedEquipmentIds, handleSearch, {
      delay: 400,
    });

    return () => {
      keywordsReaction();
      departmentReaction();
      categoryReaction();
      typeReaction();
      servicesReaction();
      equipmentsReaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStore.search = event.target.value;
  };

  const handleSelectSpeciality = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedSpecialtyIds = event.target.value;
  };

  const handleSelectEquipment = (event: React.ChangeEvent<{ value: number[] }>) => {
    localStore.selectedEquipmentIds = event.target.value;
  };

  const handleSelectType = (event: React.ChangeEvent<{ value: FacilityType }>) => {
    localStore.selectedType = event.target.value;
  };

  const handleSelectCategory = (event: React.ChangeEvent<{ value: FacilityCategory }>) => {
    localStore.selectedCategory = event.target.value;
  };

  const handleSelectDepartment = action((event: React.ChangeEvent<{ value: string }>) => {
    localStore.selectedDepartmentIshicode = event.target.value;
  });

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} sm={5}>
        <Observer>
          {() => (
            <TextField
              fullWidth={true}
              variant="outlined"
              placeholder="Rechercher un établissement"
              value={localStore.search}
              onChange={handleSearchBarChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Observer>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Département'}
                onChange={handleSelectDepartment}
                value={localStore.selectedDepartmentIshicode}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Array.from(locationStore.departments.values()).map(({ data }) => (
                  <MenuItem key={data.id} value={data.ihsiCode}>
                    {data.name}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Type'}
                onChange={handleSelectType}
                value={localStore.selectedType}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityType).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.types.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
        <Grid item xs={6} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Catégorie'}
                onChange={handleSelectCategory}
                value={localStore.selectedCategory}
                displayEmpty
              >
                <MenuItem value="">
                  <i>Aucune sélection</i>
                </MenuItem>
                {Object.entries(FacilityCategory).map(([label, value], index) => (
                  <MenuItem key={index} value={value}>
                    {t(`facilities.categories.${label}`)}
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>

        <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                size="small"
                label={'Service(s)'}
                multiple
                renderValue={(value: any) =>
                  facilityStore.specialitiesArray
                    .filter((speciality) => value.includes(speciality.Id))
                    .map((specialty) => specialty.name)
                    .join(', ')
                }
                onChange={handleSelectSpeciality}
                value={localStore.selectedSpecialtyIds}
              >
                {Array.from(facilityStore.specialitiesArray).map((specialty) => (
                  <MenuItem key={specialty.Id} value={specialty.Id}>
                    <Checkbox checked={localStore.selectedSpecialtyIds?.includes(specialty.Id)} />
                    <ListItemText primary={specialty.name} />
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>

        <Grid item xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Observer>
            {() => (
              <MaterialSelectInput
                required
                fullWidth
                multiple
                size="small"
                label={'Équipement(s)'}
                renderValue={(value: any) =>
                  facilityStore.equipmentsArray
                    .filter((equipment) => value.includes(equipment.Id))
                    .map((equipment) => equipment.name)
                    .join(', ')
                }
                onChange={handleSelectEquipment}
                value={localStore.selectedEquipmentIds}
              >
                {facilityStore.equipmentsArray.map((equipment) => (
                  <MenuItem key={equipment.Id} value={equipment.Id}>
                    <Checkbox checked={localStore.selectedEquipmentIds?.includes(equipment.Id)} />
                    <ListItemText primary={equipment.name} />
                  </MenuItem>
                ))}
              </MaterialSelectInput>
            )}
          </Observer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 900,
    },
    cardListWrapper: {
      display: 'flex',
      overflow: 'hidden',
      overflowY: 'auto',
      flexDirection: 'column',
      maxHeight: 'calc(100vh - 280px)',
      paddingRight: 8,
    },
    card: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      '&:first-child': { marginTop: 0 },
    },
    paper: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    subtitle: {
      fontSize: '1rem',
    },
    equipments: {
      fontSize: '.8rem',
      fontWeight: 500,
    },
  })
);

const StickyButtonContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  width: '100%',
  bottom: theme.spacing(1),
  marginLeft: 'auto',
  paddingTop: theme.spacing(1),
}));

export default CallPostRegulationSelectFacilityPage;
