import React, { FC } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { AddAlert, PersonOutline } from '@material-ui/icons';

import clsx from 'clsx';
import { Spacer } from 'components/Spacer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AuthenticatedUser } from 'stores/auth/auth.store';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface AppMenuDrawerProps {
  handleDrawer: (isOpen: boolean) => () => any;
  onClickLink: (link: string) => () => any;
  isCurrentPath: (path: string) => any;
  onLogout: () => any;
  isMenuOpen: boolean;
  isAuthenticated?: boolean;
  user: AuthenticatedUser | null;
}
const AppMenuDrawer: FC<AppMenuDrawerProps> = ({
  handleDrawer,
  isCurrentPath,
  onClickLink,
  onLogout,
  isMenuOpen = false,
  isAuthenticated = false,
  user,
}) => {
  const classes = useStyles();
  const sideList = (
    <div className={clsx('printHide', classes.list)}>
      <div>
        {/* <ConnectivityIndicator /> */}
        <Spacer direction="vertical" size="1rem" />
        <UserIdentity user={user} />
        <Spacer direction="vertical" size="1rem" />
        <Divider orientation="horizontal" />
      </div>
      <List>
        <div onClick={onClickLink('/evenements')} className={classes.link}>
          <ListItem button key={'Signalement'} disabled={isCurrentPath('/evenements')}>
            <ListItemIcon>
              <AddAlert />
            </ListItemIcon>
            <ListItemText primary={'Évenements'} />
          </ListItem>
        </div>
        <Spacer direction="vertical" size="1rem" />
        <Divider flexItem />
        <div className={classes.link} onClick={onLogout}>
          <ListItem button key={'logout'}>
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText primary={'Déconnexion'} />
          </ListItem>
        </div>
      </List>
    </div>
  );

  return (
    <Drawer anchor="left" open={isMenuOpen} onClose={handleDrawer(false)}>
      <div tabIndex={0} role="button" onClick={handleDrawer(false)} onKeyDown={handleDrawer(false)}>
        {sideList}
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 260,
    },
    fullList: {
      width: 'auto',
    },
    link: {
      textDecoration: 'none',
    },
  })
);

interface UserIdentityProps {
  classes: any;
  user: AuthenticatedUser | null;
}

const UserIdentity = withStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
    },
    iconWrapper: {
      display: 'flex',
      padding: theme.spacing(0, 1),
      cursor: 'pointer',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  })
)(({ classes, user }: UserIdentityProps) => {
  const { t } = useTranslation();
  return useObserver(() => (
    <>
      <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
          <AccountCircleIcon />
        </div>
        <Typography variant="body2" noWrap>
          {t(`users.profiles.${user?.profile}`)}
        </Typography>
        <div className={classes.wrapper}>
          <Typography variant="body2" noWrap>
            <span className={classes.uppercase}>{`${user?.lastName} `}</span>
            {user?.firstName}
          </Typography>
        </div>
      </div>
    </>
  ));
});

export default AppMenuDrawer;
