import { HumanBody } from 'components/HumanBody';
import { CommonAlgoResponse, UIWithResponsesQuestion } from 'model/algo';
import React from 'react';
import { QuestionContainer } from '../QuestionContainer';

interface HumanBodyQuestionProps {
  question: UIWithResponsesQuestion;
  selected: CommonAlgoResponse[] | null;
  onResponse: (choice: CommonAlgoResponse[] | CommonAlgoResponse) => void;
}

export function HumanBodyQuestion({ question, selected, onResponse }: HumanBodyQuestionProps) {
  const handleResponses = (zoneIds: String[]) => {
    const selectedResponses = question.responses.filter((response) => {
      if (response.bodyKey === undefined) {
        throw new Error("Invalid response for HumanBodyQuestion. 'bodyKey' field is required");
      }
      return zoneIds.includes(response.bodyKey);
    });
    onResponse(selectedResponses);
  };
  const selectedZones =
    selected?.map((response) => {
      if (response.bodyKey === undefined) {
        throw new Error("Invalid response for HumanBodyQuestion. 'bodyKey' field is required");
      }
      return response.bodyKey;
    }) || [];
  return (
    <QuestionContainer renderLabel={question.label} description={question.description}>
      <HumanBody initialSelected={selectedZones} onResponse={handleResponses} />
    </QuestionContainer>
  );
}
