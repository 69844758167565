import axios from 'axios';
import Equipment from 'model/facilities/Equipment';

export async function getEquipment(equipmentId: string): Promise<Equipment> {
  try {
    const equipmentRes = await axios.get<Equipment>(`api/resources/${equipmentId}`);
    return equipmentRes.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEquipments() {
  return axios
    .post('api/resources/search')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function saveEquipment(newEquipment: Equipment): Promise<Equipment> {
  return axios
    .post('api/resources', newEquipment)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateEquipment(equipment: Equipment): Promise<Equipment> {
  return axios
    .put(`api/resources/${equipment.id}`, equipment)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function deleteEquipment(equipment: Equipment): Promise<Equipment> {
  return axios
    .delete(`api/resources/${equipment.id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}
