import React, { FC } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Loader: FC = () => (
  <LoaderContainer>
    <CircularProgress />
  </LoaderContainer>
);
