import * as React from 'react';
import { Notification, NotificationType, uiStore } from 'stores/ui.store';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useObserver } from 'mobx-react-lite';
import { autorun, runInAction } from 'mobx';
import { useTranslation } from 'react-i18next';

const NotificationsContainer = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<Notification | undefined>(undefined);

  React.useEffect(() => {
    autorun(() => {
      // Reacts when notifications array has new elements
      if (uiStore.notifications.length > 0) {
        processQueue();
      }
    });
    // eslint-disable-next-line
  }, []);

  const processQueue = () => {
    setMessageInfo(uiStore.notifications[0]);
    setOpen(true);
  };

  const handleExited = () => {
    // Remove notification once previous one has been dismissed
    runInAction(() => {
      uiStore.notifications.remove(messageInfo!);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return useObserver(() => {
    if (messageInfo) {
      let severity: 'error' | 'warning' | 'success';
      switch (messageInfo.type) {
        case NotificationType.ERROR:
          severity = 'error';
          break;
        case NotificationType.WARNING:
          severity = 'warning';
          break;
        case NotificationType.SUCCESS:
        default:
          severity = 'success';
      }
      return (
        <Snackbar
          open={isOpen}
          autoHideDuration={messageInfo.duration}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          onExited={handleExited}
          onClose={handleClose}
          ClickAwayListenerProps={{
            mouseEvent: false,
          }}
        >
          <Alert severity={severity} variant="filled">
            <AlertTitle>{t(`notifications.title.${messageInfo.type}`)}</AlertTitle>
            {messageInfo ? messageInfo.message : undefined}
          </Alert>
        </Snackbar>
      );
    } else {
      return <div />;
    }
  });
};

export default NotificationsContainer;
