import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import { AlgoErrorBoundary } from 'components/algorithm/AlgoErrorBoundary';
import { CallMotifContainer } from 'components/algorithm/CallMotifContainer';
import { CommonAlgo } from 'components/algorithm/CommonAlgo';
import { observer } from 'mobx-react-lite';
import Call, { CallMotif, CallType, toPermanentAlgoRunResult } from 'model/Call';
import React, { FC, useCallback, useState } from 'react';
import { NurseAlgoStore } from 'stores/algo/nurse';
import { callStore } from 'stores/call';
import { facilityStore } from 'stores/facility';
import { AlgoContainer } from '../../../../components/algorithm/AlgoContainer';
import { PermanentAlgoResult } from '../PermanentAlgo/PermanentAlgoResult';
import { TransferForm } from '../TransfertForm';
import { NurseAlgoResult } from './NurseAlgoResult/NurseAlgoResult';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface NurseAlgoDispatcherProps {
  nurseAlgoStore: NurseAlgoStore;
  call: Call;
  formCallType?: CallType;
  algoAvailable: boolean;
  onMotifSelection?: (motif: CallMotif) => void;
}

export const NurseAlgoDispatcher: FC<NurseAlgoDispatcherProps> = observer(
  ({ nurseAlgoStore, call, formCallType, algoAvailable = false, onMotifSelection }) => {
    const permanentAlgoResult = toPermanentAlgoRunResult(call);
    const currentQuestionsGroup = nurseAlgoStore.currentQuestionsGroup;
    const algoLoaded = nurseAlgoStore.algoLoaded;

    const [algoStarted, setAlgoStarted] = useState<boolean>(!permanentAlgoResult);
    const [selectedMotif, setSelectedMotif] = useState<boolean>(false);

    const handleClickStart = () => {
      setAlgoStarted(true);
    };

    const handleClickRestart = () => {
      nurseAlgoStore.resetAlgo();
      setAlgoStarted(true);
    };

    const handleMotifSelection = (motif: CallMotif) => {
      if (onMotifSelection) {
        onMotifSelection(motif);
        if (motif) setSelectedMotif(true);
      }
    };

    // Memoization de la fonction "startAlgo" pour ne pas la recréer à chaque render et éviter de rentrer dans une boucle infernale
    const startAlgo = useCallback(() => {
      const permanentAlgoCallReasonIds = call.permanentAlgoCallReasons
        ? (call.permanentAlgoCallReasons.map(({ id }) => id).filter(Boolean) as number[])
        : undefined;
      nurseAlgoStore.startAlgo(permanentAlgoCallReasonIds);
      setAlgoStarted(true);
    }, [nurseAlgoStore, call]);
    if (formCallType === CallType.TRANSFER) {
      const transferFacilities =
        call.transferFromFacility && call.transferToFacility
          ? {
              transferFromFacility: call.transferFromFacility,
              transferToFacility: call.transferToFacility,
            }
          : undefined;
      return (
        <TransferForm
          callStore={callStore}
          facilityStore={facilityStore}
          transferFacilities={transferFacilities}
        />
      );
    }
    if (permanentAlgoResult && !algoStarted) {
      return (
        <AlgoErrorBoundary>
          <PermanentAlgoResult
            algoRunResult={permanentAlgoResult}
            actionButton={
              <ButtonContainer>
                <Button variant="outlined" color="primary" onClick={handleClickStart}>
                  Poursuivre le diagnostique
                </Button>
              </ButtonContainer>
            }
          />
        </AlgoErrorBoundary>
      );
    }

    if (nurseAlgoStore.algoRunResult) {
      return (
        <NurseAlgoResult
          algoRunResult={nurseAlgoStore.algoRunResult}
          actionButton={
            <Button onClick={handleClickRestart} variant="outlined" color="secondary">
              Recommencer
            </Button>
          }
        />
      );
    }

    if (
      algoStarted &&
      algoLoaded &&
      !currentQuestionsGroup &&
      nurseAlgoStore.userResponses.size > 0
    ) {
      return (
        <AlgoContainer
          renderTitle={
            <Typography variant="h4" color="textSecondary">
              Une erreur est survenue
            </Typography>
          }
          renderActions={
            <Button onClick={handleClickRestart} variant="outlined" color="secondary">
              Recommencer
            </Button>
          }
        />
      );
    }

    if (formCallType === CallType.EMERGENCY && algoAvailable) {
      if (!selectedMotif) {
        return <CallMotifContainer onSelectMotif={handleMotifSelection} />;
      } else {
        return (
          <CommonAlgo
            algoLoaded={nurseAlgoStore.algoLoaded}
            allQuestionsGroups={nurseAlgoStore.allQuestionsGroups}
            currentUiQuestionsGroup={currentQuestionsGroup}
            uiVerticalModeSubGroupContext={nurseAlgoStore.verticalModeSubGroupContext}
            goToPreviousQuestionGroup={nurseAlgoStore.goToPreviousQuestionGroup}
            setUserResponse={nurseAlgoStore.setUserResponse}
            startAlgo={startAlgo}
          />
        );
      }
    }

    return (
      <AlgoContainer
        renderTitle={
          <Typography variant="h4" color="textSecondary">
            Renseigner les données de l'appel
          </Typography>
        }
      />
    );
  }
);
