import { Button, Grid, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({ dsn: sentryDsn, release: process.env.REACT_APP_VERSION });
}

export function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <Grid
      container
      item
      alignItems="center"
      justify="center"
      xs={12}
      direction="column"
      style={{ height: '100vh' }}
    >
      <Typography variant="h2">Une erreur est survenue</Typography>
      <Button variant="contained" color="primary" onClick={() => resetErrorBoundary()}>
        Réessayer
      </Button>
    </Grid>
  );
}

export function sentryReactErrorHandler(error: Error, componentStack: string) {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.captureException(error, {
      contexts: { react: { componentStack } },
    });
  }
}
