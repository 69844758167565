import { SeverityDegree } from 'model/Diagnostic';
import Equipment from 'model/facilities/Equipment';
import Speciality from 'model/facilities/Speciality';
import { identifier, list, object, primitive, reference, serializable } from 'serializr';
import { resolveNurseAlgoCardDocumentById } from 'stores/algo/nurse/nurseAlgo.resolvers';
import { resolveEquipmentById, resolveSpecialityById } from 'stores/facility/facility.resolvers';
import { NurseAlgoCardDocument } from './CardDocument';

export type NurseAlgoItemDisplayType = 'IMPORTANT' | 'SUBLIST' | 'SCORE';

export class NurseAgloDecision {
  @serializable
  displayType?: NurseAlgoItemDisplayType;

  @serializable
  content: string = '';
}

export class NurseAlgoSeverityIndex {
  @serializable
  displayType?: NurseAlgoItemDisplayType;

  @serializable
  content: string = '';
}

export class NurseAlgoBehavior {
  @serializable
  displayType?: NurseAlgoItemDisplayType;

  @serializable
  content?: string;

  @serializable(reference(NurseAlgoCardDocument, resolveNurseAlgoCardDocumentById as any)) // Super le typing de serializr :(
  documentId?: NurseAlgoCardDocument;
}

export class NurseAlgoOrientation {
  @serializable(list(primitive()))
  text: string[] = [];

  @serializable(list(reference(Speciality, resolveSpecialityById as any))) // Super le typing de serializr :(
  services: Speciality[] = [];

  @serializable(list(reference(Equipment, resolveEquipmentById as any))) // Super le typing de serializr :(
  equipments: Equipment[] = [];

  @serializable
  proximity = false;
}

export class NurseAlgoBookReference {
  @serializable(identifier())
  private id?: number;
  @serializable
  pageNumber: string = '';

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
export class NurseAlgoContent {
  @serializable(list(object(NurseAgloDecision)))
  decisions: NurseAgloDecision[] = [];

  @serializable(object(NurseAlgoOrientation))
  orientation?: NurseAlgoOrientation;

  @serializable(list(object(NurseAlgoSeverityIndex)))
  severityIndexes: NurseAlgoSeverityIndex[] = [];

  @serializable(list(object(NurseAlgoBehavior)))
  behaviors: NurseAlgoBehavior[] = [];
}

export class NurseAlgoDiagnostic {
  @serializable(identifier())
  private id?: number;

  @serializable
  name?: string;

  @serializable(object(NurseAlgoContent))
  content?: NurseAlgoContent;

  @serializable
  severityDegree?: SeverityDegree;

  @serializable(list(object(NurseAlgoBookReference)))
  bookReferences?: NurseAlgoBookReference[];

  get Id() {
    if (this.id === undefined) {
      throw new Error('Undefined field "id"');
    }
    return this.id;
  }
}
