import { action, autorun, computed, decorate, observable } from 'mobx';
import { Field } from '.';

class FieldArray extends Field {
  clear() {
    this._value = new Set();
  }

  setValue = action('SET_FIELD_VALUE', (val, interract = true) => {
    if (interract && !this._interacted) {
      this._interacted = true;
    }
    if (Array.isArray(val) || val instanceof Set) {
      this._value = new Set(Array.from(this._value).concat(val));
    } else if (this._value.has(val)) {
      // this._value.delete(val);
      this._value = new Set(this._value);
    } else {
      // this._value.add(val);
      this._value = new Set(this._value);
    }
  });

  onChange = (evt, value) => {
    this.setValue(value);
  };

  get errorMessage() {
    if (!this._interacted) {
      return '';
    }
    return this._validation.message;
  }

  constructor(props = {}) {
    super(props);
    const { initValue = '', validate, debounce = false } = props;

    this._value = new Set();
    this.setValue(initValue, false);

    this._isValidFn = [];
    if (validate) {
      if (validate instanceof Array) {
        this._isValidFn = validate;
      } else {
        this._isValidFn = [validate];
      }
    }

    if (this._isValidFn.length) {
      this._validation = { valid: false };
      autorun(this.validate, { delay: debounce ? 300 : 0 });
    } else {
      this._validation = { valid: true };
    }
  }
}

export default decorate(FieldArray, {
  _value: observable,
  _interacted: observable,
  _validation: observable,
  disabled: observable,
  value: computed,
  errorMessage: computed,
  isValid: computed,
});
