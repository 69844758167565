import { Grid, Typography } from '@material-ui/core';
import MaterialInput from 'components/common/form/MaterialInput';
import MaterialSwitch from 'components/common/form/MaterialSwitch';
import React, { FC } from 'react';
import { InputFieldProps, SwitchFieldProps } from 'utils/forms';
import { InfoSubBloc } from './FormBloc';

type CallerFormFieldsProps = {
  editing: boolean;
  callerIsVictimInputProps: SwitchFieldProps;
  callerPhoneInputProps: InputFieldProps;
  callerLastNameInputProps: InputFieldProps;
  callerFirstNameInputProps: InputFieldProps;
};
export const CallerFormFields: FC<CallerFormFieldsProps> = ({
  editing,
  callerIsVictimInputProps,
  callerPhoneInputProps,
  callerLastNameInputProps,
  callerFirstNameInputProps,
}) => {
  return (
    <InfoSubBloc>
      <Grid container justify="center">
        <Grid item xs={12} container justify="space-between">
          <Typography variant="button">Appelant</Typography>
          {editing && <MaterialSwitch label="Est la victime" {...callerIsVictimInputProps} />}
        </Grid>
        <Grid container spacing={1} item xs={12} justify="space-between">
          <Grid item xs={4}>
            <MaterialInput
              size="small"
              label="N° téléphone"
              required
              fullWidth
              {...callerPhoneInputProps}
              editing={editing}
              maxLength={8}
            />
          </Grid>
          <Grid item xs={4}>
            <MaterialInput
              size="small"
              label="Nom"
              fullWidth
              {...callerLastNameInputProps}
              editing={editing}
            />
          </Grid>
          <Grid item xs={4}>
            <MaterialInput
              size="small"
              label="Prénom"
              fullWidth
              {...callerFirstNameInputProps}
              editing={editing}
            />
          </Grid>
        </Grid>
      </Grid>
    </InfoSubBloc>
  );
};
