import {
  createStyles,
  FormControl,
  FormHelperText,
  makeStyles,
  Select,
  SelectProps,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

interface MaterialSelectInputProps extends SelectProps {
  classes?: any;
  id?: string;
  label: string;
  fullWidth?: boolean;
  type?: string;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  helperText?: string;
  displayEmpty?: boolean;
  size?: 'small' | 'medium';
  editing?: boolean;
  noHelper?: boolean;
}

const MaterialSelectInput: React.FC<MaterialSelectInputProps> = observer(
  ({
    id,
    label,
    placeholder,
    fullWidth = false,
    error = false,
    disabled = false,
    type = 'text',
    required = false,
    value = undefined,
    onChange,
    onBlur,
    helperText,
    children,
    displayEmpty = false,
    multiple = false,
    renderValue,
    size,
    editing = true,
    noHelper = false,
    ...rest
  }) => {
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<any>) => {
      onChange && onChange(event);
    };
    const displayValue = (value: any) => (renderValue && renderValue(value)) || value || 'N/R';

    return (
      <FormControl
        className={classes.formControl}
        fullWidth={fullWidth}
        error={error}
        disabled={disabled}
        required={editing && required}
        size={size}
      >
        <FormLabel className={classes.label} disabled={disabled}>
          {label}
        </FormLabel>
        {!editing && (
          <Tooltip title={label || ''} placement="top-start">
            <Typography>{displayValue(value)}</Typography>
          </Tooltip>
        )}
        {editing && (
          <>
            <Select
              id={id}
              className={classes.input}
              inputProps={{
                onBlur: onBlur,
              }}
              onChange={handleChange}
              onClose={onBlur}
              value={value}
              labelWidth={0}
              variant="outlined"
              fullWidth
              displayEmpty={displayEmpty}
              multiple={multiple}
              renderValue={renderValue}
            >
              {children}
            </Select>
            {!noHelper && (
              <FormHelperText className={classes.helperText} variant="outlined">
                {(!!error && helperText) || ' '}
              </FormHelperText>
            )}
          </>
        )}
      </FormControl>
    );
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      background: 'none',
      minWidth: 120,
    },
    input: {
      backgroundColor: 'white',
    },
    label: {
      paddingLeft: '1px',
      marginBottom: theme.spacing(0.5),
    },
    helperText: {
      margin: 0,
      marginTop: 0,
      fontSize: '11px',
      marginLeft: '1px',
    },
  })
);

export default MaterialSelectInput;
