export function formatPhoneNumber(str: string | undefined): string | undefined {
  if (str) {
    const input = str.replace(/\D{1,8}/g, ''); // First height digits of input only
    return input.substring(0, 8);
  } else {
    return str;
  }
}

export const formatNumberInput = (maxLength: number) => (str: string | undefined) => {
  if (str) {
    const input = str.replace(/[^0-9]+/g, '');
    return input.substring(0, maxLength);
  } else {
    return str;
  }
};
