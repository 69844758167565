import { Button, Divider, Grid, Theme, Typography, withStyles } from '@material-ui/core';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { Spacer } from 'components/Spacer';
import { action, autorun, reaction } from 'mobx';
import { Observer, useLocalStore } from 'mobx-react-lite';
import Call, {
  CallMotif,
  CallStatus,
  CallSynchronizableData,
  CallType,
  toPermanentAlgoRunResult,
} from 'model/Call';
import {
  CallPriorityRequiredError,
  NeedConfirmError,
  NeedTransferFacilitiesError,
} from 'model/Errors';
import { VictimAge } from 'model/Victim';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { mapFormDataToCall } from 'services/call.service';
import { permanentAlgoStore } from 'stores/algo/permanent';
import { callStore, callUIStore } from 'stores/call';
import { NotificationType, uiStore } from 'stores/ui.store';
import { getInputFieldProps, getSwitchFieldProps } from 'utils/forms';
import { formatNumberInput, formatPhoneNumber } from 'utils/forms/formatters';
import { convertAgeToAgeSlice } from 'utils/victim.utilities';
import callFormBuilder from '../common/call.form.builder';
import { CallConfirmSaveDialog } from '../common/CallConfirmSaveDialog';
import { CallerFormFields } from '../common/callFormFields/CallerFormFields';
import { CallTypeFormFields } from '../common/callFormFields/CallTypeFormFields';
import { LocationFormFields } from '../common/callFormFields/LocationFormFields';
import { PageTitle } from '../common/callFormFields/PageTitle';
import { VictimFormFields } from '../common/callFormFields/VictimFormFields';
import { PermanentAlgoDispatcher } from '../common/PermanentAlgo/PermanentAlgoDispatcher';

interface ILocalStore {
  synchronizableCall: CallSynchronizableData | undefined;
  callForm: any;
  bilanEditing: boolean;
  editing: boolean;
  requiredFieldsFilled: boolean;
  isSubmitButtonDisabled: boolean;
  setSynchronizableCall: (call: CallSynchronizableData | undefined) => void;
  setEditing: any;
  setBilanEditing: any;
  setRequiredFieldsFilled: any;
  setSubmitButtonDisabled: any;
  updateSynchronizableCall: (call: CallSynchronizableData) => void;
}

const CallPreRegulationPermanentPage: React.FC = () => {
  const history = useHistory<{ call?: CallSynchronizableData } | undefined>();
  const { uuid } = useParams<{ uuid?: string }>();
  const { t } = useTranslation();
  const deactivatePromptRef = React.useRef(true);

  const localStore = useLocalStore<ILocalStore>(() => ({
    synchronizableCall: undefined,
    callForm: callFormBuilder({}),
    bilanEditing: false,
    editing: true,
    requiredFieldsFilled: false,
    isSubmitButtonDisabled: false,
    setSynchronizableCall(call: CallSynchronizableData | undefined) {
      this.synchronizableCall = call;
      if (call) {
        this.callForm = callFormBuilder({
          initialValues: call.data,
        });
      }
    },
    setEditing(editing: boolean) {
      this.editing = editing;
    },
    setBilanEditing(bilanEditing: boolean) {
      this.bilanEditing = bilanEditing;
    },
    setRequiredFieldsFilled(fieldsFilled: boolean) {
      this.requiredFieldsFilled = fieldsFilled;
    },
    setSubmitButtonDisabled(isDisabled: boolean) {
      this.isSubmitButtonDisabled = isDisabled;
    },
    updateSynchronizableCall(call: CallSynchronizableData) {
      this.synchronizableCall = call;
    },
  }));

  React.useEffect(
    () => {
      if (!uuid) {
        callStore.createNewCall().then(localStore.setSynchronizableCall);
      } else {
        callStore.getCall(uuid).then(localStore.setSynchronizableCall);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid]
  );

  // Change required fields filled indicator
  React.useEffect(
    () =>
      autorun(() => {
        if (localStore.callForm?.callType.value === CallType.EMERGENCY) {
          localStore.setRequiredFieldsFilled(
            !localStore.callForm?.callType.isEmpty &&
              !localStore.callForm?.locationDepartment.isEmpty &&
              !localStore.callForm?.locationCity.isEmpty &&
              !localStore.callForm?.callerPhone.isEmpty
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Changement du status de l'appel en fonction de son type
  React.useEffect(
    () =>
      reaction(
        () => localStore.callForm?.callType.value,
        () => {
          if (
            [CallType.TRANSFER, CallType.INFO, CallType.HARMFUL].includes(
              localStore.callForm?.callType.value
            )
          ) {
            localStore.callForm?.status.setValue(CallStatus.REGULATED);
          } else {
            localStore.callForm?.status.setValue(CallStatus.TO_REGULATE);
          }
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Re-init dialog state on leaving page
  React.useEffect(
    () => () => {
      callUIStore.closeConfirmSaveDialog();
      permanentAlgoStore.resetAlgo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = action((disableDialog = false) => {
    if (!localStore.synchronizableCall) {
      throw new Error('No call in history!');
    }
    localStore.setSubmitButtonDisabled(true);
    localStore.callForm.validate();
    if (localStore.callForm.isValid) {
      const callData: Call = mapFormDataToCall(localStore.synchronizableCall, localStore.callForm);
      callStore
        .permanentSaveCall({ ...localStore.synchronizableCall, data: callData }, disableDialog)
        .then(() => {
          uiStore.pushSuccessNotification(`Appel ${uuid ? 'modifié' : 'créé'} avec succès`);
          deactivatePromptRef.current = true;
          history.push('/appels');
        })
        .catch(
          action((error) => {
            localStore.setSubmitButtonDisabled(false);
            if (error instanceof NeedConfirmError) {
              return;
            }
            if (error instanceof NeedTransferFacilitiesError) {
              uiStore.pushNotification(
                'Les établissements de transfert doivent être renseignés',
                NotificationType.WARNING
              );
              return;
            }
            if (error instanceof CallPriorityRequiredError) {
              uiStore.pushNotification(
                "L'identification d'une priorité est obligatoire",
                NotificationType.WARNING
              );
              return;
            }
            uiStore.pushNotification("Echec de la création de l'appel", NotificationType.ERROR);
          })
        );
    } else {
      localStore.setSubmitButtonDisabled(false);
    }
  });

  const handleChangeAgeRange = action((e: React.ChangeEvent<any>) => {
    localStore.callForm.victimAge.setValue(''); //clear
  });

  const handleChangeAge = action((e: React.ChangeEvent<any>) => {
    const victimAgeSlice: VictimAge = convertAgeToAgeSlice(e.target.value);
    localStore.callForm.victimAgeRange.setValue(victimAgeSlice);
  });

  const handleSelectMotif = action((callMotif: CallMotif) => {
    if (localStore.synchronizableCall?.data) {
      localStore.updateSynchronizableCall({
        ...localStore.synchronizableCall,
        data: {
          ...localStore.synchronizableCall.data,
          motif: callMotif,
        },
      });
    }
  });

  return (
    <>
      <Observer>
        {() => (
          <Prompt
            message={() => {
              return localStore.callForm.isTouched && !deactivatePromptRef.current
                ? 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
                : true;
            }}
          />
        )}
      </Observer>
      <Grid container justify="center" spacing={2}>
        {/* PAGE TITLE */}
        <Observer>
          {() => (
            <PageTitle
              title="Nouvelle fiche d'appel"
              callStatus={localStore.callForm.status.value}
              callNumber={localStore.callForm.callNumber.value}
            />
          )}
        </Observer>
        {/* TYPE APPEL */}
        <Grid container item xs={12} sm={10} lg={7} spacing={1}>
          <Grid container item>
            <InfoBloc>
              <Observer>
                {() => (
                  <CallTypeFormFields
                    callTypeInputProps={getInputFieldProps(localStore.callForm.callType)}
                    purposeInputProps={getInputFieldProps(localStore.callForm.purpose)}
                  />
                )}
              </Observer>
            </InfoBloc>
          </Grid>

          <Grid item>
            <InfoBloc>
              {/* APPELANT */}
              <Observer>
                {() => (
                  <CallerFormFields
                    editing={true}
                    callerIsVictimInputProps={getSwitchFieldProps(
                      localStore.callForm.callerIsVictim
                    )}
                    callerFirstNameInputProps={getInputFieldProps(
                      localStore.callForm.callerFirstName
                    )}
                    callerLastNameInputProps={getInputFieldProps(
                      localStore.callForm.callerLastName
                    )}
                    callerPhoneInputProps={getInputFieldProps(
                      localStore.callForm.callerPhone,
                      {},
                      { formatter: formatPhoneNumber }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* VICTIME */}
              <Observer>
                {() => (
                  <VictimFormFields
                    editing={true}
                    formMode={localStore.callForm.mode}
                    callerIsVictim={localStore.callForm.callerIsVictim.value}
                    victimFirstNameInputProps={getInputFieldProps(
                      localStore.callForm.victimFirstName
                    )}
                    victimLastNameInputProps={getInputFieldProps(
                      localStore.callForm.victimLastName
                    )}
                    victimSexInputProps={getInputFieldProps(localStore.callForm.victimSex)}
                    victimAgeRangeInputProps={getInputFieldProps(
                      localStore.callForm.victimAgeRange,
                      {
                        onChange: handleChangeAgeRange,
                      }
                    )}
                    victimAgeInputProps={getInputFieldProps(
                      localStore.callForm.victimAge,
                      {
                        onChange: handleChangeAge,
                      },
                      { formatter: formatNumberInput(3) }
                    )}
                  />
                )}
              </Observer>
              <Divider />
              {/* LOCALISATION */}
              <Observer>
                {() => (
                  <LocationFormFields
                    editing={true}
                    locationDepartmentInputProps={getInputFieldProps(
                      localStore.callForm.locationDepartment
                    )}
                    locationStreetInputProps={getInputFieldProps(
                      localStore.callForm.locationStreet
                    )}
                    locationCityInputProps={getInputFieldProps(localStore.callForm.locationCity)}
                    locationSquareInputProps={getInputFieldProps(
                      localStore.callForm.locationSquare
                    )}
                    locationAdditionalInfosInputProps={getInputFieldProps(
                      localStore.callForm.locationAdditionalInfos
                    )}
                    currentCallType={localStore.callForm.callType.value}
                  />
                )}
              </Observer>
            </InfoBloc>
          </Grid>
        </Grid>

        {/* RIGHT COLUMN */}
        <Grid container item xs={12} lg={5} direction="column">
          <Observer>
            {() =>
              localStore.synchronizableCall?.data?.motif ? (
                <>
                  <Grid item>
                    <Paper style={{ padding: 8 }} elevation={0}>
                      <Grid container justify="space-between" item xs={12}>
                        <Typography variant="button">Raison de l'appel: </Typography>
                        <Typography>
                          {t(`calls.motif.${localStore.synchronizableCall?.data?.motif}`)}
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Spacer direction="vertical" size="0.5rem" />
                </>
              ) : (
                <></>
              )
            }
          </Observer>
          <Observer>
            {() => (
              <PermanentAlgoDispatcher
                permanentAlgoStore={permanentAlgoStore}
                callType={localStore.callForm.callType.value}
                transferFacilities={{
                  transferFromFacility: localStore.callForm.transferFromFacility.value,
                  transferToFacility: localStore.callForm.transferToFacility.value,
                }}
                callResult={
                  localStore.synchronizableCall
                    ? toPermanentAlgoRunResult(localStore.synchronizableCall.data)
                    : undefined
                }
                algoAvailable={localStore.requiredFieldsFilled}
                onMotifSelection={handleSelectMotif}
              />
            )}
          </Observer>
          <Spacer direction="vertical" size="0.5rem" />
          <Grid>
            <Observer>
              {() => (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => handleSubmit()}
                  fullWidth
                  disabled={localStore.isSubmitButtonDisabled}
                >
                  Valider la fiche
                </Button>
              )}
            </Observer>
          </Grid>
        </Grid>
      </Grid>
      <Observer>
        {() => (
          <CallConfirmSaveDialog
            open={callUIStore.confirmSaveDialogOpen}
            onClickSave={() => {
              handleSubmit(true);
            }}
            onClose={() => {
              callUIStore.closeConfirmSaveDialog();
            }}
          />
        )}
      </Observer>
    </>
  );
};

const InfoBloc = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}))((props: PaperProps) => (
  <Paper classes={props.classes} elevation={0}>
    {props.children}
  </Paper>
));

export default CallPreRegulationPermanentPage;
