import { CallSynchronizableData } from 'model/Call';
import { observable, action } from 'mobx';
import { UINurseAlgoRunResult } from 'model/algo/nurse/UINurseAlgoRunResult';

interface WithDiagnosticCallSynchronizableData {
  synchronizableCall: CallSynchronizableData;
  algoRunResult: UINurseAlgoRunResult;
}
export class NurseCallStore {
  @observable pendingCall?: WithDiagnosticCallSynchronizableData;

  @action('NURSE_CALL_SET')
  setPendingCall(call: WithDiagnosticCallSynchronizableData) {
    this.pendingCall = call;
  }

  @action('NURSE_CALL_UNSET')
  unsetPendingCall() {
    this.pendingCall = undefined;
  }
}
export const nurseCallStore = new NurseCallStore();
