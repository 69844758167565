import { Loader } from 'components/common/Loader';
import MainLayout from 'layout/MainLayout';
import { Observer, observer } from 'mobx-react-lite';
import { Permission_V15, Profile } from 'model/User';
import CreateUserPage from 'pages/admin/users/user.create.page';
import DetailUserPage from 'pages/admin/users/user.edit.page';
import UsersPage from 'pages/admin/users/user.list.page';
import CallListPage from 'pages/calls/call.list.page';
import CallSearchPage from 'pages/calls/call.search.page';
import CallPostRegulationPage from 'pages/calls/PostRegulation/call.postRegulation.page';
import CallPreRegulationRouter from 'pages/calls/PreRegulation/call.preRegulation.router';
import { SelectFacilityPage } from 'pages/calls/SelectFacility/SelectFacility.page';
import CallPostRegulationSelectFacilityPage from 'pages/calls/PostRegulation/call.selectFacility.page';
import { EventDashboardPage } from 'pages/events/dashboard/event.dashboard.page';
import EventCreatePage from 'pages/events/event.create.page';
import EventDetailsPage from 'pages/events/event.details.page';
import EventListPage from 'pages/events/event.list.page';
import VictimSearchDetailsPage from 'pages/events/victims/search/victim.search.details.page';
import VictimSearchPage from 'pages/events/victims/search/victim.search.page';
import VictimDetailsPage from 'pages/events/victims/victim.details.page';
import VictimAlgorithmPage from 'pages/events/victims/victim.regulate.page';
import { EventExportPage } from 'pages/events/event.export.page';
import { SelectFacilityPage as VictimSelectFacilityPage } from 'pages/events/victims/victim.selectFacility.page';
import { FacilityDashboardPage } from 'pages/facilities/dashboard/facility.dashboard.page';
import FacilityListPage from 'pages/facilities/facility.list.page';
import LoginPage from 'pages/login/login.page';
import NoAuthorizationPage from 'pages/NoAuthorizationPage';
import PasswordResetConfirmPage from 'pages/password/confirm/PasswordResetConfirm.page';
import PasswordResetRequestPage from 'pages/password/request/passwordResetRequest.page';
import { AccountPage } from 'pages/account/account.page';
import { ReportsPage } from 'pages/reports/reports.page';
import React, { FC } from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { nurseAlgoStore } from 'stores/algo/nurse/NurseAlgo.store';
import { permanentAlgoStore } from 'stores/algo/permanent';
import { authStore } from 'stores/auth/auth.store';
import { permissionsStore } from 'stores/auth/permissions.store';
import { facilityStore } from 'stores/facility';
import { locationStore } from 'stores/location.store';
import { FacilityDetailsPage } from 'pages/facilities/facility.details.page';
import { FacilityCreatePage } from 'pages/facilities/facility.create.page';

function isMandatoryDataLoaded() {
  return (
    locationStore.dataLoaded &&
    permanentAlgoStore.algoLoaded &&
    facilityStore.dataLoaded &&
    nurseAlgoStore.algoLoaded &&
    permissionsStore.dataLoaded
  );
}
export interface ProtectedRouteProps extends RouteProps {
  isAuthorized?: boolean;
}

const LOGIN_PATH = '/login';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
  ({ isAuthorized = true, ...props }) => {
    const currentLocation = useLocation();
    const isAuthenticated = authStore.isAuthenticated;

    if ((!isAuthenticated || !isAuthorized) && LOGIN_PATH !== currentLocation.pathname) {
      return (
        <Redirect
          to={{
            pathname: LOGIN_PATH,
            state: { redirectPathOnAuthentication: currentLocation.pathname },
          }}
        />
      );
    } else {
      return isMandatoryDataLoaded() ? <Route {...props} /> : <Loader />;
    }
  }
);

const ConnectedRoutes: FC = () => {
  useBeforeUnload(
    true,
    'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
  );

  return (
    <MainLayout>
      <Observer>
        {() => (
          <Switch>
            <ProtectedRoute
              exact
              path="/appels"
              component={CallListPage}
              isAuthorized={
                authStore.hasPermission(Permission_V15.CALLS_HOME) ||
                authStore.hasPermission(Permission_V15.CALLS_EXPORT_MULTI) ||
                authStore.hasPermission(Permission_V15.CALLS_SEARCH)
              }
            />
            <ProtectedRoute
              exact
              path="/etablissements"
              component={FacilityListPage}
              isAuthorized={authStore.hasPermission(Permission_V15.FACILITIES_SEARCH)}
            />
            <ProtectedRoute
              exact
              path="/etablissements/nouveau"
              component={FacilityCreatePage}
              isAuthorized={authStore.hasPermission(Permission_V15.FACILITIES_ADMIN)}
            />
            <ProtectedRoute
              exact
              path="/etablissements/:facilityId/dashboard"
              component={FacilityDashboardPage}
              isAuthorized={authStore.hasPermission(Permission_V15.FACILITIES_DASHBOARD)}
            />
            <ProtectedRoute
              exact
              path="/etablissements/:facilityId/consultation"
              component={FacilityDetailsPage}
              isAuthorized={
                authStore.hasPermission(Permission_V15.FACILITIES_SEARCH) ||
                authStore.hasPermission(Permission_V15.FACILITIES_ADMIN)
              }
            />
            <ProtectedRoute
              exact
              path="/evenements"
              component={EventListPage}
              isAuthorized={authStore.hasPermission(Permission_V15.EVENTS_SEARCH)}
            />
            <ProtectedRoute
              exact
              path="/evenements/nouveau"
              component={EventCreatePage}
              isAuthorized={authStore.hasPermission(Permission_V15.EVENTS_CREATE)}
            />
            <ProtectedRoute
              exact
              path="/evenements/consultation"
              component={EventDetailsPage}
              isAuthorized={authStore.hasPermission(Permission_V15.EVENTS_SEARCH)}
            />
            <ProtectedRoute
              exact
              path="/evenements/export"
              component={EventExportPage}
              isAuthorized={authStore.hasPermission(Permission_V15.EVENTS_EXPORT_MULTI)}
            />
            <ProtectedRoute
              exact
              path="/evenements/victimes/nouveau"
              component={VictimDetailsPage}
              isAuthorized={authStore.hasPermission(Permission_V15.VICTIMS_CREATE)}
            />
            <ProtectedRoute
              exact
              path="/evenements/victimes/consultation"
              component={VictimDetailsPage}
              isAuthorized={authStore.hasPermission(
                Permission_V15.VICTIMS_SEARCH || Permission_V15.VICTIMS_UPDATE
              )}
            />
            <ProtectedRoute
              exact
              path="/evenements/victimes/regulation"
              component={VictimAlgorithmPage}
              isAuthorized={authStore.hasPermission(Permission_V15.VICTIMS_REGULATION_IDE)}
            />
            <ProtectedRoute
              exact
              path={'/evenements/victimes/regulation/etablissements'}
              component={VictimSelectFacilityPage}
              isAuthorized={authStore.hasPermission(Permission_V15.VICTIMS_REGULATION_IDE)}
            />
            <ProtectedRoute
              exact
              path="/evenements/:eventUuid/dashboard"
              component={EventDashboardPage}
              isAuthorized={authStore.hasPermission(Permission_V15.EVENTS_DASHBOARD)}
            />
            <ProtectedRoute
              exact
              path="/victimes/consultation"
              component={VictimSearchDetailsPage}
              isAuthorized={authStore.hasPermission(Permission_V15.VICTIMS_SEARCH)}
            />
            <ProtectedRoute
              exact
              path="/victimes/recherche/:code"
              component={VictimSearchPage}
              isAuthorized={authStore.hasPermission(Permission_V15.VICTIMS_SEARCH)}
            />

            <ProtectedRoute
              exact
              path="/appels/recherche"
              component={CallSearchPage}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_SEARCH)}
            />
            <ProtectedRoute
              exact
              path={['/appels/nouveau']}
              component={CallPreRegulationRouter}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_CREATE)}
            />
            <ProtectedRoute
              exact
              path="/appels/preRegulation/:uuid"
              component={CallPreRegulationRouter}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_UPDATE)}
            />
            <ProtectedRoute
              exact
              path="/appels/postRegulation/:uuid"
              component={CallPostRegulationPage}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_UPDATE)}
            />
            <ProtectedRoute
              exact
              path="/appels/postRegulation/:uuid/etablissements"
              component={CallPostRegulationSelectFacilityPage}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_UPDATE)}
            />
            <ProtectedRoute
              exact
              path={'/appels/etablissements'}
              component={SelectFacilityPage}
              isAuthorized={authStore.hasProfile(Profile.INFIRMIER)}
            />
            <ProtectedRoute
              exact
              path="/admin/utilisateurs"
              component={UsersPage}
              isAuthorized={authStore.hasPermission(Permission_V15.ADMIN_USERS_SEARCH)}
            />
            <ProtectedRoute
              exact
              path="/admin/utilisateurs/nouveau"
              component={CreateUserPage}
              isAuthorized={authStore.hasPermission(Permission_V15.ADMIN_USERS_CREATE)}
            />
            <ProtectedRoute
              exact
              path="/admin/utilisateurs/:username"
              component={DetailUserPage}
              isAuthorized={authStore.hasPermission(Permission_V15.ADMIN_USERS_UPDATE)}
            />
            <ProtectedRoute
              exact
              path="/reports"
              component={ReportsPage}
              isAuthorized={authStore.hasPermission(Permission_V15.CALLS_EXPORT_MULTI)}
            />
            <ProtectedRoute exact path="/account" component={AccountPage} />
            <Route exact path="/notauthorized" component={NoAuthorizationPage} />
            <Redirect from="*" to={redirectUserTo()} />
          </Switch>
        )}
      </Observer>
    </MainLayout>
  );
};

export const redirectUserTo = () => {
  let redirectionPath = '/appels';
  if (authStore.hasProfile(Profile.ADMIN)) {
    redirectionPath = '/admin/utilisateurs';
  } else if (
    authStore.hasProfile(Profile.PARAMEDIC) ||
    authStore.hasProfile(Profile.FACILITY_PERSONAL)
  ) {
    redirectionPath = '/evenements';
  }
  return redirectionPath;
};

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={LOGIN_PATH} component={LoginPage} />
        <Route exact path="/password-reset/request" component={PasswordResetRequestPage} />
        <Route
          exact
          path="/password-reset/confirm/:username"
          component={PasswordResetConfirmPage}
        />
        <Route component={ConnectedRoutes} />
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
