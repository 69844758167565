import { action, extendObservable, observable } from 'mobx';
import { Profile } from 'model/User';
import {
  Field,
  fieldsAggregatorBuilder,
  FORM_MODE_CONSULT,
  FORM_MODE_CREATE,
  FORM_VALID_ON_BLUR,
  FORM_VALID_ON_CHANGE,
} from 'utils/forms';
import { isEmail, isRequired, maxLength } from 'utils/forms/validators';

export default function userFormBuilder({ initialValues = null, mode = FORM_MODE_CREATE }) {
  const fields = {
    username: new Field({
      initValue: (initialValues && initialValues.username) || undefined,
      disabled: true,
    }),
    lastName: new Field({
      initValue: (initialValues && initialValues.lastName) || undefined,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired, maxLength(32)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    firstName: new Field({
      initValue: (initialValues && initialValues.firstName) || '',
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired, maxLength(32)],
      validateOn: [FORM_VALID_ON_BLUR],
      label: '',
    }),
    email: new Field({
      initValue: (initialValues && initialValues.email) || '',
      validate: [
        (val, label) =>
          fields.phone.isEmpty
            ? isRequired(val, label)
            : {
                valid: true,
              },
        isEmail,
      ],
      validateOn: [FORM_VALID_ON_BLUR],
      disabled: mode === FORM_MODE_CONSULT,
      label: 'Email',
    }),
    phone: new Field({
      initValue: (initialValues && initialValues.phone) || '',
      validate: [
        (val, label) =>
          fields.email.isEmpty
            ? isRequired(val, label)
            : {
                valid: true,
              },
        maxLength(8),
      ],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      disabled: mode === FORM_MODE_CONSULT,
      label: '',
    }),
    profile: new Field({
      initValue: (initialValues && initialValues.profile) || Profile.PERMANENCIER,
      disabled: mode === FORM_MODE_CONSULT,
      validate: [isRequired],
      validateOn: [FORM_VALID_ON_BLUR, FORM_VALID_ON_CHANGE],
      label: '',
    }),
    enabled: new Field({
      initValue: initialValues && !!initialValues.enabled,
      validateOn: [FORM_VALID_ON_CHANGE],
      label: '',
    }),
    permissions: new Field({
      initValue: (initialValues && initialValues.permissions) || [],
      disabled: mode === FORM_MODE_CONSULT,
      validateOn: [FORM_VALID_ON_CHANGE],
      label: '',
    }),
    facilities: new Field({
      initValue: (initialValues && initialValues.facilities) || [],
      disabled: mode === FORM_MODE_CONSULT,
      validate: [
        (val, label) =>
          fields.profile.value === Profile.FACILITY_PERSONAL
            ? isRequired(val, label)
            : {
                valid: true,
              },
      ],
      validateOn: [FORM_VALID_ON_CHANGE],
      label: '',
    }),
  };
  const aggregatedFields = fieldsAggregatorBuilder({
    fields,
  });
  return extendObservable(
    aggregatedFields,
    {
      mode: mode,
      setMode(mode) {
        this.mode = mode;
      },
    },
    {
      mode: observable,
      setMode: action,
    }
  );
}
