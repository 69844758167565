import { Divider } from '@material-ui/core';
import { AlgoContainer } from 'components/algorithm/AlgoContainer';
import { QuestionContainer } from 'components/algorithm/Question/QuestionContainer';
import { UIPermanentAlgoRunResult } from 'model/algo/permanent';
import React, { FC, ReactNode } from 'react';
import { PermanentAlgoResultAdvices } from './PermanentAlgoResultAdvices';
import { PermanentAlgoResultReasons } from './PermanentAlgoResultReasons';
import { PermanentAlgoResultTitle } from './PermanentAlgoResultTitle';

interface PermanentAlgoResultProps {
  algoRunResult: UIPermanentAlgoRunResult;
  actionButton: ReactNode;
}
export const PermanentAlgoResult: FC<PermanentAlgoResultProps> = ({
  algoRunResult: { priority, reasons, advices },
  actionButton,
}) => {
  return (
    <AlgoContainer
      renderTitle={priority ? <PermanentAlgoResultTitle priority={priority} /> : undefined}
      renderActions={actionButton}
    >
      <QuestionContainer>
        {reasons.length > 0 && (
          <PermanentAlgoResultReasons
            reasons={reasons.map((reason) => reason.value).filter(Boolean) as string[]}
          />
        )}
        <Divider />
        {advices.length > 0 && <PermanentAlgoResultAdvices advices={advices} />}
      </QuestionContainer>
    </AlgoContainer>
  );
};
