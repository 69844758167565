import { action, computed, extendObservable, observable, transaction } from 'mobx';

export function defaultValuesGetter(form, fieldsNames) {
  return fieldsNames.reduce((res, fieldName) => {
    res[fieldName] = form[fieldName].value;
    return res;
  }, {});
}

export function fieldsAggregatorBuilder({
  fields,
  data,
  dataMapper = (data) => data,
  valuesGetter = defaultValuesGetter,
}) {
  if (typeof fields === 'function') {
    fields = fields();
  }
  const fieldsNames = Object.keys(fields);
  const allFields = fieldsNames.map((fieldName) => fields[fieldName]);

  const obj = observable(
    {
      ...fields,
      allFields,
      setPristine() {
        this.allFields.forEach((field) => field.markAsPristine());
      },
      setTouched() {
        this.allFields.forEach((field) => field.markAsTouch());
      },
      updateData(newData) {
        if (!newData) return;
        const mappedData = dataMapper(newData);
        fieldsNames.forEach((fieldName) => {
          this[fieldName].setValue(mappedData[fieldName], false);
        });
      },
      validate() {
        this.allFields.forEach((field) => field.validate());
      },
      reset() {
        transaction(() =>
          this.allFields.forEach((field) => {
            field.reset();
            field.markAsPristine();
          })
        );
      },
    },
    {
      setPristine: action('FORM_SET_PRISTINE'),
      setTouched: action('FORM_SET_TOUCHED'),
      updateData: action('FORM_UPDATE_DATA'),
      validate: action('FORM_VALIDATE_FIELDS'),
      reset: action('FORM_RESET_FIELDS'),
    },
    { deep: false }
  );

  extendObservable(
    obj,
    {
      get isValid() {
        return obj.allFields.every((field) => field.isValid);
      },
      get values() {
        return valuesGetter(obj, fieldsNames);
      },
      get isTouched() {
        return !!obj.allFields.filter((field) => field.isTouched).length;
      },
    },
    {
      isValid: computed,
      values: computed,
      isTouched: computed,
    }
  );

  const fieldsAggregator = obj;
  if (data) {
    fieldsAggregator.updateData(data);
  }

  return fieldsAggregator;
}
