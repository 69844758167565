import { SearchCallProps } from 'api/call.api';
import { SearchEventProps } from 'api/event.api';
import { callApi, eventApi } from 'api';
import { saveAs } from 'file-saver';

type exportFilters = Pick<SearchCallProps, 'userCreationDateStart' | 'userCreationDateEnd'>;

export async function saveCallExportAs(filters: Partial<exportFilters>) {
  const csv = await callApi.exportCalls(filters);
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  return saveAs(csvBlob, 'export.csv');
}

export async function saveEventExportAs(
  filters: Partial<Pick<SearchEventProps, 'userCreationDateStart' | 'userCreationDateEnd'>>
) {
  const csv = await eventApi.exportEvents(filters);
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  return saveAs(csvBlob, 'export.csv');
}
