import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { Button } from '@material-ui/core';

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

const ApplicationInstallBanner: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState<
    BeforeInstallPromptEvent | undefined
  >();

  const beforeInstallPrompt = (event: any) => {
    console.debug('beforeInstallPrompt event captured');
    event.preventDefault();
    setDeferredPrompt(event);
  };

  React.useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt, true);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt, true);
    };
  }, []);

  React.useEffect(() => {
    console.debug(isStandalone());
    setOpen(!isStandalone());
  }, [setOpen]);

  const handleClickInstall = () => {
    // Hide the app provided install promotion
    setOpen(false);
    // Show the install prompt
    deferredPrompt?.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt?.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  return (
    <>
      {isOpen && (
        <Alert
          variant="filled"
          severity="warning"
          action={
            <Button variant="outlined" color="inherit" onClick={handleClickInstall}>
              Installer
            </Button>
          }
        >
          <AlertTitle>Attention</AlertTitle>
          Pour une utilisation optimale, merci de procéder à l'installation de l'application
        </Alert>
      )}
    </>
  );
};

const isStandalone = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export default ApplicationInstallBanner;
