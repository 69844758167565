import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface ConfirmationDialogProps {
  title?: string;
  message?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onConfirmLabel?: string;
  onCancelLabel?: string;
  fullScreen?: boolean;
  modal?: boolean;
}
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title = '',
  message = '',
  onCancel,
  onConfirm,
  onConfirmLabel = 'Confirmer',
  onCancelLabel = 'Annuler',
  modal = true,
  fullScreen = false,
}) => {
  // const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleConfirm = () => {
    // setOpen(false);
    if (onConfirm) onConfirm();
  };

  const handleCancel = () => {
    // setOpen(false);
    if (onCancel) onCancel();
  };
  return (
    <Dialog
      disableBackdropClick={modal}
      disableEscapeKeyDown={modal}
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {onCancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {onConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.up('sm')]: {
        minWidth: 400,
        minHeight: 250,
      },
    },
  })
);

export default ConfirmationDialog;
