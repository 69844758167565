import axios from 'axios';
import { PermanentAlgoPriority } from 'model/algo/permanent';
import Call, { CallStatus, CallType, RegulationMeans } from 'model/Call';
import { SeverityDegree } from 'model/Diagnostic';
import Facility from 'model/facilities/Facility';
import { IPageResponse } from 'model/IPageResponse';
import IPaginate from 'model/IPaginate';
import ISearch from 'model/ISearch';

export type UpdateAlgo = { updatePermanentAlgo: boolean; updateNurseAlgo: boolean };

function formatCallData(call: Call, { updatePermanentAlgo, updateNurseAlgo }: UpdateAlgo): unknown {
  const { transferFromFacility, transferToFacility, ...rest } = call;

  const formattedCall = {
    ...rest,
    transferFromFacilityId: transferFromFacility,
    transferToFacilityId: transferToFacility,
    upadtePermanentAlgo: updatePermanentAlgo,
    updateNurseAlgo,
  };
  delete formattedCall.diagnostic;
  delete formattedCall.facility;
  return formattedCall;
}

export async function createCall(callData: Call, updateAlgo: UpdateAlgo): Promise<Call> {
  return axios
    .post(`/api/calls`, formatCallData(callData, updateAlgo))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function updateCall(
  callId: string,
  callData: Call,
  updateAlgo: UpdateAlgo
): Promise<Call> {
  return axios
    .put(`/api/calls/${callId}`, formatCallData(callData, updateAlgo))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function getCall(callId: string): Promise<any> {
  try {
    const [callRes, reasonsRes, advicesRes, responsesRes] = await Promise.all([
      axios.get<Call>(`/api/calls/${callId}`),
      axios.get<IPageResponse<any>>(`/api/calls/${callId}/callReasons`),
      axios.get<IPageResponse<any>>(`/api/calls/${callId}/waitingAdvices`),
      axios.get<IPageResponse<any>>(`/api/calls/${callId}/permanentAlgoResponses`),
    ]);

    const permanentAlgoResponsesIds = responsesRes.data.content.map(
      (response) => response.id
    ) as number[];
    const permanentAlgoCallReasonsIds = reasonsRes.data.content.map(
      (reason) => reason.id
    ) as number[];
    const permanentAlgoWaitingAdvices = advicesRes.data.content.map(
      (advice) => advice.id
    ) as number[];

    return {
      ...callRes.data,
      permanentAlgoResponsesIds,
      permanentAlgoCallReasonsIds,
      permanentAlgoWaitingAdvices,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface SearchCallProps extends IPaginate, ISearch {
  status: CallStatus[];
  priority: PermanentAlgoPriority[];
  callType: CallType[];
  sort: Partial<Record<keyof Call, 'ASC' | 'DESC'>>;
  userCreationDateStart: string;
  userCreationDateEnd: string;
  loadCallReasons?: false;
  diagnosticSeverityIndexes: SeverityDegree[];
  regulationMeans: RegulationMeans[];
  anyDiagnostics: number[];
}

const defaultTypes = [CallType.EMERGENCY, CallType.TRANSFER];

export async function searchCalls(
  search: Partial<SearchCallProps> = {
    pageIndex: 0,
    pageSize: 10,
    keywords: '',
    status: [],
    priority: [],
    callType: defaultTypes,
    sort: { userCreationDate: 'ASC', priority: 'DESC' },
  }
): Promise<IPageResponse<Call>> {
  return axios
    .post('/api/calls/search', { ...search, loadCallReasons: true })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getCalls() {
  return searchCalls({
    pageIndex: 0,
    pageSize: 50,
    keywords: '',
    status: [CallStatus.TO_REGULATE],
    priority: [],
    callType: defaultTypes,
    sort: { userCreationDate: 'ASC', priority: 'DESC' },
  });
}

export async function exportCalls(search: Partial<SearchCallProps>) {
  const filters = {
    loadCallReasons: true,
    sort: { userCreationDate: 'ASC', priority: 'DESC' },
    ...search,
  };
  return axios
    .post('/api/calls/export', filters)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

/**
 * Save "bilan"
 */
export async function saveCallOutcome(callUuid: string, outcome: string) {
  return axios
    .put(`/api/calls/${callUuid}/results`, outcome, {
      headers: { 'Content-Type': 'text/plain' },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function countCalls({
  userCreationDateStart,
  userCreationDateEnd,
  callType = [],
  status = [],
  diagnosticSeverityIndexes = [],
  regulationMeans = [],
  anyDiagnostics = [],
}: Partial<SearchCallProps>): Promise<number> {
  const filters = {
    loadCallReasons: false,
    userCreationDateStart,
    userCreationDateEnd,
    callType,
    status,
    diagnosticSeverityIndexes,
    regulationMeans,
    anyDiagnostics,
  };
  return axios
    .post('/api/calls/count', filters)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export enum Graphic {
  TYPES_APPUI = 'TYPES_APPUI',
  MOTIFS_REGULES = 'MOTIFS_REGULES',
  MOTIFS_REGULES_FEMMES = 'MOTIFS_REGULES_FEMMES',
  MOTIFS_REGULES_HOMMES = 'MOTIFS_REGULES_HOMMES',
  MOTIFS_REGULES_ENFANTS_0_15 = 'MOTIFS_REGULES_ENFANTS_0_15',
  CAUSES_TRAUMA = 'CAUSES_TRAUMA',
  TRANSFERS_BY_FACILITIES = 'TRANSFERS_BY_FACILITIES',
}

export async function getCallStatistics({
  graphic,
  userCreationDateStart,
  userCreationDateEnd,
}: {
  graphic: Graphic;
  userCreationDateStart?: string;
  userCreationDateEnd?: string;
}): Promise<CallItemStatistics> {
  return axios
    .post('api/callsStatistics/data', { graphic, userCreationDateStart, userCreationDateEnd })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export async function getFacilityTranferStatistics({
  userCreationDateStart,
  userCreationDateEnd,
}: {
  userCreationDateStart?: string;
  userCreationDateEnd?: string;
}): Promise<FacilityTransferStatistics[]> {
  return axios
    .post('api/callsStatistics/facilityTransfer', {
      graphic: Graphic.TRANSFERS_BY_FACILITIES,
      userCreationDateStart,
      userCreationDateEnd,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export interface CallItemStatistics {
  search: any;
  total: number;
  details: Record<string, number>;
}

export interface FacilityTransferStatistics {
  facility: Facility;
  totalOrigine: number;
  totalDestination: number;
}
